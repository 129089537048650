import { Box } from "@mui/material";
import CountryFlag from "components/CountryFlag/CountryFlag";
import getSymbolFromCurrency from "currency-symbol-map";
import React from "react";
import { SelectedCurrency } from "utils/currency";

type AllowedCurrenciesListProps = {
  selectedCurrencies: SelectedCurrency[];
};
const AllowedCurrenciesList: React.FC<AllowedCurrenciesListProps> = ({ selectedCurrencies }) => {
  return (
    <Box display="flex" flexDirection="column" gap={1} mt={2}>
      {selectedCurrencies.map((item) => (
        <Box key={item.currency}>
          <CountryFlag countryCode={item.countryCode} />
          &nbsp;{item.currency}&nbsp;({getSymbolFromCurrency(item.currency)})
          <Box display="inline" pl={1}>
            [Margin: {item.margin}%]
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default AllowedCurrenciesList;
