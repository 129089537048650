import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";
import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

type MonetCardProps = PropsWithChildren &
  VariantProps<typeof variants> & {
    className?: string;
  };

const MonetCard: React.FC<MonetCardProps> = ({ children, className, variant }) => {
  return <div className={twMerge(clsx(variants({ variant }), className))}>{children}</div>;
};

export default MonetCard;

const variants = cva("flex flex-col gap-4 bg-white overflow-hidden", {
  variants: {
    variant: {
      styled: "border border-gray-200 rounded-lg shadow-sm p-4",
      unstyled: undefined,
    },
  },
  defaultVariants: {
    variant: "styled",
  },
});
