/**
 * Splits a camel case string into separate words.
 *
 * @param {string} str - The camel case string to be split.
 * @returns {string} - The split string with words separated by spaces.
 */
export const splitCamelCaseString = (str: string): string => {
  const sentenceCase = str
    // Look for long acronyms and filter out the last letter
    .replace(/([A-Z]+)([A-Z][a-z])/g, " $1 $2")
    // Look for lower-case letters followed by upper-case letters
    .replace(/([a-z\d])([A-Z])/g, "$1 $2")
    // Look for lower-case letters followed by numbers
    .replace(/([a-zA-Z])(\d)/g, "$1 $2")
    .replace(/^./, (letter: string) => {
      return letter.toUpperCase();
    })
    // Remove any white space left around the word
    .trim();

  // Capitalize the first letter and remove leading spaces
  return sentenceCase.charAt(0).toUpperCase() + sentenceCase.slice(1).trim().toLowerCase();
};

export const AGGREGATE_DATE_FORMAT = "MMM YYYY";

export const removeCommas = (input: string | undefined): string | undefined => {
  if (!input) return undefined;
  return input.replace(/,/g, "");
};
