import Graphic from "assets/tailwind/auth-layout-graphic.png";
import { ReactComponent as MonetLogo } from "assets/tailwind/monet-logo.svg";
import { PropsWithChildren } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { AUTH_PATH, SIGN_IN_PATH } from "views/auth/AuthPath";
import MonetLink from "../MonetLink";

type AuthViewProps = PropsWithChildren & {
  helmet: string;
};

const AuthViewLayout: React.FC<AuthViewProps> = ({ children, helmet }) => {
  return (
    <>
      <Helmet>
        <title>{helmet} - MONET</title>
      </Helmet>
      <main className="flex min-h-screen lg:h-screen bg-gray-50 p-4 dark:bg-neutral-950">
        {/* Sidebar */}
        <div className="hidden lg:w-[500px] lg:flex flex-col justify-between p-4 pr-8 gap-4">
          {/* Header */}
          {/* Logo */}
          <div className="text-monet-blue dark:text-white">
            <Link to={AUTH_PATH + SIGN_IN_PATH}>
              <MonetLogo width={120} />
            </Link>
          </div>
          {/* End Logo */}
          {/* End Header */}
          {/* Body */}
          <div className="flex flex-col p-5">
            <p className="text-3xl font-light text-gray-900 dark:text-white">Your financial co-pilot</p>
            <p>Fast and frictionless finance tools to fuel your agency</p>
            <img src={Graphic} alt="Monet graphic" className="mt-10" />
          </div>
          {/* End Body */}
          {/* Footer */}
          <div className="flex gap-x-8 text-xs text-gray-500 dark:text-white">
            <span>
              © MONET Money Ltd • <MonetLink href="mailto:support@monet.money">Contact us</MonetLink> •{" "}
              <MonetLink href="https://www.linkedin.com/company/monet-money/">LinkedIn</MonetLink>
            </span>
          </div>
          {/* End Footer */}
        </div>
        {/* End Sidebar */}
        {/* Main Content */}
        <div className="flex flex-col grow p-4 gap-10 lg:overflow-y-auto lg:max-h-screen bg-white dark:bg-neutral-800">
          {/* Logo */}
          <div className="lg:hidden text-monet-blue dark:text-white">
            <Link to={AUTH_PATH + SIGN_IN_PATH}>
              <MonetLogo width={120} />
            </Link>
          </div>
          {/* End Logo */}
          {/* Content */}
          <div className="w-full max-w-[448px] flex flex-col grow justify-center mx-auto gap-5 sm:px-5">{children}</div>
          {/* End Content */}
          {/* Footer */}
          <div className="lg:hidden flex gap-x-8 text-xs text-gray-500 dark:text-white">
            <span>
              © MONET Money Ltd • <MonetLink href="mailto:support@monet.money">Contact us</MonetLink> •{" "}
              <MonetLink href="https://www.linkedin.com/company/monet-money/">LinkedIn</MonetLink>
            </span>
          </div>
          {/* End Footer */}
        </div>
        {/* End Main Content */}
      </main>
    </>
  );
};

export default AuthViewLayout;
