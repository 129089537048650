import { useMediaQuery, useTheme } from "@mui/material";
import React, { Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from "react";

export type AppContextValue = {
  isAppMenuOpen: boolean;
  isMobile: boolean;
  setIsAppMenuOpen: Dispatch<SetStateAction<boolean>>;
};

export const AppContext = React.createContext<AppContextValue>({
  isAppMenuOpen: false,
  isMobile: false,
  setIsAppMenuOpen: () => {},
});

export const AppProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isAppMenuOpen, setIsAppMenuOpen] = useState<boolean>(false);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(1024));

  return <AppContext.Provider value={{ isAppMenuOpen, setIsAppMenuOpen, isMobile: !isDesktop }}>{children}</AppContext.Provider>;
};

const useAppProvider = (): AppContextValue => {
  return useContext(AppContext);
};

export default useAppProvider;
