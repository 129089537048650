import { FormControl, Stack, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import GroupedRadioButtons from "components/utils/GroupedRadioButtons";
import { TextField } from "mui-rff";
import React from "react";
import { useFormState } from "react-final-form";
import AgreementTermsUploadField from "../components/AgreementTermsUploadField";
import { termsAndConditionsOptions } from "../utils";
import PageTitle from "./PageTitle";

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(2),
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "flex-end",
  gap: 2,
  maxWidth: "100%",

  [theme.breakpoints.up("md")]: {
    maxWidth: 417, // This is the min width before the radio btns break line
  },
}));

const TermsAndConditions: React.FC = () => {
  const { values } = useFormState();

  const invoiceAssignmentTermsLinkType = values.defaultConfiguration.invoiceAssignmentTerms.linkType;
  const termsAndConditionsLinkType = values.defaultConfiguration.termsAndConditions.linkType;

  return (
    <Box>
      <PageTitle>Agreements / Terms & Conditions</PageTitle>
      <Stack direction="column" spacing={6}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={1} justifyContent="space-between">
          <Box>
            <StyledTypography>Invoice assignment agreement</StyledTypography>
            <Typography maxWidth={{ xs: "100%", md: 250 }}>These are the terms that a partner will agree to when creating a campaign</Typography>
          </Box>
          <StyledBox>
            <GroupedRadioButtons options={termsAndConditionsOptions} name="defaultConfiguration.invoiceAssignmentTerms.linkType" showError={() => false} />
          </StyledBox>
        </Stack>
        {invoiceAssignmentTermsLinkType === "Partner" && (
          <FormControl fullWidth sx={{ mt: 2 }}>
            <AgreementTermsUploadField name="onboardingAgreement" title="Onboarding agreement" />
            <AgreementTermsUploadField name="standardTerms" title="Standard terms" />
            <AgreementTermsUploadField name="earlyPayAgreement" title="EarlyPay agreement" />
          </FormControl>
        )}
        <Stack direction={{ xs: "column", md: "row" }} spacing={1} justifyContent="space-between">
          <Box>
            <StyledTypography>Creator Terms & Conditions</StyledTypography>
            <Typography maxWidth={{ xs: "100%", md: 250 }}>These are the terms that a Creator will agree to when accepting an EarlyPay payout</Typography>
          </Box>
          <StyledBox>
            <GroupedRadioButtons options={termsAndConditionsOptions} name="defaultConfiguration.termsAndConditions.linkType" showError={() => false} />
            {termsAndConditionsLinkType === "Partner" && (
              <FormControl fullWidth sx={{ mt: 2 }}>
                <TextField
                  data-testid="partnerJourney-termsConditions-partnerSpecificField"
                  name="defaultConfiguration.termsAndConditions.link"
                  placeholder="Enter partner specific terms"
                  autoComplete="off"
                />
              </FormControl>
            )}
          </StyledBox>
        </Stack>
      </Stack>
    </Box>
  );
};

export default TermsAndConditions;
