import { Button, TextField as MuiTextField, Stack, Typography, useTheme } from "@mui/material";
import CustomDrawer from "components/CustomDrawer";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { Form, useForm, useFormState } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { CollectionAccount, formatSortCode } from "shared/utils/beneficiaryDetails";
import { SYSTEM_ALLOWED_CURRENCIES } from "shared/utils/currency";
import { AddCollectionAccountSchema } from "shared/utils/validators";
import { makeValidate } from "utils/rffValidation";
import AddEditAccountForm from "./AddEditAccountForm";

const initialAccount: CollectionAccount = {
  accountName: "",
  accountNumber: "",
  bankName: "",
  currency: "",
  iban: "",
  sortCode: "",
  swiftCode: "",
};

const CollectionAccounts = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [selectedAccount, setSelectedAccount] = useState<CollectionAccount>(initialAccount);
  const theme = useTheme();
  const { values } = useFormState();
  const { change } = useForm();

  const { enqueueSnackbar } = useSnackbar();
  const initialValues = useMemo(() => selectedAccount, [selectedAccount]);
  const collectionAccounts: CollectionAccount[] = useMemo(() => values.collectionAccounts || [], [values.collectionAccounts]);

  const allowedCurrencies: string[] = useMemo(() => {
    const currenciesAlreadySelected = [...collectionAccounts].filter((x) => x.currency != selectedAccount.currency).map((x) => x.currency);
    return [...SYSTEM_ALLOWED_CURRENCIES.filter((x) => !currenciesAlreadySelected.includes(x))];
  }, [collectionAccounts, selectedAccount]);

  const validate = makeValidate(AddCollectionAccountSchema);

  const removeAccount = (index: number) => {
    change("collectionAccounts", [...collectionAccounts.filter((x, i) => i != index)]);
  };

  const onSubmit = useCallback(
    async (formData: CollectionAccount) => {
      try {
        setIsOpen(false);
        setIsEdit(false);
        formData.sortCode = formData.sortCode.replace(/-/g, "");

        if (isEdit) {
          change("collectionAccounts", [...collectionAccounts.map((x, i) => (selectedIndex == i ? formData : x))]);
          setSelectedIndex(undefined);
        } else {
          change("collectionAccounts", [...collectionAccounts, formData]);
        }
      } catch (err: any) {
        enqueueSnackbar("Error adding account", {
          variant: "error",
        });
      }
    },
    [collectionAccounts, selectedIndex],
  );
  return (
    <div>
      <Stack mb={2} direction={{ md: "row" }} gap={2} justifyContent="space-between">
        <Typography fontWeight="bold">EarlyPay collection account</Typography>
        <Button
          data-testid="collectionAccounts-addBtn"
          onClick={() => {
            setIsEdit(false);
            setSelectedIndex(undefined);
            setSelectedAccount(initialAccount);
            setIsOpen(true);
          }}
        >
          + Add another
        </Button>
      </Stack>

      {!collectionAccounts.length && <MuiTextField fullWidth disabled sx={{ "& input": { textAlign: "center" } }} placeholder="NO ACCOUNTS ADDED" />}

      <Stack gap={1}>
        <FieldArray name="collectionAccounts">
          {({ fields }) =>
            ((fields.value || []) as CollectionAccount[]).map((x, index) => (
              <Stack direction="row" key={x.currency} gap={2}>
                <Stack
                  direction="row"
                  width="100%"
                  gap={2}
                  p={2}
                  sx={{
                    border: `1px solid ${theme.palette.primary.main}`,
                  }}
                  justifyContent="space-between"
                >
                  <Typography fontWeight="bold">{x.currency} Account</Typography>
                  <Stack direction="row" gap={2}>
                    <Typography>
                      Sort code<strong> {formatSortCode(x.sortCode)}</strong>
                    </Typography>
                    <Typography>
                      Account number<strong> {x.accountNumber}</strong>
                    </Typography>
                  </Stack>
                </Stack>

                <Stack direction="row" gap={2}>
                  <Button
                    data-testid={`collectionAccounts-editBtn-${x.currency}`}
                    color="inherit"
                    onClick={() => {
                      setSelectedIndex(index);
                      setIsEdit(true);
                      setIsOpen(true);
                      setSelectedAccount(x);
                    }}
                  >
                    View/edit
                  </Button>
                  <Button
                    data-testid={`collectionAccounts-removeBtn-${x.currency}`}
                    color="error"
                    onClick={() => {
                      removeAccount(index);
                    }}
                  >
                    Delete
                  </Button>
                </Stack>
              </Stack>
            ))
          }
        </FieldArray>
      </Stack>

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, valid }) => {
          return (
            <CustomDrawer
              title="EarlyPay collection account"
              subTitle="Bank account inforamtion for the client to invoice their vendors with. (These are MONET accounts under the client’s name)"
              submitButtonText="Save"
              onClose={() => {
                setIsOpen(false);
              }}
              open={isOpen}
              submitButtonProps={{
                onClick: handleSubmit,
                color: "primary",
                disabled: !valid,
              }}
            >
              <AddEditAccountForm currencies={allowedCurrencies} isEdit={isEdit} />
            </CustomDrawer>
          );
        }}
      />
    </div>
  );
};

export default CollectionAccounts;
