import { yupResolver } from "@hookform/resolvers/yup";
import { confirmResetPassword } from "aws-amplify/auth";
import MonetAlert, { MonetAlertProps } from "components/tailwind/MonetAlert";
import MonetButton from "components/tailwind/MonetButton";
import MonetAuthTitle from "components/tailwind/auth/MonetAuthTitle";
import MonetPasswordHint from "components/tailwind/form/MonetPasswordHint";
import MonetPasswordInput from "components/tailwind/form/MonetPasswordInput";
import AuthViewLayout from "components/tailwind/templates/AuthViewLayout";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { isObjEmpty } from "utils/objects";
import { AUTH_PATH, SIGN_IN_PATH } from "../AuthPath";
import { SetPasswordFormObject, validationSchema } from "./SetPasswordValidationSchema";

const SetPassword: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [alert, setAlert] = useState<undefined | MonetAlertProps>();

  const username = searchParams.get("username");
  const code = searchParams.get("verificationCode");

  const onSubmit = async (values: SetPasswordFormObject) => {
    setAlert(undefined);

    if (!username || !code) {
      setAlert({
        title: "Error",
        variant: "error",
        message: "The password reset link is broken, please request a new one",
      });
      return;
    }

    try {
      await confirmResetPassword({ username, confirmationCode: code, newPassword: values.new_password });
      setAlert({
        title: "Password changed",
        variant: "success",
        message: "Your password has been changed. You will be redirected to sign in shortly",
      });

      setTimeout(() => {
        navigate(AUTH_PATH + SIGN_IN_PATH);
      }, 5000);
    } catch (err) {
      const e = err as Error;
      setAlert({
        title: "Failed to change password",
        variant: "error",
        message: e?.message,
        error: e,
      });
    }
  };

  const {
    register,
    formState: { errors, isValid, isSubmitting },
    handleSubmit,
  } = useForm<SetPasswordFormObject>({
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
  });

  if (!username || !code) {
    return <Navigate to={AUTH_PATH + SIGN_IN_PATH} />;
  }

  return (
    <AuthViewLayout helmet="Reset password">
      <MonetAuthTitle subtitle="Please enter and confirm a new password below">Set a new password</MonetAuthTitle>
      {alert && <MonetAlert variant={alert.variant} title={alert.title} message={alert.message} error={alert.error} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-5">
          <MonetPasswordHint
            input={
              <MonetPasswordInput
                {...register("new_password")}
                label={"New Password"}
                data-testid="setPassword-newPassword"
                type="password"
                id="new_password"
                disabled={isSubmitting}
                error={errors.new_password?.message}
              />
            }
            inputId="new_password"
          />
          <MonetPasswordInput
            {...register("new_password_confirmation")}
            label="Confirm new password"
            data-testid="setPassword-newPassword"
            type="password"
            id="new_password_confirmation"
            disabled={isSubmitting}
            error={errors.new_password_confirmation?.message}
          />
          <MonetButton loading={isSubmitting} type="submit" disabled={!isObjEmpty(errors) || !isValid || isSubmitting} className="w-full">
            Set password
          </MonetButton>
        </div>
      </form>
    </AuthViewLayout>
  );
};

export default SetPassword;
