import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const IconAndText = styled(Box, {
  shouldForwardProp: (propertyName) => !["iconMargin"].includes(propertyName.toString()),
})<{ iconMargin?: number }>(({ theme, iconMargin = 5 }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",

  "& > *:first-child": {
    marginRight: theme.spacing(iconMargin),
  },
}));
