import { Box, FormControl, FormLabel, Stack } from "@mui/material";
import { Autocomplete, TextField } from "mui-rff";
import React, { useEffect, useMemo } from "react";
import { useForm } from "react-final-form";
import { formatAccountNumber, formatSortCode } from "shared/utils/beneficiaryDetails";

type AddEditAccountFormProps = {
  isEdit: boolean;
  currencies: string[];
};

const AddEditAccountForm: React.FC<AddEditAccountFormProps> = ({ isEdit, currencies }) => {
  const { reset } = useForm();

  const currenciesList = useMemo(() => currencies.map((x) => ({ value: x, label: x })), [currencies]);

  useEffect(() => {
    if (!isEdit) {
      reset();
    }
  }, [isEdit]);
  return (
    <Stack gap={2}>
      <FormControl fullWidth disabled={isEdit}>
        <FormLabel disabled={isEdit}>Currency</FormLabel>
        <Autocomplete
          name="currency"
          id="addAccount-currency"
          data-testid="addAccount-currency"
          fullWidth
          disabled={isEdit}
          disablePortal
          getOptionValue={(option) => option.value}
          options={currenciesList}
          textFieldProps={{
            placeholder: "Select currency here",
          }}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option.label}
            </Box>
          )}
        />
      </FormControl>

      <FormControl fullWidth>
        <FormLabel>Bank</FormLabel>
        <TextField name="bankName" data-testid="addAccount-bankName" placeholder="Enter bank name here" autoComplete="off" />
      </FormControl>

      <FormControl fullWidth>
        <FormLabel>Account Name</FormLabel>
        <TextField name="accountName" data-testid="addAccount-accountName" placeholder="Enter account name here" autoComplete="off" />
      </FormControl>

      <FormControl fullWidth>
        <FormLabel>Sort Code</FormLabel>
        <TextField
          data-testid="addAccount-sortCode"
          inputProps={{ maxLength: 8, inputMode: "numeric" }}
          fieldProps={{
            parse: (value: string) => formatSortCode(value),
          }}
          placeholder="Enter sort code here"
          name="sortCode"
        />
      </FormControl>

      <FormControl fullWidth>
        <FormLabel>IBAN</FormLabel>
        <TextField name="iban" data-testid="addAccount-iban" placeholder="Type IBAN here" autoComplete="off" />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel>Account number</FormLabel>
        <TextField
          name="accountNumber"
          inputProps={{ inputMode: "numeric" }}
          fieldProps={{
            parse: (value: string) => formatAccountNumber(value),
          }}
          data-testid="addAccount-accountNumber"
          placeholder="Type account number here"
          autoComplete="off"
        />
      </FormControl>

      <FormControl fullWidth>
        <FormLabel>SWIFT/BIC</FormLabel>
        <TextField name="swiftCode" data-testid="addAccount-swiftCode" placeholder="Type SWIFT/BIC here" autoComplete="off" />
      </FormControl>
    </Stack>
  );
};

export default AddEditAccountForm;
