import { confirmSignUp } from "aws-amplify/auth";
import MonetAlert, { MonetAlertProps } from "components/tailwind/MonetAlert";
import MonetButton from "components/tailwind/MonetButton";
import MonetLoadingSpinner from "components/tailwind/MonetLoadingSpinner";
import MonetAuthTitle from "components/tailwind/auth/MonetAuthTitle";
import AuthViewLayout from "components/tailwind/templates/AuthViewLayout";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AUTH_PATH, SIGN_IN_PATH } from "../AuthPath";
import ResendEmailVerification from "./ResendEmailVerification";

const VerifyEmail: React.FC = () => {
  const [alert, setAlert] = useState<undefined | MonetAlertProps>();
  const [emailVerified, setEmailVerified] = useState(false);
  const [resendEnabled, setResendEnabled] = useState(true);

  const query = new URLSearchParams(useLocation().search);
  const username = query.get("username");
  const code = query.get("verificationCode");

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!username || !code) {
      setAlert({
        title: "Verification failed",
        message: "Verification link is broken. Please use the link in the email, if the problem continues contact us",
        variant: "error",
      });
      setResendEnabled(false);
    } else {
      confirmUser();
    }
  }, [code, username]);

  const confirmUser = async () => {
    try {
      if (username && code) {
        setAlert({
          title: "Verify...",
          variant: "info",
          icon: <MonetLoadingSpinner size="small" />,
        });
        await confirmSignUp({ username, confirmationCode: code }).then(() => {
          setAlert({
            title: "Email verified",
            message: "You can now sign in and access your Monet account",
            variant: "success",
          });
          setEmailVerified(true);
        });
      }
    } catch (error: Error | any) {
      setAlert({
        title: "Verification failed",
        message: "Please request a new verification email. If the problem continues please contact us",
        variant: "error",
        error: error,
      });
    }
  };

  return (
    <AuthViewLayout helmet="Verify email">
      <MonetAuthTitle subtitle="Please wait while we verify your email address">Verifying your email</MonetAuthTitle>
      {alert && <MonetAlert {...alert} />}
      {emailVerified && (
        <MonetButton type="submit" className="w-full" onClick={() => navigate(AUTH_PATH + SIGN_IN_PATH)}>
          Sign in
        </MonetButton>
      )}
      {resendEnabled && <ResendEmailVerification email={username} text="Need a new verification email?" />}
    </AuthViewLayout>
  );
};

export default VerifyEmail;
