import { yupResolver } from "@hookform/resolvers/yup";
import MonetDrawerButtons from "components/tailwind/MonetDrawerButtons";
import MonetInput from "components/tailwind/form/MonetInput";
import MonetMoneyInput from "components/tailwind/form/MonetMoneyInput";
import MonetTextArea from "components/tailwind/form/MonetTextArea";
import TailwindDrawer from "components/tailwind/headlessTailwind/TailwindDrawer";
import TailwindSelectInput from "components/tailwind/headlessTailwind/TailwindSelectInput";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import { useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import { SYSTEM_ALLOWED_CURRENCIES } from "shared/utils/currency";
import { CreateCampaignFormObject, createCampaignSchema } from "shared/utils/validators";
import { toast } from "sonner";
import { getAllCountries } from "utils/country";
import { CAMPAIGNS_DETAILS_PATH, CAMPAIGNS_PATH } from "../CampaignsPath";
import { useCreateCampaignMutation } from "../graphql/mutations.generated";

const CreateCampaignDrawer: React.FC = () => {
  const { partner } = useSessionProvider();
  const [createCampaign] = useCreateCampaignMutation();
  const { closeModal } = useModal();

  const navigate = useNavigate();

  const onSubmit = async (formData: CreateCampaignFormObject) => {
    try {
      await createCampaign({
        variables: {
          campaign: {
            campaignName: formData.campaignName,
            campaignValueAmount: formData.campaignValueAmount,
            campaignValueCurrency: formData.campaignValueCurrency,
            clientLegalName: formData.clientLegalName,
            clientCountryOfRegistration: formData.clientCountryOfRegistration,
            brief: formData.brief,
            brandPromoted: formData.brandPromoted,
          },
        },
      }).then((res) => {
        toast.success("Success", { description: "Campaign has been created" });
        if (res.data?.createCampaign) {
          navigate(
            generatePath(CAMPAIGNS_PATH + CAMPAIGNS_DETAILS_PATH, {
              campaignId: encodeURIComponent(res.data?.createCampaign.campaignId),
              partnerId: encodeURIComponent(res.data?.createCampaign.partnerId),
            }),
          );
        }
      });
      closeModal();
    } catch (error: any) {
      toast.error("Request failed", { description: error.message });
    }
  };

  const methods = useForm<CreateCampaignFormObject>({
    mode: "onTouched",
    resolver: yupResolver(createCampaignSchema),
    defaultValues: {
      campaignName: "",
      campaignValueAmount: "",
      campaignValueCurrency: "GBP",
      clientCountryOfRegistration: "",
      clientLegalName: "",
      brandPromoted: "",
      brief: "",
    },
  });

  const allowedCurrencies = useMemo(() => {
    return SYSTEM_ALLOWED_CURRENCIES;
  }, [partner]);

  return (
    <TailwindDrawer title="Create campaign" subtitle="Add the basic details of your campaign. Any supporting documents and additional information can be added later.">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col flex-1 justify-between mt-2">
          <div className="flex flex-col gap-2">
            <MonetInput
              {...methods.register("campaignName")}
              label="Campaign name"
              error={methods.formState.errors.campaignName?.message as string}
              disabled={methods.formState.isSubmitting}
              id="campaignName"
              type="text"
            />
            <MonetInput
              {...methods.register("clientLegalName")}
              label="Client legal name"
              error={methods.formState.errors.clientLegalName?.message as string}
              disabled={methods.formState.isSubmitting}
              id="clientLegalName"
            />
            <Controller
              control={methods.control}
              name="clientCountryOfRegistration"
              render={({ field: { ...inputProps } }) => (
                <TailwindSelectInput
                  inputProps={inputProps}
                  label="Client country of registration"
                  options={getAllCountries()}
                  placeholder="Please select..."
                  error={methods.formState.errors.clientCountryOfRegistration?.message as string}
                />
              )}
            />
            <MonetInput
              {...methods.register("brandPromoted")}
              label="Brand Promoted"
              error={methods.formState.errors.brandPromoted?.message as string}
              disabled={methods.formState.isSubmitting}
              id="brandPromoted"
            />
            <MonetMoneyInput
              name="campaignValueAmount"
              label="Campaign value"
              showCurrencyDropdown={true}
              allowedCurrencies={allowedCurrencies}
              currencyInputName="campaignValueCurrency"
              currencyInputDefaultValue="GBP"
            />
            <MonetTextArea
              {...methods.register("brief")}
              label="Brief"
              error={methods.formState.errors.brief?.message as string}
              disabled={methods.formState.isSubmitting}
              isOptional={true}
              tooltip="An optional short summary that describes the campaign. This will be visible to your clients when signing of the campaign."
              id="brief"
            />
          </div>
          <MonetDrawerButtons
            cancelDisabled={methods.formState.isSubmitting}
            submitDisabled={methods.formState.isSubmitting || !methods.formState.isValid}
            loading={methods.formState.isSubmitting}
          />
        </form>
      </FormProvider>
    </TailwindDrawer>
  );
};

export default CreateCampaignDrawer;
