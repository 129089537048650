import clsx from "clsx";
import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

type MonetBorderedCardContentProps = PropsWithChildren & {
  className?: string;
};

const MonetBorderedCardContent: React.FC<MonetBorderedCardContentProps> = ({ children, className }) => {
  return <div className={twMerge(clsx("border border-gray-200 rounded-lg p-4 flex flex-col gap-2 text-sm", className))}>{children}</div>;
};
export default MonetBorderedCardContent;
