import { isValid, toAlpha2 } from "i18n-iso-countries/index";
import { countries, isValid as isIbanValid } from "iban";
import { REGEX_3_CHARACTERS } from "./regex";

type FieldType = "STRING" | "ENUM" | "DATE" | "COUNTRY";
const groupedBorderlessFields: Record<string, FieldType> = {
  country: "COUNTRY",
  currency: "STRING",
  email: "STRING",
  phoneNumber: "STRING",
  firstName: "STRING",
  lastName: "STRING",
  placeOfBirthCountry: "COUNTRY",
  dateOfBirth: "DATE",

  addressLine1: "STRING",
  addressLine2: "STRING",
  postalCode: "STRING",
  city: "STRING",
  placeOfBirthRegion: "STRING",
  popCode: "STRING",

  businessName: "STRING",
  personalTaxId: "STRING",
  businessTaxId: "STRING",
  clientInternalReference: "STRING",
  bankAccountType: "ENUM",
  accountType: "ENUM",
  bankName: "STRING",
  bankSwiftBic: "STRING",
  routingNumber: "STRING",
  branchName: "STRING",
  accountNumber: "STRING",
  zoneCode: "ENUM",
};

const getFieldByType = (selectedType: FieldType): string[] => {
  return Object.keys(groupedBorderlessFields).filter((x) => groupedBorderlessFields[x] == selectedType);
};

export const borderlessStringFields = getFieldByType("STRING");
export const borderlessIsoDateFields = getFieldByType("DATE");
export const borderlessCountryCodeFields = getFieldByType("COUNTRY");
export const borderlessEnumFields = getFieldByType("ENUM");

export const borderlessAllFields = Object.keys(groupedBorderlessFields);

export const validateBorderlessString = (fieldName: string, value: string, countryCode = "", isIban = false): boolean => {
  switch (fieldName) {
    case "routingNumber":
      const cleanedNumber = (value || "").toString().replace(/-/g, "");
      if (countryCode === "CAN") {
        return /^\d{8}$/.test(value);
      }
      if (["GBR", "AUS"].includes(countryCode)) {
        return /^\d{6}$/.test(cleanedNumber);
      }
      if (countryCode === "USA") {
        return /^\d{9}$/.test(value);
      }
      return /^\d{6,9}$/.test(value);
    case "bankSwiftBic":
      return /^[A-Z0-9]{8,11}$/.test(value);
    case "accountNumber":
      if (!isIban) {
        if (countryCode === "GBR") {
          return /^\d{7,8}$/.test(value);
        }
        if (countryCode === "AUS") {
          return /^[A-Z0-9]{6,10}$/.test(value);
        }
      }

      const twoLetterCountryCode = toAlpha2(countryCode);

      const accountValue = value.replace(/\s+/g, "");

      // check iban
      if (countries[twoLetterCountryCode]) {
        return isIbanValid(accountValue) && accountValue.startsWith(twoLetterCountryCode);
      }

      // account number
      return /^\d{6,34}$/.test(accountValue);
    case "bankAccountNumber":
      return /^\d{6,12}$/.test(value);
    case "currency":
      return REGEX_3_CHARACTERS.test(value);
    default:
      return value.length > 0 && value.length < 512;
  }
};

export const borderlessBankAccountTypeList = ["CHECKING", "SAVINGS"];
export const borderlessAccountTypeList = ["PERSONAL", "BUSINESS"];

//source: https://stackoverflow.com/questions/3143070/regex-to-match-an-iso-8601-datetime-string
const isoDateRegex =
  /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;
const dateRegex = /^(?:[1-2]\d{3})-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12][0-9]|3[01])$/;
export const validateBorderlessDateFields = (fieldName: string, value: string): boolean => {
  if (fieldName == "dateOfBirth") {
    return dateRegex.test(value);
  }

  return isoDateRegex.test(value);
};

export const validateBorderlessCountryCodeFields = (value: string): boolean => {
  return isValid(value);
};

export const validateBorderlessEnumFields = (fieldName: string, value: string) => {
  switch (fieldName) {
    case "accountType":
      return borderlessAccountTypeList.includes(value);
    case "bankAccountType":
      return borderlessBankAccountTypeList.includes(value);
    case "zoneCode":
      return /[A-Z0-9]{1,4}/.test(value);
    default:
      return false;
  }
};

export const validateBorderlessField = (fieldName: string, value: string | undefined, countryCode = ""): boolean | undefined => {
  if (!borderlessAllFields.includes(fieldName)) {
    return true;
  }

  if (!value) {
    return false;
  }
  if (borderlessStringFields.includes(fieldName)) {
    return validateBorderlessString(fieldName, value, countryCode);
  }
  if (borderlessIsoDateFields.includes(fieldName)) {
    return validateBorderlessDateFields(fieldName, value);
  }
  if (borderlessCountryCodeFields.includes(fieldName)) {
    return validateBorderlessCountryCodeFields(value);
  }
  if (borderlessEnumFields.includes(fieldName)) {
    return validateBorderlessEnumFields(fieldName, value);
  }
  return undefined;
};
