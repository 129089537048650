import { Box, Button, Stack, styled, Typography, useTheme } from "@mui/material";
import { ReactComponent as PartnerAddSuccessIcon } from "assets/partner-add-success-icon.svg";
import React from "react";
import { useFormState } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { PARTNERS_LIST_ALL_PATH, PARTNERS_PATH } from "../PartnersPath";
import PageTitle from "./PageTitle";
import { dotOpacities, dotsRange } from "./utils";

const SuccessIconContainer = styled(Box)(({ theme }) => ({
  "& .partner-add-success-svg .symbol1": {
    stopColor: theme.palette.tertiary.main,
  },

  "& .partner-add-success-svg .symbol2": {
    stopColor: theme.palette.primary.main,
  },
  "& .partner-add-success-svg .symbol3": {
    fill: theme.palette.common.white,
  },
}));

const PartnerNameContainer = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(6)} ${theme.spacing(5)}`,
  marginTop: theme.spacing(6),
  alignItems: "center",
  justifyContent: "space-around",
  border: `1px solid ${theme.palette.primary.main}`,

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

const SuccessSection: React.FC = () => {
  const { values } = useFormState();
  const navigate = useNavigate();
  const theme = useTheme();

  const finalOpacity = React.useMemo(() => [...dotOpacities(), ...dotOpacities().reverse()], []);

  return (
    <Box>
      <PageTitle>All done</PageTitle>
      <Box mb={3}>
        <Stack spacing={3} direction={{ md: "row" }} justifyContent="space-between">
          <Box maxWidth={400} width="100%">
            <Typography>
              Partner <strong>{values.name}</strong> has successfully been added
            </Typography>
          </Box>
          <Box width={{ md: 300 }} mt={{ md: 0, xs: 4 }}>
            <Button fullWidth variant="contained" onClick={() => navigate(PARTNERS_PATH + PARTNERS_LIST_ALL_PATH)} color="secondary">
              Go to partners view
            </Button>
          </Box>
        </Stack>
      </Box>
      <PartnerNameContainer spacing={6} direction="row">
        {values?.logo?.file && (
          <Box width={64} height={64} display="flex" justifyContent="center" alignItems="center">
            <img src={values.logo.file} style={{ maxWidth: 64, maxHeight: 64 }} alt={values.partnerId} />
          </Box>
        )}
        <Typography variant="body1Large" fontWeight={600} color={theme.palette.primary.main}>
          {values.name}
        </Typography>
        <Box display={{ md: "block", xs: "none" }}>
          <Stack spacing={1} direction="row">
            {dotsRange(1, 16).map((item, index) => {
              return (
                <Box
                  key={item}
                  sx={{
                    opacity: finalOpacity[index],
                  }}
                  width={8}
                  height={8}
                  borderRadius="50%"
                  bgcolor={theme.palette.primary.main}
                />
              );
            })}
          </Stack>
        </Box>
        <SuccessIconContainer display={{ md: "block", xs: "none" }}>
          <PartnerAddSuccessIcon />
        </SuccessIconContainer>
      </PartnerNameContainer>
    </Box>
  );
};

export default SuccessSection;
