export const removeKeysFromObject = (keysToOmit: string[], obj: Record<string, any>): Record<string, any> => {
  const data: Record<string, any> = {};

  for (const key of Object.keys(obj)) {
    if (!keysToOmit.includes(key)) {
      data[key] = obj[key];
    }
  }

  return data;
};

export type NestedObjKey = `${string}.${string}`;

export const getNestedValue = <T>(key: NestedObjKey, obj: Record<string, unknown | T>): T => {
  let finalObject = obj;
  let value = "" as T;
  const keyArray = key.split(".");

  if (obj[keyArray[0]]) {
    for (const [index, part] of keyArray.entries()) {
      finalObject = finalObject[part] as Record<string, unknown>;
      if (index == keyArray.length - 1) {
        value = finalObject as T;
      }
    }
  }
  return value;
};

export const isObjEmpty = (obj: Object) => {
  return Object.keys(obj).length === 0;
};

export type ApiResponse = {
  statusCode: number;
  body: string;
};
