import clsx from "clsx";
import MonetLoadingSpinner from "components/tailwind/MonetLoadingSpinner";
import React from "react";
import { twMerge } from "tailwind-merge";

type LoadingViewProps = {
  title?: string;
  content?: string;
  overlay?: boolean;
};

const LoadingView: React.FC<LoadingViewProps> = ({ title, content, overlay = false }) => {
  const overlayStyling = overlay ? "absolute" : "relative";
  return (
    <div className={twMerge(clsx("flex flex-col items-center justify-center w-full h-full gap-2", overlayStyling))}>
      <MonetLoadingSpinner size="large" />
      <p className="text-center text-sm">{title ?? "Loading..."}</p>
      {content && <p className="text-center text-sm">{content}</p>}
    </div>
  );
};

export default LoadingView;
