/**
 * Regular expression to match strings that consist only of numeric characters.
 * - Matches one or more digits from 0 to 9.
 * @type {RegExp}
 */
export const REGEX_NUMERIC_ONLY: RegExp = /^[0-9+]+$/;

/**
 * Regular expression for validating the local part of the email address.
 * - It allows alphanumeric characters and special characters '.', '_', '-', '+'
 * - It enforces at least one alphanumeric character at the beginning and end.
 * - Allows multiple but not concurrent occurrences of '.', '_', '-', '+' in between.
 *  @type {RegExp}
 */
export const REGEX_EMAIL_LOCAL: RegExp = /^[A-Za-z0-9]+([._+\-][A-Za-z0-9]+)*$/;

/**
 * Regular expression for validating the domain part of the email address.
 * - It allows alphanumeric characters and '-' as separators.
 * - It enforces that '-' cannot appear consecutively and at the beginning or end.
 * @type {RegExp}
 */
export const REGEX_EMAIL_DOMAIN: RegExp = /^(?!.*([.-]){2})[A-Za-z0-9]+(?:[-][A-Za-z0-9]+)*$/;

/**
 * Regular expression for validating the top-level domain part of the email address.
 * It allows two or more alphabetical characters.
 * @type {RegExp}
 */
export const REGEX_EMAIL_TOP_LEVEL_DOMAIN: RegExp = /^[A-Za-z]{2,}$/;

/**
 * Regular expression to match strings with the following constraints.
 *
 * - Matches strings that do not start or end with whitespace.
 * - Allows letters (both uppercase and lowercase), numbers, and various special characters.
 * - String length must be between 3 and 64 characters.
 * - % Character not allowed. % Character is a reserved in URI
 *
 * @type {RegExp}
 */
export const REGEX_URI_COMPLIANT_NO_WHITESPACE_START_OR_END: RegExp = /^[^\s].{1,62}[^\s%]$/;

/**
 * Regular expression to match file names with the following constraints.
 *
 * - Matches strings that do not start or end with whitespace.
 * - Allows letters (both uppercase and lowercase), numbers, underscore and hyphen.
 *
 * @type {RegExp}
 */
export const REGEX_FILE_NAME: RegExp = /^\w[\w.'*!()-]+(\.[\w.'*!()-]+)?\.[A-Za-z][A-Za-z0-9]{1,3}$/;

/**
 * Regular expression to match UTC ISO timestamp
 *
 * Example: 2023‐07‐24T21:38:30.000Z
 *
 * @type {RegExp}
 */
export const REGEX_TIMESTAMP: RegExp = /^(\d{4})-(((0)([1-9]))|((1)([0-2])))-(\d{2})T(\d{2}):(\d{2}):(\d{2})((.\d{3})?Z?)?$/;

/**
 * Regular expression to match 3 letter country code or currency
 *
 * Example: GBR
 *
 * @type {RegExp}
 */
export const REGEX_3_CHARACTERS: RegExp = /^([A-Z]{3})$/;

/**
 * Regular expression pattern for validating addresses.
 * @type {RegExp}
 * @description The pattern checks the format of an address, allowing specific characters commonly found in addresses.
 *
 * @allowedCharacters
 * - The address can contain uppercase and lowercase letters (a-z, A-Z).
 * - Digits (0-9) are allowed.
 * - Special characters: hyphen (-), single quote ('), period (.), parentheses (()), number sign (#), whitespace (\s), slash (/), comma (,), and plus sign (+).
 * - The address must be at least 2 characters long.
 */
export const REGEX_ADDRESS: RegExp = /^[a-zA-Z0-9-.'()#\s/,+]{2,}$/;

/**
 * Regular expression pattern for validating postal codes.
 * @type {RegExp}
 * @description The pattern checks the format of a postal code, allowing alphanumeric characters commonly found in postal codes.
 *
 * @allowedCharacters
 * - The postal code can contain uppercase and lowercase letters (a-z, A-Z).
 * - Digits (0-9) are allowed.
 * - The postal code can include alphanumeric characters.
 * - The postal code can also include spaces.
 */
export const REGEX_POST_CODE: RegExp = /^[a-zA-Z0-9\s]+$/;

/**
 * Regular expression pattern for validating URLs.
 * @type {RegExp}
 * @description The pattern checks the format of a URL, ensuring it follows common conventions.
 *
 * @structure
 * - The URL can start with an optional "http://" or "https://" protocol.
 * - It can also start with an optional "www." subdomain.
 * - The main domain can contain alphanumeric characters.
 * - Hyphens (-) are allowed within the domain, but not at the start or end.
 * - The domain can have multiple levels with dot (.) separators.
 * - The URL can have an optional path, query parameters, or fragment identifier.
 */
export const REGEX_URL: RegExp = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9]+(?:[-][a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+(?:[-][a-zA-Z0-9]+)*)+([/?#]\S*)?$/;

/**
 * Regular expression to validate names with specific rules:
 * - Must start with at least two alphabetic characters (A-Z, case-insensitive).
 * - Can include additional alphabetic characters, spaces, hyphens (`-`), and apostrophes (`'`).
 * - Must not start or end with a whitespace character.
 * - Must not end with a hyphen (`-`).
 * - Allows spaces, hyphens, and apostrophes only after the first two alphabetic characters.
 *
 * Examples:
 * - Valid: "John", "Mary-Anne", "O'Connor", "Jean Paul".
 * - Invalid: " Jo", "Mary-", "-John", "John ", "O' ", "Mary- ".
 *
 * @constant
 * @type {RegExp}
 */
export const REGEX_NAME_SPECIAL_CHARS: RegExp = /^(?! )[A-Za-z]{2}[A-Za-z \-']*(?<![ \-])$/;

/**
 * Regular expression to match a string with the length between 12 and 256 characters, at least one uppercase letter, at least one lowercase letter, at least one digit, at least one special character and no leading or trailing whitespaces
 * Example !Testinguser123
 *
 * @type {RegExp}
 */

export const AWS_PASSWORD_REGEX: RegExp =
  /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z\d$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{12,256}$/;

/**
 *  Regular expression to match a string with a minimum of 5 characters, allowing only letters (both uppercase and lowercase), hyphen, apostrophe and a space
 * Example John Doe
 *
 * @type {RegExp}
 */
export const REGEX_FULL_NAME: RegExp = /^([A-Za-z]{2,})\s([A-Za-z]{2,}[A-Za-z-'\s]*)$/;

/**
 *  Regular expression to match a string with a minimum of 3 characters, allowing only letters (both uppercase and lowercase), hyphen
 * Example JFJE8323
 *
 * @type {RegExp}
 */
export const REGEX_PAYOUT_REFERENCE: RegExp = /^([0-9A-Za-z]+)([A-Za-z0-9-]{3,})$/;
