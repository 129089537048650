import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import NavigationMenu from "./NavigationMenu";

const MobileNavigation: React.FC<{ open: boolean; toggle(): void }> = ({ open, toggle }) => {
  return (
    <Dialog open={open} onClose={toggle} className="relative z-10">
      <DialogBackdrop transition className="fixed inset-0 bg-black bg-opacity-30 transition-opacity duration-300 ease-in-out data-[closed]:opacity-0" />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 left-0 flex pr-1 w-full max-w-[275px]">
            <DialogPanel transition className="pointer-events-auto relative w-screen max-w-md transform transition duration-300 ease-in-out data-[closed]:-translate-x-full">
              <div className="flex flex-col h-full justify-between overflow-y-auto bg-white border-s dark:bg-neutral-800 dark:border-neutral-700">
                <div className="flex flex-col h-full max-h-full">
                  <div className="h-full overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
                    <NavigationMenu />
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default MobileNavigation;
