import { Box, FormControl, FormLabel, Stack } from "@mui/material";
import CountriesField from "components/Forms/CountriesField";
import React from "react";
import PageTitle from "./PageTitle";

const PartnerLocationSection: React.FC = () => {
  return (
    <Box>
      <PageTitle>Where is the partner located?</PageTitle>
      <Stack spacing={3} mb={3}>
        <FormControl fullWidth>
          <FormLabel>Partner Location</FormLabel>
          <CountriesField label="" id="country" name="country" data-testid="partnerJourney-partnerLocationField" />
        </FormControl>
      </Stack>
    </Box>
  );
};

export default PartnerLocationSection;
