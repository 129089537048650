import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";
import { InputHTMLAttributes, PropsWithChildren, forwardRef } from "react";
import { twMerge } from "tailwind-merge";

type MonetCheckboxProps = PropsWithChildren &
  Omit<InputHTMLAttributes<HTMLInputElement>, "size"> &
  VariantProps<typeof variants> & {
    id: string;
    label?: string;
    className?: string;
    placement?: "start" | "end";
  };

const MonetCheckbox: React.FC<MonetCheckboxProps> = forwardRef<HTMLInputElement, MonetCheckboxProps>(({ children, label, id, size, className, placement, ...props }, ref) => {
  const placementStyling = placement === "end" ? "flex-row-reverse justify-between" : "";
  return (
    <div className={twMerge(clsx("flex items-center", placementStyling))}>
      <input {...props} type="checkbox" id={id} ref={ref} className={twMerge(clsx(variants({ size }), className))} />
      <label htmlFor={id}>
        {label && <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-300">{label}</span>}
        {children && (
          <span id="hs-checkbox-delete-description" className="block text-sm text-gray-600 dark:text-neutral-500">
            {children}
          </span>
        )}
      </label>
    </div>
  );
});

export default MonetCheckbox;

const variants = cva(
  "shrink-0 mr-2 border-monet-blue rounded text-monet-blue focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-monet-blue dark:checked:border-monet-blue dark:focus:ring-offset-gray-800",
  {
    variants: {
      size: {
        small: "h-4 w-4",
        default: "h-5 w-5",
      },
    },
    defaultVariants: {
      size: "default",
    },
  },
);
