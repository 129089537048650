import { ReactComponent as ChevronRight } from "assets/tailwind/icons/chevron-right.svg";
import { ButtonHTMLAttributes, PropsWithChildren } from "react";
import MonetButton from "../MonetButton";

type MonetAuthFooterProps = PropsWithChildren & {
  buttonText: string;
  buttonProps: Omit<ButtonHTMLAttributes<HTMLButtonElement>, "color">;
};

const MonetAuthFooter: React.FC<MonetAuthFooterProps> = ({ children, buttonText, buttonProps }) => {
  return (
    <p className="text-sm text-gray-500 flex flex-wrap gap-1 dark:text-white">
      {children}
      <MonetButton variant="link" size="fit-content" className="text-sm" {...buttonProps}>
        {buttonText} <ChevronRight width={14} height={14} />
      </MonetButton>
    </p>
  );
};

export default MonetAuthFooter;
