import { Money, MoneyStorage } from "@monet-money/money-type";
import MonetAlert from "components/tailwind/MonetAlert";
import { useSessionProvider } from "contexts/SessionProvider";
import { useMemo } from "react";
import { Campaign } from "shared/types/eep.contract.types";

type FundingAvailableBanner = {
  isLoading: boolean;
  invoices: number;
  campaign?: Campaign;
};

const FundingAvailableBanner: React.FC<FundingAvailableBanner> = ({ campaign, invoices, isLoading }) => {
  const { partner } = useSessionProvider();

  const completedDeliverables = useMemo(() => {
    if (campaign && campaign.deliverables) {
      return Boolean(campaign.deliverables.find((deliverable) => deliverable.status === "COMPLETED"));
    } else return undefined;
  }, [campaign]);

  if (partner?.defaultConfiguration.allowedCurrencies) {
    const isCurrencyValid = partner?.defaultConfiguration.allowedCurrencies.find((currency) => currency.currency === campaign?.campaignValue.currency);

    if (!isCurrencyValid) {
      return null;
    }
  }

  if (!completedDeliverables || invoices > 0 || isLoading) return null;

  return (
    <MonetAlert title="Invoice funding available" variant="info">
      Campaign deliverables are complete, and you can now request funding for this campaign. <br />
      To get started, click on the funding tab, upload your invoice, and submit it for approval. You may be eligible for up to{" "}
      {Money.fromStorageType(campaign?.campaignValue as MoneyStorage).format(true, true)} in funding.
    </MonetAlert>
  );
};

export default FundingAvailableBanner;
