import { Money } from "@monet-money/money-type";
import MonetAlert from "components/tailwind/MonetAlert";
import usePayoutProvider from "contexts/PayoutProvider";

type FundingRemainingAlertProps = {
  remainingFunds: Money;
};

const FundingRemainingAlert: React.FC<FundingRemainingAlertProps> = ({ remainingFunds }) => {
  const { selectedInvoice } = usePayoutProvider();

  if (!selectedInvoice) return null;

  return (
    <MonetAlert variant="info" title="Note">
      <p>
        The invoice <span className="font-semibold">{selectedInvoice.invoiceNumber}</span> has {remainingFunds?.format(true, true)} of funding remaining
      </p>
    </MonetAlert>
  );
};

export default FundingRemainingAlert;
