import { DialogPanel } from "@headlessui/react";
import { ReactComponent as TriangleAlertIcon } from "assets/tailwind/icons/triangle-alert.svg";
import clsx from "clsx";
import { useModal } from "contexts/ModalProvider";
import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import MonetButton, { MonetButtonProps } from "../MonetButton";

type TailwindDialogProps = PropsWithChildren & {
  type: "warning" | "passive" | "danger" | "info";
  title?: string;
  submitButtonColor?: MonetButtonProps["color"];
  submitDisabled?: boolean;
  onConfirmCallback?(): Promise<boolean>;
  className?: string;
};

const TailwindDialog: React.FC<TailwindDialogProps> = ({ type, title, submitButtonColor, onConfirmCallback, children, className, submitDisabled = false }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { closeModal } = useModal();

  const handleConfirm = useCallback(async () => {
    if (onConfirmCallback) {
      setIsLoading(true);
      await onConfirmCallback()
        .then(() => {
          setIsLoading(false);
        })
        .finally(() => {
          closeModal();
        });
    } else closeModal();
  }, []);

  const color = useMemo(() => {
    switch (type) {
      case "passive":
        return {
          foreground: "bg-blue-100 text-blue-600",
          background: "bg-blue-50",
        };
      case "warning":
        return {
          foreground: "bg-yellow-100 text-yellow-600",
          background: "bg-yellow-50",
        };
      case "danger":
        return {
          foreground: "bg-red-100 text-red-600",
          background: "bg-red-50",
        };
      default:
        return {
          foreground: "bg-blue-100 text-blue-600",
          background: "bg-blue-50",
        };
    }
  }, [type]);

  return (
    <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
      <DialogPanel
        transition
        className={twMerge(
          clsx(
            "flex flex-col bg-white border shadow-sm max-h-[500px] overflow-auto max-w-[450px] p-4 w-full rounded-lg pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70 transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95",
            className,
          ),
        )}
      >
        <div className="flex flex-col items-center gap-4">
          {title && (
            <>
              <div className={twMerge(clsx("rounded-full p-2", color.background))}>
                <div className={twMerge(clsx("rounded-full p-4", color.foreground))}>
                  <TriangleAlertIcon width={24} height={24} />
                </div>
              </div>
              <h3 className="text-2xl font-light text-gray-800 dark:text-white text-center">{title}</h3>
            </>
          )}
          <div className="text-sm text-center w-full">{children}</div>
          <div className="flex flex-row gap-4 w-full">
            <MonetButton variant="outlined" color="gray-outlined" className="flex-1" disabled={isLoading} onClick={closeModal}>
              {type !== "passive" ? "Cancel" : "Close"}
            </MonetButton>
            {type !== "passive" && (
              <MonetButton onClick={handleConfirm} className="flex-1" loading={isLoading} disabled={isLoading || submitDisabled} color={submitButtonColor}>
                Confirm
              </MonetButton>
            )}
          </div>
        </div>
      </DialogPanel>
    </div>
  );
};

export default TailwindDialog;
