import { yupResolver } from "@hookform/resolvers/yup";
import { Money, MoneyStorage } from "@monet-money/money-type";
import MonetDrawerButtons from "components/tailwind/MonetDrawerButtons";
import MonetInput from "components/tailwind/form/MonetInput";
import MonetMoneyInput from "components/tailwind/form/MonetMoneyInput";
import MonetTextArea from "components/tailwind/form/MonetTextArea";
import TailwindDrawer from "components/tailwind/headlessTailwind/TailwindDrawer";
import TailwindSelectInput from "components/tailwind/headlessTailwind/TailwindSelectInput";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import { useMemo } from "react";
import { Controller, FormProvider, Resolver, useForm } from "react-hook-form";
import { Campaign } from "shared/types/eep.contract.types";
import { UpdateCampaignDetailsFormObject, updateCampaignDetailsSchema } from "shared/utils/validators";
import { toast } from "sonner";
import { Invoice } from "utils/Invoice";
import { isInPartnerContext } from "utils/User";
import { useUpdateCampaignMutation } from "views/campaigns/graphql/mutations.generated";

type EditCampaignDrawerProps = {
  refetchCampaign(): void;
  campaign?: Campaign;
  invoices?: Invoice[];
};

const EditCampaignDrawer: React.FC<EditCampaignDrawerProps> = ({ campaign, refetchCampaign, invoices }) => {
  const [updateCampaign] = useUpdateCampaignMutation();
  const { user } = useSessionProvider();
  const { closeModal } = useModal();

  const onSubmit = async (formData: UpdateCampaignDetailsFormObject) => {
    if (campaign) {
      try {
        await updateCampaign({
          variables: {
            campaign: {
              status: formData.status!,
              campaignName: formData.campaignName!,
              brandPromoted: formData.brandPromoted!,
              brief: formData.brief,
              campaignId: campaign.campaignId,
              campaignValue: formData.campaignValue!,
            },
          },
        });
        toast.success("Success", { description: "The campaign has been updated" });
        refetchCampaign();
        closeModal();
      } catch (error: any) {
        toast.error("Request failed", { description: error.message });
      }
    }
  };

  const methods = useForm<UpdateCampaignDetailsFormObject>({
    mode: "onTouched",
    resolver: yupResolver(updateCampaignDetailsSchema) as Resolver<UpdateCampaignDetailsFormObject>,
    defaultValues: {
      status: campaign?.campaignStatus,
      campaignName: campaign?.campaignName,
      brandPromoted: campaign?.brandPromoted,
      campaignValue: Money.fromStorageType(campaign?.campaignValue as MoneyStorage).format(false, false),
      brief: campaign?.brief,
    },
  });

  const activeOrSettledInvoice = useMemo(() => {
    if (invoices) {
      return invoices.find((invoice) => invoice.funding && ["ACTIVE", "SETTLED"].includes(invoice.funding?.fundingStatus)) ? true : false;
    } else return false;
  }, [invoices]);

  const campaignStatusTransitions = [
    { label: "Draft", value: "DRAFT" },
    { label: "Active", value: "ACTIVE" },
    { label: "Completed", value: "COMPLETED" },
  ];

  if (!campaign || !isInPartnerContext(user)) return null;

  if (campaign?.campaignStatus === "COMPLETED") return null;

  return (
    <TailwindDrawer title="Edit campaign" subtitle="Edit the details of the campaign. Only the details that can be edited are listed below">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col h-full justify-between">
          <div className="flex flex-col gap-2">
            <Controller
              control={methods.control}
              name="status"
              render={({ field: { ...inputProps } }) => (
                <TailwindSelectInput
                  inputProps={inputProps}
                  placeholder="Please select a new status..."
                  disabled={methods.formState.isSubmitting}
                  options={campaignStatusTransitions}
                  label="Campaign status"
                  error={methods.formState.errors.status?.message}
                />
              )}
            />
            <MonetInput
              {...methods.register("campaignName")}
              label="Name"
              error={methods.formState.errors.campaignName?.message as string}
              disabled={methods.formState.isSubmitting}
              id="name"
            />
            <MonetInput
              {...methods.register("brandPromoted")}
              label="Brand promoted"
              error={methods.formState.errors.brandPromoted?.message as string}
              disabled={methods.formState.isSubmitting || activeOrSettledInvoice}
              id="brandPromoted"
            />
            <MonetMoneyInput currency={campaign?.campaignValue.currency} name="campaignValue" label="Campaign value" disabled={activeOrSettledInvoice} />
            <MonetTextArea
              {...methods.register("brief")}
              label="Brief"
              disabled={methods.formState.isSubmitting}
              error={methods.formState.errors.brief?.message as string}
              id="brief"
            />
          </div>
          <MonetDrawerButtons
            cancelDisabled={methods.formState.isSubmitting}
            submitDisabled={methods.formState.isSubmitting || !methods.formState.isValid}
            loading={methods.formState.isSubmitting}
          />
        </form>
      </FormProvider>
    </TailwindDrawer>
  );
};

export default EditCampaignDrawer;
