import { useApolloClient } from "@apollo/client";
import { FetchUserAttributesOutput, fetchAuthSession, signOut } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import LoadingView from "components/Loaders/LoadingView";
import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";
import { Partner } from "utils/Partner";
import { AnonymousEP3User, EP3User, loadImpersonationCtx, removeImpersonationCtx, saveImpersonationCtx } from "utils/User";
import createApolloLink from "./apollo/createApolloLink";
import { useGetPartnerQuery } from "./graphql/queries.generated";

type SessionContextValue = {
  user: EP3User;
  partner?: Partner;
  setCurrentUser: (user: any) => void;
  refetchCurrentPartner: () => Promise<void>;
  initialiseContext: () => Promise<void>;
  handleImpersonation: (partner?: string) => Promise<void>;
  handleSignOut: () => void;
};

// Create context
const SessionContext = createContext<SessionContextValue>({
  user: AnonymousEP3User,
  partner: undefined,
  setCurrentUser: () => {},
  refetchCurrentPartner: async () => {},
  initialiseContext: async () => {},
  handleImpersonation: async () => {},
  handleSignOut: () => {},
});

// Create provider
export const SessionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { refetch } = useGetPartnerQuery({ fetchPolicy: "network-only", skip: true });
  const [user, setUser] = useState<EP3User>(AnonymousEP3User);
  const [partner, setPartner] = useState<Partner>();
  const [isLoading, setIsLoading] = useState(true);
  const client = useApolloClient();

  const setCurrentUser = (cognitoUser: FetchUserAttributesOutput) => {
    setUser({
      ...user,
      email: cognitoUser.email!,
      firstName: cognitoUser.given_name!,
      lastName: cognitoUser.family_name!,
      phoneNumber: cognitoUser.phone_number!,
      partner: cognitoUser["custom:partner"]!,
      role: cognitoUser["custom:role"]!,
    });
  };

  const refetchCurrentPartner = async (): Promise<void> => {
    const partner = (await refetch({ partnerId: user.partner })).data;
    setPartner(JSON.parse(partner.getPartner)[0] as Partner);
  };

  const initialiseContext = async (): Promise<void> => {
    try {
      const currentUser = (await fetchAuthSession()).tokens?.idToken?.payload as any;
      if (currentUser) {
        currentUser!.isImpersonating = false;
        let partnerId = currentUser!["custom:partner"] as string;

        // Get impersonation context
        const impersonationCtx = loadImpersonationCtx();
        if (impersonationCtx) {
          currentUser!["custom:partner"] = impersonationCtx.partnerId;
          partnerId = impersonationCtx.partnerId;
          currentUser!.isImpersonating = true;
        }

        // Set user
        const userGroups = currentUser["cognito:groups"] ?? [];

        setUser({
          email: currentUser.email,
          firstName: currentUser.given_name,
          lastName: currentUser.family_name,
          phoneNumber: currentUser.phone_number,
          partner: currentUser["custom:partner"],
          role: currentUser["custom:role"],
          isAuthenticated: true,
          isAdministrator: userGroups.findIndex((x: string) => x === process.env.REACT_APP_MONET_ADMIN_GROUP) >= 0,
          isImpersonating: currentUser.isImpersonating,
          groups: userGroups,
        });

        // Set Apollo client
        await Promise.all([client.setLink(createApolloLink(partnerId)), client.clearStore()]);

        // set partner
        const partner = (await refetch({ partnerId: partnerId })).data;
        setPartner(JSON.parse(partner.getPartner)[0] as Partner);
      }
    } catch (error) {
      console.error("Session not initialised:", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      setUser(AnonymousEP3User);
      removeImpersonationCtx();
      client.cache.reset();
    } catch (e) {
      console.error(e);
    }
  };

  const handleImpersonation = async (partnerId?: string): Promise<void> => {
    setIsLoading(true);
    if (partnerId) {
      await saveImpersonationCtx(partnerId);
    } else {
      await removeImpersonationCtx();
    }

    await initialiseContext();
    setIsLoading(false);
  };

  // Set the session context if the page is reloaded
  useEffect(() => {
    const initialise = async () => {
      try {
        await initialiseContext();
      } catch (error) {
        console.error("Error during initialise user:", error);
      } finally {
        setIsLoading(false);
      }
    };

    initialise();
  }, []);

  // Listen for Amplify auth events
  Hub.listen("auth", async ({ payload, source, channel }) => {
    //this will solve some issue with duplicated msgs
    if (source !== "Auth" || channel !== "auth") {
      return;
    }

    if (payload.event === "tokenRefresh") {
      await fetchAuthSession();
      initialiseContext();
    }
  });

  const contextValues = useMemo(() => {
    return { user, partner, setCurrentUser, initialiseContext, handleImpersonation, handleSignOut, refetchCurrentPartner };
  }, [user, partner]);

  return <SessionContext.Provider value={contextValues}>{isLoading ? <LoadingView overlay title="Loading context..." /> : children}</SessionContext.Provider>;
};

// Create custom hook for using the context
export const useSessionProvider = (): SessionContextValue => {
  const context = useContext(SessionContext);
  return context;
};
