import * as Types from 'shared/generated/ep3.graphql.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StripeFulfillCheckoutMutationVariables = Types.Exact<{
  stripeSessionId: Types.Scalars['String']['input'];
}>;


export type StripeFulfillCheckoutMutation = { __typename?: 'Mutation', stripeFulfillCheckout: boolean };


export const StripeFulfillCheckoutDocument = gql`
    mutation stripeFulfillCheckout($stripeSessionId: String!) {
  stripeFulfillCheckout(stripeSessionId: $stripeSessionId)
}
    `;
export type StripeFulfillCheckoutMutationFn = Apollo.MutationFunction<StripeFulfillCheckoutMutation, StripeFulfillCheckoutMutationVariables>;

/**
 * __useStripeFulfillCheckoutMutation__
 *
 * To run a mutation, you first call `useStripeFulfillCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeFulfillCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeFulfillCheckoutMutation, { data, loading, error }] = useStripeFulfillCheckoutMutation({
 *   variables: {
 *      stripeSessionId: // value for 'stripeSessionId'
 *   },
 * });
 */
export function useStripeFulfillCheckoutMutation(baseOptions?: Apollo.MutationHookOptions<StripeFulfillCheckoutMutation, StripeFulfillCheckoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StripeFulfillCheckoutMutation, StripeFulfillCheckoutMutationVariables>(StripeFulfillCheckoutDocument, options);
      }
export type StripeFulfillCheckoutMutationHookResult = ReturnType<typeof useStripeFulfillCheckoutMutation>;
export type StripeFulfillCheckoutMutationResult = Apollo.MutationResult<StripeFulfillCheckoutMutation>;
export type StripeFulfillCheckoutMutationOptions = Apollo.BaseMutationOptions<StripeFulfillCheckoutMutation, StripeFulfillCheckoutMutationVariables>;