import { Box, Button, Divider, Stack, styled, SxProps, Typography } from "@mui/material";
import useAppProvider from "contexts/AppProvider";
import { PropsWithChildren } from "react";

const StyledStackContainer = styled(Stack, { shouldForwardProp: (propertyName) => !["isBordered", "direction", "sx"].includes(propertyName.toString()) })<{
  isBordered?: boolean;
  direction?: "row" | "column";
  sx?: SxProps;
}>(({ isBordered = true, theme, direction }) => ({
  flexDirection: direction ? direction : "column",
  padding: theme.spacing(2),
  ...(isBordered && { border: `1px solid ${theme.palette.primary.main}` }),
  [theme.breakpoints.up("md")]: {
    flexDirection: direction ? direction : "row",
  },
}));

type StackWithBorderProps = {
  title?: string;
  subtitle?: string;
  isBordered?: boolean;
  isHidden?: boolean;
  isEditable?: boolean;
  isProcessing?: boolean;
  editButtonText?: string;
  editButtonColor?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  id?: string;
  pageStep?: string;
  editButtonAction?(): void;
  sx?: SxProps;
};
const StackWithBorder: React.FC<PropsWithChildren<StackWithBorderProps>> = ({
  title,
  subtitle,
  isBordered = true,
  isEditable = false,
  editButtonText,
  editButtonColor,
  id,
  isHidden = false,
  children,
  isProcessing,
  editButtonAction,
  sx,
}) => {
  const { isMobile } = useAppProvider();
  if (isHidden) return null;
  return (
    <StyledStackContainer id={id} width="100%" isBordered={isBordered} sx={sx} data-testid={`stack-with-border-${id ?? title ?? "unknown"}`}>
      <Stack p={2} width={{ xs: "100%", md: "30%" }} justifyContent="flex-start">
        <Box mb={2} display="flex" flexDirection="column" width="100%" justifyContent="space-between">
          <Typography variant="h2">{title}</Typography>
          <Typography>{subtitle}</Typography>
          {!isMobile && <Divider orientation="vertical" flexItem sx={{ borderColor: "primary.main", opacity: 0.3 }} />}
        </Box>
        {isEditable ? (
          <Box>
            <Button
              variant="text"
              color={editButtonColor}
              sx={{ minWidth: 0, padding: 0, opacity: isProcessing ? 0.3 : undefined }}
              onClick={() => {
                if (isProcessing) {
                  return;
                }
                if (editButtonAction) {
                  editButtonAction();
                }
              }}
            >
              {editButtonText ? editButtonText : "Edit"}
            </Button>
          </Box>
        ) : null}
      </Stack>
      <Stack p={2} flex={1} width={{ xs: "100%", md: "70%" }} spacing={2} className="children">
        {children}
      </Stack>
    </StyledStackContainer>
  );
};

export default StackWithBorder;
