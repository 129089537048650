import MonetAlert from "components/tailwind/MonetAlert";
import TailwindDialog from "components/tailwind/headlessTailwind/TailwindDialog";
import { useMemo } from "react";
import { toast } from "sonner";
import { Invoice } from "utils/Invoice";
import { useDeleteInvoiceMutation } from "views/campaigns/graphql/mutations.generated";

type DeleteInvoiceModalProps = {
  invoice: Invoice;
  refetchInvoices(): void;
};

const DeleteInvoiceModal: React.FC<DeleteInvoiceModalProps> = ({ invoice, refetchInvoices }) => {
  const [deleteInvoice] = useDeleteInvoiceMutation();

  const handleDelete = (): Promise<boolean> => {
    return new Promise((resolve) => {
      if (invoice) {
        deleteInvoice({
          variables: {
            invoiceId: invoice.invoiceId,
            campaignId: invoice.campaignId,
          },
        })
          .then(() => {
            refetchInvoices();
            toast.success("Success", { description: "Invoice has been deleted" });
          })
          .catch((err) => {
            toast.error("Request failed", { description: err.message });
          })
          .finally(() => {
            resolve(true);
          });
      }
    });
  };

  const isActiveSettledFunding = useMemo(() => {
    if (invoice.funding) {
      return ["ACTIVE", "SETTLED"].includes(invoice.funding?.fundingStatus);
    } else return false;
  }, [invoice]);

  return (
    <TailwindDialog type={isActiveSettledFunding ? "passive" : "danger"} title="Delete invoice" submitButtonColor="red-solid" onConfirmCallback={() => handleDelete()}>
      {isActiveSettledFunding ? (
        <MonetAlert variant="warning" message="This invoice cannot be deleted because it has either active or settled funding associated with it" />
      ) : (
        <p>
          Are you sure? You will be permanently deleting invoice: <br /> <span className="font-semibold">{invoice.invoiceId}</span>
        </p>
      )}
    </TailwindDialog>
  );
};

export default DeleteInvoiceModal;
