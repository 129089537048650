import { PropsWithChildren } from "react";
import MonetCard from "./MonetCard";
import MonetCardTitle from "./MonetCardTitle";

type NoDataCardProps = PropsWithChildren & {
  title?: string;
  showAsUnstyledCard?: boolean;
};

const NoDataCard: React.FC<NoDataCardProps> = ({ title, children, showAsUnstyledCard = false }) => {
  return (
    <MonetCard variant={showAsUnstyledCard ? "unstyled" : "styled"} className="h-full">
      {title && <MonetCardTitle>{title}</MonetCardTitle>}
      <div className="w-full h-full min-h-[150px] bg-gray-50 rounded-xl flex justify-center items-center font-light text-sm text-center p-4">{children}</div>
    </MonetCard>
  );
};

export default NoDataCard;
