import { resendSignUpCode } from "aws-amplify/auth";
import MonetAuthFooter from "components/tailwind/auth/MonetAuthFooter";
import dayjs from "dayjs";
import { useCallback } from "react";
import { useTimer } from "react-timer-hook";
import { toast } from "sonner";

type ResendEmailVerificationProps = {
  email: string | null;
  text: string;
};

const ResendEmailVerification: React.FC<ResendEmailVerificationProps> = ({ email, text }) => {
  const { isRunning, minutes, seconds, restart } = useTimer({
    autoStart: true,
    expiryTimestamp: dayjs().add(120, "s").toDate(),
  });
  // return 2:00 instead of 2:0
  const fullSeconds = `0${seconds}`.slice(-2);

  const restartTimer = useCallback(() => {
    const newExpiryTimestamp = dayjs().add(120, "s");
    restart(newExpiryTimestamp.toDate());
  }, []);

  const resendButtonText = isRunning ? `Resend (available in ${minutes}:${fullSeconds})` : "Resend";

  const handleResend = async () => {
    try {
      if (email) {
        await resendSignUpCode({ username: email }).then(() => {
          toast.success("Verification email sent", {
            description: `We have sent you a new verification email to email address ${email}`,
          });
        });
      }
    } catch (err: any) {
      const message = err.message.replace("CustomMessage failed with error", "");
      toast.error("Request failed", {
        description: message,
      });
    } finally {
      restartTimer();
    }
  };

  return (
    <MonetAuthFooter buttonText={resendButtonText} buttonProps={{ onClick: handleResend, disabled: isRunning }}>
      {text}
    </MonetAuthFooter>
  );
};

export default ResendEmailVerification;
