import MonetAuthTitle from "components/tailwind/auth/MonetAuthTitle";

type DoneStepProps = {
  userEmail?: string;
};

const DoneStep: React.FC<DoneStepProps> = ({ userEmail }) => {
  return (
    <MonetAuthTitle
      subtitle={
        <span>
          You will soon receive an verification email sent to <span className="font-semibold">{userEmail}</span>, follow the instruction in the email to verify your account
        </span>
      }
    >
      Thank you for signing up
    </MonetAuthTitle>
  );
};

export default DoneStep;
