import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, FormControl, FormHelperText, FormLabel, Stack, styled, Typography } from "@mui/material";
import { ReactComponent as UploadIcon } from "assets/upload-icon.svg";
import AlertView from "components/AlertView/AlertView";
import { TextField } from "mui-rff";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useForm, useFormState } from "react-final-form";
import { convertFileToBase64, convertImageUrlToFileInput } from "utils/file";
import PageTitle from "./PageTitle";

const DropzoneContainer = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  gap: 8,
  border: `1px ${theme.palette.primary.main} dotted`,
  borderSpacing: 4,
  fontWeight: 600,
  "& button": {
    fontWeight: 600,
  },
  padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));
const fileSizeErrorMessage = "Height and width of image must be minimum 200px and maximum 400px";
const PartnerLogoSection: React.FC<{ hideTitle?: boolean; hideLabel?: boolean }> = ({ hideTitle = false, hideLabel }) => {
  const { change } = useForm();
  const { values } = useFormState();
  const logoDetails = values?.logo || {};
  const [errorMessage, setErrorMessage] = useState<string>();
  useEffect(() => {
    if (values?.defaultConfiguration?.logoUrl) {
      convertImageUrlToFileInput(values?.defaultConfiguration?.logoUrl).then((res) => {
        change("logo", res);
      });
    }
  }, []);
  const onDrop = React.useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setErrorMessage(undefined);

    const maximumSize = 400;
    const minimumSize = 200;

    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = async () => {
      if (img.width >= minimumSize && img.width <= maximumSize && img.height >= minimumSize && img.height <= maximumSize) {
        const fileBase64 = (await convertFileToBase64(file)) as string;
        const fileType = file.type.split("/")[1];
        change("logo", { file: fileBase64, type: fileType, name: file.name });
        change("defaultConfiguration.logoUrl", new Date().getTime());
        window.URL.revokeObjectURL(img.src);
        return true;
      }
      setErrorMessage(fileSizeErrorMessage);
      window.URL.revokeObjectURL(img.src);
      return false;
    };
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false });

  const removeLogo = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setErrorMessage(undefined);
    change("logo", { file: "", type: "", name: "" });
    change("defaultConfiguration.logoUrl", "");
  }, []);

  return (
    <Box>
      {!hideTitle && <PageTitle hasOptionalText>Does the partner have a logo?</PageTitle>}
      <Stack spacing={3}>
        <FormControl>
          {!hideLabel && <FormLabel>Partner Logo</FormLabel>}
          <Stack direction={{ md: "row" }} spacing={2}>
            {logoDetails.file && (
              <Stack direction="row" justifyContent="center" spacing={2} alignItems="center" sx={{ border: (theme) => `1px solid ${theme.palette.primary.main}`, width: "100%" }}>
                <Box p={2}>
                  <img src={logoDetails.file} style={{ maxWidth: 80, maxHeight: 80 }} />
                </Box>
                <Typography>{logoDetails.name}</Typography>
              </Stack>
            )}
            <Box {...getRootProps()} width="100%">
              <input {...getInputProps()} multiple={false} />

              <DropzoneContainer>
                {isDragActive ? (
                  <Button variant="text">Drop the files here ...</Button>
                ) : (
                  <Button variant="text" data-testid="partnerJourney-partnerLogoUploadBTN">
                    <UploadIcon />
                    {logoDetails.file ? "Click to Replace" : "Click to upload or drag and drop"}
                  </Button>
                )}

                {logoDetails.file && (
                  <Button variant="text" color="error" onClick={removeLogo}>
                    <ClearIcon /> Remove File
                  </Button>
                )}
              </DropzoneContainer>
            </Box>
          </Stack>
          <FormHelperText>{fileSizeErrorMessage}</FormHelperText>
        </FormControl>
        {errorMessage && <AlertView severity="error" title={errorMessage} />}
        {/* Fix to handle logo change */}
        <Box display="none">
          <TextField name="defaultConfiguration.logoUrl" autoComplete="off" />
        </Box>
      </Stack>
    </Box>
  );
};

export default PartnerLogoSection;
