import * as Types from 'shared/generated/ep3.graphql.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPartnersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPartnersQuery = { __typename?: 'Query', getPartners: any };

export type GetPartnerQueryVariables = Types.Exact<{
  partnerId: Types.Scalars['String']['input'];
}>;


export type GetPartnerQuery = { __typename?: 'Query', getPartner: any };

export type GetUsersQueryVariables = Types.Exact<{
  partnerId: Types.Scalars['String']['input'];
}>;


export type GetUsersQuery = { __typename?: 'Query', getUsers: any };

export type GetPartnerDocumentsQueryVariables = Types.Exact<{
  category?: Types.InputMaybe<Types.DocumentCategory>;
  partnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetPartnerDocumentsQuery = { __typename?: 'Query', getPartnerDocuments: Array<{ __typename?: 'PartnerDocument', linkText?: string | null, documentType?: Types.DocumentLinkType | null, documentId: string, category?: Types.DocumentCategory | null, target: string }> };

export type GetPartnerDocumentQueryVariables = Types.Exact<{
  documentId: Types.Scalars['String']['input'];
  partnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetPartnerDocumentQuery = { __typename?: 'Query', getPartnerDocument: { __typename?: 'PartnerDocument', documentId: string, target: string } };

export type GetApiKeyQueryVariables = Types.Exact<{
  apiKeyId: Types.Scalars['String']['input'];
}>;


export type GetApiKeyQuery = { __typename?: 'Query', getApiKey: string };


export const GetPartnersDocument = gql`
    query getPartners {
  getPartners
}
    `;

/**
 * __useGetPartnersQuery__
 *
 * To run a query within a React component, call `useGetPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPartnersQuery(baseOptions?: Apollo.QueryHookOptions<GetPartnersQuery, GetPartnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartnersQuery, GetPartnersQueryVariables>(GetPartnersDocument, options);
      }
export function useGetPartnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnersQuery, GetPartnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartnersQuery, GetPartnersQueryVariables>(GetPartnersDocument, options);
        }
export function useGetPartnersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPartnersQuery, GetPartnersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPartnersQuery, GetPartnersQueryVariables>(GetPartnersDocument, options);
        }
export type GetPartnersQueryHookResult = ReturnType<typeof useGetPartnersQuery>;
export type GetPartnersLazyQueryHookResult = ReturnType<typeof useGetPartnersLazyQuery>;
export type GetPartnersSuspenseQueryHookResult = ReturnType<typeof useGetPartnersSuspenseQuery>;
export type GetPartnersQueryResult = Apollo.QueryResult<GetPartnersQuery, GetPartnersQueryVariables>;
export const GetPartnerDocument = gql`
    query getPartner($partnerId: String!) {
  getPartner(partnerId: $partnerId)
}
    `;

/**
 * __useGetPartnerQuery__
 *
 * To run a query within a React component, call `useGetPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useGetPartnerQuery(baseOptions: Apollo.QueryHookOptions<GetPartnerQuery, GetPartnerQueryVariables> & ({ variables: GetPartnerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartnerQuery, GetPartnerQueryVariables>(GetPartnerDocument, options);
      }
export function useGetPartnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerQuery, GetPartnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartnerQuery, GetPartnerQueryVariables>(GetPartnerDocument, options);
        }
export function useGetPartnerSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPartnerQuery, GetPartnerQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPartnerQuery, GetPartnerQueryVariables>(GetPartnerDocument, options);
        }
export type GetPartnerQueryHookResult = ReturnType<typeof useGetPartnerQuery>;
export type GetPartnerLazyQueryHookResult = ReturnType<typeof useGetPartnerLazyQuery>;
export type GetPartnerSuspenseQueryHookResult = ReturnType<typeof useGetPartnerSuspenseQuery>;
export type GetPartnerQueryResult = Apollo.QueryResult<GetPartnerQuery, GetPartnerQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers($partnerId: String!) {
  getUsers(partnerId: $partnerId)
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables> & ({ variables: GetUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export function useGetUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersSuspenseQueryHookResult = ReturnType<typeof useGetUsersSuspenseQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetPartnerDocumentsDocument = gql`
    query getPartnerDocuments($category: DocumentCategory, $partnerId: String) {
  getPartnerDocuments(category: $category, partnerId: $partnerId) {
    linkText
    documentType
    documentId
    category
    target
  }
}
    `;

/**
 * __useGetPartnerDocumentsQuery__
 *
 * To run a query within a React component, call `useGetPartnerDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerDocumentsQuery({
 *   variables: {
 *      category: // value for 'category'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useGetPartnerDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<GetPartnerDocumentsQuery, GetPartnerDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartnerDocumentsQuery, GetPartnerDocumentsQueryVariables>(GetPartnerDocumentsDocument, options);
      }
export function useGetPartnerDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerDocumentsQuery, GetPartnerDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartnerDocumentsQuery, GetPartnerDocumentsQueryVariables>(GetPartnerDocumentsDocument, options);
        }
export function useGetPartnerDocumentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPartnerDocumentsQuery, GetPartnerDocumentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPartnerDocumentsQuery, GetPartnerDocumentsQueryVariables>(GetPartnerDocumentsDocument, options);
        }
export type GetPartnerDocumentsQueryHookResult = ReturnType<typeof useGetPartnerDocumentsQuery>;
export type GetPartnerDocumentsLazyQueryHookResult = ReturnType<typeof useGetPartnerDocumentsLazyQuery>;
export type GetPartnerDocumentsSuspenseQueryHookResult = ReturnType<typeof useGetPartnerDocumentsSuspenseQuery>;
export type GetPartnerDocumentsQueryResult = Apollo.QueryResult<GetPartnerDocumentsQuery, GetPartnerDocumentsQueryVariables>;
export const GetPartnerDocumentDocument = gql`
    query getPartnerDocument($documentId: String!, $partnerId: String) {
  getPartnerDocument(documentId: $documentId, partnerId: $partnerId) {
    documentId
    target
  }
}
    `;

/**
 * __useGetPartnerDocumentQuery__
 *
 * To run a query within a React component, call `useGetPartnerDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerDocumentQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useGetPartnerDocumentQuery(baseOptions: Apollo.QueryHookOptions<GetPartnerDocumentQuery, GetPartnerDocumentQueryVariables> & ({ variables: GetPartnerDocumentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartnerDocumentQuery, GetPartnerDocumentQueryVariables>(GetPartnerDocumentDocument, options);
      }
export function useGetPartnerDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerDocumentQuery, GetPartnerDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartnerDocumentQuery, GetPartnerDocumentQueryVariables>(GetPartnerDocumentDocument, options);
        }
export function useGetPartnerDocumentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPartnerDocumentQuery, GetPartnerDocumentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPartnerDocumentQuery, GetPartnerDocumentQueryVariables>(GetPartnerDocumentDocument, options);
        }
export type GetPartnerDocumentQueryHookResult = ReturnType<typeof useGetPartnerDocumentQuery>;
export type GetPartnerDocumentLazyQueryHookResult = ReturnType<typeof useGetPartnerDocumentLazyQuery>;
export type GetPartnerDocumentSuspenseQueryHookResult = ReturnType<typeof useGetPartnerDocumentSuspenseQuery>;
export type GetPartnerDocumentQueryResult = Apollo.QueryResult<GetPartnerDocumentQuery, GetPartnerDocumentQueryVariables>;
export const GetApiKeyDocument = gql`
    query getApiKey($apiKeyId: String!) {
  getApiKey(apiKeyId: $apiKeyId)
}
    `;

/**
 * __useGetApiKeyQuery__
 *
 * To run a query within a React component, call `useGetApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiKeyQuery({
 *   variables: {
 *      apiKeyId: // value for 'apiKeyId'
 *   },
 * });
 */
export function useGetApiKeyQuery(baseOptions: Apollo.QueryHookOptions<GetApiKeyQuery, GetApiKeyQueryVariables> & ({ variables: GetApiKeyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApiKeyQuery, GetApiKeyQueryVariables>(GetApiKeyDocument, options);
      }
export function useGetApiKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApiKeyQuery, GetApiKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApiKeyQuery, GetApiKeyQueryVariables>(GetApiKeyDocument, options);
        }
export function useGetApiKeySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetApiKeyQuery, GetApiKeyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetApiKeyQuery, GetApiKeyQueryVariables>(GetApiKeyDocument, options);
        }
export type GetApiKeyQueryHookResult = ReturnType<typeof useGetApiKeyQuery>;
export type GetApiKeyLazyQueryHookResult = ReturnType<typeof useGetApiKeyLazyQuery>;
export type GetApiKeySuspenseQueryHookResult = ReturnType<typeof useGetApiKeySuspenseQuery>;
export type GetApiKeyQueryResult = Apollo.QueryResult<GetApiKeyQuery, GetApiKeyQueryVariables>;