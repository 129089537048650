import { Currency, Money } from "@monet-money/money-type";
import { ReactComponent as PlusIcon } from "assets/tailwind/icons/plus.svg";
import ErrorView from "components/ErrorView/ErrorView";
import CampaignsTable from "components/Tables/CampaignsTable";
import MonetButton from "components/tailwind/MonetButton";
import MonetDropdownMenuItem from "components/tailwind/MonetDropdownMenuItem";
import MonetMetric from "components/tailwind/MonetMetric";
import MonetViewTitle from "components/tailwind/MonetViewTitle";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import React, { useEffect, useState } from "react";
import { Campaign } from "shared/types/eep.contract.types";
import { Invoice } from "utils/Invoice";
import { isInPartnerContext } from "utils/User";
import CreateCampaignDrawer from "../AddCampaign/CreateCampaignDrawer";
import { useGetCampaignsQuery, useGetInvoicesQuery } from "../graphql/queries.generated";

const ListCampaigns: React.FC = () => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [invoices, setInvoices] = useState<Invoice[]>();

  const { openModal } = useModal();
  const { user } = useSessionProvider();

  const [aggregations, setAggregations] = useState({
    activeCampaigns: 0,
    draftCampaigns: 0,
    totalFunding: new Money(0, Currency.GBP),
    completedCampaigns: 0,
  });

  const { data: campaignsData, error: campaignsError, loading: isCampaignsLoading } = useGetCampaignsQuery({ fetchPolicy: "network-only" });
  const { data: invoicesData, error: invoicesError, loading: isInvoicesLoading } = useGetInvoicesQuery({ fetchPolicy: "network-only" });

  useEffect(() => {
    if (invoicesData) {
      const parsedInvoices = JSON.parse(invoicesData.getInvoices) as Invoice[];
      setInvoices(parsedInvoices);
    }
  }, [invoicesData]);

  useEffect(() => {
    if (campaignsData) {
      const parsedCampaigns = JSON.parse(campaignsData.getCampaigns) as Campaign[];

      const totals = {
        activeCampaigns: 0,
        draftCampaigns: 0,
        totalFunding: new Money(0, Currency.GBP),
        completedCampaigns: 0,
      };

      parsedCampaigns.forEach((campaign) => {
        if (campaign.campaignStatus === "ACTIVE") {
          totals.activeCampaigns++;
          if (campaign.budgetLimit) {
            totals.totalFunding = totals.totalFunding.add(campaign.allocatedFunds.amount);
          }
        }
        if (campaign.campaignStatus === "DRAFT") {
          totals.draftCampaigns++;
        }
        if (campaign.campaignStatus === "COMPLETED") {
          totals.completedCampaigns++;
        }
      });

      setAggregations(totals);
      setCampaigns(parsedCampaigns);
    }
  }, [campaignsData]);

  if (campaignsError) {
    return <ErrorView title="Error loading campaigns" error={campaignsError} />;
  }

  return (
    <>
      <MonetViewTitle>Campaigns</MonetViewTitle>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-6">
        <MonetMetric
          loading={isCampaignsLoading}
          label="Total funding"
          tooltip="The total value of funding used across all active campaigns. This is a estimated value, no currency conversion rate has been applied"
          value={aggregations.totalFunding.format(true, true)}
        />
        <MonetMetric loading={isCampaignsLoading} label="Active campaigns" tooltip="Total campaigns in active status" value={aggregations.activeCampaigns} />
        <MonetMetric loading={isCampaignsLoading} label="Draft campaigns" tooltip="Total campaigns in draft status" value={aggregations.draftCampaigns} />
        <MonetMetric loading={isCampaignsLoading} label="Completed campaigns" tooltip="Total campaigns in completed status" value={aggregations.completedCampaigns} />
      </div>
      <CampaignsTable
        campaigns={campaigns}
        invoices={invoices}
        isLoading={isCampaignsLoading || isInvoicesLoading}
        actionItems={[
          {
            key: "create-campaign",
            visible: isInPartnerContext(user),
            isPrimaryAction: true,
            element: (
              <MonetButton size="x-small" onClick={() => openModal(CreateCampaignDrawer, {})} className="hidden sm:flex">
                <PlusIcon />
                Create campaign
              </MonetButton>
            ),
          },
          {
            key: "create-campaign-mobile",
            visible: isInPartnerContext(user),
            element: (
              <MonetDropdownMenuItem className="flex sm:hidden" onClick={() => openModal(CreateCampaignDrawer, {})}>
                Create campaign
              </MonetDropdownMenuItem>
            ),
          },
        ]}
      />
    </>
  );
};

export default ListCampaigns;
