import libphonenumber from "google-libphonenumber";
import { REGEX_NUMERIC_ONLY } from "./regex";

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

export const validatePhoneNumber = (phoneNumber: string, separatePhoneCode = false): { isValid: boolean; phoneNumber: string } => {
  if (!REGEX_NUMERIC_ONLY.test(phoneNumber)) {
    return {
      isValid: false,
      phoneNumber: phoneNumber,
    };
  }
  let phone = phoneNumber.toString();
  // Add + sign if it doesn't exist
  if (phone.substring(0, 1) !== "+") {
    phone = "+" + phone;
  }

  const parsedPhoneNumber = phoneUtil.parse(phone, "");
  const phoneNumberRegion = phoneUtil.getRegionCodeForNumber(parsedPhoneNumber);
  const isPhoneNumberValid = phoneUtil.isValidNumberForRegion(parsedPhoneNumber, phoneNumberRegion);

  const addSpace = separatePhoneCode ? " " : "";

  return {
    isValid: isPhoneNumberValid,
    phoneNumber: isPhoneNumberValid ? `+${parsedPhoneNumber.getCountryCode()}${addSpace}${parsedPhoneNumber.getNationalNumber()}`.toString() : phoneNumber,
  };
};
