import { ReactComponent as MonetLogo } from "assets/tailwind/monet-logo.svg";
import { Link } from "react-router-dom";
import { DASHBOARD_PATH } from "routing/paths";
import NavigationMenu from "./NavigationMenu";

const DesktopNavigation = () => {
  return (
    <aside className="hidden w-[260px] fixed bottom-0 start-0 z-[1] bg-white border-e border-gray-200 lg:block lg:top-0 lg:translate-x-0 lg:end-auto lg:bottom-0 dark:bg-neutral-800 dark:border-neutral-700">
      <div className="flex flex-col h-full max-h-full py-3">
        <header className="px-4 pb-5 pt-1 text-monet-blue dark:text-white hidden lg:block">
          {/* Logo */}
          <Link to={DASHBOARD_PATH}>
            <MonetLogo />
          </Link>
          {/* End Logo */}
        </header>
        <div className="h-full overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
          <NavigationMenu />
        </div>
      </div>
    </aside>
  );
};

export default DesktopNavigation;
