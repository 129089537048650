import { ReactComponent as EditIcon } from "assets/tailwind/icons/pen-line.svg";
import MonetButton from "components/tailwind/MonetButton";
import MonetCard from "components/tailwind/MonetCard";
import MonetCardTitle from "components/tailwind/MonetCardTitle";
import MonetRow from "components/tailwind/MonetRow";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import { getPartnerInitials } from "utils/Partner";
import OrganisationLogoDrawer from "./OrganisationLogoDrawer";

const OrganisationLogoSection = () => {
  const { partner } = useSessionProvider();
  const { openModal } = useModal();

  return (
    <MonetCard className="flex flex-col">
      <div className="flex flex-row justify-between gap-4 mb-4">
        <MonetCardTitle>Company Logo</MonetCardTitle>
        <MonetButton size="x-small" className="self-end" onClick={() => openModal(OrganisationLogoDrawer, {})}>
          <EditIcon />
          Edit
        </MonetButton>
      </div>
      <MonetRow>
        <div className="w-full max-w-[450px]">
          <p className="font-semibold">Logo</p>
          <p>This logo will be used thought-out the MONET platform and will be visible to your clients and talent. </p>
        </div>
        <div>
          {partner?.defaultConfiguration?.logoUrl ? (
            <img src={partner?.defaultConfiguration?.logoUrl} style={{ maxWidth: 100, maxHeight: 100 }} alt="partner-logo" />
          ) : (
            <div className="p-4 rounded-full w-[75px] h-[75px] bg-gray-200 flex justify-center items-center text-3xl font-light">{getPartnerInitials(partner!.name)}</div>
          )}
        </div>
      </MonetRow>
    </MonetCard>
  );
};

export default OrganisationLogoSection;
