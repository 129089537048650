import { Box, BoxProps, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const ExitWizard: React.FC<{ path: string; isProcessing?: boolean } & BoxProps> = ({ path, isProcessing, ...props }) => {
  const navigate = useNavigate();
  return (
    <Box textAlign="center" mt={10} {...props}>
      <Button
        color="inherit"
        disabled={isProcessing}
        onClick={() => {
          if (isProcessing) return;
          navigate(path);
        }}
      >
        Cancel Adding &amp; Exit Wizard
      </Button>
    </Box>
  );
};

export default ExitWizard;
