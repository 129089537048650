import * as Types from 'shared/generated/ep3.graphql.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePartnerBusinessMetadataMutationVariables = Types.Exact<{
  partnerBusinessMetadata: Types.PartnerBusinessMetadataInput;
}>;


export type UpdatePartnerBusinessMetadataMutation = { __typename?: 'Mutation', updatePartnerBusinessMetadata: boolean };

export type UpdateConfigurationMutationVariables = Types.Exact<{
  configuration: Types.ConfigurationInput;
}>;


export type UpdateConfigurationMutation = { __typename?: 'Mutation', updateConfiguration: boolean };

export type DeleteUserMutationVariables = Types.Exact<{
  username?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type CreateUserMutationVariables = Types.Exact<{
  user: Types.Scalars['AWSJSON']['input'];
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: any };

export type UpdatePartnerLogoMutationVariables = Types.Exact<{
  logo: Types.LogoInput;
}>;


export type UpdatePartnerLogoMutation = { __typename?: 'Mutation', updatePartnerLogo: boolean };


export const UpdatePartnerBusinessMetadataDocument = gql`
    mutation updatePartnerBusinessMetadata($partnerBusinessMetadata: PartnerBusinessMetadataInput!) {
  updatePartnerBusinessMetadata(partnerBusinessMetadata: $partnerBusinessMetadata)
}
    `;
export type UpdatePartnerBusinessMetadataMutationFn = Apollo.MutationFunction<UpdatePartnerBusinessMetadataMutation, UpdatePartnerBusinessMetadataMutationVariables>;

/**
 * __useUpdatePartnerBusinessMetadataMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerBusinessMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerBusinessMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerBusinessMetadataMutation, { data, loading, error }] = useUpdatePartnerBusinessMetadataMutation({
 *   variables: {
 *      partnerBusinessMetadata: // value for 'partnerBusinessMetadata'
 *   },
 * });
 */
export function useUpdatePartnerBusinessMetadataMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartnerBusinessMetadataMutation, UpdatePartnerBusinessMetadataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePartnerBusinessMetadataMutation, UpdatePartnerBusinessMetadataMutationVariables>(UpdatePartnerBusinessMetadataDocument, options);
      }
export type UpdatePartnerBusinessMetadataMutationHookResult = ReturnType<typeof useUpdatePartnerBusinessMetadataMutation>;
export type UpdatePartnerBusinessMetadataMutationResult = Apollo.MutationResult<UpdatePartnerBusinessMetadataMutation>;
export type UpdatePartnerBusinessMetadataMutationOptions = Apollo.BaseMutationOptions<UpdatePartnerBusinessMetadataMutation, UpdatePartnerBusinessMetadataMutationVariables>;
export const UpdateConfigurationDocument = gql`
    mutation updateConfiguration($configuration: ConfigurationInput!) {
  updateConfiguration(configuration: $configuration)
}
    `;
export type UpdateConfigurationMutationFn = Apollo.MutationFunction<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>;

/**
 * __useUpdateConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConfigurationMutation, { data, loading, error }] = useUpdateConfigurationMutation({
 *   variables: {
 *      configuration: // value for 'configuration'
 *   },
 * });
 */
export function useUpdateConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>(UpdateConfigurationDocument, options);
      }
export type UpdateConfigurationMutationHookResult = ReturnType<typeof useUpdateConfigurationMutation>;
export type UpdateConfigurationMutationResult = Apollo.MutationResult<UpdateConfigurationMutation>;
export type UpdateConfigurationMutationOptions = Apollo.BaseMutationOptions<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($username: String) {
  deleteUser(username: $username)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($user: AWSJSON!) {
  createUser(user: $user)
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdatePartnerLogoDocument = gql`
    mutation updatePartnerLogo($logo: LogoInput!) {
  updatePartnerLogo(logo: $logo)
}
    `;
export type UpdatePartnerLogoMutationFn = Apollo.MutationFunction<UpdatePartnerLogoMutation, UpdatePartnerLogoMutationVariables>;

/**
 * __useUpdatePartnerLogoMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerLogoMutation, { data, loading, error }] = useUpdatePartnerLogoMutation({
 *   variables: {
 *      logo: // value for 'logo'
 *   },
 * });
 */
export function useUpdatePartnerLogoMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartnerLogoMutation, UpdatePartnerLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePartnerLogoMutation, UpdatePartnerLogoMutationVariables>(UpdatePartnerLogoDocument, options);
      }
export type UpdatePartnerLogoMutationHookResult = ReturnType<typeof useUpdatePartnerLogoMutation>;
export type UpdatePartnerLogoMutationResult = Apollo.MutationResult<UpdatePartnerLogoMutation>;
export type UpdatePartnerLogoMutationOptions = Apollo.BaseMutationOptions<UpdatePartnerLogoMutation, UpdatePartnerLogoMutationVariables>;