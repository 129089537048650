import { Box, styled } from "@mui/material";

const CenteredMainContainer = styled(Box)(() => ({
  maxWidth: 1220,
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  marginLeft: "auto",
  marginRight: "auto",
  justifyContent: "center",
}));

export default CenteredMainContainer;
