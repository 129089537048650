import { Money, MoneyStorage } from "@monet-money/money-type";
import { Percentage } from "@monet-money/percentage-type";
import { ReactComponent as CopyIcon } from "assets/tailwind/icons/copy.svg";
import { ReactComponent as ExternalLink } from "assets/tailwind/icons/external-link.svg";
import { ReactComponent as DeleteIcon } from "assets/tailwind/icons/trash-2.svg";
import ErrorView from "components/ErrorView/ErrorView";
import LoadingView from "components/Loaders/LoadingView";
import MonetBorderedCardContent from "components/tailwind/MonetBorderedCardContent";
import MonetButton from "components/tailwind/MonetButton";
import MonetCard from "components/tailwind/MonetCard";
import MonetCardTitle from "components/tailwind/MonetCardTitle";
import MonetDropdownMenuItem from "components/tailwind/MonetDropdownMenuItem";
import MonetLabelledItem from "components/tailwind/MonetLabelledItem";
import MonetLink from "components/tailwind/MonetLink";
import MonetMetric from "components/tailwind/MonetMetric";
import MonetViewTitle from "components/tailwind/MonetViewTitle";
import StatusBadge from "components/tailwind/StatusBadge";
import TailwindDropdown from "components/tailwind/headlessTailwind/TailwindDropdown";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { getPayoutTotalDetails } from "shared/logic/payouts";
import { Campaign, FullPayout } from "shared/types/eep.contract.types";
import { toast } from "sonner";
import { generatePayoutLink } from "utils/Payouts";
import { isInAdminContext } from "utils/User";
import { DATETIME_STRING_FORMAT } from "utils/date";
import { enumToNiceString } from "utils/strings";
import { CAMPAIGNS_DETAILS_PATH, CAMPAIGNS_PATH } from "views/campaigns/CampaignsPath";
import { useGetCampaignLazyQuery } from "views/campaigns/graphql/queries.generated";
import { useGetPayoutQuery } from "../graphql/queries.generated";
import BeneficiaryDetailsModel from "./components/BeneficiaryDetailsModel";
import DeletePayoutModal from "./components/DeletePayoutModal";
import UpdatePayoutStatusDrawer from "./components/UpdatePayoutStatusDrawer";

type PayoutWithPartner = FullPayout & { createdAt: string; updatedAt: string; partner: { name: string; partnerId: string } };
const PayoutDetails: React.FC = () => {
  const [payout, setPayout] = useState<PayoutWithPartner>();
  const [campaign, setCampaign] = useState<Campaign>();

  const navigate = useNavigate();
  const { search } = useLocation();
  const { user } = useSessionProvider();
  const { openModal } = useModal();

  const payoutId = new URLSearchParams(search).get("payoutId");

  const {
    data: payoutData,
    error: payoutError,
    loading: isPayoutLoading,
    refetch: refetchPayout,
  } = useGetPayoutQuery({ fetchPolicy: "network-only", variables: { payoutId: payoutId as string } });
  const [getCampaign, { data: campaignData, error: campaignError, loading: isCampaignLoading }] = useGetCampaignLazyQuery();

  useEffect(() => {
    refetchPayout({ payoutId: payoutId as string });
  }, [payoutId]);

  useEffect(() => {
    if (campaignData) {
      const campaignResponse = JSON.parse(campaignData?.getCampaign) as Campaign[];
      setCampaign(campaignResponse[0]);
    }
  }, [campaignData]);

  useEffect(() => {
    if (payoutData) {
      const payoutResponse = JSON.parse(payoutData?.getPayout) as PayoutWithPartner;
      setPayout(payoutResponse);
      getCampaign({ variables: { campaignId: payoutResponse.campaignId, partnerId: payoutResponse.partner.partnerId } });
    }
  }, [payoutData]);

  const payoutLink = useMemo(() => generatePayoutLink(payout?.payoutId), [payout?.payoutId]);

  const payoutAmountDetails = useMemo(() => {
    if (payout) {
      return getPayoutTotalDetails(
        payout.payoutValue as MoneyStorage,
        Percentage.fromStorageValue(payout.feeSettings.monetFee),
        Percentage.fromStorageValue(payout.feeSettings.partnerFee),
        payout.feeSettings.chargeCreator,
      );
    }
    return {
      monetFee: new Money(0),
      partnerFee: new Money(0),
      fees: new Money(0),
      earlyPayTotal: new Money(0),
    };
  }, [payout]);

  const showStatusUpdateAction = useMemo(() => {
    if (payout && isInAdminContext(user)) {
      return ["BENEFICIARY_ACCEPTED", "MONET_REJECTED", "PAYMENT_FAILED"].includes(payout.payoutStatus);
    }
    return false;
  }, [payout, user]);

  const showDeleteAction = useMemo(() => {
    if (payout) {
      return ["MONET_REJECTED", "PAYMENT_FAILED", "EXPIRED", "CREATED"].includes(payout.payoutStatus);
    }
    return false;
  }, [payout]);

  if (payoutError || campaignError) {
    return <ErrorView title="Error getting payout details." error={payoutError ?? campaignError} />;
  }

  if ((isPayoutLoading && !payout) || isCampaignLoading) {
    return <LoadingView title="Loading payout details" />;
  }

  if (!payout) {
    return <ErrorView title="Payout failed to load" />;
  }

  return (
    <>
      <div className="flex flex-row flex-wrap gap-4 justify-between items-center">
        <MonetViewTitle>
          <span className="text-base">{campaign?.campaignName}</span>&nbsp;/&nbsp;{payout.payoutId}
        </MonetViewTitle>
        {(showDeleteAction || showStatusUpdateAction) && (
          <TailwindDropdown
            menuButtonProps={{
              children: "Actions",
              size: "x-small",
            }}
            showChevron
            footerChildren={
              showDeleteAction && (
                <MonetDropdownMenuItem variant="danger" icon={<DeleteIcon />} onClick={() => openModal(DeletePayoutModal, { payout: payout })}>
                  Delete payout
                </MonetDropdownMenuItem>
              )
            }
          >
            {showStatusUpdateAction && (
              <MonetDropdownMenuItem onClick={() => openModal(UpdatePayoutStatusDrawer, { payout: payout, refetchPayout: refetchPayout })}>Update status</MonetDropdownMenuItem>
            )}
          </TailwindDropdown>
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-6">
        <MonetMetric label="Status" value={<StatusBadge type="payout" text={payout.payoutStatus} />} />
        <MonetMetric label="EarlyPay total" value={payoutAmountDetails.earlyPayTotal.format(true, true)} tooltip="This is the total amount the payee will receive including fees" />
        <MonetMetric label="MONET fee" value={payoutAmountDetails.monetFee.format(true, true)} tooltip="The total MONET fee associated with this payout." />
        <MonetMetric
          label="Agency fee"
          value={
            payout?.feeSettings.chargeCreator === true &&
            Money.fromStorageType(payout.payoutValue as MoneyStorage)
              .multiply(Percentage.fromStorageValue(payout.feeSettings.partnerFee).percentage)
              .format(true, true)
          }
          tooltip={`Any additional fee ${payout.partner.name} have added to this payout`}
        />
      </div>
      <MonetCard>
        <MonetCardTitle>Details</MonetCardTitle>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <MonetBorderedCardContent>
            <MonetLabelledItem label="Invoice ID" value={payout.invoiceId} />
            <MonetLabelledItem label="Payout ID" value={payout.payoutId} />
            <MonetLabelledItem label="Payout reference" value={payout.payoutRef} />
            <MonetLabelledItem
              label="Payee"
              value={
                <MonetButton variant="link" size="fit-content" onClick={() => openModal(BeneficiaryDetailsModel, { payee: payout.payee })}>
                  {payout.payee.email}
                  <ExternalLink width={14} height={14} />
                </MonetButton>
              }
            />
            <MonetLabelledItem
              label="Campaign"
              value={
                <MonetButton
                  variant="link"
                  size="fit-content"
                  onClick={() => {
                    navigate(
                      generatePath(CAMPAIGNS_PATH + CAMPAIGNS_DETAILS_PATH, {
                        campaignId: encodeURIComponent(payout.campaignId),
                        partnerId: encodeURIComponent(payout.partnerId),
                      }),
                    );
                  }}
                >
                  {campaign?.campaignName}
                  <ExternalLink width={14} height={14} />
                </MonetButton>
              }
            />
            <MonetLabelledItem label="Payout type" value={enumToNiceString(payout.payoutType)} />
            <MonetLabelledItem label="Created date" value={dayjs(payout.createdAt).format(DATETIME_STRING_FORMAT)} />
            <MonetLabelledItem label="Accepted date" value={payout.termsAndConditions && dayjs(payout.termsAndConditions.timestamp).format(DATETIME_STRING_FORMAT)} />
            <MonetLabelledItem label="Expiry date" value={payout.expiryDate && dayjs(payout.expiryDate).format(DATETIME_STRING_FORMAT)} />
          </MonetBorderedCardContent>
          <MonetBorderedCardContent>
            <MonetLabelledItem
              label="Payout total"
              value={Money.fromStorageType(payout.payoutValue as MoneyStorage).format(true, true)}
              tooltip="The total value of this payout excluding any fees"
            />
            <MonetLabelledItem label="MONET fee" value={Percentage.fromStorageValue(payout.feeSettings.monetFee).format()} />
            <MonetLabelledItem
              label="Days funded"
              value={payout.feeSettings.daysFunded}
              tooltip="The amount of days between this payouts created date and the associated invoice's due date"
            />
            <MonetLabelledItem
              label="Charge fee to payee"
              value={payout.feeSettings.chargeCreator ? "Yes" : "No"}
              tooltip="Whether or not the MONET fee has been charged to the payee"
            />
            <MonetLabelledItem
              label={`Fees covered by partner`}
              value={payout?.feeSettings.chargeCreator === false ? payoutAmountDetails.monetFee.format(true, true) : undefined}
              tooltip={`Any charge to ${payout.partner.name} for this payout`}
            />
          </MonetBorderedCardContent>
        </div>
      </MonetCard>
      {payout.paymentDetails && (
        <MonetCard>
          <MonetCardTitle>Payment details</MonetCardTitle>
          <MonetBorderedCardContent className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <MonetLabelledItem arrangement="row" label="Status" value={payout.paymentDetails?.status} />
            <MonetLabelledItem arrangement="row" label="Payment ID" value={payout.paymentDetails?.paymentId} />
            <MonetLabelledItem
              arrangement="row"
              label="Created date"
              value={payout.paymentDetails?.createdAt && dayjs(payout.paymentDetails?.createdAt).format(DATETIME_STRING_FORMAT)}
            />
            <MonetLabelledItem
              arrangement="row"
              label="Estimated arrival date"
              value={payout.paymentDetails?.estimatedArrival && dayjs(payout.paymentDetails?.estimatedArrival).format(DATETIME_STRING_FORMAT)}
            />
            <MonetLabelledItem
              arrangement="row"
              label="Paid date"
              value={payout.paymentDetails?.payoutPaidDate && dayjs(payout.paymentDetails?.payoutPaidDate).format(DATETIME_STRING_FORMAT)}
            />
            {isInAdminContext(user) && <MonetLabelledItem arrangement="row" label="Payment error" value={payout.paymentDetails?.paymentError} />}
          </MonetBorderedCardContent>
        </MonetCard>
      )}
      {payout.payoutStatus === "CREATED" && (
        <MonetCard>
          <MonetCardTitle tooltip="The link to Monet checkout. This is where the payee claims the payout">Payout link</MonetCardTitle>
          <MonetBorderedCardContent className="flex flex-row gap-4 justify-between items-center">
            <p className="text-sm">{payoutLink}</p>
            <MonetButton
              variant="outlined"
              size="x-small"
              onClick={() => {
                navigator.clipboard.writeText(payoutLink);
                toast.success("Success", { description: "Payout link copied to clipboard" });
              }}
            >
              <CopyIcon />
            </MonetButton>
          </MonetBorderedCardContent>
        </MonetCard>
      )}
      <MonetCard>
        <MonetCardTitle tooltip="The terms and conditions that were agreed when the payee accepted the payout">Terms and conditions</MonetCardTitle>
        <MonetBorderedCardContent>
          {payout.termsAndConditions && (
            <MonetLink href={payout.termsAndConditions.link} className="text-monet-blue font-semibold" showIcon={true}>
              Terms and conditions
            </MonetLink>
          )}
        </MonetBorderedCardContent>
      </MonetCard>
    </>
  );
};

export default PayoutDetails;
