import { InputAdornment } from "@mui/material";
import getSymbolFromCurrency from "currency-symbol-map";
import { TextField, TextFieldProps } from "mui-rff";
import { useFormState } from "react-final-form";
import { handleWheel } from "utils/inputs";

const CurrencyTextField = (props: TextFieldProps) => {
  const { values } = useFormState();

  const selectedCurrency = values?.limits?.global.currency;
  return (
    <TextField
      {...props}
      type="number"
      onWheel={handleWheel}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {selectedCurrency !== "GBP" ? selectedCurrency : ""}
            {getSymbolFromCurrency(selectedCurrency)}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CurrencyTextField;
