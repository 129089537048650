/**
 *
 * @param value string
 * @returns string
 */
export const formatSortCode = (value: string): string => {
  let newValue = (value ?? "").toString().replace(/\D/g, "");
  if (newValue.length >= 2) {
    newValue = newValue.substring(0, 2) + "-" + newValue.substring(2);
  }
  if (newValue.length >= 5) {
    newValue = newValue.substring(0, 5) + "-" + newValue.substring(5);
  }
  return newValue;
};

/**
 * Format bsb number
 * @param value string
 * @returns string
 */
export const formatBsbNumber = (value: string): string => {
  let newValue = (value ?? "").toString().replace(/\D/g, "");
  if (newValue.length >= 3) {
    newValue = newValue.substring(0, 3) + "-" + newValue.substring(3);
  }
  return newValue;
};

export const beneficiaryDetailsToBankAccountInfo = (details: Components.Schemas.Payee): { friendlyName: string; creditPartyIdentifier: string } => {
  let friendlyName: string | undefined = "";
  if ("firstName" in details && "lastName" in details) {
    friendlyName = `${details.firstName} ${details.lastName}`;
  } else {
    friendlyName = details.businessName ?? details.firstName ?? "";
  }

  let creditPartyIdentifier: string | undefined = "";
  if ("accountNumber" in details) {
    const accountNo = details.accountNumber!.slice(-2).padStart(8, "*");
    creditPartyIdentifier = accountNo;
  } else {
    creditPartyIdentifier = details.routingNumber ?? details.bankSwiftBic ?? details.accountNumber;
    const length = creditPartyIdentifier?.length ?? 0;
    creditPartyIdentifier = creditPartyIdentifier?.slice(-4).padStart(length, "*") ?? "";
  }
  return { friendlyName, creditPartyIdentifier };
};

export const accountTypeToTransactionType = (accountType: Components.Schemas.Payee["accountType"]): string | undefined => {
  if (accountType == "BUSINESS") return "B2B";
  if (accountType == "PERSONAL") return "B2C";
  return undefined;
};

export const formatAccountNumber = (value: string) => {
  return (value ?? "").replace(/\D/g, "");
};

export type CollectionAccount = {
  accountName: string;
  accountNumber: string;
  bankName: string;
  currency: string;
  iban: string;
  sortCode: string;
  swiftCode: string;
};
