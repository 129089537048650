import { Box, FormControl, FormLabel, Stack, Typography } from "@mui/material";
import FlexRow from "components/FlexRow/FlexRow";
import { PercentageTextField } from "components/Forms/PercentageTextField";
import { TextField } from "mui-rff";

import React from "react";
import { useForm } from "react-final-form";
import { handleWheel, limitDecimalPlaces } from "utils/inputs";
import PageTitle from "./PageTitle";

const FeeSettings: React.FC = () => {
  const { change } = useForm();
  return (
    <Box>
      <PageTitle>Fee rules</PageTitle>

      <Box mb={3}>
        <Stack spacing={3}>
          <FormControl fullWidth>
            <FormLabel>Maximum fee %</FormLabel>
            <PercentageTextField
              data-testid="partnerJourney-feeRules-maxFeeField"
              name="defaultConfiguration.feeSettings.max"
              InputProps={{
                onChange: (e) => change("defaultConfiguration.feeSettings.max", limitDecimalPlaces(e.target.value, 2)),
              }}
            />
          </FormControl>

          <FormControl fullWidth>
            <FormLabel>Default fee %</FormLabel>
            <PercentageTextField
              data-testid="partnerJourney-feeRules-defaultFeeField"
              name="defaultConfiguration.feeSettings.default"
              InputProps={{
                onChange: (e) => change("defaultConfiguration.feeSettings.default", limitDecimalPlaces(e.target.value, 2)),
              }}
            />
          </FormControl>

          <FormControl fullWidth>
            <FlexRow mb={2} flexDirection="row">
              <FormLabel>EarlyPay payout duration</FormLabel>
              <Typography variant="body2" textAlign="right" sx={{ color: (theme) => theme.palette.text.disabled }}>
                The hours an EarlyPay payout will be valid before expiry
              </Typography>
            </FlexRow>
            <TextField
              name="defaultConfiguration.payoutTtlDelta"
              data-testid="partnerJourney-epPayoutDurationField"
              InputProps={{
                endAdornment: <>hours</>,
              }}
              type="number"
              onWheel={handleWheel}
              inputProps={{ min: "0", max: "1000", step: "1" }}
              placeholder="Type hours here"
              autoComplete="off"
            />
          </FormControl>
        </Stack>
      </Box>
    </Box>
  );
};

export default FeeSettings;
