import { validatePhoneNumber } from "shared/utils/phone";
import { REGEX_NAME_SPECIAL_CHARS } from "shared/utils/regex";
import { getRequiredMessage } from "shared/utils/validators";
import { SchemaOf, boolean, object, string } from "yup";

export type MFAOptions = "SMS" | "TOTP" | "NOMFA";

export type MFASettings = {
  mfaEnabled: boolean;
  mfaMethod: MFAOptions;
};

export type UserProfileFormObject = {
  mfaEnabled: boolean;
  mfaMethod?: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  role: string;
};

export const MFASchema = <SchemaOf<MFASettings>>object().shape({
  mfaEnabled: boolean().required(),
  mfaMethod: string().when("mfaEnabled", {
    is: true,
    then: string().oneOf(["SMS", "TOTP"]).required(),
    otherwise: string().notRequired(),
  }),
});

export const editUserProfileSchema = <SchemaOf<UserProfileFormObject>>object().shape({
  mfaEnabled: boolean().required(),
  mfaMethod: string().when("mfaEnabled", {
    is: true,
    then: string().oneOf(["SMS", "TOTP"]).required("Please select a MFA method"),
    otherwise: string().notRequired(),
  }),
  firstName: string()
    .min(2, "Must be at least 2 characters")
    .matches(REGEX_NAME_SPECIAL_CHARS, "First Name can only contain letters, hyphens, and apostrophes")
    .required(getRequiredMessage("First Name")),
  lastName: string()
    .min(2, "Must be at least 2 characters")
    .matches(REGEX_NAME_SPECIAL_CHARS, "Last Name can only contain letters, hyphens, and apostrophes")
    .required(getRequiredMessage("Last Name")),
  role: string()
    .min(2, "Must be at least 2 characters")
    .matches(REGEX_NAME_SPECIAL_CHARS, "Role can only contain letters, hyphens, and apostrophes")
    .required(getRequiredMessage("Role")),
  phoneNumber: string()
    .test("validate-phone-number", "Enter a valid phone number", (value, ctx) => {
      if (!value) return false;
      try {
        return validatePhoneNumber(value).isValid;
      } catch (error) {
        return ctx.createError({
          message: "Enter a valid phone number",
        });
      }
    })
    .required("Phone number is a required field"),
});
