import { ReactComponent as Cross } from "assets/tailwind/icons/cross.svg";
import { ReactComponent as Tick } from "assets/tailwind/icons/tick.svg";
import { HSStrongPassword } from "preline/preline";
import { useEffect } from "react";

type MonetPasswordHintProps = {
  input: React.ReactNode;
  inputId: string;
};

const MonetPasswordHint: React.FC<MonetPasswordHintProps> = ({ input, inputId }) => {
  useEffect(() => {
    HSStrongPassword.autoInit();
  }, []);

  return (
    <div className="relative flex-1">
      <div className="flex mb-2">
        <div className="flex-1">
          {input}
          <div
            id="hs-strong-password"
            data-hs-strong-password={`{
              "target": "#${inputId}",
              "hints": "#hs-strong-password-hints",
              "mode": "popover",
              "minLength": "12",
              "stripClasses": "hs-strong-password:opacity-100 hs-strong-password-accepted:bg-teal-500 h-2 flex-auto rounded-full bg-blue-500 opacity-50 mx-1"
            }`}
            className="flex mt-2 -mx-1"
          ></div>
        </div>
      </div>
      <div
        id="hs-strong-password-hints"
        className="hidden mb-3 absolute rounded-lg border shadow-lg min-w-[350px] bg-white p-4 z-20 w-full dark:border-neutral-700 dark:bg-neutral-900"
      >
        <h4 className="mb-2 text-sm font-semibold text-gray-800 dark:text-white">Your password must contain:</h4>
        <ul className="space-y-1 text-sm text-gray-500 dark:text-neutral-500">
          <li data-hs-strong-password-hints-rule-text="min-length" className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2">
            <span className="hidden" data-check="">
              <Tick />
            </span>
            <span data-uncheck="">
              <Cross />
            </span>
            Minimum number of characters is 12.
          </li>
          <li data-hs-strong-password-hints-rule-text="lowercase" className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2">
            <span className="hidden" data-check="">
              <Tick />
            </span>
            <span data-uncheck="">
              <Cross />
            </span>
            Should contain lowercase.
          </li>
          <li data-hs-strong-password-hints-rule-text="uppercase" className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2">
            <span className="hidden" data-check="">
              <Tick />
            </span>
            <span data-uncheck="">
              <Cross />
            </span>
            Should contain uppercase.
          </li>
          <li data-hs-strong-password-hints-rule-text="numbers" className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2">
            <span className="hidden" data-check="">
              <Tick />
            </span>
            <span data-uncheck="">
              <Cross />
            </span>
            Should contain numbers.
          </li>
          <li data-hs-strong-password-hints-rule-text="special-characters" className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2">
            <span className="hidden" data-check="">
              <Tick />
            </span>
            <span data-uncheck="">
              <Cross />
            </span>
            Should contain special characters.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MonetPasswordHint;
