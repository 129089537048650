import { MonetBadgeVariants } from "components/tailwind/MonetBadge";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Campaign } from "shared/types/eep.contract.types";
import { splitCamelCaseString } from "shared/utils/string";

dayjs.extend(utc);

export type CurrentViewType = "upload" | "review" | "summary";

export type DropdownOption = { label: string; value: string };

export type RequiredFieldData = {
  name: string;
  label: string;
  placeholder?: string;
  subTitle?: string;
  options?: { label: string; value: string }[];
};

export type PayeeField = { key: string; value: boolean };

export const generatePayoutLink = (payoutId?: string): string => `${process.env.REACT_APP_EEP_CHECKOUT_DOMAIN}/?payoutId=${payoutId}`;

export const getDaysFunded = (daysFunded: number) => {
  if (daysFunded) {
    return `${daysFunded} ${daysFunded > 1 ? "days" : "day"}`;
  }

  return "N/A";
};

export const getCampaignField = <T extends keyof Campaign>(campaignAttribute: T, campaignId: string, campaigns: Campaign[]): string | undefined => {
  const campaign = campaigns.find((campaign) => campaign.campaignId === campaignId);
  if (campaign) {
    return campaign[campaignAttribute] as string;
  }

  return undefined;
};

export const getPayeeRequiredFieldKeys = (obj: Record<string, any>): PayeeField[] => {
  let keys: PayeeField[] = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        keys = keys.concat(getPayeeRequiredFieldKeys(obj[key]).map((nestedKey) => nestedKey));
      } else if (!["accountType", "country", "popCode", "email", "currency", "phoneNumber", "firstName"].includes(key) && !keys.find((x) => x.key == key) && !!obj[key]) {
        keys.push({ key, value: obj[key] });
      }
    }
  }

  return [...new Set(keys)];
};

export const getLocalisedFieldName = (field: string, selectedCountry?: string) => {
  switch (field.toLowerCase()) {
    case "routingnumber":
      if (selectedCountry == "GBR") {
        return "Sort code";
      } else if (selectedCountry == "AUS") {
        return "BSB number";
      } else if (selectedCountry == "USA") {
        return "ABA routing number";
      }
      return "Routing number";
    case "accountnumber":
      if (["GBR", "AUS"].includes(selectedCountry!)) {
        return "Account number";
      }
      return "IBAN / Account number";
    case "bankswiftbic":
      return "Swift / BIC code";

    default:
      return splitCamelCaseString(field);
  }
};

export const payoutStatuses: Components.Schemas.Payout["status"][] = [
  "CREATED",
  "BENEFICIARY_ACCEPTED",
  "MONET_ACCEPTED",
  "BENEFICIARY_PAID",
  "SETTLED",
  "MONET_REJECTED",
  "PAYMENT_FAILED",
  "PAYMENT_PROCESSING",
  "EXPIRED",
];

export type StatusSetting = {
  color: MonetBadgeVariants["variant"];
  transitions: Array<Components.Schemas.Payout["status"]>;
};

export const getPayoutStatusSettings = (status: Components.Schemas.Payout["status"]): StatusSetting => {
  const statusSettings: Record<Components.Schemas.Payout["status"], StatusSetting> = {
    CREATED: {
      color: "gray",
      transitions: [],
    },
    BENEFICIARY_ACCEPTED: {
      color: "blue",
      transitions: ["MONET_ACCEPTED", "MONET_REJECTED"],
    },
    MONET_ACCEPTED: {
      color: "blue",
      transitions: [],
    },
    BENEFICIARY_PAID: {
      color: "green",
      transitions: [],
    },
    SETTLED: {
      color: "purple",
      transitions: [],
    },
    MONET_REJECTED: {
      color: "red",
      transitions: ["CREATED"],
    },
    PAYMENT_PROCESSING: {
      color: "blue",
      transitions: [],
    },
    PAYMENT_FAILED: {
      color: "red",
      transitions: ["MONET_ACCEPTED"],
    },
    EXPIRED: {
      color: "yellow",
      transitions: [],
    },
  };

  return (
    statusSettings[status] || {
      color: "gray",
      transitions: [],
    }
  );
};

export const PAYOUT_TYPES_LIST = ["VENDOR", "WITHDRAWAL"];
