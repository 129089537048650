import { Money } from "@monet-money/money-type";
import { Percentage } from "@monet-money/percentage-type";
import { removeCommas } from "shared/utils/string";
import { SchemaOf, boolean, object, string } from "yup";

export type PayoutDetailsFormObject = {
  campaign?: string;
  payee?: string;
  payoutReference?: string;
  invoiceId?: string;
};

export type PayoutSettingsFormObject = {
  payoutRequest?: string;
  chargeFeeToVendor?: boolean;
  addAgencyFee?: boolean;
  agencyFee?: string;
};

export type PayoutSummaryFormObject = {
  confirmDetails: boolean;
};

export const defaultPayoutDetailsValue: PayoutDetailsFormObject = {
  campaign: undefined,
  invoiceId: undefined,
  payee: undefined,
};

export const defaultPayoutSettingsValue: PayoutSettingsFormObject = {
  payoutRequest: undefined,
  chargeFeeToVendor: true,
  addAgencyFee: false,
  agencyFee: undefined,
};

export const defaultPayoutSummaryValue: PayoutSummaryFormObject = {
  confirmDetails: false,
};

export type CreatePayoutFormObject = PayoutDetailsFormObject & PayoutSettingsFormObject & PayoutSummaryFormObject;

export const createPayoutDefaultValues = {
  ...defaultPayoutDetailsValue,
  ...defaultPayoutSettingsValue,
  ...defaultPayoutSummaryValue,
};

export const addPayoutSchema = <SchemaOf<CreatePayoutFormObject>>object().shape({
  campaign: string().required("Please select a campaign"),
  invoiceId: string().required("Please select an invoice"),
  payee: string().optional(),
  chargeFeeToVendor: boolean().optional(),
  payoutReference: string().required("Payout reference is a required field"),
  payoutRequest: string()
    .test("validate-payout-request", "Please enter a valid payout request amount", (value, ctx: any) => {
      if (value && !isNaN(Number(removeCommas(value)))) {
        if (Number(removeCommas(value)) <= 0) {
          return ctx.createError({
            message: `Payout request amount must be greater than 0`,
            path: `payoutRequest`,
          });
        } else {
          const remainingFunds = ctx.options.context.remainingFunds as Money;
          const valueMoney = new Money(removeCommas(value)!, remainingFunds.currency);

          if (valueMoney.amount > remainingFunds.amount) {
            return ctx.createError({
              message: `Payout request can not exceed remaining funds of ${remainingFunds.format(true, true)}`,
              path: `payoutRequest`,
            });
          }
          return true;
        }
      }
      return true;
    })
    .required("Please enter a payout request amount"),
  addAgencyFee: boolean().optional(),
  agencyFee: string().when("addAgencyFee", {
    is: true,
    then: string()
      .required("Please enter your additional agency fee")
      .test("exceeds-maxFee", "Please enter a valid agency fee", (value, ctx: any) => {
        if (value) {
          const maxFee = Percentage.fromStorageValue(ctx.options.context.maxFee);
          const agencyFee = new Percentage(value);

          if (agencyFee.percentage <= 0) {
            return ctx.createError({
              message: `Agency fee must be greater than 0`,
              path: `agencyFee`,
            });
          }

          if (agencyFee.percentage > maxFee.percentage) {
            return ctx.createError({
              message: `Agency fee can not exceed maximum fee of ${maxFee.format()}`,
              path: `agencyFee`,
            });
          }
        }
        return true;
      }),
    otherwise: string().optional(),
  }),
  confirmDetails: boolean().required("Please confirm that the above details are correct").oneOf([true], "Please confirm that the above details are correct"),
});
