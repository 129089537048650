export const handleWheel = (event: React.WheelEvent<HTMLInputElement>) => {
  event.currentTarget.querySelector("input")?.blur();
};

export const limitDecimalPlaces = (value: string, decimalPlaces = 2) => {
  const [beforeDecimal, afterDecimal] = value.toString().split(".");
  if (afterDecimal && afterDecimal.length > decimalPlaces) {
    return `${beforeDecimal}.${afterDecimal.substring(0, decimalPlaces)}`;
  }
  return value;
};
