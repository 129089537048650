import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";
import { TextareaHTMLAttributes, forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import MonetToolTip from "../MonetTooltip";

type MonetTextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> &
  VariantProps<typeof variants> & {
    id: string;
    label: string;
    error?: string;
    isOptional?: boolean;
    helperText?: string;
    tooltip?: string;
  };

const MonetTextArea: React.FC<MonetTextAreaProps> = forwardRef<HTMLTextAreaElement, MonetTextAreaProps>(
  ({ id, label, error, variant, helperText, isOptional, tooltip, ...props }, ref) => {
    const errorStyling = error ? "border-red-500 ring ring-red-100 ring-offset-0 focus:ring-red-500 focus:ring focus:ring-red-100 focus:outline-offset-0" : undefined;

    return (
      <div>
        <div className="flex flex-col sm:flex-row justify-between mb-2 text-sm">
          <label htmlFor={id} className="block font-semibold text-gray-800 dark:text-white">
            {label}
            {isOptional && <sup className="text-gray-600 font-normal">(optional)</sup>}
            {tooltip && <MonetToolTip text={tooltip} />}
          </label>
        </div>
        <textarea {...props} id={id} ref={ref} rows={6} className={twMerge(clsx(variants({ variant }), errorStyling))} />
        {helperText && <p className="text-xs ps-1 pt-1">{helperText}</p>}
        <p className="text-sm text-red-500 mt-2">{error}</p>
      </div>
    );
  },
);

export default MonetTextArea;

const variants = cva(
  "block resize-none w-full text-base sm:text-sm border rounded-lg disabled:opacity-70 disabled:pointer-events-none disabled:bg-gray-100 dark:bg-transparent dark:disabled:bg-transparent",
  {
    variants: {
      variant: {
        default:
          "border-gray-200 placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);
