import { ReactComponent as DownChevron } from "assets/tailwind/icons/chevron-down.svg";
import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";
import { HSAccordion } from "preline/preline";
import { PropsWithChildren, useEffect } from "react";
import { twMerge } from "tailwind-merge";

type MonetAccordionProps = PropsWithChildren &
  VariantProps<typeof variants> & {
    triggerElement?: React.ReactNode;
    label?: string;
    id: string;
    icon?: React.ReactNode;
  };

const MonetAccordion: React.FC<MonetAccordionProps> = ({ label, icon, id, children, size, triggerElement }) => {
  useEffect(() => {
    HSAccordion.autoInit();
  }, []);

  return (
    <div className="hs-accordion" id={"accordion-item-" + id}>
      {label && (
        <button type="button" className={twMerge(clsx(variants({ size })))}>
          {icon && <div className="text-neutral-600 hs-accordion-active:text-monet-blue">{icon}</div>}
          {label}
          <DownChevron className="hs-accordion-active:-rotate-180 flex-shrink-0 mt-1 size-3.5 ms-auto transition" />
        </button>
      )}
      {triggerElement && (
        <div>
          {triggerElement}
          <DownChevron className="hs-accordion-active:-rotate-180 flex-shrink-0 mt-1 size-3.5 ms-auto transition" />
        </div>
      )}
      <div id={"accordion-content-" + id} className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300 hidden">
        <ul className="ps-7 mt-1.5 space-y-1.5 relative before:absolute before:top-0 before:start-[18px] before:w-0.5 before:h-full before:bg-neutral-200 dark:before:bg-neutral-600">
          {children}
        </ul>
      </div>
    </div>
  );
};

export default MonetAccordion;

const variants = cva(
  "hs-accordion-toggle hs-accordion-active:text-monet-blue hs-accordion-active:hover:bg-transparent w-full text-start flex items-center gap-x-2 text-sm text-neutral-800 rounded-lg hover:bg-gray-100 dark:bg-neutral-800 dark:hover:bg-neutral-700 dark:text-white dark:hs-accordion-active:text-white",
  {
    variants: {
      size: {
        small: "py-2 px-2.5",
        default: "py-2 px-5",
      },
    },
    defaultVariants: {
      size: "default",
    },
  },
);
