import { CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";

type LoadingSpinnerProps = {
  text?: string;
  alignment?: React.CSSProperties["alignItems"];
};

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ text, alignment = "center" }) => (
  <Stack justifyContent="center" alignItems={alignment} position="relative" width="100%" height="100%">
    <Stack flexDirection="row" gap={1} alignItems="center" py={1}>
      <CircularProgress sx={{ color: "primary" }} size={25} />
      <Typography color="text.secondary">{text}</Typography>
    </Stack>
  </Stack>
);

export default LoadingSpinner;
