import React from "react";
import { Partner, getPartnerInitials } from "utils/Partner";

const PartnerIconAndName: React.FC<{ partner: Partner }> = ({ partner }) => {
  return (
    <div className="flex flex-row gap-2 items-center">
      {partner.defaultConfiguration?.logoUrl ? (
        <img className="h-[38px] w-[38px] rounded-lg" src={partner.defaultConfiguration?.logoUrl} />
      ) : (
        <div className="p-2 bg-slate-300 text-slate-800 rounded-lg h-[38px] w-[38px] flex justify-center">
          <span>{getPartnerInitials(partner.name)}</span>
        </div>
      )}
      <span>{partner.name}</span>
    </div>
  );
};

export default PartnerIconAndName;
