import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";
import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

type MonetNavListItemProps = PropsWithChildren &
  React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof variants> & {
    id: string;
    icon?: React.ReactNode;
  };

const MonetNavLinkItem: React.FC<MonetNavListItemProps> = ({ children, icon, id, variant, ...props }) => {
  return (
    <button className={twMerge(clsx(variants({ variant })))} data-testid={"nav-item-" + id} {...props}>
      <span className="text-neutral-600">{icon}</span>
      {children}
    </button>
  );
};

const variants = cva("flex items-center gap-x-2 py-2 px-5 text-sm text-neutral-800 rounded-lg hover:bg-gray-100 dark:hover:bg-neutral-700 dark:text-white w-full", {
  variants: {
    variant: {
      comingSoon: "pointer-events-none",
    },
  },
  defaultVariants: {
    variant: undefined,
  },
});

export default MonetNavLinkItem;
