import { CSSInterpolation, PaletteColor, ThemeOptions } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface PaletteOptions {
    tertiary: PaletteColor;
    lightBackground: string;
  }
  interface Palette {
    tertiary: PaletteColor;
    lightBackground: string;
  }
  interface TypographyVariants {
    body1Large: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    body1Large?: React.CSSProperties;
  }
}

declare module "@mui/material" {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
  interface SvgIconPropsColorOverrides {
    tertiary: true;
    white: true;
  }
  interface ButtonPropsVariantOverrides {
    textBold: true;
    action: true;
  }
  interface ButtonClassesOverrides {
    textBold: CSSInterpolation;
    action: true;
  }
  interface TypographyPropsVariantOverrides {
    body1Large: true;
  }
}
export const DEFAULT_BORDER_RADIUS = 5;
// A custom theme for this app
//use https://bareynol.github.io/mui-theme-creator/#Typography to preview <3
const theme: ThemeOptions = {
  // typography: {
  //   fontSize: 13,
  //   fontFamily: "'Titillium Web', sans-serif",
  //   h1: {
  //     fontSize: "2rem", //32px
  //     fontWeight: 600,
  //     lineHeight: 1.2,
  //   },
  //   h2: {
  //     fontSize: "1.625rem", //26px
  //     fontWeight: 600,
  //     lineHeight: 1.2,
  //   },
  //   h3: {},
  //   h4: {},
  //   h5: {},
  //   h6: {},
  //   body1: {},
  //   body1Large: {
  //     fontSize: "1.375rem", //22px
  //   },
  //   body2: {
  //     fontSize: "0.8125rem", //13px
  //   },
  // },
  // palette: {
  //   mode: "light",
  //   lightBackground: "#F6F6F7",
  //   text: {
  //     primary: "#170935",
  //     secondary: "#8C80A5",
  //     disabled: "#B3B1B8",
  //   },
  //   action: {
  //     disabled: "#E8E6ED",
  //   },
  //   background: {
  //     default: "#F2EFF8",
  //     paper: "#EEEBF6",
  //   },
  //   primary: {
  //     light: "#B9BFF7",
  //     main: "#162BE4",
  //     dark: "#173686",
  //     contrastText: "#F2EFF8",
  //   },
  //   secondary: {
  //     light: "#D4CBF9",
  //     main: "#7052E9",
  //     dark: "#452EA6",
  //     contrastText: "#F2EFF8",
  //   },
  //   tertiary: {
  //     light: "#B8D5F0",
  //     main: "#1273CC",
  //     dark: "#0D5BA3",
  //     contrastText: "#F2EFF8",
  //   },
  //   error: {
  //     light: "#F1BFBF",
  //     main: "#D12828",
  //     dark: "#8E0C0C",
  //     contrastText: "#F2EFF8",
  //   },
  //   success: {
  //     light: "#BCE8DB",
  //     main: "#21B186",
  //     dark: "#188A68",
  //     contrastText: "#F2EFF8",
  //   },
  //   warning: {
  //     light: "#F4C498",
  //     main: "#EA8A32",
  //     dark: "#CC721F",
  //     contrastText: "#222222",
  //   },
  //   info: {
  //     light: "#99E6EB",
  //     main: "#00C1CD",
  //     dark: "#76B1B5",
  //     contrastText: "#222222",
  //   },
  //   common: {
  //     black: "#222222",
  //     white: "#F2EFF8",
  //   },
  //   grey: {
  //     50: "#EDEAF3",
  //     100: "#E8E6EB",
  //     200: "#DCDBDE",
  //     300: "#CBC9CE",
  //     400: "#B9B7BE",
  //     500: "#514C5C",
  //     600: "#443F4E",
  //     700: "#3D3945",
  //     800: "#30323A",
  //     900: "#272930",
  //     A100: "#575757",
  //     A200: "#454545",
  //     A400: "#393939",
  //     A700: "#222222",
  //   },
  // },
  // components: {
  //   MuiBackdrop: {
  //     styleOverrides: {
  //       root: ({ theme }) => ({
  //         backgroundColor: alpha(theme.palette.common.black, 0.15),
  //       }),
  //     },
  //   },
  //   // Name of the component
  //   MuiLink: {
  //     defaultProps: {
  //       component: LinkBehaviour,
  //     } as unknown as LinkProps,
  //   },
  //   MuiButtonBase: {
  //     defaultProps: {
  //       LinkComponent: LinkBehaviour,
  //     },
  //   },
  //   MuiStack: {
  //     styleOverrides: {
  //       root: {
  //         borderRadius: DEFAULT_BORDER_RADIUS,
  //       },
  //     },
  //   },
  //   MuiPaper: {
  //     styleOverrides: {
  //       root: {
  //         borderRadius: DEFAULT_BORDER_RADIUS,
  //       },
  //     },
  //   },
  //   MuiTimelineDot: {
  //     styleOverrides: {
  //       root: {
  //         borderRadius: DEFAULT_BORDER_RADIUS,
  //       },
  //     },
  //   },
  //   MuiCssBaseline: {
  //     styleOverrides: () => ({
  //       "& .MuiBox-root": {
  //         borderRadius: DEFAULT_BORDER_RADIUS,
  //       },
  //     }),
  //   },
  //   MuiBox: {
  //     styleOverrides: {
  //       root: {
  //         borderRadius: DEFAULT_BORDER_RADIUS,
  //       },
  //     },
  //   },
  //   MuiListItemIcon: {
  //     styleOverrides: {
  //       root: {
  //         borderRadius: DEFAULT_BORDER_RADIUS,
  //       },
  //     },
  //   },
  //   MuiSvgIcon: {
  //     variants: [
  //       {
  //         props: { color: "white" },
  //         /* eslint-disable @typescript-eslint/no-shadow */
  //         style: ({ theme }) => ({
  //           color: theme.palette.common.white,
  //         }),
  //       },
  //       {
  //         props: { color: "tertiary" },
  //         /* eslint-disable @typescript-eslint/no-shadow */
  //         style: ({ theme }) => ({
  //           color: theme.palette.tertiary.main,
  //         }),
  //       },
  //     ],
  //   },
  //   MuiButton: {
  //     variants: [
  //       {
  //         props: { variant: "text" },
  //         style: {
  //           textDecoration: "none",
  //           fontWeight: 700,
  //           filter: "brightness(100%)",
  //           "&:hover": {
  //             backgroundColor: "transparent",
  //           },
  //           "&.Mui-disabled": {
  //             backgroundColor: "transparent",
  //           },
  //         },
  //       },
  //       {
  //         props: { variant: "contained" },
  //         style: {
  //           filter: "brightness(100%)",
  //         },
  //       },
  //       {
  //         props: { color: "inherit" },
  //         style: {
  //           color: "inherit",
  //         },
  //       },
  //       {
  //         props: { variant: "textBold" },
  //         style: ({ theme }) => ({
  //           fontWeight: 700,
  //           color: theme.palette.common.white,
  //         }),
  //       },
  //       {
  //         props: { variant: "outlined" },
  //         style: ({ theme }) => ({
  //           border: `1px solid ${theme.palette.text.secondary}`,
  //           backgroundColor: theme.palette.background.default,
  //           "&:hover": {
  //             backgroundColor: theme.palette.background.default,
  //             borderColor: theme.palette.text.secondary,
  //           },
  //         }),
  //       },
  //       {
  //         props: { variant: "action" },
  //         /* eslint-disable @typescript-eslint/no-shadow */
  //         style: ({ theme }) => ({
  //           padding: `0px ${theme.spacing(3)}`,
  //           border: "1px solid",
  //           textTransform: "uppercase",
  //           fontWeight: 400,
  //           lineHeight: 1.75,
  //           boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
  //         }),
  //       },
  //     ],
  //     styleOverrides: {
  //       root: ({ theme }) => ({
  //         fontWeight: 700,
  //         fontSize: 14,
  //         padding: theme.spacing(2, 3),
  //         textTransform: "uppercase",
  //         "&.Mui-disabled": {
  //           background: theme.palette.action.disabled,
  //           color: theme.palette.text.secondary,
  //         },
  //         "&:hover": {
  //           filter: "brightness(98%)",
  //         },
  //         "& .MuiLoadingButton-loadingIndicator": {
  //           position: "initial",
  //           paddingLeft: theme.spacing(2),
  //         },
  //       }),
  //       text: ({ theme, ownerState }) => ({
  //         color: ownerState.color && ownerState.color != "inherit" ? theme.palette[ownerState.color].main : theme.palette.common.black,
  //         padding: 0,
  //         "&.Mui-disabled": {
  //           color: theme.palette.text.secondary,
  //           opacity: 0.5,
  //         },
  //       }),
  //       contained: ({ theme, ownerState }) => {
  //         let background = undefined;
  //         if (ownerState.variant === "contained" && ownerState.color === "primary") {
  //           background = `linear-gradient(to bottom right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`;
  //         }
  //         return {
  //           background,
  //           backgroundSize: "100% 100%",
  //           backgroundRepeat: "no-repeat",
  //           color: theme.palette.common.white,
  //           borderRadius: DEFAULT_BORDER_RADIUS,
  //         };
  //       },
  //       outlined: ({ theme }) => ({
  //         background: theme.palette.common.white,
  //         color: theme.palette.primary.main,
  //         borderRadius: DEFAULT_BORDER_RADIUS,
  //       }),
  //       /* eslint-disable @typescript-eslint/no-shadow */
  //       // eslint-disable-next-line
  //       // @ts-ignore
  //       textBold: ({ ownerState, theme }) => ({
  //         ...(ownerState.color && ownerState.color != "inherit" && { color: theme.palette[ownerState.color].main }),
  //       }),
  //       /* eslint-disable @typescript-eslint/no-shadow */
  //       // eslint-disable-next-line
  //       // @ts-ignore
  //       action: ({ ownerState, theme }) => ({
  //         ...(ownerState.color && ownerState.color != "inherit" && { color: theme.palette[ownerState.color].main }),
  //         svg: {
  //           fontSize: "1em !important",
  //         },
  //       }),
  //     },
  //   },
  //   MuiTouchRipple: {
  //     styleOverrides: {
  //       root: {
  //         display: "none",
  //       },
  //     },
  //   },
  //   MuiToggleButton: {
  //     styleOverrides: {
  //       root: ({ theme }) => ({
  //         fontWeight: 400,
  //         color: theme.palette.primary.dark,
  //         textTransform: "none",
  //         ":hover": {
  //           backgroundColor: theme.palette.primary.light,
  //           color: theme.palette.common.black,
  //         },
  //         "&.Mui-selected:hover, &.Mui-selected": {
  //           cursor: "default",
  //           backgroundColor: theme.palette.primary.main,
  //           color: theme.palette.common.white,
  //           "&.Mui-disabled": {
  //             backgroundColor: theme.palette.grey[400],
  //           },
  //         },
  //       }),
  //     },
  //   },
  //   MuiAutocomplete: {
  //     styleOverrides: {
  //       root: () => ({
  //         "& + .MuiAutocomplete-popper": {
  //           filter: "brightness(0.95)",
  //         },
  //       }),
  //     },
  //   },
  //   MuiOutlinedInput: {
  //     styleOverrides: {
  //       root: ({ theme }) => ({
  //         borderRadius: DEFAULT_BORDER_RADIUS,
  //         "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
  //           borderColor: theme.palette.text.secondary,
  //         },
  //         "& .MuiOutlinedInput-notchedOutline": {
  //           borderColor: theme.palette.primary.main,
  //         },
  //         "&:hover .MuiOutlinedInput-notchedOutline": {
  //           borderColor: theme.palette.primary.main,
  //         },
  //         "&.Mui-disabled": {
  //           pointerEvents: "none",
  //         },
  //         // special case of increasing the fontSize to prevent zoom on mobile devices
  //         [theme.breakpoints.down("md")]: {
  //           fontSize: "16px",
  //         },
  //         "& input": {
  //           "&.Mui-readOnly": {
  //             color: theme.palette.text.secondary,
  //           },
  //         },
  //       }),
  //     },
  //   },
  //   MuiFormLabel: {
  //     styleOverrides: {
  //       root: ({ theme }) => ({
  //         fontWeight: 400,
  //         color: theme.palette.text.primary,
  //         marginBottom: theme.spacing(1),
  //         "&.Mui-disabled": {
  //           color: theme.palette.grey[400],
  //         },
  //       }),
  //     },
  //   },
  //   MuiFormHelperText: {
  //     styleOverrides: {
  //       root: {
  //         lineHeight: 1.1,
  //         marginLeft: 0,
  //         fontSize: "inherit",
  //       },
  //     },
  //   },
  //   MuiToggleButtonGroup: {
  //     styleOverrides: {
  //       root: {
  //         alignItems: "start",
  //         marginLeft: 0,
  //         width: "fit-content",
  //       },
  //     },
  //   },
  //   MuiDataGrid: {
  //     styleOverrides: {
  //       cell: {
  //         "&:focus": {
  //           outline: "none",
  //         },
  //       },
  //       root: {
  //         border: "none",
  //         // Increase data grid cell font-size
  //         fontSize: "0.9rem",
  //         "& .MuiDataGrid-columnHeader": {
  //           borderLeft: "1px solid #e0e0e0",
  //           "&::first-of-type": {
  //             borderLeft: "none",
  //           },
  //         },
  //         "& .MuiDataGrid-row": {
  //           position: "relative",
  //         },
  //         "& .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders": {
  //           borderBottom: "none",
  //         },
  //         "& .MuiDataGrid-columnSeparator svg": {
  //           display: "none",
  //         },
  //         "& .MuiDataGrid-columnHeaderTitle": {
  //           fontWeight: 700,
  //         },
  //         "& .MuiDataGrid-toolbarContainer": {
  //           justifyContent: "flex-end",
  //         },
  //       },
  //     },
  //   },
  //   MuiCheckbox: {
  //     styleOverrides: {
  //       root: ({ theme }) => ({
  //         color: theme.palette.primary.main,
  //         "&$checked": {
  //           color: theme.palette.primary.main,
  //         },
  //       }),
  //     },
  //   },
  // },
  // shape: {
  //   borderRadius: 0,
  // },
  // // eslint-disable-next-line
  // // @ts-ignore
  // shadows: Array(22).fill("none"),
};

export default theme;
