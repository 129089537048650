import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import ViewLayout from "components/tailwind/templates/ViewLayout";
import { useSessionProvider } from "contexts/SessionProvider";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PAGE_NOT_FOUND_PATH } from "routing/paths";
import { isInAdminContext, isInPartnerContext } from "utils/User";
import { SETTINGS_BASE_RATES_ADMIN, SETTINGS_DAILY_FEES } from "./SettingsPath";
import BaseRates from "./baseRates/BaseRates";
import DailyFees from "./dailyFees/DailyFees";

const SettingsRoutes: React.FC = () => {
  const { user } = useSessionProvider();

  return (
    <ErrorBoundary>
      <ViewLayout helmet="Settings">
        <Routes>
          <Route path={SETTINGS_DAILY_FEES} element={isInPartnerContext(user) ? <DailyFees /> : <Navigate to={PAGE_NOT_FOUND_PATH} />} />
          <Route path={SETTINGS_BASE_RATES_ADMIN} element={isInAdminContext(user) ? <BaseRates /> : <Navigate to={PAGE_NOT_FOUND_PATH} />} />
        </Routes>
      </ViewLayout>
    </ErrorBoundary>
  );
};

export default SettingsRoutes;
