import { REGEX_URL } from "shared/utils/regex";
import { getRequiredMessage } from "shared/utils/validators";
import { SchemaOf, boolean, mixed, number, object, string } from "yup";

export type UpdateConfigurationFormObject = {
  configurationId: string;
  payoutTtlDelta?: number;
  showLanding?: boolean;
  returnUrl?: string;
  vendorTermsAndConditions?: string;
  useGenericTermsAndConditions?: boolean;
};

export const updateConfigurationSchema: SchemaOf<UpdateConfigurationFormObject> = object().shape({
  configurationId: string().required("Configuration Id is missing"),
  payoutTtlDelta: number()
    .positive()
    .min(1, "Payout Expiry must be greater than or equal to 1")
    .required(getRequiredMessage("Payout Expiry"))
    .typeError(getRequiredMessage("Payout Expiry")),
  showLanding: boolean().required(getRequiredMessage("Show Landing Page")).typeError(getRequiredMessage("Show Landing Page")),
  returnUrl: string().required("Please enter a return URL").matches(REGEX_URL, "Please enter a valid return URL"),
  useGenericTermsAndConditions: boolean().optional(),
  vendorTermsAndConditions: mixed().when("useGenericTermsAndConditions", {
    is: false,
    then: string().required("Please enter a URL that links to your terms and conditions").matches(REGEX_URL, "Please enter a valid URL"),
  }),
  otherwise: mixed().optional().nullable(),
});
