import { yupResolver } from "@hookform/resolvers/yup";
import { fetchMFAPreference, fetchUserAttributes, updateMFAPreference, updateUserAttributes } from "aws-amplify/auth";
import DeleteAccountModal from "components/tailwind/DeleteAccountModal";
import MonetButton from "components/tailwind/MonetButton";
import MonetCard from "components/tailwind/MonetCard";
import MonetDivider from "components/tailwind/MonetDivider";
import MonetEditSaveButton from "components/tailwind/MonetEditSaveButton";
import MonetRow from "components/tailwind/MonetRow";
import MonetViewTitle from "components/tailwind/MonetViewTitle";
import MonetInput from "components/tailwind/form/MonetInput";
import MonetPhoneNumberInput from "components/tailwind/form/MonetPhoneNumberInput";
import MonetSwitch from "components/tailwind/form/MonetSwitch";
import TailwindSelectInput from "components/tailwind/headlessTailwind/TailwindSelectInput";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, FormProvider, Resolver, useForm } from "react-hook-form";
import { toast } from "sonner";
import AddAuthenticatorAppDrawer from "./components/AddAuthenticatorAppDrawer";
import ChangePasswordDrawer from "./components/ChangePasswordDrawer";
import { UserProfileFormObject, editUserProfileSchema } from "./validationSchemas/ProfileValidationSchema";

const UserProfile: React.FC = () => {
  const [editable, setEditable] = useState(false);
  const [preferredMFA, setPreferredMFA] = useState<string>();
  const [mfaEnabled, setMfaEnabled] = useState<boolean>(false);

  const { user, setCurrentUser, handleSignOut } = useSessionProvider();
  const { openModal } = useModal();

  useEffect(() => {
    setMfaEnabled(preferredMFA !== undefined);
  }, [preferredMFA]);

  useEffect(() => {
    const fetchPreferredMFA = async () => {
      try {
        const preferredMFA = await fetchMFAPreference();
        setPreferredMFA(preferredMFA.preferred);
        setMfaEnabled(preferredMFA.enabled ? true : false);
      } catch (error: Error | any) {
        toast.error(`Error fetching user information: ${error.message}`);
      }
    };
    fetchPreferredMFA();
  }, []);

  const toggleEdit = useCallback(() => {
    if (editable) {
      methods.reset();
    }
    setEditable(!editable);
  }, [editable]);

  const onSubmit = async (formData: UserProfileFormObject) => {
    try {
      await updateUserAttributes({
        userAttributes: {
          family_name: formData.lastName,
          given_name: formData.firstName,
          "custom:role": formData.role,
          phone_number: formData.phoneNumber,
        },
      });

      await updateMFAPreference({
        sms: formData.mfaEnabled && formData.mfaMethod == "SMS" ? "PREFERRED" : "DISABLED",
        totp: formData.mfaEnabled && formData.mfaMethod == "TOTP" ? "PREFERRED" : "DISABLED",
      })
        .then(() => {
          setMfaEnabled(formData.mfaEnabled);
          setPreferredMFA(formData.mfaMethod);
        })
        .catch((error) => {
          if (error.message === "User has not verified software token mfa") {
            toast.error("Failed to save MFA settings", { description: "You must have an authenticator app set up to use this preferred method" });
          } else {
            toast.error("Failed to save MFA settings", { description: "Please try again" });
          }
        });

      const userAttributes = await fetchUserAttributes();
      setCurrentUser(userAttributes);
      toast.success("Success", { description: "Your profile details have been updated" });
    } catch (err: Error | any) {
      toast.error("Request failed", { description: "Failed to update your profile details. Please try again" });
    } finally {
      toggleEdit();
    }
  };

  const methods = useForm<UserProfileFormObject>({
    mode: "onTouched",
    resolver: yupResolver(editUserProfileSchema) as Resolver<UserProfileFormObject>,
    values: {
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      role: user.role,
      mfaEnabled: mfaEnabled,
      mfaMethod: preferredMFA,
    },
  });

  const mfaEnabledValue = methods.watch("mfaEnabled");

  useEffect(() => {
    methods.setValue("mfaMethod", undefined);
  }, [mfaEnabledValue]);

  return (
    <>
      <div className="flex flex-row gap-6 justify-between items-start">
        <MonetViewTitle>Profile</MonetViewTitle>
        <MonetEditSaveButton
          showSaveButton={editable}
          onEditCallBack={toggleEdit}
          onCancelCallback={toggleEdit}
          onSaveCallback={() => methods.handleSubmit(onSubmit)()}
          saveButtonDisabled={!methods.formState.isValid || !methods.formState.isDirty}
          loading={methods.formState.isSubmitting}
        />
      </div>
      <MonetCard className="overflow-visible">
        <FormProvider {...methods}>
          <form className="flex flex-col gap-4" onSubmit={methods.handleSubmit(onSubmit)}>
            {!editable ? (
              <MonetRow>
                <p className="font-semibold w-full max-w-[450px]">Name</p>
                <MonetInput value={`${user.firstName} ${user.lastName}`} readOnly={true} />
              </MonetRow>
            ) : (
              <>
                <MonetRow>
                  <p className="font-semibold w-full max-w-[450px]">First Name</p>
                  <MonetInput {...methods.register("firstName")} id="firstName" disabled={methods.formState.isSubmitting} error={methods.formState.errors?.firstName?.message} />
                </MonetRow>
                <MonetRow>
                  <p className="font-semibold w-full max-w-[450px]">Last Name</p>
                  <MonetInput {...methods.register("lastName")} id="lastName" disabled={methods.formState.isSubmitting} error={methods.formState.errors?.lastName?.message} />
                </MonetRow>
              </>
            )}
            <MonetRow>
              <p className="font-semibold w-full max-w-[450px]">Mobile number</p>
              <MonetPhoneNumberInput
                name="phoneNumber"
                error={methods.formState.errors.phoneNumber?.message as string}
                readOnly={!editable}
                disabled={!editable || methods.formState.isSubmitting}
              />
            </MonetRow>
            <MonetRow>
              <p className="font-semibold w-full max-w-[450px]">Email</p>
              <MonetInput id="email" value={user.email} readOnly={!editable} disabled={editable} />
            </MonetRow>
            <MonetRow>
              <p className="font-semibold w-full max-w-[450px]">Job role</p>
              <MonetInput {...methods.register("role")} id="role" readOnly={!editable} disabled={methods.formState.isSubmitting} error={methods.formState.errors?.role?.message} />
            </MonetRow>
            <MonetDivider margin="large" />
            <MonetRow>
              <div className="max-w-[450px]">
                <p className="font-semibold">Two-Factor-authentication</p>
                <p>When enabled, you will be prompted to authenticate using your chosen method. We recommend enabling this setting to enhance your security.</p>
              </div>
              <div>
                <MonetSwitch disabled={!editable || methods.formState.isSubmitting} showLabels={false} {...methods.register("mfaEnabled")} />
              </div>
            </MonetRow>
            <MonetRow>
              <p className="font-semibold max-w-[450px] w-full">Preferred authentication method</p>
              <div className="w-full sm:w-[230px]">
                <Controller
                  control={methods.control}
                  name="mfaMethod"
                  render={({ field: { ...inputProps } }) => (
                    <TailwindSelectInput
                      inputProps={inputProps}
                      disabled={!editable || methods.formState.isSubmitting || !mfaEnabledValue}
                      placeholder={mfaEnabledValue ? "Please select..." : "N/A"}
                      error={methods.formState.errors?.mfaMethod?.message}
                      options={[
                        {
                          label: "SMS",
                          value: "SMS",
                        },
                        {
                          label: "Authenticator app",
                          value: "TOTP",
                        },
                      ]}
                    />
                  )}
                />
              </div>
            </MonetRow>
            <MonetRow>
              <div className="sm:max-w-[450px]">
                <p className="font-semibold">Authenticator app</p>
                <p>You can set up an authenticator app of your choice for authentication.</p>
              </div>
              <MonetButton
                variant="outlined"
                size="small"
                className="whitespace-nowrap"
                disabled={!editable || methods.formState.isSubmitting}
                onClick={() => openModal(AddAuthenticatorAppDrawer, {})}
              >
                Set up authenticator app
              </MonetButton>
            </MonetRow>
            <MonetDivider margin="large" />
            <MonetRow>
              <div className="max-w-[450px]">
                <p className="font-semibold">Password</p>
              </div>
              <MonetButton variant="outlined" size="small" onClick={() => openModal(ChangePasswordDrawer, {})}>
                Change password
              </MonetButton>
            </MonetRow>
            <MonetDivider margin="large" />
            <MonetRow>
              <div className="max-w-[450px]">
                <p className="font-semibold">Danger zone</p>
                <p>You can only delete your account if there is another user active for your organisation. This action is irreversible.</p>
              </div>
              <MonetButton color="red-solid" size="small" onClick={() => openModal(DeleteAccountModal, { onSuccessCallback: handleSignOut, user: user })}>
                Delete account
              </MonetButton>
            </MonetRow>
          </form>
        </FormProvider>
      </MonetCard>
    </>
  );
};

export default UserProfile;
