import { yupResolver } from "@hookform/resolvers/yup";
import { updatePassword } from "aws-amplify/auth";
import MonetDrawerButtons from "components/tailwind/MonetDrawerButtons";
import MonetPasswordHint from "components/tailwind/form/MonetPasswordHint";
import MonetPasswordInput from "components/tailwind/form/MonetPasswordInput";
import TailwindDrawer from "components/tailwind/headlessTailwind/TailwindDrawer";
import { useModal } from "contexts/ModalProvider";
import { Resolver, useForm } from "react-hook-form";
import { toast } from "sonner";
import { ChangePasswordFormObject, changePasswordSchema } from "../validationSchemas/ChangePasswordSchema";

const ChangePasswordDrawer: React.FC = () => {
  const { closeModal } = useModal();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<ChangePasswordFormObject>({
    mode: "onTouched",
    resolver: yupResolver(changePasswordSchema) as Resolver<ChangePasswordFormObject>,
    defaultValues: {
      oldPassword: undefined,
      newPassword: undefined,
      newPasswordConfirmation: undefined,
    },
  });

  const onSubmit = async (formData: ChangePasswordFormObject) => {
    try {
      await updatePassword({ oldPassword: formData.oldPassword, newPassword: formData.newPassword });
      toast.success("Success", { description: "Your password has been changed" });
      closeModal();
    } catch (error: Error | any) {
      toast.error("Request failed", { description: `Failed to update your password: ${error.message}` });
    }
  };

  return (
    <TailwindDrawer title="Change your password" subtitle="Enter your current password and the password you would like it changed to below">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col flex-1 justify-between mt-4">
        <div className="flex flex-col gap-4">
          <MonetPasswordInput {...register("oldPassword")} label="Current password" id="oldPassword" error={errors.oldPassword?.message as string} />
          <MonetPasswordHint
            input={<MonetPasswordInput {...register("newPassword")} label="New password" id="newPassword" error={errors.newPassword?.message as string} />}
            inputId="newPassword"
          />
          <MonetPasswordInput
            {...register("newPasswordConfirmation")}
            label="Confirm new Password"
            id="newPasswordConfirmation"
            error={errors.newPasswordConfirmation?.message as string}
          />
        </div>
        <MonetDrawerButtons cancelDisabled={isSubmitting} submitDisabled={isSubmitting || !isValid} loading={isSubmitting} />
      </form>
    </TailwindDrawer>
  );
};

export default ChangePasswordDrawer;
