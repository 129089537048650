import { Currency, Money, MoneyStorage } from "@monet-money/money-type";
import { Percentage } from "@monet-money/percentage-type";
import BookOnboardingCallBanner from "components/onboarding/BookOnboardingCallBanner";
import CreatePayoutButton from "components/tailwind/CreatePayoutButton";
import MonetAlert from "components/tailwind/MonetAlert";
import MonetBorderedCardContent from "components/tailwind/MonetBorderedCardContent";
import MonetCard from "components/tailwind/MonetCard";
import MonetCardTitle from "components/tailwind/MonetCardTitle";
import MonetLabelledItem from "components/tailwind/MonetLabelledItem";
import MonetMetric from "components/tailwind/MonetMetric";
import NoDataCard from "components/tailwind/NoDataCard";
import { useSessionProvider } from "contexts/SessionProvider";
import { useEffect, useMemo, useState } from "react";
import { calculateDFF } from "shared/logic/calculate";
import { getPayoutTotalDetails } from "shared/logic/payouts";
import { Campaign, FullPayout } from "shared/types/eep.contract.types";
import { Invoice } from "utils/Invoice";
import { isPartnerVerified } from "utils/Partner";
import { isInAdminContext, isInPartnerContext } from "utils/User";
import { useGetInvoicesByCampaignQuery, useGetPartnerFeesLazyQuery } from "views/campaigns/graphql/queries.generated";
import InvoiceOverDueBanner from "../../banners/InvoiceOverDueBanner";
import PayoutsTable from "./PayoutsTable";
import InvoiceSection from "./invoiceSection/InvoiceSection";

type FundingTabProps = {
  isLoading: boolean;
  campaign?: Campaign;
  refetchCampaign(): void;
  refetchPayouts(): void;
  payouts?: FullPayout[];
};

const FundingTab: React.FC<FundingTabProps> = ({ isLoading, campaign, payouts, refetchCampaign, refetchPayouts }) => {
  const [getPartnerFees, { data: partnerFeesData, loading: isPartnerFeesLoading }] = useGetPartnerFeesLazyQuery({ fetchPolicy: "network-only" });

  const [invoices, setInvoices] = useState<Invoice[]>();

  const { user, partner } = useSessionProvider();

  const {
    data: campaignInvoices,
    loading: isInvoiceLoading,
    refetch: refetchInvoices,
  } = useGetInvoicesByCampaignQuery({
    fetchPolicy: "cache-first",
    variables: {
      campaignId: campaign?.campaignId as string,
      partnerId: isInAdminContext(user) ? campaign?.partnerId : undefined,
    },
    skip: !isPartnerVerified(partner, user),
  });

  useEffect(() => {
    if (campaignInvoices) {
      const invoices = JSON.parse(campaignInvoices.getInvoicesByCampaign) as Invoice[];
      if (invoices.length > 0) {
        setInvoices(invoices);
      } else {
        setInvoices(undefined);
      }
    }
  }, [campaignInvoices]);

  // Get partner fees
  useEffect(() => {
    if (campaign && isPartnerVerified(partner, user)) {
      getPartnerFees({
        variables: {
          partnerId: campaign.partnerId,
        },
      });
    }
  }, [campaign, partner]);

  const dailyMonetFee = useMemo(() => {
    if (partnerFeesData && campaign && ["ACTIVE"].includes(campaign?.campaignStatus)) {
      const fees = partnerFeesData.GetPartnerFees;
      const feePerSelectedCampaign = fees.find((item) => item.currency == campaign?.campaignValue.currency);
      if (feePerSelectedCampaign) {
        return calculateDFF(feePerSelectedCampaign.margin, feePerSelectedCampaign!.baseRate).format(false, 5);
      } else return undefined;
    } else return undefined;
  }, [partnerFeesData]);

  const allocatedCampaignFunds = useMemo(() => {
    if (campaign?.allocatedFunds) {
      return Money.fromStorageType(campaign?.allocatedFunds as MoneyStorage).format(true, true);
    } else return undefined;
  }, [campaign]);

  const remainingCampaignFunds = useMemo(() => {
    if (campaign?.budgetLimit) {
      console.log(campaign);
      return Money.fromStorageType(campaign?.budgetLimit as MoneyStorage)
        .subtract(Money.fromStorageType(campaign?.allocatedFunds as MoneyStorage))
        .format(true, true);
    } else return undefined;
  }, [campaign]);

  const campaignFeeTotals = useMemo(() => {
    if (campaign && payouts && payouts?.length > 0) {
      const currency = campaign.campaignValue.currency as Currency;
      const totalFees = {
        monetFee: new Money(0, currency),
        agencyFee: new Money(0, currency),
      };
      payouts.forEach((payout) => {
        const totals = getPayoutTotalDetails(
          payout.payoutValue as MoneyStorage,
          Percentage.fromStorageValue(payout.feeSettings.monetFee),
          Percentage.fromStorageValue(payout.feeSettings.partnerFee),
          payout.feeSettings.chargeCreator,
        );
        totalFees.monetFee = totalFees.monetFee.add(totals.monetFee);
        totalFees.agencyFee = totalFees.agencyFee.add(totals.partnerFee);
      });
      return totalFees;
    }
    return undefined;
  }, [payouts, campaign]);

  const totalValueOfAllInvoices = useMemo(() => {
    if (invoices) {
      return invoices
        .reduce(
          (total, item) => {
            return total.add(Money.fromStorageType(item.invoiceValue));
          },
          new Money(0, campaign?.campaignValue.currency as Currency),
        )
        .format(true, true);
    } else return undefined;
  }, [invoices]);

  const isActiveFunding = useMemo(() => {
    if (invoices) {
      return Boolean(invoices.find((invoice) => invoice.funding && invoice.funding.fundingStatus === "ACTIVE"));
    } else return undefined;
  }, [invoices]);

  if (!isPartnerVerified(partner, user)) {
    return (
      <div className="flex flex-col gap-6">
        <BookOnboardingCallBanner campaignValue={campaign?.campaignValue as MoneyStorage} />
      </div>
    );
  }

  if (partner?.defaultConfiguration.allowedCurrencies) {
    const isCurrencyValid = partner?.defaultConfiguration.allowedCurrencies.find((currency) => currency.currency === campaign?.campaignValue.currency);

    if (!isCurrencyValid) {
      return (
        <div className="flex flex-col gap-6">
          <MonetAlert
            variant="warning"
            title="Unsupported currency"
            message="The currency of this campaign is not valid for funding. If you think this is a mistake please contact MONET using the email support@monet.money"
          />
        </div>
      );
    }
  }

  return (
    <div className="flex flex-col gap-6">
      <InvoiceOverDueBanner invoices={invoices} />
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-6">
        <MonetMetric loading={isLoading} label="Funding available" value={remainingCampaignFunds} tooltip="This is the funding that you have access to for this campaign" />
        <MonetMetric loading={isLoading} label="Funds used" value={allocatedCampaignFunds} tooltip="The total value of funds advanced through payouts for this campaign" />
        <MonetMetric
          loading={isLoading}
          label="MONET fees"
          value={campaignFeeTotals?.monetFee.format(true, true)}
          tooltip="The sum of all MONET fees across each payout for this campaign"
        />
        <MonetMetric loading={isLoading} label="Payouts" value={payouts?.length ?? undefined} tooltip="The total number of payouts for this campaign" />
      </div>
      <MonetCard>
        <div className="flex flex-row justify-between gap-4">
          <MonetCardTitle>Details</MonetCardTitle>
        </div>
        {isActiveFunding ? (
          <MonetBorderedCardContent className="grid grid-cols-1 gap-1 xl:grid-cols-2 xl:gap-6">
            <div className="flex flex-col gap-1">
              <MonetLabelledItem label="Campaign value" value={Money.fromStorageType(campaign?.campaignValue as MoneyStorage).format(true, true)} />
              <MonetLabelledItem
                label="Total value of invoices"
                value={totalValueOfAllInvoices}
                tooltip="This is a the total value of all invoices associated with this campaign"
              />
              <MonetLabelledItem label="Daily funding fee" value={dailyMonetFee} />
            </div>
            <div className="flex flex-col gap-1">
              <MonetLabelledItem
                label="Advanced limit"
                value={Money.fromStorageType(campaign?.budgetLimit as MoneyStorage).format(true, true)}
                tooltip="The funding limit for this campaign"
              />
              <MonetLabelledItem
                label="Agency earnings"
                value={campaignFeeTotals?.agencyFee.format(true, true)}
                tooltip="The sum of all agency fees across each payout for this campaign"
              />
            </div>
          </MonetBorderedCardContent>
        ) : (
          <div className="w-full h-[150px] bg-gray-50 rounded-xl flex justify-center items-center font-light">No active funding</div>
        )}
      </MonetCard>
      <InvoiceSection campaign={campaign} refetchCampaign={refetchCampaign} payouts={payouts} refetchPayouts={refetchPayouts} />
      <MonetCard>
        <div className="flex flex-row justify-between">
          <MonetCardTitle>Payouts</MonetCardTitle>
          {isInPartnerContext(user) && campaign?.campaignStatus === "ACTIVE" && <CreatePayoutButton />}
        </div>
        {payouts && payouts?.length <= 0 && !isLoading ? (
          <NoDataCard showAsUnstyledCard={true}>No payouts</NoDataCard>
        ) : (
          <PayoutsTable isLoading={isLoading} payouts={payouts ?? []} />
        )}
      </MonetCard>
    </div>
  );
};

export default FundingTab;
