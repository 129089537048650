import MissingProfileInformationBanner from "components/onboarding/MissingProfileInformationBanner";
import MonetViewTitle from "components/tailwind/MonetViewTitle";
import TailwindTabGroup from "components/tailwind/headlessTailwind/TailwindTabGroup";
import { useSessionProvider } from "contexts/SessionProvider";
import { isPartnerVerified, restrictPlatformAccess } from "utils/Partner";
import Billing from "./components/tabs/Billing";
import EarlyPayCollectionAccounts from "./components/tabs/EarlyPaySettings/EarlyPayCollectionAccounts";
import EarlyPaySettings from "./components/tabs/EarlyPaySettings/EarlyPaySettings";
import OrganisationLogoSection from "./components/tabs/OrganisationDetails/OrganisationLogoSection";
import OrganisationProfileSection from "./components/tabs/OrganisationDetails/OrganisationProfileSection";
import UsersTable from "./components/tabs/UsersTable";

const OrganisationProfile = () => {
  const { partner, user } = useSessionProvider();

  const env = process.env.REACT_APP_ENV_NAME;

  return (
    <>
      <MissingProfileInformationBanner />
      <MonetViewTitle>Organisation profile</MonetViewTitle>
      <TailwindTabGroup
        tabs={[
          {
            label: "Details",
            content: (
              <div className="flex flex-col gap-6">
                <OrganisationLogoSection />
                <OrganisationProfileSection />
              </div>
            ),
            isVisible: true,
          },
          {
            label: "Billing",
            content: <Billing />,
            isVisible: env !== "Sandbox",
          },
          {
            label: "EarlyPay settings",
            content: (
              <div className="flex flex-col gap-6">
                <EarlyPaySettings />
                <EarlyPayCollectionAccounts />
              </div>
            ),
            isVisible: !restrictPlatformAccess(partner, user) && isPartnerVerified(partner, user),
          },
          {
            label: "Users",
            content: <UsersTable />,
            isVisible: !restrictPlatformAccess(partner, user) && isPartnerVerified(partner, user),
          },
        ]}
      />
    </>
  );
};

export default OrganisationProfile;
