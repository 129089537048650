import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";
import { InputHTMLAttributes, forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import MonetToolTip from "../MonetTooltip";

type MonetSwitchProps = Omit<InputHTMLAttributes<HTMLInputElement>, "size"> &
  VariantProps<typeof variants> & {
    label?: string;
    secondaryLabel?: string;
    offLabel?: string;
    onLabel?: string;
    showLabels?: boolean;
    showInRow?: boolean;
    tooltip?: string;
  };

const MonetSwitch: React.FC<MonetSwitchProps> = forwardRef<HTMLInputElement, MonetSwitchProps>(
  ({ label, offLabel = "No", onLabel = "Yes", showLabels = true, showInRow, secondaryLabel, tooltip, size, ...props }, ref) => {
    const arrangement = showInRow ? "flex flex-row" : "flex flex-col";

    return (
      <div className={twMerge(clsx(arrangement, "flex-1 gap-1.5 justify-between"))}>
        {label && (
          <div className="flex flex-col justify-center truncate">
            <label htmlFor="hs-default-checkbox" className="pr-1">
              <div className="block text-sm font-semibold text-gray-800 dark:text-neutral-300 truncate">
                {label}
                {tooltip && <MonetToolTip text={tooltip} />}
              </div>
            </label>
            <p className="text-sm">{secondaryLabel}</p>
          </div>
        )}
        <div className="flex items-center">
          {showLabels && (
            <label htmlFor="hs-basic-with-description" className="text-sm text-gray-500 me-3 dark:text-neutral-400">
              {offLabel}
            </label>
          )}
          <input ref={ref} type="checkbox" id="hs-basic-with-description" className={twMerge(clsx(variants({ size })))} {...props} />
          {showLabels && (
            <label htmlFor="hs-basic-with-description" className="text-sm text-gray-500 ms-3 dark:text-neutral-400">
              {onLabel}
            </label>
          )}
        </div>
      </div>
    );
  },
);

export default MonetSwitch;

const variants = cva(
  "relative p-px bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-monet-blue checked:border-monet-blue focus:checked:border-blue-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-600 before:inline-block before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-blue-200",
  {
    variants: {
      size: {
        small: "w-[2.75rem] h-6 before:w-5 before:h-5",
        default: "w-[3.25rem] h-7 before:w-6 before:h-6",
      },
    },
    defaultVariants: {
      size: "default",
    },
  },
);
