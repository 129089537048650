import { Box } from "@mui/material";
import CountryFlag from "components/CountryFlag/CountryFlag";
import { countries } from "countries-list";
import { Autocomplete } from "mui-rff";
import React, { SyntheticEvent, useMemo, useState } from "react";
import { getAllCountries } from "utils/country";

type CountriesFieldProps = {
  name: string;
  id?: string;
  showOnlyList?: string[];
  label?: string;
  required?: boolean;
  disabled?: boolean;
  helperText?: string;
  defaultValue?: string;
  countryCode?: string;
  onClose?: (event: SyntheticEvent, reason: string) => void;
};

type SelectedCountry = {
  label: string;
  value: string;
};

const CountriesField = ({ showOnlyList, defaultValue, countryCode, ...field }: CountriesFieldProps) => {
  const countriesList = useMemo(() => {
    if (showOnlyList) {
      return getAllCountries().filter((item) => showOnlyList.includes(item.value));
    }
    return getAllCountries();
  }, [countries, showOnlyList]);

  const getDefaultValue = useMemo(() => {
    if (defaultValue) {
      const defaultCountry = countriesList.find((item) => item.value === defaultValue);
      return defaultCountry ? defaultCountry : null;
    }

    return null;
  }, [defaultValue]);

  const [selectedCountryCode, setSelectedCountryCode] = useState<string>(countryCode || "");

  const optionLabel = React.useCallback(
    (option: string | SelectedCountry): string => {
      return `${(option as SelectedCountry).label}`;
    },
    [countryCode],
  );

  return (
    <Autocomplete
      {...field}
      defaultValue={getDefaultValue}
      fullWidth
      disablePortal
      clearOnBlur
      getOptionValue={(option) => option.value}
      getOptionLabel={optionLabel}
      onChange={(event, value) => setSelectedCountryCode((value as SelectedCountry)?.value)}
      textFieldProps={{
        InputProps: { startAdornment: <CountryFlag countryCode={selectedCountryCode} /> },
        placeholder: "Select or type country",
      }}
      options={countriesList}
      renderOption={(props, option) => (
        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
          <CountryFlag countryCode={option.value} />
          {option.label}
        </Box>
      )}
    />
  );
};

export default CountriesField;
