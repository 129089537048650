import { useMemo } from "react";
import { Invoice, InvoiceFundingStatus, getInvoiceStatusSettings } from "utils/Invoice";
import { Partner, SubscriptionStatus, getPartnerKYBStatusSettings, getPartnerSubscriptionSettings } from "utils/Partner";
import { getPayoutStatusSettings } from "utils/Payouts";
import { enumToNiceString } from "utils/strings";
import { getCampaignDeliverableStatusSettings, getCampaignStatusColor } from "views/campaigns/utils";
import MonetBadge from "./MonetBadge";

type StatusBadgeProps = {
  type: "campaign" | "payout" | "partner" | "deliverable" | "invoice" | "subscription";
  text?: string;
};

const StatusBadge: React.FC<StatusBadgeProps> = ({ type, text }) => {
  const config = useMemo(() => {
    if (type === "campaign") {
      return getCampaignStatusColor(text as Components.Schemas.CampaignStatus);
    } else if (type === "payout") {
      return getPayoutStatusSettings(text as Components.Schemas.Payout["status"]);
    } else if (type === "partner") {
      return getPartnerKYBStatusSettings(text as Partner["kybStatus"]);
    } else if (type === "deliverable") {
      return getCampaignDeliverableStatusSettings(text as Components.Schemas.DeliverableStatus);
    } else if (type === "invoice") {
      return getInvoiceStatusSettings(text as Invoice["invoiceStatus"] | InvoiceFundingStatus);
    } else if (type === "subscription") {
      return getPartnerSubscriptionSettings(text as SubscriptionStatus);
    } else return null;
  }, [type, text]);

  if (!text) return null;

  return <MonetBadge variant={config?.color}>{enumToNiceString(text)}</MonetBadge>;
};

export default StatusBadge;
