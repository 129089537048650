import { ReactComponent as CrossIcon } from "assets/tailwind/icons/cross.svg";
import { ReactComponent as TickIcon } from "assets/tailwind/icons/tick.svg";
import MonetAlert from "components/tailwind/MonetAlert";
import MonetButton from "components/tailwind/MonetButton";
import MonetLoadingSpinner from "components/tailwind/MonetLoadingSpinner";
import { useSessionProvider } from "contexts/SessionProvider";
import { useEffect, useState } from "react";
import { useStripeFulfillCheckoutMutation } from "./graphql/mutations.generated";

type StripeConfirmationModalProps = {
  stripeSessionId: string;
};

const StripeConfirmationModal: React.FC<StripeConfirmationModalProps> = ({ stripeSessionId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();

  const [fulfillCheckout] = useStripeFulfillCheckoutMutation();

  const { refetchCurrentPartner, partner } = useSessionProvider();

  useEffect(() => {
    if (stripeSessionId) {
      fulfillCheckout({
        variables: {
          stripeSessionId: stripeSessionId,
        },
      })
        .catch((err) => {
          setError(err.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  return (
    <div className="m-auto">
      {isLoading ? (
        <div className="flex flex-col items-center">
          <MonetLoadingSpinner size="large" />
          <p>Processing request</p>
        </div>
      ) : error ? (
        <div className="flex flex-col items-center gap-2">
          <div className="rounded-full p-2 bg-red-50">
            <div className="rounded-full p-4 bg-red-100 text-red-600">
              <CrossIcon width={24} height={24} />
            </div>
          </div>
          <h1 className="text-2xl font-light text-gray-800 dark:text-white text-center">Failed to activate subscription</h1>
          <MonetAlert variant="error" message={error} />
          <p className="text-xs text-center max-w-[500px]">Please contact support@monet.money quoting partner ID: {partner?.partnerId}</p>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-2">
          <div className="rounded-full p-2 bg-green-50">
            <div className="rounded-full p-4 bg-green-100 text-green-600">
              <TickIcon width={24} height={24} />
            </div>
          </div>
          <h1 className="text-2xl font-light text-gray-800 dark:text-white text-center">Subscription active</h1>
          <p className="text-sm text-center max-w-[500px]">
            Your subscription has been activated. Click get started below to start managing and funding your campaigns. To view and manage you subscription go to the billing tab
            under the organisation profile.
          </p>
          <MonetButton onClick={refetchCurrentPartner} className="w-full max-w-[200px] mt-4">
            Get started
          </MonetButton>
        </div>
      )}
    </div>
  );
};

export default StripeConfirmationModal;
