import ErrorPage from "components/ErrorPage/ErrorPage";
import React from "react";
import Catch from "./CatchError";

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

const ErrorBoundary = Catch(function ErrorBoundary(props: ErrorBoundaryProps, error?: Error) {
  const { children } = props;
  if (error) {
    return <ErrorPage statusCode={500} />;
  }
  return <>{children}</>;
});

export default ErrorBoundary;
