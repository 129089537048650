import MonetAlert from "components/tailwind/MonetAlert";
import MonetViewTitle from "components/tailwind/MonetViewTitle";
import { useEffect, useState } from "react";
import { GetPartnerFeesResponse } from "shared/generated/ep3.graphql.types";
import { calculateDFF } from "shared/logic/calculate";
import { toast } from "sonner";
import { useGetPartnerFeesQuery } from "../graphql/queries.generated";
import DailyFeeCard from "./components/DailyFeeCard";

const DailyFees: React.FC = () => {
  const [fees, setFees] = useState<GetPartnerFeesResponse[]>([]);
  const { data: partnerFeesData, loading, error } = useGetPartnerFeesQuery();

  useEffect(() => {
    if (partnerFeesData?.GetPartnerFees?.length) {
      setFees(partnerFeesData.GetPartnerFees);
    }
  }, [partnerFeesData]);

  useEffect(() => {
    if (error) toast.error("There was an error fetching the daily fees. Please try again");
  }, [error]);

  return (
    <>
      <MonetViewTitle>Daily funding fees</MonetViewTitle>
      <MonetAlert
        className="mt-0 max-w-[600px]"
        variant="info"
        title="How the fees are calculated"
        message="Fees are calculated daily based on the international currency bank rates plus the added margin per currency as set during the onboarding phase. 
                Daily fee formula: Base rate + margin / 365 = Daily funding fee"
      />
      {loading && <DailyFeeCard loading={loading} />}
      {!loading && fees.length <= 0 && <p>There are no daily funding fees configured for this account.</p>}
      <div className="flex flex-col sm:flex-row flex-wrap gap-6">
        {fees.map((fee) => (
          <DailyFeeCard currency={fee.currency} margin={fee.margin} baseRate={fee.baseRate} dailyFee={calculateDFF(fee.margin, fee.baseRate).format(false, 5) as string} />
        ))}
      </div>
    </>
  );
};

export default DailyFees;
