import { ReactComponent as EyeOff } from "assets/tailwind/icons/eye-off.svg";
import { ReactComponent as Eye } from "assets/tailwind/icons/eye.svg";
import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";
import { HSTogglePassword } from "preline/preline";
import { InputHTMLAttributes, PropsWithChildren, forwardRef, useEffect } from "react";
import { twMerge } from "tailwind-merge";

type MonetPasswordInputProps = PropsWithChildren &
  InputHTMLAttributes<HTMLInputElement> &
  VariantProps<typeof variants> & {
    label: string;
    error?: string;
    secondaryLabel?: React.ReactNode;
  };

const MonetPasswordInput: React.FC<MonetPasswordInputProps> = forwardRef<HTMLInputElement, MonetPasswordInputProps>(
  ({ error, label, className, variant, size, children, secondaryLabel, ...props }, ref) => {
    useEffect(() => {
      HSTogglePassword.autoInit();
    }, []);

    const errorStyling = error ? "border-red-500 ring ring-red-100 ring-offset-0 focus:ring-red-500 focus:ring focus:ring-red-100 focus:outline-offset-0" : undefined;

    return (
      <div className="flex-1">
        <div className="flex justify-between items-center mb-2 text-sm">
          <label htmlFor={label} className="block font-semibold text-gray-800 dark:text-white">
            {label}
          </label>
          <div className="block text-sm text-gray-500 dark:text-neutral-500">{secondaryLabel}</div>
        </div>
        <div className="relative">
          <input ref={ref} className={twMerge(clsx(variants({ variant, size }), className, errorStyling))} {...props} />
          <button
            type="button"
            data-hs-toggle-password={`{
                            "target": "#${props.id}"
                        }`}
            className="absolute inset-y-0 end-0 flex items-center z-10 px-3 cursor-pointe text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600 dark:text-neutral-600 dark:focus:text-blue-500"
          >
            <EyeOff className="hs-password-active:hidden bg-white dark:bg-neutral-800" />
            <Eye className="hidden hs-password-active:block bg-white dark:bg-neutral-800" />
          </button>
        </div>
        <p className="text-sm text-red-500 mt-2">{error}</p>
      </div>
    );
  },
);

export default MonetPasswordInput;

const variants = cva(
  "block w-full test-base sm:text-sm border rounded-lg disabled:opacity-70 disabled:pointer-events-none disabled:bg-gray-100 dark:bg-transparent dark:disabled:bg-transparent",
  {
    variants: {
      variant: {
        default:
          "border-gray-200 placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600",
      },
      size: {
        small: "py-2 px-3.5",
        default: "py-3.5 px-4",
        large: "py-6 px-5",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);
