import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

export const DUE_DATE_FORMAT = "YYYYMMDD";
export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
export const DATETIME_STRING_FORMAT = "DD/MM/YYYY HH:mm:ss";

export const getHumanizeDate = (dateString?: string) => dayjs(dateString).fromNow();

/**
 * Formats a date string
 * The default format is 'Fri, 25 Jan 2019 02:00:00 GMT'.
 *
 * @param {string | number} date - The date string to be formatted.
 * @param {string} [format] - Optional. The format to customize the output. If not provided, the default is used.
 * @returns {string} The formatted date string.
 */
export const formatDate = (date: string | number, format?: string) => {
  const dateObject = typeof date === "string" ? dayjs(date) : dayjs.unix(date);
  if (format) {
    return dateObject.format(format);
  }
  return dateObject.toString();
};

/**
 * Formats a unix date string
 * The default format is 'Tue Jan 20 2023 14:46:17 GMT+0100 (Greenwich Mean Time)'.
 *
 * @param {string} unixString - The date string to be formatted.
 * @returns {string} The formatted date string.
 */
export const convertUnixTimestampToDate = (unixString: number) => {
  if (!unixString) {
    return null;
  }
  return dayjs.unix(unixString).toDate();
};
