import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";
import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

export type MonetBadgeVariants = VariantProps<typeof variants>;

const MonetBadge: React.FC<PropsWithChildren & VariantProps<typeof variants>> = ({ children, variant, size }) => {
  return <span className={twMerge(clsx(variants({ variant, size })))}>{children}</span>;
};

export default MonetBadge;

const variants = cva("inline-flex items-center gap-x-1.5 rounded-lg text-xs bg-gray-100 text-gray-800 dark:bg-white/10 dark:text-white w-max font-regular font-normal", {
  variants: {
    variant: {
      gray: "bg-gray-100 text-gray-800 dark:bg-white/10 dark:text-white",
      red: "bg-red-100 text-red-800 dark:bg-red-800/30 dark:text-red-500",
      yellow: "bg-yellow-100 text-yellow-800 dark:bg-yellow-800/30 dark:text-yellow-500",
      light: "bg-gray-50 text-gray-500 dark:bg-white/10 dark:text-white",
      green: "bg-teal-100 text-teal-800 dark:bg-teal-800/30 dark:text-teal-500",
      blue: "bg-blue-100 text-blue-800 dark:bg-blue-800/30 dark:text-blue-500",
      white: "bg-white/10 text-white",
      purple: "bg-purple-100 text-purple-800 dark:bg-purple-800/30 dark:text-purple-500",
    },
    size: {
      default: "p-1.5",
    },
  },
  defaultVariants: {
    variant: "gray",
    size: "default",
  },
});
