import { Menu, MenuButton, MenuItems } from "@headlessui/react";
import { ReactComponent as DownChevron } from "assets/tailwind/icons/chevron-down.svg";
import clsx from "clsx";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import MonetButton, { MonetButtonProps } from "../MonetButton";
import MonetDivider from "../MonetDivider";

export type TailwindDropdownProps = PropsWithChildren & {
  menuButtonProps: MonetButtonProps;
  footerChildren?: React.ReactNode;
  dropdownPosition?: "top start" | "top end" | "right start" | "right end" | "bottom start" | "bottom end" | "left start" | "left end";
  className?: string;
  hideFooter?: boolean;
  onCloseCallback?(): void;
  showChevron?: boolean;
};

const TailwindDropdown: React.FC<TailwindDropdownProps> = ({
  menuButtonProps,
  children,
  className,
  footerChildren,
  hideFooter,
  onCloseCallback,
  showChevron,
  dropdownPosition = "bottom end",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const footer = useMemo(() => {
    if (hideFooter) {
      return null;
    } else {
      return (
        <>
          {footerChildren && children && <MonetDivider />}
          {footerChildren}
        </>
      );
    }
  }, [hideFooter, footerChildren]);

  useEffect(() => {
    if (!isOpen) {
      onCloseCallback && onCloseCallback();
    }
  }, [isOpen, onCloseCallback]);

  return (
    <Menu as="div">
      {({ open }) => {
        setIsOpen(open);
        return (
          <>
            <MenuButton>
              {({ open }) => (
                <MonetButton {...menuButtonProps}>
                  {menuButtonProps.children && menuButtonProps.children}
                  {showChevron && <DownChevron className={clsx("transition", open && "rotate-180")} />}
                </MonetButton>
              )}
            </MenuButton>
            <MenuItems
              modal={false}
              transition
              anchor={{
                to: dropdownPosition,
                padding: 8,
              }}
              className={twMerge(
                "absolute w-fit right-0 z-10 mt-2 origin-top-right rounded-lg bg-white shadow-lg border border-gray-200 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in",
                className,
              )}
            >
              <div className="p-2">
                {children}
                {footer}
              </div>
            </MenuItems>
          </>
        );
      }}
    </Menu>
  );
};

export default TailwindDropdown;
