import { yupResolver } from "@hookform/resolvers/yup";
import { signUp } from "aws-amplify/auth";
import { MonetAlertProps } from "components/tailwind/MonetAlert";
import MonetStepper from "components/tailwind/MonetStepper";
import MonetAuthFooter from "components/tailwind/auth/MonetAuthFooter";
import AuthViewLayout from "components/tailwind/templates/AuthViewLayout";
import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { checkIfAllFieldsAreDirty, checkIfStepHasErrors } from "utils/form";
import { addPlusSignToPhoneNumber } from "utils/strings";
import { AUTH_PATH, SIGN_IN_PATH } from "../AuthPath";
import ResendEmailVerification from "../VerifyEmail/ResendEmailVerification";
import { SignUpFormObject, companyDefaultValues, signUpDefaultValues, signUpValidationSchema, userDefaultValues } from "./SignUpValidationSchema";
import CompanyStep from "./steps/CompanyStep";
import DoneStep from "./steps/DoneStep";
import ProfileStep from "./steps/ProfileStep";

const SignUp: React.FC = () => {
  const [alert, setAlert] = useState<MonetAlertProps>();
  const [currentStep, setCurrentStep] = useState(1);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmissionSuccess, setFormSubmissionSuccess] = useState(false);
  const navigate = useNavigate();

  const methods = useForm<SignUpFormObject>({
    defaultValues: signUpDefaultValues,
    resolver: yupResolver(signUpValidationSchema),
    mode: "onTouched",
  });

  const stepperSteps = [
    { index: 1, label: "Profile", content: <ProfileStep /> },
    { index: 2, label: "Company", content: <CompanyStep isLoading={methods.formState.isSubmitting} alert={alert} /> },
  ];

  const onSubmit = async (formData: SignUpFormObject) => {
    try {
      setFormSubmitted(false);
      setAlert(undefined);
      // get partner Id
      const partnerId = formData.companyName.trim().replace(/ +/g, "_").toUpperCase();

      await signUp({
        password: formData.password,
        username: formData.email,
        options: {
          userAttributes: {
            email: formData.email,
            phone_number: addPlusSignToPhoneNumber(formData.phoneNumber),
            website: formData.website,
            given_name: formData.firstName,
            family_name: formData.lastName,
            "custom:role": formData.position,
            "custom:partner": partnerId,
          },
          clientMetadata: {
            companyName: formData.companyName.trim().replace(/ +/g, " "),
            turnover: formData.turnover,
            country: formData.country,
            industry: formData.industry,
            website: formData.website,
            partnerId: partnerId,
          },
        },
      });
      setFormSubmissionSuccess(true);
    } catch (error: Error | any) {
      if (error.message?.includes("PartnerExistsException")) {
        setAlert({
          title: "The company name you have provided already exist",
          message: "Please enter a unique company name",
          error: error,
        });
      } else if (error.name === "UsernameExistsException") {
        setAlert({
          title: "Email has already been taken",
          message: "Please enter a unique email address",
          error: error,
        });
      } else {
        setAlert({
          title: "Unexpected error during sign up",
          message: "There was a problem registering your account. Please try again.",
          error: error,
        });
      }
      setFormSubmissionSuccess(false);
    } finally {
      setFormSubmitted(true);
    }
  };

  const footer = useMemo(() => {
    if (formSubmissionSuccess && formSubmitted) {
      return <ResendEmailVerification email={methods.getValues("email")} text="Didn’t receive the email?" />;
    } else {
      return (
        <MonetAuthFooter buttonText="Sign in" buttonProps={{ onClick: () => navigate(AUTH_PATH + SIGN_IN_PATH) }}>
          Already have an account?
        </MonetAuthFooter>
      );
    }
  }, [formSubmissionSuccess, formSubmitted]);

  const isStepValid = useMemo(() => {
    if (currentStep === 1) {
      return checkIfAllFieldsAreDirty(methods.formState.dirtyFields, userDefaultValues) && !checkIfStepHasErrors(methods.formState.errors, userDefaultValues);
    } else if (currentStep === 2) {
      return checkIfAllFieldsAreDirty(methods.formState.dirtyFields, companyDefaultValues) && !checkIfStepHasErrors(methods.formState.errors, companyDefaultValues);
    } else return true;
  }, [currentStep, methods.formState]);

  const passwordWatch = methods.watch("password");

  useEffect(() => {
    if (methods.getValues("passwordConfirmation")) {
      methods.trigger("passwordConfirmation");
    }
  }, [passwordWatch]);

  return (
    <AuthViewLayout helmet="Sign up">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <MonetStepper
            formSubmissionSuccess={formSubmissionSuccess}
            currentStep={currentStep}
            formSubmitted={formSubmitted}
            stepperId="sign-up-stepper"
            steps={stepperSteps}
            finalStep={<DoneStep userEmail={methods.getValues("email")} />}
            currentStepCallback={setCurrentStep}
            loading={methods.formState.isSubmitting}
            isButtonDisabled={!isStepValid || methods.formState.isSubmitting}
          />
        </form>
      </FormProvider>
      {footer}
    </AuthViewLayout>
  );
};

export default SignUp;
