import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";
import MonetToolTip from "./MonetTooltip";

type MonetLabelledItemProps = VariantProps<typeof variants> & {
  label?: string;
  value?: string | number | boolean | React.ReactNode;
  className?: string;
  tooltip?: string;
  shouldTruncate?: boolean;
};

const MonetLabelledItem: React.FC<MonetLabelledItemProps> = ({ label, value, arrangement, className, tooltip, shouldTruncate = false }) => {
  return (
    <div className={twMerge(clsx(variants({ arrangement }), className))}>
      <div className={shouldTruncate ? "whitespace-nowrap" : undefined}>
        <span className={twMerge(clsx("font-semibold min-w-fit", shouldTruncate && "whitespace-nowrap"))}>{label}</span>
        {tooltip && <MonetToolTip text={tooltip} />}
      </div>
      <span className={shouldTruncate ? "truncate sm:max-w-[75%]" : undefined}>{value ? value : "-"}</span>
    </div>
  );
};

export default MonetLabelledItem;

const variants = cva("flex flex-col text-sm flex mb-1 sm:mb-0 ", {
  variants: {
    arrangement: {
      row: "sm:flex-col sm:text-left",
      column: "sm:flex-row justify-between sm:gap-4 sm:text-right",
    },
  },
  defaultVariants: {
    arrangement: "column",
  },
});
