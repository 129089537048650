import { Dialog, DialogBackdrop } from "@headlessui/react";
import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface ModalContextType {
  openModal: <T extends React.FC<any>>(ModalComponent: T, props: React.ComponentProps<T>) => void;
  closeModal: () => void;
}

// Provide a default context value
const ModalContext = createContext<ModalContextType>({
  openModal: () => {},
  closeModal: () => {},
});

export const ModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [activeModal, setActiveModal] = useState<React.FC<any> | null>(null);
  const [modalProps, setModalProps] = useState<any>({});
  const [open, setOpen] = useState(false);

  const location = useLocation();

  const openModal = <T extends React.FC<any>>(ModalComponent: T, props: React.ComponentProps<T>) => {
    setActiveModal(() => ModalComponent);
    setModalProps(props);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const value: ModalContextType = {
    openModal,
    closeModal,
  };

  //Close currently opened modal
  useEffect(() => {
    closeModal();
  }, [location]);

  const CurrentModal = activeModal ? activeModal : null;

  return (
    <ModalContext.Provider value={value}>
      {children}
      <Dialog open={open} onClose={closeModal} className="relative z-10">
        <DialogBackdrop transition className="fixed inset-0 bg-black bg-opacity-30 transition-opacity duration-300 ease-in-out data-[closed]:opacity-0" />
        {CurrentModal && <CurrentModal {...modalProps} />}
      </Dialog>
    </ModalContext.Provider>
  );
};

// Hook to use Modal Context
export const useModal = () => useContext(ModalContext);
