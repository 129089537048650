import { yupResolver } from "@hookform/resolvers/yup";
import { Percentage } from "@monet-money/percentage-type";
import MonetCard from "components/tailwind/MonetCard";
import MonetEditSaveButton from "components/tailwind/MonetEditSaveButton";
import MonetLoadingSpinner from "components/tailwind/MonetLoadingSpinner";
import MonetViewTitle from "components/tailwind/MonetViewTitle";
import MonetInput from "components/tailwind/form/MonetInput";
import { useModal } from "contexts/ModalProvider";
import getSymbolFromCurrency from "currency-symbol-map";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { CurrencyRate } from "shared/generated/ep3.graphql.types";
import { toast } from "sonner";
import { CurrencyWithRates, getCurrencyReferenceName } from "utils/currency";
import { limitDecimalPlaces } from "utils/inputs";
import { useUpdateCurrencyBaseRateMutation } from "../graphql/mutations.generated";
import { useGetCurrencyBaseRatesQuery } from "../graphql/queries.generated";
import CurrencyUpdatedTime from "./components/RateLastUpdatedTime";
import UpdateBaseRatesModal from "./components/UpdateBaseRatesModal";
import { BaseRatesFormObject, balanceSheetReportFormSchema } from "./validationSchema/EditBaseRatesSchema";

const BaseRates = () => {
  const [editable, setEditable] = useState(false);
  const [currencies, setCurrencies] = useState<CurrencyWithRates[]>([]);

  const { openModal } = useModal();

  const { loading: isCurrencyRatesLoading, data: currencyRatesData, refetch: refetchCurrencyRates } = useGetCurrencyBaseRatesQuery({ fetchPolicy: "network-only" });
  const [updateBaseRates] = useUpdateCurrencyBaseRateMutation();

  useEffect(() => {
    if (currencyRatesData?.getCurrencyBaseRates) {
      const data = currencyRatesData?.getCurrencyBaseRates as CurrencyRate[];

      const formattedCurrencies = data.map((item) => {
        const baseRate = Percentage.fromStorageValue(item.baseRate).format(true, 5) as number;
        return {
          currency: item.currency,
          baseRate: baseRate,
          oldBaseRate: baseRate,
          updatedAt: item.createdAt,
          updatedBy: [item.updatedBy.firstName, item.updatedBy.lastName].join(" "),
        };
      });
      setCurrencies([...formattedCurrencies]);
    }
  }, [currencyRatesData]);

  const toggleEdit = useCallback(() => {
    if (editable) {
      reset();
    }
    setEditable(!editable);
  }, [editable]);

  const defaultValues = useMemo<{ [key: string]: string } | undefined>(() => {
    return currencies.reduce((acc: { [key: string]: string }, currency: CurrencyWithRates) => {
      acc[currency.currency] = currency.baseRate.toString();
      return acc;
    }, {});
  }, [currencies]);

  const { register, reset, setValue, getValues, formState, handleSubmit } = useForm<BaseRatesFormObject>({
    mode: "onTouched",
    values: defaultValues as BaseRatesFormObject,
    resolver: yupResolver(balanceSheetReportFormSchema),
  });

  const onSubmit = (): Promise<boolean> => {
    return new Promise((resolve) => {
      const values = getValues();
      const formattedValues = Object.entries(values)
        .filter(([currency]) => formState.dirtyFields[currency as Components.Schemas.Currency])
        .map(([currency, baseRate]) => ({
          currency,
          baseRate: new Percentage(baseRate, 5).percentage,
        }));

      try {
        updateBaseRates({
          variables: {
            currencies: formattedValues,
          },
        }).then(() => {
          refetchCurrencyRates();
        });
        setEditable(false);
        toast.success("Success", { description: "Base rates have been updated" });
      } catch (error) {
        toast.error("Failed to process request", { description: "Failed to update the base rates. Please try again" });
      } finally {
        resolve(true);
      }
    });
  };

  const getInputLabel = (currency: string) => {
    return `${getCurrencyReferenceName(currency as Components.Schemas.Currency)} (${currency} ${getSymbolFromCurrency(currency)})`;
  };

  const dirtyFields = useMemo(() => {
    if (formState.isDirty) {
      const dirtyFieldsArray = Object.keys(formState.dirtyFields).map((field) => getInputLabel(field));
      return <span className="font-semibold">{dirtyFieldsArray.join(", ")}</span>;
    } else return undefined;
  }, [formState]);

  return (
    <>
      <MonetViewTitle>Base rates</MonetViewTitle>
      <MonetCard className="max-w-[1000px]">
        <form onSubmit={handleSubmit(() => openModal(UpdateBaseRatesModal, { onConfirmCallback: onSubmit, dirtyFields: dirtyFields }))} className="flex flex-col gap-4">
          <div className="flex flex-row justify-between items-start gap-6">
            <p className="max-w-[700px] text-sm">
              As an Administrator you can manage currency base rates for the partners. Any changes to base rates will be applied as soon as they are saved.
            </p>
            <MonetEditSaveButton
              showSaveButton={editable}
              saveButtonDisabled={!formState.isDirty || !formState.isValid}
              onEditCallBack={toggleEdit}
              onCancelCallback={toggleEdit}
            />
          </div>
          {isCurrencyRatesLoading && <MonetLoadingSpinner size="default" />}
          {currencies &&
            !isCurrencyRatesLoading &&
            currencies.map((currency) => (
              <MonetInput
                key={currency.currency}
                label={getInputLabel(currency.currency)}
                endAdornment="%"
                inputMode="decimal"
                step="any"
                secondaryLabel={<CurrencyUpdatedTime currency={currency} />}
                readOnly={!editable}
                type="number"
                min={0}
                error={formState.errors[currency.currency as Components.Schemas.Currency]?.message as string}
                {...register(currency.currency as Components.Schemas.Currency, {
                  onChange: (e) => setValue(currency.currency as Components.Schemas.Currency, limitDecimalPlaces(e.target.value, 5)),
                })}
              />
            ))}
        </form>
      </MonetCard>
    </>
  );
};

export default BaseRates;
