import { Box, Button, Stack, Typography, alpha, styled } from "@mui/material";
import { ReactComponent as MonetLogo } from "assets/tailwind/monet-logo.svg";
import emails from "config/emails.json";
import links from "config/links.json";
import React from "react";

type ErrorPageProps = {
  statusCode: number;
};

const Container = styled(Stack)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  padding: theme.spacing(0, 2),
  overflow: "hidden",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: theme.spacing(0, 4),
  },
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  position: "absolute",
  top: theme.spacing(2),
  left: theme.spacing(2),
  "& svg": {
    width: 100,
  },
  [theme.breakpoints.up("md")]: {
    position: "relative",
    top: "unset",
    left: "unset",
    "& svg": {
      width: 150,
    },
  },
}));

const HelpDeskButton = styled(Button)(({ theme }) => ({
  padding: 0,
  position: "absolute",
  top: theme.spacing(4),
  right: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    bottom: theme.spacing(4),
    left: theme.spacing(4),
    top: "unset",
    right: "unset",
  },
}));

const ErrorDescContainer = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  maxWidth: 600,
  border: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
}));

const TransparentBlurBackground = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: alpha(theme.palette.common.white, 0.3),
  backdropFilter: "blur(1px)",
  boxShadow: `0px 0px 6px 1px ${alpha(theme.palette.common.black, 0.3)}`,
}));

const ErrorPage: React.FC<ErrorPageProps> = ({ statusCode }) => {
  let errorMessage = "";
  let errorDescription = "";

  switch (statusCode) {
    case 403:
      errorMessage = "Access denied, sorry.";
      errorDescription = "If you think this is a mistake contact us by clicking below";
      break;
    case 404:
      errorMessage = "Page not found";
      errorDescription = "The page you are looking for does not exist.";
      break;
    case 500:
      errorMessage = "Server error, give us a minute";
      errorDescription = "Something went wrong on our end, please reload the page or contact us";
      break;
    default:
      errorMessage = "Error";
      errorDescription = "An error occurred.";
      break;
  }

  return (
    <Container>
      <LogoContainer>
        <MonetLogo />
      </LogoContainer>
      <ErrorDescContainer sx={{ position: "relative" }}>
        <Box position={"relative"} width={"inherit"}>
          <TransparentBlurBackground />
          <Box px={4} position="inherit" width="100%" display={"flex"} justifyContent="center" flexDirection="column" gap={3} height={{ md: 400, xs: "80vh" }}>
            <Typography variant="h4" fontWeight="bold" data-testid="errorPage-heading">
              {errorMessage}
            </Typography>
            <Typography data-testid="errorPage-description">{errorDescription}</Typography>
            {[403, 500].includes(statusCode) && (
              <Button variant="contained" href={"mailto:" + emails.support}>
                Contact us
              </Button>
            )}
          </Box>
        </Box>
      </ErrorDescContainer>
      <HelpDeskButton
        variant="text"
        color="primary"
        onClick={() => {
          window.open(links.helpDesk, "_blank", "noopener,noreferrer");
        }}
      >
        Help desk
      </HelpDeskButton>
    </Container>
  );
};

export default ErrorPage;
