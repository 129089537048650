import TailwindDialog from "components/tailwind/headlessTailwind/TailwindDialog";
import { CampaignDeliverable } from "shared/types/eep.contract.types";
import { toast } from "sonner";
import { useDeleteCampaignDeliverableMutation } from "views/campaigns/graphql/mutations.generated";

type DeleteDeliverablesModalProps = {
  refetchCampaign(): void;
  deliverable?: CampaignDeliverable;
  campaignId?: string;
};

const DeleteDeliverablesModal: React.FC<DeleteDeliverablesModalProps> = ({ deliverable, campaignId, refetchCampaign }) => {
  const [deleteDeliverable] = useDeleteCampaignDeliverableMutation();

  const handleDelete = (): Promise<boolean> => {
    return new Promise(async (resolve) => {
      if (campaignId && deliverable) {
        await deleteDeliverable({
          variables: {
            campaignId: campaignId,
            deliverableId: deliverable.deliverableId,
          },
        })
          .then(() => {
            toast.success("Success", { description: "Campaign deliverable has been deleted" });
            refetchCampaign();
          })
          .catch((err: any) => {
            toast.error("Request failed", { description: `Failed to delete deliverable. ${err.message}` });
          })
          .finally(() => {
            resolve(true);
          });
      }
    });
  };

  return (
    <TailwindDialog type="danger" title="Delete campaign deliverable" submitButtonColor="red-solid" onConfirmCallback={handleDelete}>
      Are you sure? You will be permanently deleting deliverable <br /> <span className="font-semibold">{deliverable?.name}</span>
    </TailwindDialog>
  );
};

export default DeleteDeliverablesModal;
