import { LoadingButton } from "@mui/lab";
import { Button, Stack } from "@mui/material";
import React from "react";
import { useFormState } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { PARTNERS_LIST_ALL_PATH, PARTNERS_PATH } from "../../PartnersPath";
import { CurrentViewType } from "../utils";

const NavigationButtons: React.FC<{
  loading: boolean;
  handleSubmit: () => void;
  currentView: CurrentViewType;
  setCurrentView: React.Dispatch<React.SetStateAction<CurrentViewType>>;
}> = ({ currentView, setCurrentView, loading, handleSubmit }) => {
  const { errors, valid, values } = useFormState();
  const navigate = useNavigate();

  const getBackButtonView = React.useMemo((): CurrentViewType => {
    switch (currentView) {
      case "Confirmation":
        return "KybStatus";
      case "KybStatus":
        return "TermsAndConditions";
      case "TermsAndConditions":
        return "FeeSettings";
      case "FeeSettings":
        return "FeeSubscriptions";
      case "FeeSubscriptions":
        return "UpperCreditLimit";
      case "UpperCreditLimit":
        return "Address";
      case "Address":
        return "LandingPage";
      case "LandingPage":
        return "PartnerUrl";
      case "PartnerUrl":
        return "PartnerLogo";
      case "PartnerLogo":
        return "PartnerLocation";
      case "PartnerLocation":
        return "PartnerId";
      case "PartnerId":
        return "PartnerName";

      default:
        return "PartnerName";
    }
  }, [currentView]);

  const getNextButtonView = React.useMemo((): CurrentViewType => {
    switch (currentView) {
      case "PartnerName":
        return "PartnerId";
      case "PartnerId":
        return "PartnerLocation";
      case "PartnerLocation":
        return "PartnerLogo";
      case "PartnerLogo":
        return "PartnerUrl";
      case "PartnerUrl":
        return "LandingPage";
      case "Address":
        return "UpperCreditLimit";
      case "UpperCreditLimit":
        return "FeeSubscriptions";
      case "FeeSubscriptions":
        return "FeeSettings";
      case "FeeSettings":
        return "TermsAndConditions";
      case "TermsAndConditions":
        return "KybStatus";
      case "KybStatus":
        return "Confirmation";
      case "Confirmation":
        return "Success";
      default:
        return "Address";
    }
  }, [currentView]);

  // Check Validation for each stage
  const isFormValid = React.useMemo((): boolean => {
    switch (currentView) {
      case "Address":
        return !errors?.address;
      case "UpperCreditLimit":
        return !errors?.limits?.global && !errors?.defaultConfiguration?.allowedCurrencies;
      case "FeeSubscriptions":
        return !errors?.feeRules?.subscriptions;
      case "FeeSettings":
        return (
          !errors?.defaultConfiguration?.feeSettings?.default &&
          !errors?.defaultConfiguration?.feeSettings?.max &&
          !errors?.defaultConfiguration?.feeSettings?.monet &&
          !errors?.defaultConfiguration?.payoutTtlDelta
        );
      case "TermsAndConditions":
        return !errors?.defaultConfiguration?.termsAndConditions && !errors?.defaultConfiguration?.invoiceAssignmentTerms;
      case "KybStatus":
        return !errors?.kybStatus;
      case "PartnerName":
        return !errors?.name;
      case "PartnerId":
        return !errors?.partnerId;
      case "PartnerLocation":
        return !errors?.country;
      case "PartnerLogo":
        return !errors?.logo;
      case "PartnerUrl":
        return !errors?.returnUrl;
      default:
        return valid;
    }
  }, [currentView, errors]);

  const navigationButtonText = React.useMemo(() => {
    switch (currentView) {
      case "Confirmation":
        return "Confirm";
      case "PartnerUrl":
        return values?.returnUrl ? "Next" : "Skip";
      case "PartnerLogo":
        return values?.logo?.file ? "Next" : "Skip";
      default:
        return "Next";
    }
  }, [currentView, values?.returnUrl, values?.logo?.file]);

  const handleBackButtonClick = () => {
    if (currentView == "PartnerName") {
      navigate(PARTNERS_PATH + PARTNERS_LIST_ALL_PATH);
    } else {
      setCurrentView(getBackButtonView);
    }
  };

  const handleNextButtonClick = async () => {
    if (currentView == "Confirmation") {
      handleSubmit();
    } else {
      setCurrentView(getNextButtonView);
    }
  };

  if (["KybStatus", "Success", "LandingPage"].includes(currentView)) return null;

  return (
    <Stack alignSelf="end" direction={{ xs: "column-reverse", md: "row" }} spacing={2}>
      <Button data-testid="partnerJourney-backBtn" type="button" disabled={loading} onClick={handleBackButtonClick} color="inherit">
        Back
      </Button>

      <LoadingButton data-testid="partnerJourney-continueBtn" loading={loading} loadingPosition="end" disabled={!isFormValid} onClick={handleNextButtonClick} variant="contained">
        {loading ? "Confirming" : navigationButtonText}
      </LoadingButton>
    </Stack>
  );
};

export default NavigationButtons;
