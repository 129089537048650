import TailwindDialog from "components/tailwind/headlessTailwind/TailwindDialog";

type UpdateBaseRatesModalProps = {
  onConfirmCallback(): Promise<boolean>;
  dirtyFields?: React.ReactNode;
};

const UpdateBaseRatesModal: React.FC<UpdateBaseRatesModalProps> = ({ onConfirmCallback, dirtyFields }) => {
  return (
    <TailwindDialog type="warning" title="Updating base rates" onConfirmCallback={onConfirmCallback}>
      Are you sure? You will be updating the following base rates {dirtyFields}. This change will take effect immediately
    </TailwindDialog>
  );
};

export default UpdateBaseRatesModal;
