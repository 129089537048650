import { Box, FormControl, FormLabel } from "@mui/material";
import FlexRow from "components/FlexRow/FlexRow";
import { TextField } from "mui-rff";
import React, { useCallback, useEffect } from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { Partner } from "utils/Partner";
import PageTitle from "./PageTitle";

type PartnerNameSectionProps = {
  existingPartners?: Partner[];
};

const PartnerIdSection: React.FC<PartnerNameSectionProps> = ({ existingPartners }) => {
  const { values, initialValues } = useFormState();
  const { change } = useForm();

  const validatePartnerId = useCallback(
    (id: string) => {
      if (existingPartners) {
        const isDuplicate = existingPartners!.find((partner) => partner.partnerId === id);
        if (isDuplicate) {
          return "Partner Id already exists. Please enter a unique Id.";
        }
      }
      return false;
    },
    [existingPartners],
  );

  useEffect(() => {
    change("defaultConfiguration.invoiceAssignmentTerms", initialValues.defaultConfiguration.invoiceAssignmentTerms);
  }, [values.partnerId]);

  return (
    <Box>
      <PageTitle>What's the partner ID?</PageTitle>
      <Box mb={2}>
        <FlexRow>
          <Box>
            <Field name="partnerId" validate={validatePartnerId}>
              {({ input }) => (
                <FormControl fullWidth>
                  <FormLabel>Partner Id</FormLabel>
                  <TextField name={input.name} data-testid="partnerJourney-partnerIdField" placeholder="Enter partner id" autoComplete="off" />
                </FormControl>
              )}
            </Field>
          </Box>
        </FlexRow>
      </Box>
    </Box>
  );
};

export default PartnerIdSection;
