import { Money } from "@monet-money/money-type";
import { Percentage } from "@monet-money/percentage-type";
import MonetBorderedCardContent from "components/tailwind/MonetBorderedCardContent";
import MonetDrawerButtons from "components/tailwind/MonetDrawerButtons";
import MonetLabelledItem from "components/tailwind/MonetLabelledItem";
import StatusBadge from "components/tailwind/StatusBadge";
import TailwindDrawer from "components/tailwind/headlessTailwind/TailwindDrawer";
import { useSessionProvider } from "contexts/SessionProvider";
import dayjs from "dayjs";
import { Invoice } from "utils/Invoice";
import { isInAdminContext } from "utils/User";
import { DEFAULT_DATE_FORMAT } from "utils/date";

type ViewFundingRequestDrawerProps = {
  invoice: Invoice;
};

const ViewFundingRequestDrawer: React.FC<ViewFundingRequestDrawerProps> = ({ invoice }) => {
  const { user } = useSessionProvider();

  if (!invoice.funding) {
    return null;
  }

  return (
    <>
      <TailwindDrawer title="Funding request" subtitle="View the funding request details for this invoice below">
        <div className="flex flex-col gap-4 h-full">
          <MonetBorderedCardContent>
            <MonetLabelledItem label="Request date" value={dayjs(invoice.funding?.request.requestDate).format(DEFAULT_DATE_FORMAT)} />
            <MonetLabelledItem label="Requested by" value={invoice.funding?.request.requestBy} />
            <MonetLabelledItem label="Funding status" value={<StatusBadge type="invoice" text={invoice.funding?.fundingStatus} />} />
            <MonetLabelledItem label="Processed date" value={invoice.funding?.request.processedDate && dayjs(invoice.funding?.request.processedDate).format(DEFAULT_DATE_FORMAT)} />
            {isInAdminContext(user) && <MonetLabelledItem label="Processed by" value={invoice.funding?.request.processedBy} />}
          </MonetBorderedCardContent>
          <MonetBorderedCardContent>
            <MonetLabelledItem label="Advance rate" value={invoice.funding?.advanceRate && Percentage.fromStorageValue(invoice.funding?.advanceRate).format()} />
            <MonetLabelledItem label="Principal" value={invoice.funding?.principalAmount && Money.fromStorageType(invoice.funding?.principalAmount).format(true, true)} />
            <MonetLabelledItem label="Invoice value" value={Money.fromStorageType(invoice.invoiceValue).format(true, true)} />
          </MonetBorderedCardContent>
          {invoice.funding?.notes && (
            <div>
              <p className="font-semibold text-sm">Notes</p>
              <MonetBorderedCardContent>
                <span>{invoice.funding?.notes?.note}</span>
              </MonetBorderedCardContent>
            </div>
          )}
          {isInAdminContext(user) && (
            <>
              {invoice.funding?.underwritingNotes && (
                <div>
                  <p className="font-semibold text-sm">Underwriting commentary</p>
                  <MonetBorderedCardContent>
                    <span>{invoice.funding?.underwritingNotes?.note}</span>
                  </MonetBorderedCardContent>
                </div>
              )}
              {(invoice.funding?.wiserfundingRiskScore || invoice.funding?.experianRiskScore) && (
                <div>
                  <p className="font-semibold text-sm">Risk scores</p>
                  <MonetBorderedCardContent>
                    <MonetLabelledItem label="WiserFunding" value={invoice.funding?.wiserfundingRiskScore} />
                    <MonetLabelledItem label="Experian" value={invoice.funding?.experianRiskScore} />
                  </MonetBorderedCardContent>
                </div>
              )}
            </>
          )}
        </div>
        <MonetDrawerButtons isPassive={true} cancelDisabled={false} submitDisabled={false} />
      </TailwindDrawer>
    </>
  );
};

export default ViewFundingRequestDrawer;
