import { ReactComponent as Loader } from "assets/tailwind/icons/loader-2.svg";
import { useMemo } from "react";

type MonetLoadingSpinnerProps = {
  size: "x-small" | "small" | "default" | "large";
};

const MonetLoadingSpinner: React.FC<MonetLoadingSpinnerProps> = ({ size }) => {
  const sizeValue = useMemo(() => {
    switch (size) {
      case "x-small":
        return "14";
      case "small":
        return "16";
      case "default":
        return "20";
      case "large":
        return "24";
      default:
        return "20";
    }
  }, [size]);

  return <Loader className="animate-spin text-monet-blue" aria-label="loading" width={sizeValue} height={sizeValue} />;
};

export default MonetLoadingSpinner;
