import { Button, Typography } from "@mui/material";
import CustomModal from "components/CustomModal/CustomModal";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { EP3User } from "utils/User";
import { useDeleteUserMutation } from "../graphql/mutations.generated";

type DeleteUserButtonProps = {
  user: EP3User;
  onDeleteCallback(): void;
};

const DeleteUserButton: React.FC<DeleteUserButtonProps> = ({ user, onDeleteCallback }) => {
  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [deleteUser, { loading }] = useDeleteUserMutation({ fetchPolicy: "network-only" });

  const handleDeleteUser = () => {
    deleteUser({
      variables: {
        username: user.email,
      },
    })
      .then(() => {
        onDeleteCallback();
        setOpen(false);
      })
      .catch(() => {
        enqueueSnackbar("Error deleting user. Please try again", {
          variant: "error",
        });
      });
  };

  return (
    <>
      <Button data-testid="deleteUser-btn" data-testvalue={user.email} variant="text" color="error" sx={{ p: 0 }} onClick={() => setOpen(true)}>
        Delete user
      </Button>
      <CustomModal title="Delete user" modalOpen={open} onConfirmCallback={handleDeleteUser} onCancelCallback={() => setOpen(false)} confirmBtnText="Delete" isLoading={loading}>
        <Typography>
          Are you sure? You will permanently delete user
          <Typography component="span" fontWeight={600}>
            &nbsp;{user.firstName} {user.lastName}&nbsp;
          </Typography>
          and all stored information on this user will be erased
        </Typography>
      </CustomModal>
    </>
  );
};

export default DeleteUserButton;
