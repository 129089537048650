/**
 * Get symbol for a given currency text
 * @param currency string
 * @return string
 */
const getCurrencySymbol = (currency: string): string =>
  (0)
    .toLocaleString("en-GB", {
      minimumFractionDigits: 0,
      currency,
      style: "currency",
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();
export default getCurrencySymbol;

export const enumToNiceString = (word: string): string => {
  const tmp = word.replaceAll(/_/gi, " ");
  return tmp.charAt(0).toUpperCase() + tmp.slice(1).toLowerCase();
};

/**
 * Removes whitespace from string
 * @param value string
 * @returns string
 */
export const removeAllWhitespaces = (value: string): string => value.replace(/\s/g, "");

export const formatURL = (link: string): string => {
  if (!link) return "/";
  return link.startsWith("http://") || link.startsWith("https://") ? link : `https://${link}`;
};

export const addPlusSignToPhoneNumber = (phone: string) => {
  if (!phone.startsWith("+")) {
    phone = "+" + phone;
  }

  return phone;
};

export const escapeDots = (id?: string) => {
  return id?.replace(/\./g, "\\.");
};

export const chainCaseToSentenceCase = (chainCaseStr: string) => {
  const words = chainCaseStr.split("-");

  if (words.length === 0) {
    return "";
  }

  const firstWord = words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();

  const remainingWords = words.slice(1).map((word) => word.toLowerCase());

  return [firstWord, ...remainingWords].join(" ");
};

export const toSentenceCase = (str: string): string => {
  // Insert space before each uppercase letter, except the first letter
  const formatted = str.replace(/([A-Z])/g, " $1");

  // Capitalize the first letter and convert the rest to lowercase
  const result = formatted.charAt(0).toUpperCase() + formatted.slice(1).toLowerCase();

  return result.trim();
};
