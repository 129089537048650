import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import ViewLayout from "components/tailwind/templates/ViewLayout";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { REPORTS_PATH } from "./AnalyticsPath";
import Reports from "./reports/Reports";

const AnalyticsRoutes: React.FC = () => {
  return (
    <ErrorBoundary>
      <ViewLayout helmet="Reports">
        <Routes>
          <Route path={REPORTS_PATH} element={<Reports />} />
        </Routes>
      </ViewLayout>
    </ErrorBoundary>
  );
};

export default AnalyticsRoutes;
