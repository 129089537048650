import { SchemaOf, mixed, object } from "yup";

export type OrganisationLogoUploadFormObject = {
  logo: any;
};

const MAX_IMAGE_FILE_SIZE = 5000000; //5MB

const validImageExtensions: string[] = ["png", "jpg", "jpeg"];

function isValidFileType(value: FileList) {
  if (value && value.length > 0) {
    return validImageExtensions.includes(value[0].name.split(".").pop()!);
  } else return false;
}

export const organisationLogoUploadSchema = <SchemaOf<OrganisationLogoUploadFormObject>>object().shape({
  logo: mixed()
    .required("Please select a file to upload")
    .test("is-required", "Please select a file to upload", (value) => {
      if (value && value.length === 0) {
        return false;
      }
      return true;
    })
    .test("is-valid-type", `Invalid file. Only the following types are allowed: ${validImageExtensions.join(", ")}`, (value) => isValidFileType(value))
    .test("is-valid-size", "File to large. Maximum file size is 5MB", (value) => value && value.length > 0 && value[0].size <= MAX_IMAGE_FILE_SIZE),
});
