import { useSessionProvider } from "contexts/SessionProvider";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const StripePricingTable: React.FC = () => {
  const { partner } = useSessionProvider();

  const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  const stripePricingTableId = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;

  return <stripe-pricing-table pricing-table-id={stripePricingTableId} publishable-key={stripePublishableKey} client-reference-id={partner?.partnerId}></stripe-pricing-table>;
};

export default StripePricingTable;
