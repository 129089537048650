import { ReactComponent as MenuIcon } from "assets/tailwind/icons/ellipsis-vertical.svg";
import { PropsWithChildren } from "react";
import { MonetButtonProps } from "./MonetButton";
import TailwindDropdown, { TailwindDropdownProps } from "./headlessTailwind/TailwindDropdown";

type MonetMenuDropdownProps = PropsWithChildren & {
  footerChildren?: TailwindDropdownProps["footerChildren"];
  menuButtonProps?: {
    children?: React.ReactNode;
    size?: MonetButtonProps["size"];
    variant?: MonetButtonProps["variant"];
    className?: string;
  };
  dropdownPosition?: TailwindDropdownProps["dropdownPosition"];
};

const MonetMenuDropdown: React.FC<MonetMenuDropdownProps> = ({ children, menuButtonProps, footerChildren, dropdownPosition }) => {
  return (
    <TailwindDropdown
      menuButtonProps={{
        children: <MenuIcon />,
        size: "x-small",
        variant: "outlined",
        ...menuButtonProps,
      }}
      footerChildren={footerChildren}
      dropdownPosition={dropdownPosition}
    >
      {children}
    </TailwindDropdown>
  );
};

export default MonetMenuDropdown;
