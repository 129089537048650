import { AWS_PASSWORD_REGEX } from "shared/utils/regex";
import { object, ref, SchemaOf, string } from "yup";

export type ChangePasswordFormObject = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

export const changePasswordSchema: SchemaOf<ChangePasswordFormObject> = object().shape({
  oldPassword: string().required("Current Password is required"),
  newPassword: string()
    .required("New Password is required")
    .matches(AWS_PASSWORD_REGEX, "Password is invalid")
    .notOneOf([ref("oldPassword")], "New Password should not be the same as the old password"),
  newPasswordConfirmation: string()
    .oneOf([ref("newPassword"), null], "Password Confirmation should match the new password")
    .required("New Password Confirmation is required"),
});
