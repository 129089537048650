import { LoadingButton } from "@mui/lab";
import { Box, Button, ButtonPropsColorOverrides, CircularProgress, Modal, Stack, Typography, styled, useTheme } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { PropsWithChildren } from "react";

type CustomModalProps = {
  title: string;
  modalOpen: boolean;
  onCancelCallback?: () => void;
  onConfirmCallback?: () => void;
  confirmBtnText?: string;
  isPassiveModal?: boolean;
  isLoadingModal?: boolean;
  confirmBtnColor?: OverridableStringUnion<"inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning", ButtonPropsColorOverrides>;
  isLoading?: boolean;
  isConfirmBtnDisabled?: boolean;
};

const ModalContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 550,
  width: "100%",
  backgroundColor: theme.palette.background.default,
}));

const CustomModal: React.FC<PropsWithChildren & CustomModalProps> = ({
  onCancelCallback,
  modalOpen,
  onConfirmCallback,
  confirmBtnText,
  title,
  children,
  isPassiveModal = false,
  isLoadingModal = false,
  confirmBtnColor = "primary",
  isLoading,
  isConfirmBtnDisabled = false,
}) => {
  const theme = useTheme();

  return (
    <Modal open={modalOpen} onClose={onCancelCallback} data-testid="customModal">
      <ModalContainer>
        <Stack p={3} borderBottom={!isPassiveModal ? `solid 2px ${theme.palette.grey[200]}` : "none"} flexDirection="row" gap={2}>
          {isLoadingModal && <CircularProgress size={25} />}
          <Typography variant="body1Large">{title}</Typography>
        </Stack>
        {!isLoadingModal && (
          <Stack flexDirection="column">
            {children && (
              <Stack p={3} gap={2}>
                {children}
              </Stack>
            )}
            <Stack p={3} gap={1} pt={0} flexDirection="row" alignSelf="end">
              {isPassiveModal ? (
                <Button fullWidth variant="contained" data-testid="customModal-dismissBtn" onClick={onCancelCallback ?? onConfirmCallback}>
                  Dismiss
                </Button>
              ) : (
                <>
                  <Button disabled={isLoading} variant="text" color="inherit" onClick={onCancelCallback} data-testid="customModal-cancelBtn">
                    Cancel
                  </Button>
                  <LoadingButton
                    disabled={isConfirmBtnDisabled}
                    loading={isLoading}
                    variant="contained"
                    loadingPosition="end"
                    data-testid="customModal-confirmBtn"
                    color={confirmBtnColor}
                    onClick={onConfirmCallback}
                  >
                    {confirmBtnText || "Confirm"}
                  </LoadingButton>
                </>
              )}
            </Stack>
          </Stack>
        )}
      </ModalContainer>
    </Modal>
  );
};

export default CustomModal;
