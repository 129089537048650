import { Currency } from "@monet-money/money-type";
import { MonetBadgeVariants } from "components/tailwind/MonetBadge";
import { countries } from "countries-list";
import getSymbolFromCurrency from "currency-symbol-map";
import { alpha3ToAlpha2 } from "i18n-iso-countries";
import { CurrencyWithMargin } from "shared/types/currency";
import { Address, InvoiceAssignmentTerms, KYBStatus, Subscription, TermsAndConditionsWithLinkType } from "shared/types/eep.contract.types";
import { CollectionAccount } from "shared/utils/beneficiaryDetails";
import { EP3User, isInAdminContext } from "./User";
import { Country } from "./country";

export type MonetAdmin = {
  partnerId: string;
  apiKeyId: string;
  name: string;
};

export type SubscriptionStatus = "ACTIVE" | "INACTIVE" | "TRIALING" | "CANCELED";

export type Partner = {
  partnerId: string;
  apiKeyId: string;
  name: string;
  collectionAccounts: CollectionAccount[];
  kybStatus: KYBStatus;
  country?: string;
  address?: Address;
  limits?: {
    global: Components.Schemas.Money;
  };
  defaultConfiguration: {
    logoUrl: string;
    termsAndConditions: TermsAndConditionsWithLinkType;
    invoiceAssignmentTerms: InvoiceAssignmentTerms;
    payoutTtlDelta: number;
    allowedCurrencies: CurrencyWithMargin[];
    feeSettings: {
      max: number;
      default: number;
    };
  };
  subscription?: {
    status: SubscriptionStatus;
    stripeProductName?: string;
    stripeProductID?: string;
    limits?: {
      campaignLimit?: number;
    };
    subscriptionId: string;
    createdDate: number;
    currentPeriodStart: number;
    currentPeriodEnd: number;
  };
  feeRules?: {
    currency: Components.Schemas.Currency;
    subscriptions: Subscription[];
  };
  businessMetadata?: {
    industry: string;
    turnover: string;
    countryOfRegistration: string;
    companyWebsite: string;
    functionalCurrencies?: string[];
    logoUrl?: string;
  };
};

export type FeeLimits = {
  costPerMonth: { amount: number | string; currency: Currency };
  creditLimit: { amount: number | string; currency: Currency };
};

export const initialSubscriptionRow = (currency: Currency = Currency.GBP) =>
  ({ costPerMonth: { amount: "", currency: currency }, creditLimit: { amount: "", currency: currency } }) as FeeLimits;

export const industries = [
  "Influencer Marketing",
  "Modelling and Casting",
  "Talent Management",
  "Content",
  "Media",
  "PR and Comms",
  "Creative",
  "Social",
  "Affiliates",
  "Advertising",
  "Performance",
  "Production",
  "Other",
];

export const turnovers = [
  "0-500,000",
  "500,001-1,000,000",
  "1,000,001-5,000,000",
  "5,000,001-10,000,000",
  "10,000,001-15,000,000",
  "15,000,001-20,000,000",
  "20,000,001-30,000,000",
  "30,000,001-40,000,000",
  "40,000,001-50,000,000",
  "50,000,001+",
];

const formatTurnoverWithCurrency = (country: string, value: string) => {
  let currencySymbol = "";
  if (!value) {
    return "";
  }
  if (country) {
    const selectedCurrency = (countries as Country)[alpha3ToAlpha2(country) as string]?.currency;
    if (selectedCurrency) {
      currencySymbol = getSymbolFromCurrency(selectedCurrency) ?? "";
    }
  }

  const [lowerValue, upperValue] = value.split("-");
  const upperValueWithSymbol = upperValue ? `- ${currencySymbol}${upperValue}` : "";
  return `${currencySymbol}${lowerValue} ${upperValueWithSymbol}`;
};

export const getTurnoversForInput = (country: string) => {
  return turnovers.map((x) => {
    return { label: formatTurnoverWithCurrency(country, x), value: x };
  });
};

const defaultDocument: Components.Schemas.DocumentLink = {
  link: "",
  linkType: "Generic",
};

export const PartnerDefaultConfiguration = {
  logoUrl: "",
  returnUrl: "",
  showLanding: false,
  termsAndConditions: { ...defaultDocument, accepted: false, timestamp: new Date().toISOString() },
  invoiceAssignmentTerms: { ...defaultDocument, accepted: false, timestamp: new Date().toISOString(), earlyPayAgreement: "", standardTerms: "", onboardingAgreement: "" },
  payoutTtlDelta: 0,
  allowedCurrencies: [],
  feeSettings: {
    max: 0,
    default: 0,
  },
};

export const getPartnerKYBStatusSettings = (status: Partner["kybStatus"]): { color: MonetBadgeVariants["variant"] } => {
  switch (status) {
    case "NOT_VERIFIED":
      return {
        color: "gray",
      };
    case "VERIFIED":
      return {
        color: "green",
      };
    default:
      return {
        color: "gray",
      };
  }
};

export const getPartnerSubscriptionSettings = (status: SubscriptionStatus): { color: MonetBadgeVariants["variant"] } => {
  switch (status) {
    case "ACTIVE":
      return {
        color: "green",
      };
    case "TRIALING":
      return {
        color: "blue",
      };
    case "CANCELED":
    case "INACTIVE":
      return {
        color: "gray",
      };
    default:
      return {
        color: "gray",
      };
  }
};

export const getPartnerInitials = (partnerName: string) => {
  const [name1, name2] = partnerName.split(" ");
  return (name1.substring(0, 1) + (name2?.substring(0, 1) || "")).toUpperCase();
};

export const missingOrganisationProfileInformation = (partner: Partner) => {
  return [
    !partner?.name && "Name",
    !partner?.address && "Address",
    !partner?.businessMetadata?.companyWebsite && "Company website",
    !partner?.businessMetadata?.countryOfRegistration && "Country of registration",
    !partner?.businessMetadata?.functionalCurrencies && "Transaction currencies",
    !partner?.defaultConfiguration?.logoUrl && "Logo",
    !partner?.businessMetadata?.industry && "Industry",
    !partner?.businessMetadata?.turnover && "Turnover",
  ].filter(Boolean);
};

export const isPartnerProfileComplete = (partner?: Partner, user?: EP3User): boolean => {
  if (isInAdminContext(user!)) return true;
  if (!user || !partner) return false;

  return missingOrganisationProfileInformation(partner).length === 0;
};

export const isPartnerVerified = (partner?: Partner, user?: EP3User): boolean => {
  if (isInAdminContext(user!)) return true;
  return partner?.kybStatus === "VERIFIED";
};

export const isPartnerSubscribed = (partner?: Partner, user?: EP3User): boolean => {
  if (isInAdminContext(user!)) return true;
  return Boolean(partner?.subscription) && partner?.subscription?.status !== "INACTIVE";
};

export const restrictPlatformAccess = (partner?: Partner, user?: EP3User) => {
  if (!isPartnerSubscribed(partner, user)) {
    return "No active subscription";
  } else if (!isPartnerProfileComplete(partner, user)) {
    return "Please complete your organisation profile";
  } else return false;
};
