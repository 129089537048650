import { ReactComponent as Cross } from "assets/tailwind/icons/cross.svg";
import { ReactComponent as Tick } from "assets/tailwind/icons/tick.svg";
import { HSStepper } from "preline/preline";
import { useCallback, useEffect, useMemo } from "react";
import MonetButton from "./MonetButton";

type MonetStepperProps = {
  stepperId: string;
  steps: Array<{
    index: number;
    content: React.ReactNode;
    label?: string;
  }>;
  finalStep?: React.ReactNode;
  currentStep: number;
  formSubmitted: boolean;
  formSubmissionSuccess: boolean;
  currentStepCallback(value: number): void;
  loading?: boolean;
  isButtonDisabled?: boolean;
};

const MonetStepper: React.FC<MonetStepperProps> = ({
  steps,
  stepperId,
  isButtonDisabled,
  currentStepCallback,
  currentStep,
  formSubmitted,
  loading,
  finalStep,
  formSubmissionSuccess,
}) => {
  useEffect(() => {
    HSStepper.autoInit();
    const stepper = HSStepper.getInstance(`#${stepperId}`) as HSStepper;
    stepper.on("next", (currentIndex: any) => currentStepCallback(currentIndex));
    stepper.on("back", (currentIndex: any) => currentStepCallback(currentIndex));
  }, []);

  const handleContinueBtn = useCallback(
    (e: any) => {
      const stepper = HSStepper.getInstance(`#${stepperId}`) as HSStepper;
      if (currentStep !== steps.length) {
        e.stopPropagation();
        e.preventDefault();
        stepper.goToNext();
      }
    },
    [currentStep],
  );

  useEffect(() => {
    if (formSubmitted && formSubmissionSuccess) {
      const finishButton = document.getElementById("finishButton");
      if (finishButton) {
        finishButton.click();
      }
    }
  }, [formSubmitted, formSubmissionSuccess]);

  const stepIcons = useMemo(() => {
    return steps.map((step) => (
      <li key={step.label} className="flex items-center gap-x-2 flex-1 group" data-hs-stepper-nav-item={`{ "index": ${step.index}}`}>
        <span className="min-w-7 min-h-7 group flex flex-col items-center text-xs align-middle">
          <span className="w-7 h-7 flex justify-center items-center flex-shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full group-focus:bg-gray-200 hs-stepper-active:bg-monet-blue hs-stepper-active:text-white hs-stepper-success:bg-monet-blue hs-stepper-success:text-white hs-stepper-completed:bg-teal-500 hs-stepper-completed:group-focus:bg-teal-600 hs-stepper-error:bg-red-500 dark:bg-neutral-700 dark:text-white dark:group-focus:bg-gray-600 dark:hs-stepper-active:bg-monet-blue dark:hs-stepper-success:bg-monet-blue dark:hs-stepper-completed:bg-teal-500 dark:hs-stepper-error:bg-red-500 dark:hs-stepper-completed:group-focus:bg-teal-600">
            <span className="hs-stepper-success:hidden hs-stepper-completed:hidden hs-stepper-error:hidden hs-stepper-processed:hidden">{step.index}</span>
            <Tick className="hidden flex-shrink-0 size-3 hs-stepper-success:block" width={20} height={20} />
            <Cross className="hidden flex-shrink-0 size-3 hs-stepper-error:block" width={20} height={20} />
          </span>
          {step.label && (
            <span className="hidden sm:block text-sm font-medium text-gray-800 group-focus:text-gray-500 dark:text-white dark:group-focus:text-gray-400">{step.label}</span>
          )}
        </span>
        <div className="w-full h-px flex-1 bg-gray-200 group-last:hidden hs-stepper-success:bg-monet-blue hs-stepper-completed:bg-teal-600 dark:bg-neutral-700 dark:hs-stepper-success:bg-monet-blue dark:hs-stepper-completed:bg-teal-500" />
      </li>
    ));
  }, [steps]);

  const stepsContent = useMemo(() => {
    return steps.map((step) => (
      <div key={step.label + "content"} data-hs-stepper-content-item={`{ "index": ${step.index} }`} style={{ display: "none" }}>
        <div>{step.content}</div>
      </div>
    ));
  }, [steps]);

  return (
    <div id={stepperId} className="flex flex-col gap-6" data-hs-stepper={`{ "mode": "linear" }`}>
      {/* Stepper Nav */}
      <ul className="relative flex flex-row gap-x-2 p-2 sm:p-5">
        {stepIcons}
        {/* Static done step */}
        <li className="flex items-center gap-x-2 group">
          <span className="min-w-7 min-h-7 group flex flex-col items-center text-xs align-middle">
            <span className="w-7 h-7 flex justify-center items-center flex-shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full group-focus:bg-gray-200 hs-stepper-completed:text-white hs-stepper-completed:bg-teal-500 hs-stepper-completed:group-focus:bg-teal-600 dark:bg-neutral-700 dark:text-white dark:group-focus:bg-gray-600 dark:hs-stepper-completed:bg-teal-500 dark:hs-stepper-completed:group-focus:bg-teal-600">
              <span className="hs-stepper-completed:hidden hs-stepper-processed:hidden">{steps.length + 1}</span>
              <Tick className="hidden flex-shrink-0 size-3 hs-stepper-completed:block" width={20} height={20} />
            </span>
            <span className="hidden sm:block text-sm font-medium text-gray-800 group-focus:text-gray-500 dark:text-white dark:group-focus:text-gray-400">Done</span>
          </span>
          <div className="w-full h-px flex-1 bg-gray-200 group-last:hidden hs-stepper-success:bg-monet-blue hs-stepper-completed:bg-teal-600 dark:bg-neutral-700 dark:hs-stepper-success:bg-monet-blue dark:hs-stepper-completed:bg-teal-500" />
        </li>
        {/* End static done step */}
      </ul>
      {/* End Stepper Nav */}
      {/* Stepper Content */}
      <div>
        {stepsContent}
        {/* Final Concent */}
        <div data-hs-stepper-content-item='{ "isFinal": true }' style={{ display: "none" }}>
          {finalStep}
        </div>
        {/* End Final Content */}
        <div className="mt-6 flex justify-between items-center gap-x-5">
          <MonetButton data-hs-stepper-back-btn variant="outlined" className="flex-grow" disabled={loading}>
            Back
          </MonetButton>
          {!(formSubmitted && formSubmissionSuccess) && (
            <MonetButton type="submit" variant="solid" className="basis-3/4" onClick={handleContinueBtn} disabled={isButtonDisabled || loading} loading={loading}>
              Continue
            </MonetButton>
          )}
          <MonetButton id="finishButton" data-hs-stepper-finish-btn variant="solid" className="!hidden" style={{ display: "none" }}>
            Finished
          </MonetButton>
        </div>
      </div>
      {/* End Stepper Content */}
    </div>
  );
};

export default MonetStepper;
