import { SchemaOf, array, mixed, object, string } from "yup";

export type CampaignDocuments = {
  file: any;
  fileType: string;
};

export type UploadCampaignDocumentFormObject = {
  documents: [
    {
      file: any;
      fileType: string;
    },
  ];
};

const MAX_FILE_SIZE = 5000000; //10MB

const validFileExtensions: string[] = ["png", "jpg", "jpeg", "pdf", "docx", "doc"];

function isValidFileType(value: FileList) {
  if (value.length > 0) {
    return validFileExtensions.includes(value[0].name.split(".").pop()!);
  } else return false;
}

export const uploadCampaignDocumentSchema = <SchemaOf<UploadCampaignDocumentFormObject>>object().shape({
  documents: array().of(
    object().shape({
      file: mixed()
        .required("Please select a file to upload")
        .test("is-required", "Please select a file to upload", (value) => {
          if (value.length === 0) {
            return false;
          }
          return true;
        })
        .test("is-valid-type", `Invalid file. Only the following types are allowed: ${validFileExtensions.join(", ")}`, (value) => isValidFileType(value))
        .test("is-valid-size", "File to large. Maximum file size is 5MB", (value) => value.length > 0 && value[0].size <= MAX_FILE_SIZE),
      fileType: string().required("Please select a document type"),
    }),
  ),
});
