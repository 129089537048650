import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import ViewLayout from "components/tailwind/templates/ViewLayout";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CampaignDetails from "./CampaignDetails/CampaignDetails";
import { CAMPAIGNS_DETAILS_PATH, CAMPAIGNS_LIST_ALL_PATH } from "./CampaignsPath";
import ListCampaigns from "./ListCampaigns/ListCampaigns";

const CampaignsRoutes: React.FC = () => {
  return (
    <ErrorBoundary>
      <ViewLayout helmet="Campaigns">
        <Routes>
          <Route path={CAMPAIGNS_LIST_ALL_PATH} element={<ListCampaigns />} />
          <Route path={CAMPAIGNS_DETAILS_PATH} element={<CampaignDetails />} />
          <Route path="/" element={<Navigate to={CAMPAIGNS_LIST_ALL_PATH} />} />
        </Routes>
      </ViewLayout>
    </ErrorBoundary>
  );
};

export default CampaignsRoutes;
