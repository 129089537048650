import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { ReactComponent as DownChevron } from "assets/tailwind/icons/chevron-down.svg";
import React, { PropsWithChildren } from "react";

type TailwindDisclosureProps = PropsWithChildren & {
  buttonElement: React.ReactNode;
};

const TailwindDisclosure: React.FC<TailwindDisclosureProps> = ({ children, buttonElement }) => {
  return (
    <Disclosure>
      <div className="flex flex-col justify-between gap-4 py-2 px-3 rounded-lg border border-gray-200 w-full">
        <DisclosureButton className="group flex flex-row justify-between">
          {buttonElement}
          <DownChevron className="group-data-[open]:rotate-180 flex-shrink-0 mt-1 size-3.5 ms-auto transition" />
        </DisclosureButton>
        <DisclosurePanel className="w-full">{children}</DisclosurePanel>
      </div>
    </Disclosure>
  );
};

export default TailwindDisclosure;
