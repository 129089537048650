import { Box, FormControl, FormLabel } from "@mui/material";
import FlexRow from "components/FlexRow/FlexRow";
import { TextField } from "mui-rff";
import React from "react";
import PageTitle from "./PageTitle";

const AddressSection: React.FC = () => {
  return (
    <Box>
      <PageTitle>Address of the partner</PageTitle>

      <Box mb={2}>
        <FlexRow>
          <Box>
            <FormControl fullWidth>
              <FormLabel>Address Line 1</FormLabel>
              <TextField name="address.addressLine1" data-testid="partnerAddress-address1FormField" placeholder="Enter address here" autoComplete="off" />
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth>
              <FormLabel>Address Line 2</FormLabel>
              <TextField name="address.addressLine2" data-testid="partnerAddress-address2FormField" placeholder="Enter address here" autoComplete="off" />
            </FormControl>
          </Box>
        </FlexRow>
      </Box>
      <FlexRow>
        <Box>
          <FormControl fullWidth>
            <FormLabel>Postcode</FormLabel>
            <TextField name="address.postcode" data-testid="partnerAddress-postcodeFormField" placeholder="Enter postcode here" autoComplete="off" />
          </FormControl>
        </Box>
        <Box>
          <FormControl fullWidth>
            <FormLabel>City</FormLabel>
            <TextField name="address.city" data-testid="partnerAddress-cityFormField" placeholder="Enter city here" autoComplete="off" />
          </FormControl>
        </Box>
      </FlexRow>
    </Box>
  );
};

export default AddressSection;
