export type CurrentViewType =
  | "PartnerName"
  | "PartnerId"
  | "PartnerLocation"
  | "PartnerLogo"
  | "PartnerUrl"
  | "LandingPage"
  | "Address"
  | "UpperCreditLimit"
  | "FeeSubscriptions"
  | "FeeSettings"
  | "TermsAndConditions"
  | "KybStatus"
  | "Confirmation"
  | "Success";

export const dotsRange = (start: number, end: number): number[] => {
  if (start === end) return [start];
  return [start, ...dotsRange(start + 1, end)];
};

export const dotOpacities = (): string[] => {
  let initialOpacity = 0;
  const finalOpacity: string[] = [];
  dotsRange(1, 8).forEach((_item, index) => {
    if (index < 7) {
      initialOpacity += 0.1;
      finalOpacity.push(initialOpacity.toFixed(1));
    } else {
      finalOpacity.push("1");
    }
  });
  return finalOpacity;
};
