import { Money, MoneyStorage } from "@monet-money/money-type";
import { ReactComponent as ChevronRight } from "assets/tailwind/icons/chevron-right.svg";
import MonetAlert from "components/tailwind/MonetAlert";
import MonetButton from "components/tailwind/MonetButton";
import links from "config/links.json";
import { useSessionProvider } from "contexts/SessionProvider";
import { useMemo, useState } from "react";
import { PopupModal } from "react-calendly";
import { isPartnerVerified } from "utils/Partner";

type BookOnboardingCallBannerProps = {
  campaignValue?: MoneyStorage;
};

const BookOnboardingCallBanner: React.FC<BookOnboardingCallBannerProps> = ({ campaignValue }) => {
  const [isCalendlyWidgetOpen, setIsCalendlyWidgetOpen] = useState(false);
  const { user, partner } = useSessionProvider();

  const message = useMemo(() => {
    if (campaignValue) {
      return (
        <p>
          You may be eligible to receive funding of up to {Money.fromStorageType(campaignValue).format(true, true)} for this campaign, subject to underwriting approval. To proceed,
          please book a call with our team, where we will guide you through the final steps of onboarding, enabling access to campaign funding. If you have already scheduled a
          call, please sit tight and our team will reach out within one business day.
        </p>
      );
    } else
      return (
        <p>
          To access payouts you need to complete onboarding. To proceed, please book a call with our team, where we will guide you through the final steps of onboarding, enabling
          access to payouts. If you have already scheduled a call, please sit tight and our team will reach out within one business day.
        </p>
      );
  }, [campaignValue]);

  if (isPartnerVerified(partner, user)) return null;

  return (
    <>
      <MonetAlert variant="info" title="Book onboarding call">
        <div className="flex flex-col gap-4 items-start">
          {message}
          <MonetButton size="small" onClick={() => setIsCalendlyWidgetOpen(true)}>
            Book onboarding call
            <ChevronRight />
          </MonetButton>
        </div>
      </MonetAlert>
      <PopupModal
        url={links.calendlyDemoLink}
        rootElement={document.getElementById("root")!}
        open={isCalendlyWidgetOpen}
        prefill={{
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          name: `${user.firstName} ${user.lastName}`,
          smsReminderNumber: user.phoneNumber,
          customAnswers: {
            a1: partner?.name,
            a2: partner?.businessMetadata?.companyWebsite,
            a3: user.phoneNumber,
          },
        }}
        onModalClose={() => {
          setIsCalendlyWidgetOpen(false);
        }}
      />
    </>
  );
};

export default BookOnboardingCallBanner;
