import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AUTH_PATH, FORGOT_PASSWORD_PATH, MFA_PATH, SET_PASSWORD_PATH, SIGN_IN_PATH, SIGN_UP_PATH, VERIFY_EMAIL_PATH } from "./AuthPath";
import PasswordReset from "./ForgotPassword/ForgotPassword";
import MFAAuthentication from "./MFAAuthentication/MFAAuthentication";
import SetPassword from "./SetPassword/SetPassword";
import SignIn from "./SignIn/SignIn";
import SignUp from "./SignUp/SignUp";
import VerifyEmail from "./VerifyEmail/VerifyEmail";

const AuthRoutes: React.FC = () => {
  const env = process.env.REACT_APP_ENV_NAME;

  const protectedRoute = (route: JSX.Element) => {
    if (env === "sandbox") {
      return <Navigate to={AUTH_PATH + SIGN_IN_PATH} replace />;
    }

    return route;
  };

  return (
    <Routes>
      <Route index element={<Navigate to={AUTH_PATH + SIGN_IN_PATH} />} />
      <Route path={MFA_PATH} element={<MFAAuthentication />} />
      <Route path={FORGOT_PASSWORD_PATH} element={<PasswordReset />} />
      <Route path={SET_PASSWORD_PATH} element={<SetPassword />} />
      <Route path={SIGN_IN_PATH} element={<SignIn />} />
      <Route path={SIGN_UP_PATH} element={protectedRoute(<SignUp />)} />
      <Route path={VERIFY_EMAIL_PATH} element={protectedRoute(<VerifyEmail />)} />
    </Routes>
  );
};

export default AuthRoutes;
