import { PropsWithChildren } from "react";

const MonetAuthTitle: React.FC<PropsWithChildren & { subtitle?: string | React.ReactNode }> = ({ children, subtitle }) => {
  return (
    <h1 className="text-2xl sm:text-3xl font-medium text-gray-800 dark:text-white">
      {children}
      {subtitle && <p className="text-base font-normal mt-1 dark:text-white">{subtitle}</p>}
    </h1>
  );
};

export default MonetAuthTitle;
