import MonetDrawerButtons from "components/tailwind/MonetDrawerButtons";
import TailwindDrawer from "components/tailwind/headlessTailwind/TailwindDrawer";
import TailwindSelectInput from "components/tailwind/headlessTailwind/TailwindSelectInput";
import { useModal } from "contexts/ModalProvider";
import { useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FullPayout } from "shared/types/eep.contract.types";
import { toast } from "sonner";
import { getPayoutStatusSettings } from "utils/Payouts";
import { enumToNiceString } from "utils/strings";
import { useUpdatePayoutStatusMutation } from "../../graphql/mutation.generated";

type UpdatePayoutStatusProps = {
  payout: FullPayout;
  refetchPayout(): void;
};

const UpdatePayoutStatusDrawer: React.FC<UpdatePayoutStatusProps> = ({ payout, refetchPayout }) => {
  const [updatePayoutStatus] = useUpdatePayoutStatusMutation();

  const { closeModal } = useModal();

  const onSubmit = async (formData: { status: string }) => {
    await updatePayoutStatus({
      variables: {
        payoutId: payout.payoutId,
        payoutStatus: formData.status,
      },
    })
      .then(() => {
        refetchPayout();
        closeModal();
        toast.success("Success", { description: "Payout status updated" });
      })
      .catch(() => {
        toast.error("Request failed", { description: "Failed to update payout status. Please try again" });
      });
  };

  const methods = useForm<{ status: string }>({
    mode: "onTouched",
  });

  const payoutStatuses = useMemo(() => {
    const transitions = getPayoutStatusSettings(payout.payoutStatus).transitions;
    return transitions.map((item) => ({
      label: enumToNiceString(item),
      value: item,
    }));
  }, [payout]);

  return (
    <TailwindDrawer title="Update payout status" subtitle="Update the status of this payout using the drop down list below">
      <FormProvider {...methods}>
        <form className="flex flex-col h-full justify-between" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-6">
            <Controller
              control={methods.control}
              name="status"
              rules={{
                required: { value: true, message: "Please select a new payout status" },
              }}
              render={({ field: { ...inputProps } }) => (
                <TailwindSelectInput
                  inputProps={inputProps}
                  placeholder="Please select a new status..."
                  disabled={methods.formState.isSubmitting}
                  options={payoutStatuses}
                  label="New payout status"
                  error={methods.formState.errors.status?.message}
                />
              )}
            />
          </div>
          <MonetDrawerButtons
            drawerId="update-payout-status-drawer"
            cancelDisabled={methods.formState.isSubmitting}
            submitDisabled={methods.formState.isSubmitting || !methods.formState.isValid}
            loading={methods.formState.isSubmitting}
          />
        </form>
      </FormProvider>
    </TailwindDrawer>
  );
};

export default UpdatePayoutStatusDrawer;
