import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react";
import { ReactComponent as UpDownChevron } from "assets/tailwind/icons/chevron-up-down.svg";
import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";
import { InputHTMLAttributes, forwardRef, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import getCurrencySymbol from "utils/strings";

export type MonetInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "size"> &
  VariantProps<typeof variants> & {
    label?: string;
    allowedCurrencies?: string[];
    error?: string;
    startAdornment?: React.ReactNode;
    currencyInputName?: string;
    currencyInputDefaultValue?: string;
  };

const MonetInputWithCurrency: React.FC<MonetInputProps> = forwardRef<HTMLInputElement, MonetInputProps>(
  ({ error, label, className, variant, size, allowedCurrencies, currencyInputName, currencyInputDefaultValue, ...props }, ref) => {
    const [currencySymbol, setCurrencySymbol] = useState(getCurrencySymbol(currencyInputDefaultValue!));
    const [selected, setSelected] = useState(currencyInputDefaultValue);
    const errorStyling = error ? "border-red-500 ring ring-red-100 ring-offset-0 focus:ring-red-500 focus:ring focus:ring-red-100 focus:outline-offset-0" : undefined;

    const { setValue } = useFormContext();

    const currencies = useMemo(() => {
      if (allowedCurrencies) {
        return allowedCurrencies.map((currency) => (
          <ListboxOption
            key={currency}
            value={currency}
            className="group flex justify-center cursor-pointer items-center gap-2 rounded-lg  py-1 data-[focus]:bg-gray-100 text-gray-800"
          >
            <div className="text-sm">{currency}</div>
          </ListboxOption>
        ));
      } else return [];
    }, []);

    const handleChange = (value: string) => {
      setSelected(value);
      setValue(currencyInputName!, value, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
      setCurrencySymbol(getCurrencySymbol(value));
    };

    return (
      <div className="w-full">
        {label && (
          <div className="flex flex-col sm:flex-row justify-between mb-2 text-sm">
            <label htmlFor={label} className="block font-semibold text-gray-800 dark:text-white">
              {label}
            </label>
          </div>
        )}
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-4">{currencySymbol}</div>
          <input ref={ref} className={twMerge(clsx(variants({ variant, size }), errorStyling))} {...props} />
          <div className="absolute inset-y-0 end-0 flex items-center z-20 text-gray-500 text-sm bg-gray-100 rounded-e-lg m-[1px] w-[70px]">
            <Listbox onChange={handleChange} value={selected}>
              <ListboxButton className="pe-6 pl-2">
                <span className="">{selected}</span>
                <div className="group absolute top-0 right-1 h-full flex items-center text-gray-800">
                  <UpDownChevron className={twMerge(clsx("text-gray-500 w-4 h-4"))} />
                </div>
              </ListboxButton>
              <ListboxOptions
                anchor="bottom"
                transition
                className={clsx(
                  "w-[var(--button-width)] rounded-lg border border-gray-200 bg-white mt-5 text-gray-800 [--anchor-gap:var(--spacing-5)] overflow-hidden",
                  "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0",
                )}
              >
                <div className="max-h-[300px] overflow-y-auto p-1">{currencies}</div>
              </ListboxOptions>
            </Listbox>
          </div>
        </div>
        {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
      </div>
    );
  },
);

export default MonetInputWithCurrency;

const variants = cva(
  "block ps-12 w-full text-sm border rounded-lg disabled:opacity-70 disabled:pointer-events-none disabled:bg-gray-100 dark:bg-transparent dark:disabled:bg-transparent",
  {
    variants: {
      variant: {
        default:
          "border-gray-200 placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600",
      },
      size: {
        small: "py-2 px-3.5",
        default: "py-3.5 px-4 h-[50px]",
        large: "py-6 px-5",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);
