import { Button, Stack, styled, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as DarkKybStatusIcon } from "assets/dark-kyb-status-icon.svg";
import { ReactComponent as LightKybStatusIcon } from "assets/light-kyb-status-icon.svg";
import React from "react";
import { useForm, useFormState } from "react-final-form";
import { KYBStatus } from "shared/types/eep.contract.types";
import { enumToNiceString } from "utils/strings";
import PageTitle from "./PageTitle";
import { CurrentViewType, dotOpacities, dotsRange } from "./utils";

const KYBIconContainer = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(6),
  alignItems: "center",
  justifyContent: "space-around",
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

const LightKybStatusIconContainer = styled(Box)(({ theme }) => ({
  "& .light-kyb-status-svg .symbol1": {
    stopColor: theme.palette.common.white,
  },

  "& .light-kyb-status-svg .symbol2": {
    stopColor: theme.palette.secondary.main,
  },
}));

const DarkKybStatusIconContainer = styled(Box)(({ theme }) => ({
  "& .dark-kyb-status-svg .symbol1": {
    stopColor: theme.palette.tertiary.main,
  },

  "& .dark-kyb-status-svg .symbol2": {
    stopColor: theme.palette.secondary.main,
  },
}));

const KybStatusSection: React.FC<{ setCurrentView: React.Dispatch<React.SetStateAction<CurrentViewType>> }> = ({ setCurrentView }) => {
  const { change } = useForm();
  const { values } = useFormState();
  const theme = useTheme();

  const handleStatusClick = (status: KYBStatus) => {
    change("kybStatus", status);
    setCurrentView("Confirmation");
  };

  const finalOpacity = React.useMemo(() => [...dotOpacities(), ...dotOpacities().reverse()], []);

  return (
    <Box>
      <PageTitle>KYB status</PageTitle>
      <Stack mb={3} direction={{ md: "row", xs: "column" }} spacing={3} justifyContent="space-between">
        <Box maxWidth={400}>
          <Box mb={7}>
            <Typography>You can select to change KYB status from here, reminder without KYB status turned to verified you will not be able to create any campaigns</Typography>
          </Box>
          <Box>
            <Typography>The current status of the company in the database is</Typography>

            <Typography variant="body1Large" color="secondary" fontWeight={600}>
              {enumToNiceString(values.kybStatus).toUpperCase()}
            </Typography>
          </Box>
          <KYBIconContainer>
            <LightKybStatusIconContainer display={{ md: "block", xs: "none" }}>
              <LightKybStatusIcon />
            </LightKybStatusIconContainer>
            <Box display={{ md: "block", xs: "none" }}>
              <Stack spacing={1} direction="row">
                {dotsRange(1, 8).map((item, index) => {
                  return (
                    <Box
                      key={item}
                      sx={{
                        opacity: finalOpacity[index],
                      }}
                      width={8}
                      height={8}
                      borderRadius="50%"
                      bgcolor={theme.palette.primary.main}
                    />
                  );
                })}
              </Stack>
            </Box>
            <DarkKybStatusIconContainer>
              <DarkKybStatusIcon />
            </DarkKybStatusIconContainer>
          </KYBIconContainer>
        </Box>
        <Box width="100%" maxWidth={{ md: 300 }}>
          <Typography
            variant="body1Large"
            sx={{
              "& > strong": {
                color: theme.palette.primary.main,
              },
            }}
          >
            Set to <strong> VERIFIED?</strong>
          </Typography>

          <Box mb={6} mt={5}>
            <Button data-testid="kybStatusSection-yesBtn" fullWidth variant="contained" onClick={() => handleStatusClick("VERIFIED")}>
              Yes
            </Button>
          </Box>
          <Box mb={6}>
            <Button data-testid="kybStatusSection-noBtn" fullWidth variant="outlined" onClick={() => handleStatusClick("NOT_VERIFIED")}>
              No
            </Button>
          </Box>

          <Button data-testid="kybStatusSection-backBtn" fullWidth variant="text" onClick={() => setCurrentView("TermsAndConditions")}>
            Back
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default KybStatusSection;
