import { ReactComponent as PlusIcon } from "assets/tailwind/icons/plus.svg";
import { useSessionProvider } from "contexts/SessionProvider";
import { useNavigate } from "react-router-dom";
import { isInPartnerContext } from "utils/User";
import { PAYOUTS_PATH, PAYOUT_CREATE, PAYOUT_VENDOR_PATH, PAYOUT_WITHDRAWAL_PATH } from "views/payouts/PayoutPath";
import MonetDropdownMenuItem from "./MonetDropdownMenuItem";
import TailwindDropdown from "./headlessTailwind/TailwindDropdown";

const CreatePayoutButton = () => {
  const navigate = useNavigate();
  const { user } = useSessionProvider();

  if (!isInPartnerContext(user)) return null;

  return (
    <TailwindDropdown
      menuButtonProps={{
        size: "x-small",
        children: (
          <>
            <PlusIcon />
            <span>Create payout</span>
          </>
        ),
      }}
    >
      <MonetDropdownMenuItem onClick={() => navigate(PAYOUTS_PATH + PAYOUT_CREATE + PAYOUT_WITHDRAWAL_PATH)} subtext="Get invoices paid early">
        Withdraw funds
      </MonetDropdownMenuItem>
      <MonetDropdownMenuItem onClick={() => navigate(PAYOUTS_PATH + PAYOUT_CREATE + PAYOUT_VENDOR_PATH)} subtext="Pay suppliers/talent early">
        Pay vendors
      </MonetDropdownMenuItem>
    </TailwindDropdown>
  );
};

export default CreatePayoutButton;
