import { Currency, Money } from "@monet-money/money-type";
import { Percentage } from "@monet-money/percentage-type";
import MonetInput from "components/tailwind/form/MonetInput";
import MonetMoneyInput from "components/tailwind/form/MonetMoneyInput";
import MonetSwitch from "components/tailwind/form/MonetSwitch";
import usePayoutProvider from "contexts/PayoutProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { calculateDFF } from "shared/logic/calculate";
import { removeCommas } from "shared/utils/string";
import { useGetPartnerFeesLazyQuery } from "views/campaigns/graphql/queries.generated";
import FundingRemainingAlert from "./FundingRemainingAlert";

type PayoutSettingsStepProps = {
  payoutType: "WITHDRAWAL" | "VENDOR";
  isPayoutsLoading: boolean;
};

const PayoutSettingsStep: React.FC<PayoutSettingsStepProps> = ({ payoutType, isPayoutsLoading }) => {
  const [monetDailyFee, setMonetDailyFee] = useState<Percentage>();

  const { setChargeCreator, setCustomFee, selectedInvoice, setMonetFee, monetFee, setFundingFee, setPartnerFee, getCurrency, remainingInvoiceFunds } = usePayoutProvider();
  const [getPartnerFees, { data: partnerFeesData }] = useGetPartnerFeesLazyQuery({ fetchPolicy: "network-only" });

  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { partner } = useSessionProvider();

  // Get partner fees
  useEffect(() => {
    if (selectedInvoice) {
      getPartnerFees();
    }
  }, [selectedInvoice]);

  // Set Monet fee
  useEffect(() => {
    if (partnerFeesData) {
      const fees = partnerFeesData.GetPartnerFees;
      const feePerSelectedInvoice = fees.find((item) => item.currency == selectedInvoice?.invoiceValue.currency);
      if (feePerSelectedInvoice) {
        setMonetDailyFee(calculateDFF(feePerSelectedInvoice.margin, feePerSelectedInvoice.baseRate));
      }
    }
  }, [partnerFeesData]);

  useEffect(() => {
    if (selectedInvoice && monetDailyFee) {
      const dueDate = selectedInvoice.dueDate;

      // Find the days remaining by calculating the time between the to days
      const dueDateFormatted = dayjs(dueDate, "YYYYMMDD");

      // use current time to get difference
      const daysRemaining = Math.ceil(dueDateFormatted.diff(dayjs(), "days", true));

      const calculatedMonetFee = monetDailyFee.multiply(daysRemaining);

      setMonetFee(calculatedMonetFee);
    }
  }, [selectedInvoice, monetDailyFee]);

  const chargeFeeToVendor = watch("chargeFeeToVendor");
  const addAgencyFee = watch("addAgencyFee");
  const agencyFee = watch("agencyFee");
  const payoutAmount = watch("payoutRequest");

  useEffect(() => {
    if (!chargeFeeToVendor) {
      setValue("addAgencyFee", false, { shouldTouch: true, shouldValidate: true });
      setValue("agencyFee", "", { shouldTouch: true, shouldValidate: true });
      setCustomFee(false);
      setPartnerFee(new Percentage(0));
      setChargeCreator(false);
    }
    setChargeCreator(chargeFeeToVendor);
  }, [chargeFeeToVendor]);

  useEffect(() => {
    if (!addAgencyFee) {
      setValue("agencyFee", "", { shouldTouch: true, shouldValidate: true });
    }
    if (addAgencyFee) {
      setValue("agencyFee", "", { shouldTouch: true, shouldValidate: true });
    }
    setCustomFee(addAgencyFee);
  }, [addAgencyFee]);

  useEffect(() => {
    if (agencyFee) {
      const agencyFeePercentage = new Percentage(agencyFee);
      setPartnerFee(agencyFeePercentage);
    } else {
      setPartnerFee(new Percentage(0));
    }
  }, [agencyFee]);

  useEffect(() => {
    if (payoutAmount && selectedInvoice && monetFee && !isNaN(Number(removeCommas(payoutAmount)))) {
      const formattedPayoutAmount = removeCommas(payoutAmount);
      const payoutAmountMoney = new Money(formattedPayoutAmount!, selectedInvoice?.invoiceValue.currency as Currency);
      const fundingFeeMoney = payoutAmountMoney.multiply(monetFee.percentage);
      setFundingFee(fundingFeeMoney);
    }
  }, [payoutAmount, selectedInvoice, monetFee]);

  const chargeFeeToVendorHelperText = useMemo(() => {
    if (!chargeFeeToVendor && payoutAmount && monetFee && !isNaN(Number(removeCommas(payoutAmount)))) {
      const formattedPayoutAmount = removeCommas(payoutAmount);
      const payoutAmountMoney = new Money(formattedPayoutAmount!, selectedInvoice?.invoiceValue.currency as Currency);
      const monetFeeMoney = payoutAmountMoney.multiply(monetFee.percentage);

      return `${partner?.name} will be charged ${monetFeeMoney.format(true, true)} as the fee is not being passed onto the vendor`;
    } else return undefined;
  }, [chargeFeeToVendor, payoutAmount, monetFee]);

  const agencyFeeHelperText = useMemo(() => {
    if (addAgencyFee && agencyFee && payoutAmount && selectedInvoice && !isNaN(Number(removeCommas(payoutAmount)))) {
      const formattedPayoutAmount = removeCommas(payoutAmount);
      const payoutAmountMoney = new Money(formattedPayoutAmount!, selectedInvoice?.invoiceValue.currency as Currency);
      const agencyFeePercentage = new Percentage(agencyFee);
      const agencyFeeMoney = payoutAmountMoney.multiply(agencyFeePercentage.percentage);

      return `This vendor will be charged an additional ${agencyFeeMoney.format(true, true)}`;
    } else return undefined;
  }, [addAgencyFee, agencyFee, payoutAmount, selectedInvoice]);

  return (
    <div className="flex flex-col gap-4">
      <MonetMoneyInput
        {...register("payoutRequest")}
        name="payoutRequest"
        label="Payout request"
        currency={getCurrency()}
        disabled={isPayoutsLoading || remainingInvoiceFunds.amount <= 0}
      />
      <FundingRemainingAlert remainingFunds={remainingInvoiceFunds} />
      {payoutType === "VENDOR" && (
        <>
          <MonetSwitch {...register("chargeFeeToVendor")} showInRow={true} showLabels={false} label="Charge fee to vendor" secondaryLabel={chargeFeeToVendorHelperText} />
          <div className="flex flex-col gap-2">
            <MonetSwitch
              {...register("addAgencyFee")}
              showLabels={false}
              showInRow={true}
              label="Add your agency fee"
              disabled={!chargeFeeToVendor}
              tooltip={`This is a additional fee which ${partner?.name} can charge to vendors on top of MONET’s fee`}
            />
            {addAgencyFee && (
              <MonetInput
                {...register("agencyFee")}
                id="agencyFee"
                endAdornment="%"
                helperText={agencyFeeHelperText}
                error={errors.agencyFee?.message as string}
                inputMode="numeric"
                type="number"
                step="any"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PayoutSettingsStep;
