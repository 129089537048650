import { yupResolver } from "@hookform/resolvers/yup";
import { SignInOutput, signIn } from "aws-amplify/auth";
import MonetAlert, { MonetAlertProps } from "components/tailwind/MonetAlert";
import MonetButton from "components/tailwind/MonetButton";
import MonetAuthFooter from "components/tailwind/auth/MonetAuthFooter";
import MonetAuthTitle from "components/tailwind/auth/MonetAuthTitle";
import MonetInput from "components/tailwind/form/MonetInput";
import MonetPasswordInput from "components/tailwind/form/MonetPasswordInput";
import AuthViewLayout from "components/tailwind/templates/AuthViewLayout";
import { useSessionProvider } from "contexts/SessionProvider";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_PATH } from "routing/paths";
import { isObjEmpty } from "utils/objects";
import { AUTH_PATH, FORGOT_PASSWORD_PATH, MFA_PATH, SIGN_UP_PATH } from "../AuthPath";
import { SignInFormObject, validationSchema } from "./SignInValidationSchema";

const SignIn: React.FC = () => {
  const [alert, setAlert] = useState<undefined | MonetAlertProps>();
  const { initialiseContext } = useSessionProvider();
  const navigate = useNavigate();

  const onSubmit = async (formData: SignInFormObject) => {
    try {
      setAlert(undefined);
      await signIn(formData).then(async (user: SignInOutput) => {
        if (["CONFIRM_SIGN_IN_WITH_TOTP_CODE", "CONFIRM_SIGN_IN_WITH_SMS_CODE"].includes(user.nextStep.signInStep)) {
          navigate(AUTH_PATH + MFA_PATH, { state: { username: formData.username, password: formData.password, mfaMethod: user.nextStep.signInStep } });
        } else if (user.isSignedIn) {
          await initialiseContext().then(() => {
            navigate(DASHBOARD_PATH);
          });
        }
      });
    } catch (error: any) {
      if (error.name === "NotAuthorizedException") {
        setAlert({
          title: "Sign in failed",
          message: "Your email address or password are incorrect, please try again",
          error: error,
        });
      } else if (error.name === "UserNotConfirmedException") {
        setAlert({
          title: "User not confirmed",
          message: "Your account has not been confirmed. Please confirm you email address",
          error: error,
        });
      } else if (error.name === "UserAlreadyAuthenticatedException") {
        await initialiseContext().then(() => {
          navigate(DASHBOARD_PATH);
        });
      } else {
        console.error(error);
        setAlert({
          title: "Unexpected Error",
          message: "There was an expected error while logging in. Please try again",
          error: error,
        });
      }
    }
  };

  const {
    register,
    formState: { errors, isValid, isSubmitting },
    handleSubmit,
  } = useForm<SignInFormObject>({
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
  });

  return (
    <AuthViewLayout helmet="Sign in">
      <MonetAuthTitle>Sign in</MonetAuthTitle>
      {alert && <MonetAlert variant="error" title={alert.title} message={alert.message} error={alert.error} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-5">
          <MonetInput
            {...register("username")}
            data-testid="signIn-emailFormField"
            disabled={isSubmitting}
            label="Username"
            id="username"
            error={errors.username?.message}
            placeholder="Please enter your email"
          />
          <MonetPasswordInput
            {...register("password")}
            label={"Password"}
            data-testid="signIn-passwordFormField"
            type="password"
            id="password"
            placeholder="******"
            autoComplete="current-password"
            disabled={isSubmitting}
            error={errors.password?.message}
            secondaryLabel={
              <MonetButton size="fit-content" variant="link" className="text-inherit font-normal text-sm" onClick={() => navigate(AUTH_PATH + FORGOT_PASSWORD_PATH)}>
                Forgot password?
              </MonetButton>
            }
          />
          <MonetButton loading={isSubmitting} type="submit" disabled={!isObjEmpty(errors) || !isValid || isSubmitting} className="w-full">
            Sign in
          </MonetButton>
        </div>
      </form>
      <MonetAuthFooter buttonText="Sign up" buttonProps={{ onClick: () => navigate(AUTH_PATH + SIGN_UP_PATH) }}>
        New to Monet?
      </MonetAuthFooter>
    </AuthViewLayout>
  );
};

export default SignIn;
