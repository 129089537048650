import { ApolloError } from "@apollo/client/errors";

export const getEnvFormattedError = (error: Error | ApolloError | Components.Schemas.Error) => {
  const env = process.env.REACT_APP_ENV_NAME;
  if (env === "monetprd" || env === "sandbox") {
    return null;
  } else if (error) {
    // return formatted error when running on local or dev environments
    return JSON.stringify(error, Object.getOwnPropertyNames(error), 2);
  }
  return null;
};
