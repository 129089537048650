import { Radio, RadioGroup } from "@headlessui/react";
import clsx from "clsx";
import React, { useState } from "react";
import { ControllerRenderProps, Noop, RefCallBack } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import MonetButton from "./MonetButton";

type ApproveRejectRadioGroupProps = {
  inputProps: Omit<ControllerRenderProps, "ref" | "onBlur"> & {
    ref?: RefCallBack;
    onBlur?: Noop;
  };
};

const ApproveRejectRadioGroup: React.FC<ApproveRejectRadioGroupProps> = ({ inputProps }) => {
  const [selected, setSelected] = useState(false);

  const handleChange = (value: boolean) => {
    inputProps.onChange(value);
    setSelected(value);
  };

  return (
    <RadioGroup {...inputProps} value={selected} onChange={handleChange} aria-label="Server size" className="grid grid-cols-2 gap-4 my-5">
      <Radio key="reject" value={false}>
        <MonetButton className={twMerge(clsx("w-full", selected !== false && "bg-gray-200 text-gray-600 hover:text-white"))} color="red-solid">
          Reject
        </MonetButton>
      </Radio>
      <Radio key="approve" value={true}>
        <MonetButton className={twMerge(clsx("w-full", selected !== true && "bg-gray-200 text-gray-600 hover:text-white"))} color="green-solid">
          Approve
        </MonetButton>
      </Radio>
    </RadioGroup>
  );
};

export default ApproveRejectRadioGroup;
