import * as Types from 'shared/generated/ep3.graphql.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePayoutMutationVariables = Types.Exact<{
  payout: Types.CreatePayoutInput;
}>;


export type CreatePayoutMutation = { __typename?: 'Mutation', createPayout: any };

export type UpdatePayoutStatusMutationVariables = Types.Exact<{
  payoutId: Types.Scalars['String']['input'];
  payoutStatus: Types.Scalars['String']['input'];
}>;


export type UpdatePayoutStatusMutation = { __typename?: 'Mutation', updatePayoutStatus: any };

export type CreatePayeeMutationVariables = Types.Exact<{
  payee: Types.Scalars['AWSJSON']['input'];
}>;


export type CreatePayeeMutation = { __typename?: 'Mutation', createPayee: boolean };

export type SendCreatorEmailMutationVariables = Types.Exact<{
  payoutId: Types.Scalars['String']['input'];
}>;


export type SendCreatorEmailMutation = { __typename?: 'Mutation', sendCreatorEmail: boolean };

export type DeletePayoutMutationVariables = Types.Exact<{
  payoutId: Types.Scalars['String']['input'];
  partnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type DeletePayoutMutation = { __typename?: 'Mutation', deletePayout: boolean };


export const CreatePayoutDocument = gql`
    mutation createPayout($payout: CreatePayoutInput!) {
  createPayout(payout: $payout)
}
    `;
export type CreatePayoutMutationFn = Apollo.MutationFunction<CreatePayoutMutation, CreatePayoutMutationVariables>;

/**
 * __useCreatePayoutMutation__
 *
 * To run a mutation, you first call `useCreatePayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayoutMutation, { data, loading, error }] = useCreatePayoutMutation({
 *   variables: {
 *      payout: // value for 'payout'
 *   },
 * });
 */
export function useCreatePayoutMutation(baseOptions?: Apollo.MutationHookOptions<CreatePayoutMutation, CreatePayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePayoutMutation, CreatePayoutMutationVariables>(CreatePayoutDocument, options);
      }
export type CreatePayoutMutationHookResult = ReturnType<typeof useCreatePayoutMutation>;
export type CreatePayoutMutationResult = Apollo.MutationResult<CreatePayoutMutation>;
export type CreatePayoutMutationOptions = Apollo.BaseMutationOptions<CreatePayoutMutation, CreatePayoutMutationVariables>;
export const UpdatePayoutStatusDocument = gql`
    mutation updatePayoutStatus($payoutId: String!, $payoutStatus: String!) {
  updatePayoutStatus(payoutId: $payoutId, payoutStatus: $payoutStatus)
}
    `;
export type UpdatePayoutStatusMutationFn = Apollo.MutationFunction<UpdatePayoutStatusMutation, UpdatePayoutStatusMutationVariables>;

/**
 * __useUpdatePayoutStatusMutation__
 *
 * To run a mutation, you first call `useUpdatePayoutStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayoutStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayoutStatusMutation, { data, loading, error }] = useUpdatePayoutStatusMutation({
 *   variables: {
 *      payoutId: // value for 'payoutId'
 *      payoutStatus: // value for 'payoutStatus'
 *   },
 * });
 */
export function useUpdatePayoutStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePayoutStatusMutation, UpdatePayoutStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePayoutStatusMutation, UpdatePayoutStatusMutationVariables>(UpdatePayoutStatusDocument, options);
      }
export type UpdatePayoutStatusMutationHookResult = ReturnType<typeof useUpdatePayoutStatusMutation>;
export type UpdatePayoutStatusMutationResult = Apollo.MutationResult<UpdatePayoutStatusMutation>;
export type UpdatePayoutStatusMutationOptions = Apollo.BaseMutationOptions<UpdatePayoutStatusMutation, UpdatePayoutStatusMutationVariables>;
export const CreatePayeeDocument = gql`
    mutation createPayee($payee: AWSJSON!) {
  createPayee(payee: $payee)
}
    `;
export type CreatePayeeMutationFn = Apollo.MutationFunction<CreatePayeeMutation, CreatePayeeMutationVariables>;

/**
 * __useCreatePayeeMutation__
 *
 * To run a mutation, you first call `useCreatePayeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayeeMutation, { data, loading, error }] = useCreatePayeeMutation({
 *   variables: {
 *      payee: // value for 'payee'
 *   },
 * });
 */
export function useCreatePayeeMutation(baseOptions?: Apollo.MutationHookOptions<CreatePayeeMutation, CreatePayeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePayeeMutation, CreatePayeeMutationVariables>(CreatePayeeDocument, options);
      }
export type CreatePayeeMutationHookResult = ReturnType<typeof useCreatePayeeMutation>;
export type CreatePayeeMutationResult = Apollo.MutationResult<CreatePayeeMutation>;
export type CreatePayeeMutationOptions = Apollo.BaseMutationOptions<CreatePayeeMutation, CreatePayeeMutationVariables>;
export const SendCreatorEmailDocument = gql`
    mutation sendCreatorEmail($payoutId: String!) {
  sendCreatorEmail(payoutId: $payoutId)
}
    `;
export type SendCreatorEmailMutationFn = Apollo.MutationFunction<SendCreatorEmailMutation, SendCreatorEmailMutationVariables>;

/**
 * __useSendCreatorEmailMutation__
 *
 * To run a mutation, you first call `useSendCreatorEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCreatorEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCreatorEmailMutation, { data, loading, error }] = useSendCreatorEmailMutation({
 *   variables: {
 *      payoutId: // value for 'payoutId'
 *   },
 * });
 */
export function useSendCreatorEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendCreatorEmailMutation, SendCreatorEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCreatorEmailMutation, SendCreatorEmailMutationVariables>(SendCreatorEmailDocument, options);
      }
export type SendCreatorEmailMutationHookResult = ReturnType<typeof useSendCreatorEmailMutation>;
export type SendCreatorEmailMutationResult = Apollo.MutationResult<SendCreatorEmailMutation>;
export type SendCreatorEmailMutationOptions = Apollo.BaseMutationOptions<SendCreatorEmailMutation, SendCreatorEmailMutationVariables>;
export const DeletePayoutDocument = gql`
    mutation deletePayout($payoutId: String!, $partnerId: String) {
  deletePayout(payoutId: $payoutId, partnerId: $partnerId)
}
    `;
export type DeletePayoutMutationFn = Apollo.MutationFunction<DeletePayoutMutation, DeletePayoutMutationVariables>;

/**
 * __useDeletePayoutMutation__
 *
 * To run a mutation, you first call `useDeletePayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePayoutMutation, { data, loading, error }] = useDeletePayoutMutation({
 *   variables: {
 *      payoutId: // value for 'payoutId'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useDeletePayoutMutation(baseOptions?: Apollo.MutationHookOptions<DeletePayoutMutation, DeletePayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePayoutMutation, DeletePayoutMutationVariables>(DeletePayoutDocument, options);
      }
export type DeletePayoutMutationHookResult = ReturnType<typeof useDeletePayoutMutation>;
export type DeletePayoutMutationResult = Apollo.MutationResult<DeletePayoutMutation>;
export type DeletePayoutMutationOptions = Apollo.BaseMutationOptions<DeletePayoutMutation, DeletePayoutMutationVariables>;