import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { HSTooltip } from "preline/preline";
import React, { useEffect } from "react";
import { CurrencyWithRates } from "utils/currency";
import { formatDate, getHumanizeDate } from "utils/date";

type RateLastUpdatedTimeProp = {
  currency: CurrencyWithRates;
};

const RateLastUpdatedTime: React.FC<RateLastUpdatedTimeProp> = ({ currency }) => {
  useEffect(() => {
    HSTooltip.autoInit();
  }, []);

  if (!currency.updatedAt) return null;

  return (
    <>
      <div className="hs-tooltip inline-block text-xs [--trigger:hover] [--placement:bottom-right]">
        <div className="hs-tooltip-toggle">
          <p>
            Last Updated: <span className="font-semibold">{getHumanizeDate(currency.updatedAt)}</span>
            <sup>
              <InfoOutlinedIcon sx={{ width: 12, height: 12 }} />
            </sup>
          </p>
          <div
            className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 p-3 bg-white text-sm text-gray-600 rounded-lg shadow-lg dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400"
            role="tooltip"
          >
            <div className="p-3 border border-gray-200 dark:border-neutral-700 flex flex-col gap-2 rounded-lg text-sm">
              <p>
                <span>Updated: </span>
                <span className="font-semibold">{formatDate(currency.updatedAt!, "DD/MM/YYYY (HH:mm:ss)")}</span>
              </p>
              <p>
                <span>Updated by: </span>
                <span className="font-semibold">{currency.updatedBy}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RateLastUpdatedTime;
