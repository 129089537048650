import CheckIcon from "@mui/icons-material/Check";
import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { PropsWithChildren } from "react";

const StepperActiveContainer = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
  marginRight: theme.spacing(2),
  width: 30,
  height: 30,
  border: `1px solid ${theme.palette.primary.main}`,
}));
const StepperDetailsContainer = styled(Box, {
  shouldForwardProp: (name) => !["checked", "isCurrentView"].includes(name.toString()),
})<{ withMargin?: boolean; checked?: boolean; isCurrentView?: boolean }>(({ theme, checked, isCurrentView }) => ({
  width: "100%",
  maxWidth: 250,
  padding: theme.spacing(1),
  border: checked ? `1px solid ${theme.palette.primary.main}` : "none",
  background: checked ? "transparent" : `${theme.palette.primary.main}30`,
  opacity: checked || isCurrentView ? 1 : 0.3,
  overflowWrap: "anywhere",
}));

const Stepper: React.FC<PropsWithChildren & { checked?: boolean; showChildren?: boolean; title: string; isCurrentView?: boolean }> = ({
  checked,
  title,
  showChildren,
  children,
  isCurrentView,
}) => {
  return (
    <Stack direction="row" mb={2} width="100%">
      <StepperActiveContainer>{checked ? <CheckIcon color="primary" /> : ""}</StepperActiveContainer>
      <StepperDetailsContainer checked={checked} isCurrentView={isCurrentView}>
        <Typography color="primary">
          <strong>{title}</strong>
        </Typography>
        {checked || showChildren ? (
          <Typography component={Box} color="primary">
            {children}
          </Typography>
        ) : null}
      </StepperDetailsContainer>
    </Stack>
  );
};

export default Stepper;
