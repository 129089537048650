import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import ViewLayout from "components/tailwind/templates/ViewLayout";
import React from "react";
import { Route, Routes } from "react-router-dom";
import UserProfile from "./Profile/UserProfile";
import { USER_PROFILE_PATH } from "./UserPath";

const UserRoutes: React.FC = () => {
  return (
    <ErrorBoundary>
      <ViewLayout helmet="My Profile">
        <Routes>
          <Route path={USER_PROFILE_PATH} element={<UserProfile />} />
        </Routes>
      </ViewLayout>
    </ErrorBoundary>
  );
};

export default UserRoutes;
