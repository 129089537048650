import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useForm } from "react-final-form";
import PageTitle from "./PageTitle";
import { CurrentViewType } from "./utils";

const LandingPageSection: React.FC<{ setCurrentView: React.Dispatch<React.SetStateAction<CurrentViewType>> }> = ({ setCurrentView }) => {
  const { change } = useForm();

  const handleShowLandingPage = React.useCallback(
    (currentView: CurrentViewType, showLanding: boolean) => {
      change("showLandingPage", showLanding);
      setCurrentView(currentView);
    },
    [setCurrentView],
  );

  return (
    <Stack mb={3} mt={5} direction={{ md: "row", xs: "column" }} spacing={3} justifyContent="space-between">
      <Box maxWidth={400}>
        <PageTitle>Show landing page to creator?</PageTitle>
        <Box mb={7}>
          <Typography>
            We can show the creator a landing page with the MONET logo as well as the partner logo when they receive an EarlyPay payout. Select Yes or No here accordingly
          </Typography>
        </Box>
      </Box>
      <Box width="100%" maxWidth={{ md: 300 }}>
        <Box mb={5}>
          <Button fullWidth variant="contained" data-testid="partnerJourney-showLandingYesBtn" onClick={() => handleShowLandingPage("Address", true)}>
            Yes&nbsp;(Show landing page)
          </Button>
        </Box>

        <Box mb={5}>
          <Button fullWidth variant="outlined" data-testid="partnerJourney-showLandingNoBtn" onClick={() => handleShowLandingPage("Address", false)}>
            No&nbsp;(Do not show)
          </Button>
        </Box>
        <Button fullWidth variant="text" data-testid="landingPageSection-backBtn" onClick={() => handleShowLandingPage("PartnerUrl", false)}>
          Back
        </Button>
      </Box>
    </Stack>
  );
};

export default LandingPageSection;
