import MonetViewTitle from "components/tailwind/MonetViewTitle";
import TailwindTabGroup from "components/tailwind/headlessTailwind/TailwindTabGroup";
import { useSessionProvider } from "contexts/SessionProvider";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Campaign, FullPayout } from "shared/types/eep.contract.types";
import { isInAdminContext } from "utils/User";
import { useGetCampaignQuery, useGetPayoutsByCampaignQuery } from "../graphql/queries.generated";
import DetailsTab from "./components/sections/detailsTab/DetailsTab";
import FundingTab from "./components/sections/fundingTab/FundingTab";

const CampaignDetails = () => {
  const [campaign, setCampaign] = useState<Campaign>();
  const [payouts, setPayouts] = useState<FullPayout[]>([]);

  const { user } = useSessionProvider();
  const { campaignId: campaignIdFromPath, partnerId } = useParams();

  const {
    data: payoutsData,
    loading: isPayoutsLoading,
    refetch: refetchPayouts,
  } = useGetPayoutsByCampaignQuery({ fetchPolicy: "network-only", variables: { campaignId: campaignIdFromPath as string } });

  const {
    data: campaignData,
    loading: isCampaignLoading,
    refetch: refetchCampaign,
  } = useGetCampaignQuery({
    fetchPolicy: "network-only",
    variables: {
      campaignId: campaignIdFromPath as string,
      partnerId: partnerId as string,
    },
  });

  useEffect(() => {
    if (campaignData) {
      const campaigns = JSON.parse(campaignData.getCampaign) as Campaign[];
      if (campaigns.length > 0) {
        setCampaign(campaigns[0]);
      } else {
        setCampaign(undefined);
      }
    }
  }, [campaignData]);

  useEffect(() => {
    if (payoutsData) {
      const payouts = JSON.parse(payoutsData.getPayoutsByCampaign) as FullPayout[];
      if (payouts.length > 0) {
        setPayouts(payouts);
      }
    }
  }, [payoutsData]);

  const viewTitle = useMemo(
    () =>
      isInAdminContext(user) ? (
        <>
          <span className="text-base">{partnerId}</span>&nbsp;/&nbsp;{campaign?.campaignName}
        </>
      ) : (
        campaign?.campaignName
      ),

    [campaign, partnerId],
  );

  return (
    <>
      <MonetViewTitle>{viewTitle}</MonetViewTitle>
      <TailwindTabGroup
        tabs={[
          {
            label: "Details",
            content: <DetailsTab isLoading={isCampaignLoading || isPayoutsLoading} campaign={campaign} refetchCampaign={refetchCampaign} />,
            isVisible: true,
          },
          {
            label: "Funding",
            content: (
              <FundingTab
                isLoading={isCampaignLoading || isPayoutsLoading}
                payouts={payouts}
                campaign={campaign}
                refetchCampaign={refetchCampaign}
                refetchPayouts={refetchPayouts}
              />
            ),
            isVisible: true,
          },
        ]}
      />
    </>
  );
};

export default CampaignDetails;
