import { Currency, Money } from "@monet-money/money-type";
import { Box, Stack } from "@mui/material";
import AlertView from "components/AlertView/AlertView";
import ErrorView from "components/ErrorView/ErrorView";
import ExitWizard from "components/Forms/ExitWizard";
import CenteredMainContainer from "components/utils/CenteredMainContainer";
import { makeValidate } from "mui-rff";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { InvoiceAssignmentTerms, PartnerInput, TermsAndConditionsWithLinkType } from "shared/types/eep.contract.types";
import { Partner } from "utils/Partner";
import { SelectedCurrency, formatAllowedCurrenciesBeforeSaving } from "utils/currency";
import { newPartnerSchema } from "../PartnerValidation/PartnerValidationSchema";
import { PARTNERS_LIST_ALL_PATH, PARTNERS_PATH } from "../PartnersPath";
import { useCreatePartnerMutation } from "../graphql/mutations.generated";
import { useGetPartnersQuery } from "../graphql/queries.generated";
import AddressSection from "./AddressSection";
import ConfirmationSection from "./ConfirmationSection";
import FeeSettings from "./FeeSettings";
import FeeSubscriptions from "./FeeSubscriptions";
import KybStatusSection from "./KybStatusSection";
import LandingPageSection from "./LandingPageSection";
import NavigationButtons from "./NavigationButtons/NavigationButtons";
import PartnerIdSection from "./PartnerIdSection";
import PartnerLocationSection from "./PartnerLocationSection";
import PartnerLogoSection from "./PartnerLogoSection";
import PartnerNameSection from "./PartnerNameSection";
import PartnerUrlSection from "./PartnerUrlSection";
import StepperView from "./StepperView/StepperView";
import SuccessSection from "./SuccessSection";
import TermsAndConditions from "./TermsAndConditions";
import UpperCreditLimit from "./UpperCreditLimit";
import { CurrentViewType } from "./utils";

const defaultTermsAndCondition: TermsAndConditionsWithLinkType = {
  link: "",
  linkType: "Generic",
  accepted: false,
  timestamp: "",
};
const defaultFileInput = {
  file: "",
  type: "",
  name: "",
};
const defaultInvoiceAssignmentTerms: Partial<InvoiceAssignmentTerms> = {
  linkType: "Generic",
  earlyPayAgreement: "",
  standardTerms: "",
  onboardingAgreement: "",
};

const AddPartner = () => {
  const [currentView, setCurrentView] = React.useState<CurrentViewType>("PartnerName");
  const [error, setError] = React.useState<Error | undefined>();
  const [selectedCurrencies, setSelectedCurrencies] = React.useState<SelectedCurrency[]>([]);
  const [createPartner, { loading }] = useCreatePartnerMutation();
  const [partners, setPartners] = useState<Partner[]>();

  const { data: partnersData, error: partnersError, loading: isPartnersLoading, refetch: refetchPartners } = useGetPartnersQuery({ fetchPolicy: "network-only" });

  useEffect(() => {
    if (partnersData) {
      setPartners(JSON.parse(partnersData.getPartners) as Partner[]);
    }
  }, [partnersData]);

  const initialValues = React.useMemo(
    () => ({
      name: "",
      logo: defaultFileInput,
      partnerId: "",
      country: "GB",
      address: { addressLine1: "", addressLine2: "", city: "", postcode: "" },
      feeRules: {
        subscriptions: [
          {
            costPerMonth: {
              amount: "",
              currency: "GBP",
            },
            creditLimit: {
              amount: "",
              currency: "GBP",
            },
          },
        ],
      },
      defaultConfiguration: {
        termsAndConditions: defaultTermsAndCondition,
        invoiceAssignmentTerms: defaultInvoiceAssignmentTerms,
        allowedCurrencies: [],
        feeSettings: {
          max: "",
          default: "",
          monet: "",
        },
      },
      limits: {
        global: {
          amount: "",
          currency: "",
        },
      },
      kybStatus: "NOT_VERIFIED",
    }),
    [],
  );

  const convertToStorageType = (data: Components.Schemas.Money) => {
    return Money.toStorageType(new Money(data.amount, data.currency as Currency));
  };

  const onSubmit = async (formData: PartnerInput) => {
    setError(undefined);

    const limits = formData.limits;

    const allowedCurrencies = formatAllowedCurrenciesBeforeSaving(formData.defaultConfiguration.allowedCurrencies);

    const formatData = {
      ...formData,
      feeRules: {
        currency: limits.global.currency,
        subscriptions: formData.feeRules.subscriptions.map((item) => ({
          costPerMonth: convertToStorageType(item.costPerMonth),
          creditLimit: convertToStorageType(item.creditLimit),
        })),
      },
      defaultConfiguration: {
        ...formData.defaultConfiguration,
        allowedCurrencies,
      },
      limits: {
        global: convertToStorageType(limits.global),
      },
    };

    try {
      await createPartner({
        variables: {
          partner: JSON.stringify(formatData),
        },
      });
      refetchPartners();
      setCurrentView("Success");
    } catch (err: Error | any) {
      setError(err?.message);
    }
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (error) {
      setError(undefined);
    }
  }, [currentView]);

  const validate = makeValidate(newPartnerSchema);

  const getView = React.useCallback(() => {
    switch (currentView) {
      case "PartnerId":
        return <PartnerIdSection existingPartners={partners} />;

      case "PartnerLocation":
        return <PartnerLocationSection />;

      case "PartnerLogo":
        return <PartnerLogoSection />;

      case "PartnerUrl":
        return <PartnerUrlSection />;

      case "LandingPage":
        return <LandingPageSection setCurrentView={setCurrentView} />;

      case "Address":
        return <AddressSection />;

      case "UpperCreditLimit":
        return <UpperCreditLimit setSelectedCurrencies={setSelectedCurrencies} />;

      case "FeeSubscriptions":
        return <FeeSubscriptions />;

      case "FeeSettings":
        return <FeeSettings />;

      case "TermsAndConditions":
        return <TermsAndConditions />;

      case "KybStatus":
        return <KybStatusSection setCurrentView={setCurrentView} />;

      case "Confirmation":
        return <ConfirmationSection isProcessing={loading} setCurrentView={setCurrentView} selectedCurrencies={selectedCurrencies} />;

      case "Success":
        return <SuccessSection />;

      default:
        return <PartnerNameSection existingPartners={partners} loading={isPartnersLoading} />;
    }
  }, [currentView, partners, loading]);

  if (partnersError) {
    return <ErrorView title="Error loading existing partners." />;
  }

  return (
    <CenteredMainContainer>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => {
          return (
            <Stack direction="row">
              <Box width="100%" maxWidth={400} display={{ md: "block", xs: "none" }}>
                <StepperView currentView={currentView} />
              </Box>
              <Box width="100%">
                <Box mb={3}>{getView()}</Box>

                {error ? (
                  <Box mb={3}>
                    <AlertView title="Error creating partner" severity="error" error={error} />
                  </Box>
                ) : null}
                <Box mb={3} display="flex" flexDirection="column">
                  <NavigationButtons loading={loading || isPartnersLoading} handleSubmit={handleSubmit} currentView={currentView} setCurrentView={setCurrentView} />
                </Box>
                {/* Show exit wizard for only none success page */}
                {currentView !== "Success" && <ExitWizard isProcessing={loading} path={PARTNERS_PATH + PARTNERS_LIST_ALL_PATH} />}
              </Box>
            </Stack>
          );
        }}
      />
    </CenteredMainContainer>
  );
};

export default AddPartner;
