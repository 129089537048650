import { yupResolver } from "@hookform/resolvers/yup";
import { Money, MoneyStorage } from "@monet-money/money-type";
import { Percentage } from "@monet-money/percentage-type";
import ApproveRejectRadioGroup from "components/tailwind/ApproveRejectRadioGroup";
import MonetBorderedCardContent from "components/tailwind/MonetBorderedCardContent";
import MonetDivider from "components/tailwind/MonetDivider";
import MonetDrawerButtons from "components/tailwind/MonetDrawerButtons";
import MonetLabelledItem from "components/tailwind/MonetLabelledItem";
import SignedUrl from "components/tailwind/SignedUrl";
import MonetInput from "components/tailwind/form/MonetInput";
import MonetTextArea from "components/tailwind/form/MonetTextArea";
import TailwindDrawer from "components/tailwind/headlessTailwind/TailwindDrawer";
import { useModal } from "contexts/ModalProvider";
import dayjs from "dayjs";
import { useMemo } from "react";
import { Controller, Resolver, useForm } from "react-hook-form";
import { Campaign } from "shared/types/eep.contract.types";
import { UnderwriteInvoiceFormObject, underwriteInvoiceSchema } from "shared/utils/validators";
import { toast } from "sonner";
import { Invoice } from "utils/Invoice";
import { DEFAULT_DATE_FORMAT } from "utils/date";
import { useUnderwriteInvoiceMutation } from "views/campaigns/graphql/mutations.generated";

type UnderwriteInvoiceDrawerProps = {
  invoice: Invoice;
  invoices: Invoice[];
  refetchCampaign(): void;
  refetchInvoices(): void;
  campaign?: Campaign;
};

const UnderwriteInvoiceDrawer: React.FC<UnderwriteInvoiceDrawerProps> = ({ invoice, campaign, invoices, refetchInvoices, refetchCampaign }) => {
  const [underwriteInvoice] = useUnderwriteInvoiceMutation();

  const { closeModal } = useModal();

  const onSubmit = async (formData: UnderwriteInvoiceFormObject) => {
    await underwriteInvoice({
      variables: {
        campaignId: invoice.campaignId,
        invoice: {
          ...formData,
          invoiceId: invoice.invoiceId,
        },
      },
    })
      .then(() => {
        toast.success("Success", { description: "The invoice has been processed and the agency will be notified" });
        refetchInvoices();
        refetchCampaign();
        closeModal();
      })
      .catch((err) => {
        toast.error("Request failed", { description: err.message });
      });
  };

  const {
    formState: { errors, isValid, isSubmitting, isDirty },
    handleSubmit,
    register,
    watch,
    control,
  } = useForm<UnderwriteInvoiceFormObject>({
    mode: "onTouched",
    resolver: yupResolver(underwriteInvoiceSchema) as Resolver<UnderwriteInvoiceFormObject>,
    defaultValues: {
      advanceRate: undefined,
      approve: false,
      notes: undefined,
      underwritingNotes: undefined,
      wiserfundingRiskScore: undefined,
    },
  });

  const principalPercentage = watch("advanceRate");
  const approveRequest = watch("approve");

  const principalMoneyValue = useMemo(() => {
    if (!principalPercentage) return new Money(0, invoice.invoiceValue.currency);
    const percentage = new Percentage(principalPercentage);
    return Money.fromStorageType(invoice.invoiceValue).multiply(percentage.percentage);
  }, [principalPercentage]);

  const getCampaignDocument = useMemo(
    () =>
      (types: Components.Schemas.CampaignDocumentType[], filterOutTypes?: boolean): Campaign["documents"] | undefined => {
        if (campaign) {
          if (!filterOutTypes) {
            return campaign.documents.filter((item) => types.includes(item.fileType));
          } else {
            return campaign.documents.filter((item) => types.includes(item.fileType) === false);
          }
        } else return undefined;
      },
    [campaign],
  );

  const campaignInvoicesValue = useMemo(() => {
    if (campaign) {
      return invoices.reduce(
        (total, item) => {
          if (item.funding && ["ACTIVE", "SETTLED"].includes(item.funding.fundingStatus)) {
            return total.add(Money.fromStorageType(item.invoiceValue));
          } else return total;
        },
        new Money(0, invoice.invoiceValue.currency),
      );
    } else return undefined;
  }, [campaign]);

  return (
    <TailwindDrawer title="Underwrite invoice" subtitle="Review the invoice details below and either approve or reject the financing request for this invoice.">
      <div className="flex-1">
        <MonetBorderedCardContent>
          <MonetLabelledItem label="Invoice due date" value={dayjs(invoice.dueDate).format(DEFAULT_DATE_FORMAT)} />
          <MonetLabelledItem label="Days until due" value={dayjs(invoice.dueDate).diff(dayjs(), "days")} />
          <MonetDivider />
          <MonetLabelledItem label="Invoice value" value={Money.fromStorageType(invoice.invoiceValue).format(true, true)} />
          <MonetLabelledItem label="Campaign value" value={Money.fromStorageType(campaign?.campaignValue as MoneyStorage).format(true, true)} />
          <MonetLabelledItem
            label="Current value of all invoices"
            value={campaignInvoicesValue?.format(true, true)}
            tooltip="The total value of all actively funded invoices for this campaign"
          />
          <MonetDivider />
          <MonetLabelledItem label="Client" value={campaign?.client.legalName} tooltip="The campaign client" />
          <MonetLabelledItem label="Brand" value={campaign?.brandPromoted} tooltip="The campaign brand" />
          <MonetDivider />
          <MonetLabelledItem label="Invoice" value={<SignedUrl url={invoice.invoiceDocument} />} />
          <MonetLabelledItem label="Contract" value={getCampaignDocument(["contract"])?.map((item) => <SignedUrl url={item.file} />)} />
          <div className="flex flex-col sm:flex-row">
            <span className="font-semibold flex-1">Client sign off</span>
            <div className="flex-1">{getCampaignDocument(["sign-off"])?.map((item) => <SignedUrl url={item.file} className="sm:justify-end" />)}</div>
          </div>
          <div className="flex flex-col sm:flex-row">
            <span className="font-semibold flex-1">Other</span>
            <div className="flex-1">{getCampaignDocument(["sign-off", "contract"], true)?.map((item) => <SignedUrl url={item.file} className="sm:justify-end h-fit" />)}</div>
          </div>
        </MonetBorderedCardContent>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller control={control} name="approve" render={({ field: { ...inputProps } }) => <ApproveRejectRadioGroup inputProps={inputProps} />} />
        <div className="flex flex-col gap-2">
          {approveRequest && (
            <div>
              <p className="block font-semibold text-gray-800 text-sm mb-2">Advance rate</p>
              <div className="flex flex-col sm:flex-row gap-2">
                <MonetInput {...register("advanceRate")} disabled={isSubmitting} endAdornment="%" inputMode="numeric" type="number" id="advanceRate" />
                <MonetBorderedCardContent className="flex flex-row min-w-[70%] p-1 sm:p-0">
                  <div className="grid grid-cols-1 justify-items-center flex-1 content-center">
                    <span className="text-xs font-light h-">Principal value</span>
                    <span className="text-sm font-semibold">{principalMoneyValue.format(true, true)}</span>
                  </div>
                  <div className="grid grid-cols-1 justify-items-center flex-1 content-center">
                    <span className="text-xs font-light">Invoice value</span>
                    <span className="text-sm font-semibold">{Money.fromStorageType(invoice.invoiceValue).format(true)}</span>
                  </div>
                </MonetBorderedCardContent>
              </div>
              <p className="text-sm text-red-500 mt-2">{errors.advanceRate?.message as string}</p>
            </div>
          )}
          <MonetTextArea
            {...register("notes")}
            label="Notes for agency"
            disabled={isSubmitting}
            error={errors.notes?.message as string}
            id="notes"
            isOptional={approveRequest}
            tooltip="These are notes are for the agency. When rejecting a funding request the notes left here will be included in the rejection email sent to the agency"
          />
          <MonetTextArea
            {...register("underwritingNotes")}
            label="Underwriting commentary"
            disabled={isSubmitting}
            error={errors.underwritingNotes?.message as string}
            id="underwritingNotes"
            tooltip="These are private notes for use within MONET only. Any notes left here will not be visible to the agency"
          />
          <MonetInput
            {...register("wiserfundingRiskScore")}
            label="WiserFunding risk score"
            disabled={isSubmitting}
            error={errors.wiserfundingRiskScore?.message as string}
            isOptional={true}
          />
          <MonetInput
            {...register("experianRiskScore")}
            label="Experian risk score"
            disabled={isSubmitting}
            error={errors.experianRiskScore?.message as string}
            isOptional={true}
          />
        </div>
        <MonetDrawerButtons cancelDisabled={isSubmitting} loading={isSubmitting} submitDisabled={isSubmitting || !isValid || !isDirty} />
      </form>
    </TailwindDrawer>
  );
};

export default UnderwriteInvoiceDrawer;
