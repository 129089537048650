import { Currency, Money } from "@monet-money/money-type";
import { Box, Stack, Typography, styled } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { CountryNameAndFlag } from "components/CountryFlag/CountryFlag";
import FlexRow from "components/FlexRow/FlexRow";
import ItemBox from "components/ItemBox/ItemBox";
import StackWithBorder from "components/utils/StackWithBorder";
import React, { useMemo } from "react";
import { useFormState } from "react-final-form";
import { PartnerInput, Subscription } from "shared/types/eep.contract.types";
import { FeeLimits } from "utils/Partner";
import { SelectedCurrency } from "utils/currency";
import { getFileName } from "utils/file";
import { enumToNiceString } from "utils/strings";
import AllowedCurrenciesList from "../components/AllowedCurrenciesList";
import { CurrentViewType } from "./utils";

const StyledDot = styled(Box)(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  background: theme.palette.primary.main,
}));

const DocumentNameText = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

const SubscriptionTable = styled(Table)(() => ({
  "& td, & th": { border: 0 },
}));

type ConfirmationSectionProps = {
  isProcessing?: boolean;
  setCurrentView: React.Dispatch<React.SetStateAction<CurrentViewType>>;
  selectedCurrencies: SelectedCurrency[];
};
const ConfirmationSection: React.FC<ConfirmationSectionProps> = ({ setCurrentView, selectedCurrencies, isProcessing }) => {
  const { values }: { values: PartnerInput } = useFormState();

  const selectedCurrency = values.limits?.global.currency as Currency;
  const feeSettings = values.defaultConfiguration.feeSettings;

  // Order subscriptions highest -> lowest
  const subscriptions = values?.feeRules?.subscriptions.sort((a: Subscription, b: Subscription) => a.costPerMonth.amount - b.costPerMonth.amount) as FeeLimits[];

  const payoutTtlDelta = useMemo(() => {
    return values.defaultConfiguration.payoutTtlDelta;
  }, [values.defaultConfiguration.payoutTtlDelta]);

  const getDaysFromHours = useMemo(() => {
    const days = Math.round((payoutTtlDelta / 24) * 100) / 100;

    if (days < 1) {
      return null;
    }

    return <> ({days} days)</>;
  }, [payoutTtlDelta]);

  return (
    <Box>
      <Typography variant="h2">Summary</Typography>
      <Box mb={4}>
        <Typography>Please review all the data below and proceed to create the partner</Typography>
      </Box>
      <Stack spacing={3}>
        <StackWithBorder editButtonAction={() => setCurrentView("PartnerName")} isEditable isProcessing={isProcessing} title="Details">
          <FlexRow>
            <ItemBox title="Partner name" value={values.name} />
            <ItemBox title="Location of partner" value={<CountryNameAndFlag countryCode={values.country} />} />
            {values?.logo?.file && (
              <ItemBox
                title="Logo of partner"
                value={
                  <Box mt={1}>
                    <img src={values.logo.file} style={{ maxWidth: 50, maxHeight: 50 }} />
                  </Box>
                }
              />
            )}
          </FlexRow>
          <FlexRow>
            <ItemBox title="Partner ID" value={values.partnerId} />
            {values.returnUrl && <ItemBox title="Website URL" value={values.returnUrl} />}
            <ItemBox title="Landing page" value={values?.showLandingPage ? "Show Landing Page" : "Don't Show Landing Page"} />
          </FlexRow>
        </StackWithBorder>
        <StackWithBorder isEditable isProcessing={isProcessing} title="Address" editButtonAction={() => setCurrentView("Address")}>
          <ItemBox
            title="Address of partner"
            value={`${values.address?.addressLine1}, ${values.address?.addressLine2 && `${values.address?.addressLine2},`} ${values.address?.postcode}, ${values.address?.city}`}
          />
        </StackWithBorder>
        <StackWithBorder isEditable isProcessing={isProcessing} title="Currencies" editButtonAction={() => setCurrentView("UpperCreditLimit")}>
          <ItemBox title="Allowed currencies" value={<AllowedCurrenciesList selectedCurrencies={selectedCurrencies} />} />
        </StackWithBorder>
        <StackWithBorder isEditable isProcessing={isProcessing} title="Fee Rules" editButtonAction={() => setCurrentView("UpperCreditLimit")}>
          <FlexRow>
            <ItemBox title="Credit limit" value={new Money(values.limits?.global.amount || 0, selectedCurrency).format()} />
            <ItemBox title="Maximum % fee" value={`${feeSettings.max} %`} />
            <ItemBox title="Default % fee" value={`${feeSettings.default} %`} />
          </FlexRow>
          <FlexRow>
            <ItemBox title="EarlyPay payout duration" value={`${payoutTtlDelta} hour${payoutTtlDelta > 1 && "s"} ${getDaysFromHours}`} />
            <ItemBox
              title="Creator terms & conditions"
              value={values.defaultConfiguration.termsAndConditions.linkType === "Generic" ? "Generic T&C’s" : values.defaultConfiguration.termsAndConditions.link}
            />
          </FlexRow>
          <FlexRow>
            <Box width="100%">
              <Typography>Invoice assignment agreement</Typography>
              {values.defaultConfiguration.invoiceAssignmentTerms.linkType === "Generic" ? (
                <Typography variant="body1Large">Generic T&C’s</Typography>
              ) : (
                <>
                  <DocumentNameText>{getFileName(values.defaultConfiguration.invoiceAssignmentTerms.onboardingAgreement)} - (ONBOARDING AGREEMENT)</DocumentNameText>
                  <DocumentNameText>{getFileName(values.defaultConfiguration.invoiceAssignmentTerms.earlyPayAgreement)} - (EARLYPAY AGREEMENT)</DocumentNameText>
                  <DocumentNameText>{getFileName(values.defaultConfiguration.invoiceAssignmentTerms.standardTerms)} - (STANDARD TERMS)</DocumentNameText>
                </>
              )}
            </Box>
          </FlexRow>
          <Box maxWidth={500} overflow="auto">
            <SubscriptionTable size="small">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography>MONET Subscription</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>Credit Limit</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptions.map((item, index) => (
                  <TableRow key={"subscriptions-" + index}>
                    <TableCell>
                      <Typography variant="body1Large">{new Money(item.costPerMonth.amount, selectedCurrency).format()}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <StyledDot
                        sx={{
                          opacity: 0.3,
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Typography sx={{ opacity: 0.5, whiteSpace: "nowrap" }}>up to</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <StyledDot
                        sx={{
                          opacity: 0.9,
                        }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body1Large">{new Money(item.creditLimit.amount, selectedCurrency).format()}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </SubscriptionTable>
          </Box>
        </StackWithBorder>
        <StackWithBorder isEditable isProcessing={isProcessing} title="KYB status" editButtonAction={() => setCurrentView("KybStatus")}>
          <ItemBox title="Status of company" value={`Setting to ${enumToNiceString(values.kybStatus).toUpperCase()}`} />
        </StackWithBorder>
      </Stack>
    </Box>
  );
};

export default ConfirmationSection;
