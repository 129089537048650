import { Stack, StackProps, styled } from "@mui/material";
import { PropsWithChildren } from "react";

const CustomStack = styled(Stack, {
  shouldForwardProp: (propertyName) => !["removeMB"].includes(propertyName.toString()),
})<{
  removeMB?: boolean;
}>(({ theme, removeMB }) => ({
  "& > *": {
    width: "100%",
    marginBottom: removeMB ? 0 : theme.spacing(2),
  },
}));

const FlexRow: React.FC<PropsWithChildren & StackProps & { removeMB?: boolean; spacing?: number }> = ({ removeMB, children, spacing = 3, ...props }) => {
  return (
    <CustomStack removeMB={removeMB} spacing={spacing} direction={{ md: "row" }} {...props}>
      {children}
    </CustomStack>
  );
};

export default FlexRow;
