import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, Divider, FormControl, IconButton, styled, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useEffect } from "react";
import { useForm, useFormState } from "react-final-form";
import { FeeLimits, initialSubscriptionRow } from "utils/Partner";
import CurrencyTextField from "./CurrencyTextField";
import PageTitle from "./PageTitle";

const CustomTableRow = styled(TableRow)(() => ({
  "& td, & th": { padding: "0 0 16px 0", borderBottom: "none" },
}));

const DividerContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 54,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const FeeSubscriptions: React.FC = () => {
  const { change } = useForm();
  const { values } = useFormState();
  const currency = values.limits.global.currency;
  const subscriptions = React.useMemo(() => (values?.feeRules.subscriptions as FeeLimits[]) || [initialSubscriptionRow(currency)], [values]);

  useEffect(() => {
    change("feeRules.currency", currency);
  }, [values.limits]);

  const addSubscription = () => {
    change("feeRules.subscriptions", [...subscriptions, initialSubscriptionRow(currency)]);
  };

  const removeSubscription = (index: number) => {
    subscriptions.splice(index, 1);
    change("feeRules.subscriptions", [...subscriptions]);
  };
  return (
    <Box>
      <PageTitle>Fee rules</PageTitle>
      <Box width="100%" overflow="auto">
        <Table sx={{ minWidth: 600 }}>
          <TableHead>
            <CustomTableRow>
              <TableCell>MONET Subscription per month</TableCell>
              <TableCell align="right">
                <Box maxWidth={300}>Credit Limit</Box>
              </TableCell>
              <TableCell align="right"></TableCell>
            </CustomTableRow>
          </TableHead>
          <TableBody>
            {subscriptions.map((_item, index) => (
              <CustomTableRow key={"subs" + index}>
                <TableCell colSpan={2}>
                  <Box display="flex">
                    <FormControl sx={{ flex: 1 }}>
                      <CurrencyTextField data-testid="partnerJourney-feeRules-subAmountField" name={`feeRules.subscriptions[${index}].costPerMonth.amount`} autoComplete="off" />
                    </FormControl>
                    <DividerContainer>
                      <Divider variant="middle" sx={{ width: "100%" }}>
                        to a <strong>Credit limit</strong> of
                      </Divider>
                    </DividerContainer>
                    <FormControl sx={{ flex: 1 }}>
                      <CurrencyTextField
                        data-testid="partnerJourney-feeRules-subCreditLimitField"
                        name={`feeRules.subscriptions[${index}].creditLimit.amount`}
                        autoComplete="off"
                      />
                    </FormControl>
                    {index > 0 || subscriptions.length > 1 ? (
                      <IconButton onClick={() => removeSubscription(index)} sx={{ height: 54 }}>
                        <ClearIcon />
                      </IconButton>
                    ) : null}
                  </Box>
                </TableCell>
              </CustomTableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Box textAlign="center" mt={3}>
        <Button data-testid="partnerJourney-feeRules-subAddAnotherBtn" variant="text" onClick={addSubscription}>
          <AddIcon /> Add Another
        </Button>
      </Box>
    </Box>
  );
};

export default FeeSubscriptions;
