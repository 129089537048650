import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";
import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";
import { getEnvFormattedError } from "utils/error";

export type MonetAlertProps = PropsWithChildren &
  VariantProps<typeof variants> & {
    title?: string;
    className?: string;
    message?: string;
    error?: any;
    icon?: React.ReactNode;
  };

const MonetAlert: React.FC<MonetAlertProps> = ({ title, message, error, variant, position, className, icon, children }) => {
  return (
    <div className={twMerge(clsx(variants({ variant, position }), className))} role="alert">
      {icon ||
        (title && (
          <div className="flex items-center gap-2">
            {icon}
            {title && <p className="font-bold">{title}</p>}
          </div>
        ))}
      {message && <p>{message}</p>}
      {error && <pre className=" break-all whitespace-pre-line">{getEnvFormattedError(error)}</pre>}
      {children}
    </div>
  );
};

export default MonetAlert;

const variants = cva("rounded-lg p-4 text-sm", {
  variants: {
    variant: {
      dark: "bg-gray-100 border border-gray-200 text-gray-800 dark:bg-white/10 dark:border-white/20 dark:text-white",
      secondary: "bg-gray-50 border border-gray-200 text-gray-600 dark:bg-white/10 dark:border-white/10 dark:text-neutral-400",
      info: "bg-blue-100 border border-blue-200 text-blue-800 dark:bg-blue-800/10 dark:border-blue-900 dark:text-blue-500",
      success: "bg-teal-100 border border-teal-200 text-teal-800 dark:bg-teal-800/10 dark:border-teal-900 dark:text-teal-500",
      error: "bg-red-100 border border-red-200 text-red-800 dark:bg-red-800/10 dark:border-red-900 dark:text-red-500",
      warning: "bg-yellow-100 border border-yellow-200 text-yellow-800 dark:bg-yellow-800/10 dark:border-yellow-900 dark:text-yellow-500",
    },
    position: {
      relative: "relative mt-2",
      absolute: "absolute top-4 right-4",
    },
  },
  defaultVariants: {
    variant: "secondary",
    position: "relative",
  },
});
