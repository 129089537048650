import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { useSessionProvider } from "contexts/SessionProvider";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import StripeConfirmationModal from "./StripeConfirmationModal";
import StripePricingTableModal from "./StripePricingTableModal";

const StripePaywallModal: React.FC = () => {
  const { partner, user } = useSessionProvider();
  const { search } = useLocation();

  const stripeSessionId = new URLSearchParams(search).get("stripeSessionId");

  const showPricingTable = useMemo(() => {
    return !user.isAdministrator && (!partner?.subscription || partner?.subscription?.status === "INACTIVE");
  }, [partner, user.isAdministrator]);

  const showConfirmationMessage = useMemo(() => {
    return !user.isAdministrator && (!partner?.subscription || partner?.subscription?.status === "INACTIVE") && Boolean(stripeSessionId);
  }, [partner, user.isAdministrator, stripeSessionId]);

  if (!showConfirmationMessage && !showPricingTable) return null;

  return (
    <Dialog static open={true} onClose={() => null} className="relative z-50">
      <DialogBackdrop className="fixed inset-0 bg-black/30" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="flex flex-col w-full h-full max-w-[1000px] max-h-[800px] bg-white border shadow-sm overflow-auto p-4 rounded-lg pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
          {showConfirmationMessage ? <StripeConfirmationModal stripeSessionId={stripeSessionId!} /> : showPricingTable && <StripePricingTableModal />}
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default StripePaywallModal;
