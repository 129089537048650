import { Money, MoneyStorage } from "@monet-money/money-type";
import { Percentage } from "@monet-money/percentage-type";
import { getRandomValues } from "crypto";

export const generatePayoutId = (): string => {
  const timestamp = BigInt(Math.floor(Date.now() / 1000)); // eg. 1674044392 for Wednesday, 18 January 2023 12:19:52
  const random = getRandomValues(new Uint16Array(1))[0]; // eg.  27843, random value between  [0 - 65535]

  let sortKey = timestamp << BigInt(16); // moving by 16 to leave the space for randomness
  sortKey |= BigInt(random); //filing in the last 16 bits.

  if (!Number.isSafeInteger(Number(sortKey))) throw new Error("Unsafe integer generated, this should not be possible 😲 !");

  return sortKey.toString(10); // a pseudo random ID between [0 - 281474976710655]
};

export const getPayoutTotalDetails = (invoiceTotal: MoneyStorage, monetFee: Percentage, partnerFee: Percentage, chargeCreator?: boolean) => {
  let monetFeeTotal = Money.fromStorageType(invoiceTotal as MoneyStorage).multiply(monetFee.percentage);

  if (monetFeeTotal.amount <= 0) {
    monetFeeTotal = new Money(0, invoiceTotal?.currency);
  }

  let partnerFeeTotal = Money.fromStorageType(invoiceTotal as MoneyStorage).multiply(partnerFee.percentage);

  let fees = monetFeeTotal.add(partnerFeeTotal);
  if (!chargeCreator) {
    fees = new Money(0, invoiceTotal?.currency);
    partnerFeeTotal = fees.subtract(monetFeeTotal);
  }

  const earlyPayTotal = Money.fromStorageType(invoiceTotal as MoneyStorage).subtract(chargeCreator ? fees : 0);

  return {
    earlyPayTotal,
    fees,
    partnerFee: partnerFeeTotal,
    monetFee: monetFeeTotal,
    payoutTotal: Money.fromStorageType(invoiceTotal as MoneyStorage).subtract(partnerFeeTotal.add(monetFeeTotal)),
  };
};

export const getPayoutTotals = (payout: Components.Schemas.Payout) => {
  return getPayoutTotalDetails(
    payout.payoutValue as MoneyStorage,
    Percentage.fromStorageValue(payout.feeSettings.monetFee),
    Percentage.fromStorageValue(payout.feeSettings.partnerFee),
    payout.feeSettings.chargeCreator,
  );
};

const generateRandomString = (length: number) => {
  const characters = "ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz123456789";
  let result = "";
  const charLength = characters.length - 1;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * (charLength - 0 + 1) + 0);
    result += characters.charAt(randomIndex);
  }

  return result;
};

export const generatePayoutReference = (partnerName: string) => {
  const firstAndLastLetter = partnerName.slice(-1) + partnerName[0];

  const randomString = generateRandomString(6);

  return `MONET-${firstAndLastLetter}-${randomString}`.toUpperCase();
};
