import { remove, uploadData } from "aws-amplify/storage";
import { LogoInput } from "shared/types/eep.contract.types";

export const MAX_IMAGE_SIZE = 400;
const validImageExtensions = ["jpg", "png", "jpeg"];

type FileObject = {
  filePath?: string;
  name?: string;
  type?: string;
  error?: string;
};

export const convertFileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getFileName = (filePath: string) => {
  const fileNameArray = filePath.split("/");
  return fileNameArray[fileNameArray.length - 1];
};

export const getFileExtension = (fileName: string) => {
  const fileNameArray = fileName.split(".");
  return fileNameArray[fileNameArray.length - 1].toLowerCase();
};

export const removeFileFromS3 = async (key: string) => {
  await remove({ key });
};

export const uploadFileToS3 = async (key: string, file: File) => {
  await uploadData({
    key,
    data: file,
    options: {
      accessLevel: "private",
    },
  }).result;
};
const toDataURL = (url: string) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }),
    );

export const convertImageUrlToFileInput = async (url: string): Promise<LogoInput> => {
  if (!url) {
    return defaultFileInput;
  }
  const file = (await toDataURL(url)) as string;
  const urlParts = url.split("/") || [];

  const name = urlParts[urlParts.length - 1];
  const nameParts = name.split(".");

  return {
    file,
    type: nameParts[nameParts.length - 1],
    name,
  };
};

export const processImageFile = async (file: File): Promise<FileObject> => {
  const fileObject: FileObject = {};
  const img = new Image();

  await new Promise((resolve) => {
    const fileType = file.type.split("/")[1];
    if (!validImageExtensions.includes(fileType)) {
      fileObject.error = "Please upload an image in the correct format. Supported types: .png, .jpg, .jpeg";
      resolve(null);
    }

    img.src = window.URL.createObjectURL(file);
    img.onload = async () => {
      const fileBase64 = (await convertFileToBase64(file)) as string;
      fileObject.filePath = fileBase64;
      fileObject.name = file.name;
      fileObject.type = fileType;

      if (img.width > MAX_IMAGE_SIZE || img.height > MAX_IMAGE_SIZE) {
        fileObject.error = `Image is to big. Maximum size ${MAX_IMAGE_SIZE}x${MAX_IMAGE_SIZE} (px)`;
      }
      window.URL.revokeObjectURL(img.src);
      resolve(null);
    };
  });

  return fileObject;
};

export const defaultFileInput = { file: "", name: "", type: "" };
export const taggingHeaderKey = { "X-Amz-Tagging": "isTemporary=true" };
export const MAX_FILE_SIZE_IN_MB = 25;
export const MAX_FILE_HELPER_TEXT = `Max upload size is ${MAX_FILE_SIZE_IN_MB}mb`;
export const MAX_FILE_SIZE_ERROR_MESSAGE = `Upload failed. Maximum file size is ${MAX_FILE_SIZE_IN_MB}mb`;
export const getFileSizeInMB = (fileSize: number) => fileSize / 1024 / 1024;
