import { Box, FormControl, FormLabel, MenuItem, OutlinedInput, Stack, Typography } from "@mui/material";
import CountryFlag from "components/CountryFlag/CountryFlag";
import FlexRow from "components/FlexRow/FlexRow";
import { MultiSelectCurrenciesWithMargin } from "components/Forms/MultiSelectCurrenciesWithMargin/MultiSelectCurrenciesWithMargin";
import getSymbolFromCurrency from "currency-symbol-map";
import { Select, TextField } from "mui-rff";
import React, { Dispatch, SetStateAction } from "react";
import { useForm, useFormState } from "react-final-form";
import { PartnerInput } from "shared/types/eep.contract.types";
import { SelectedCurrency, getCurrenciesFromCountryCode } from "utils/currency";
import { handleWheel } from "utils/inputs";
import PageTitle from "./PageTitle";

type UpperCreditLimitProps = {
  setSelectedCurrencies: Dispatch<SetStateAction<SelectedCurrency[]>>;
};

const UpperCreditLimit: React.FC<UpperCreditLimitProps> = ({ setSelectedCurrencies }) => {
  const { change } = useForm();
  const { values }: { values: PartnerInput } = useFormState();
  // Updating currency for fees -> global limit -> subscriptions when fee.currency changes
  React.useEffect(() => {
    const currency = values?.limits?.global.currency;
    change("limits.global.currency", currency);
    change(
      "feeRules.subscriptions",
      values.feeRules.subscriptions.map((item) => ({
        costPerMonth: { ...item.costPerMonth, currency: currency },
        creditLimit: { ...item.creditLimit, currency: currency },
      })),
    );
  }, [values?.limits?.global.currency]);

  const selectedCurrencies = React.useMemo(() => getCurrenciesFromCountryCode(values?.defaultConfiguration.allowedCurrencies), [values?.defaultConfiguration.allowedCurrencies]);

  React.useEffect(() => {
    setSelectedCurrencies(selectedCurrencies);
  }, [selectedCurrencies]);

  const currenciesMenu = React.useMemo(() => {
    return selectedCurrencies.map((item) => (
      <MenuItem data-testid="partnerJourney-feeRules-creditCountryItem" key={item.currency} value={item.currency}>
        <CountryFlag countryCode={item.countryCode} />
        &nbsp;{item.currency}&nbsp;({getSymbolFromCurrency(item.currency)})
      </MenuItem>
    ));
  }, [selectedCurrencies]);

  return (
    <Box>
      <PageTitle>Fee rules</PageTitle>

      <Box mb={3} position={"relative"}>
        <Stack spacing={3} position={"relative"}>
          <FormControl fullWidth>
            <FlexRow mb={2} flexDirection="row">
              <FormLabel data-testid="partnerJourney-feeRules-allowedCurrenciesHeader">Allowed Currencies</FormLabel>
              <Typography variant="body2" textAlign="right" sx={{ color: (theme) => theme.palette.text.disabled }}>
                The allowed currencies the EarlyPay transactions can be executed in
              </Typography>
            </FlexRow>
            <MultiSelectCurrenciesWithMargin
              name="defaultConfiguration.allowedCurrencies"
              allowedCurrencies={values.defaultConfiguration.allowedCurrencies}
              data-testid="partnerJourney-feeRules-allowedCurrencySelector"
            />
          </FormControl>
          <FormControl fullWidth>
            <FlexRow mb={2} flexDirection="row">
              <FormLabel>Credit limit</FormLabel>
              <Typography variant="body2" textAlign="right" sx={{ color: (theme) => theme.palette.text.disabled }}>
                This is the upper limit allocated to the entire partner’s credit line
              </Typography>
            </FlexRow>
            <FlexRow spacing={0} mb={2} flexDirection="row">
              <TextField
                data-testid="partnerJourney-feeRules-creditLimitField"
                type="number"
                onWheel={handleWheel}
                name="limits.global.amount"
                placeholder="0"
                inputProps={{ min: "0", step: "1", "data-testid": "partnerFeeRules-globalLimitFormField" }}
                autoComplete="off"
              />
              <Box maxWidth={150}>
                <Select data-testid="partnerJourney-feeRules-creditCurrencySelector" name="limits.global.currency" displayEmpty required input={<OutlinedInput />}>
                  <MenuItem value="" disabled>
                    {<em>Select Currency</em>}
                  </MenuItem>
                  {currenciesMenu}
                </Select>
              </Box>
            </FlexRow>
          </FormControl>
        </Stack>
      </Box>
    </Box>
  );
};

export default UpperCreditLimit;
