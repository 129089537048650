import MonetAlert from "components/tailwind/MonetAlert";
import { useSessionProvider } from "contexts/SessionProvider";
import { useMemo } from "react";
import { missingOrganisationProfileInformation } from "utils/Partner";

const MissingProfileInformationBanner = () => {
  const { partner } = useSessionProvider();

  const missingInformation = useMemo(() => {
    if (partner) {
      const missingAttributes = missingOrganisationProfileInformation(partner);

      if (missingAttributes.length > 0) {
        return <ul className="ps-4 list-disc">{missingAttributes.map((attr) => attr && <li key={attr}>{attr}</li>)}</ul>;
      }
    }
    return null;
  }, [partner]);

  if (!missingInformation) return null;

  return (
    <MonetAlert variant="info" title="Missing information">
      <div className="flex flex-col gap-2">
        <p>Please complete you organisation profile and fill out the below missing information: </p>
        {missingInformation}
      </div>
    </MonetAlert>
  );
};

export default MissingProfileInformationBanner;
