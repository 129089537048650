import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Box, Stack, Typography, styled } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorView from "components/ErrorView/ErrorView";
import LoadingSpinner from "components/Loaders/LoadingSpinner";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { PartnerDocument } from "shared/generated/ep3.graphql.types";
import { getFileName } from "utils/file";
import { useGetPartnerDocumentLazyQuery, useGetPartnerDocumentsQuery } from "../graphql/queries.generated";

const LinkBox = styled(Stack, {
  shouldForwardProp: (propertyName) => !["isUrlLoading"].includes(propertyName.toString()),
})<{ isUrlLoading: boolean }>(({ theme, isUrlLoading }) => ({
  width: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(1),
  cursor: isUrlLoading ? "not-allowed" : "pointer",
  textDecoration: "underline",
  textDecorationColor: theme.palette.primary.main,
}));

type DocumentLinkProps = {
  partnerDoc: PartnerDocument;
  partnerId: string;
};

const DocumentLink: React.FC<DocumentLinkProps> = ({ partnerDoc, partnerId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [getDocument, { loading: isUrlLoading }] = useGetPartnerDocumentLazyQuery();
  const handleLinkClick = async () => {
    // get signed url when document type is s3
    if (partnerDoc.documentType == "S3") {
      const fileNotFoundMessage = "File not found, please contact support";
      try {
        const response = await getDocument({
          variables: {
            documentId: partnerDoc.documentId,
            partnerId,
          },
        });

        if (response.data?.getPartnerDocument) {
          window.open(response.data.getPartnerDocument.target);
          return;
        }
        enqueueSnackbar(fileNotFoundMessage, {
          variant: "error",
        });
      } catch (error: Error | any) {
        console.error(error?.message);
        enqueueSnackbar(fileNotFoundMessage, {
          variant: "error",
        });
      }
    } else {
      window.open(partnerDoc.target);
    }
  };

  return (
    <LinkBox direction="row" isUrlLoading={isUrlLoading} onClick={isUrlLoading ? undefined : handleLinkClick} spacing={1}>
      <AttachFileIcon />

      <Typography noWrap color="primary.main">
        {getFileName(partnerDoc.target)} - ({partnerDoc.linkText})
      </Typography>

      {isUrlLoading && <CircularProgress size={15} />}
    </LinkBox>
  );
};

type InvoiceAssignmentTermsProps = {
  partnerId: string;
};
const InvoiceAssignmentTerms: React.FC<InvoiceAssignmentTermsProps> = ({ partnerId }) => {
  const [documents, setDocuments] = useState<PartnerDocument[]>([]);

  const {
    data: documentsData,
    error: documentsError,
    loading: isDocumentsLoading,
  } = useGetPartnerDocumentsQuery({
    fetchPolicy: "network-only",
    variables: {
      partnerId,
      category: "INVOICE_FINANCE_TERMS",
    },
  });

  useEffect(() => {
    if (documentsData) {
      setDocuments(documentsData.getPartnerDocuments);
    }
  }, [documentsData]);

  if (isDocumentsLoading) return <LoadingSpinner alignment="flex-start" />;

  if (documentsError) return <ErrorView title="Error loading documents" error={documentsError} />;

  return (
    <Box width="100%">
      {documents.map((item) => (
        <DocumentLink partnerId={partnerId} partnerDoc={item} key={item.documentId} />
      ))}
    </Box>
  );
};

export default InvoiceAssignmentTerms;
