import { Box, FormControl, FormLabel } from "@mui/material";
import FlexRow from "components/FlexRow/FlexRow";
import { TextField } from "mui-rff";
import React from "react";
import PageTitle from "./PageTitle";

const PartnerUrlSection: React.FC = () => {
  return (
    <Box>
      <PageTitle hasOptionalText>Does the partner have a return URL?</PageTitle>
      <Box mb={2}>
        <FlexRow>
          <Box>
            <FormControl fullWidth>
              <FormLabel>Return URL for creator journey</FormLabel>
              <TextField name="returnUrl" data-testid="partnerJourney-partnerReturnUrlFormField" placeholder="Enter return URL here" autoComplete="off" />
            </FormControl>
          </Box>
        </FlexRow>
      </Box>
    </Box>
  );
};

export default PartnerUrlSection;
