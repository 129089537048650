import MonetAuthTitle from "components/tailwind/auth/MonetAuthTitle";
import MonetInput from "components/tailwind/form/MonetInput";
import MonetPasswordHint from "components/tailwind/form/MonetPasswordHint";
import MonetPasswordInput from "components/tailwind/form/MonetPasswordInput";
import MonetPhoneNumberInput from "components/tailwind/form/MonetPhoneNumberInput";
import { useFormContext } from "react-hook-form";

const ProfileStep = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="flex flex-col gap-6">
      <MonetAuthTitle subtitle="Create your Monet account today and gain access to instant funding">Sign up</MonetAuthTitle>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4 sm:flex-row">
          <MonetInput {...register("firstName")} label="First name" id="firstName" data-testid="signUp-firstNameFormField" error={errors.firstName?.message as string} />
          <MonetInput {...register("lastName")} label="Last name" id="lastName" data-testid="signUp-lastNameFormField" error={errors.lastName?.message as string} />
        </div>
        <MonetInput {...register("email")} label="Email" id="email" data-testid="signUp-emailFormField" autoComplete="username" error={errors.email?.message as string} />
        <MonetPhoneNumberInput label="Mobile number" name="phoneNumber" error={errors.phoneNumber?.message as string} />
        <div className="flex flex-col gap-4 sm:flex-row">
          <MonetPasswordHint
            input={
              <MonetPasswordInput
                {...register("password")}
                label="Password"
                data-testid="signUp-passwordFormField"
                id="password"
                autoComplete="new-password"
                error={errors.password?.message as string}
              />
            }
            inputId="password"
          />
          <MonetPasswordInput
            {...register("passwordConfirmation")}
            label="Confirm password"
            data-testid="signUp-passwordConfirmationFormField"
            type="password"
            id="passwordConfirmation"
            autoComplete="new-password"
            error={errors.passwordConfirmation?.message as string}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileStep;
