import MonetCard from "components/tailwind/MonetCard";
import MonetLoadingSpinner from "components/tailwind/MonetLoadingSpinner";
import getSymbolFromCurrency from "currency-symbol-map";

type DailyFeeCardProps = {
  currency?: string;
  margin?: number;
  baseRate?: number;
  dailyFee?: string;
  loading?: boolean;
};

const DailyFeeCard: React.FC<DailyFeeCardProps> = ({ currency, margin, baseRate, dailyFee, loading }) => {
  return (
    <MonetCard className="sm:w-[300px] h-[168px] flex flex-col">
      {loading ? (
        <div className="flex w-full h-full justify-center items-center">
          <MonetLoadingSpinner size="default" />
        </div>
      ) : (
        <>
          <div>
            <p>{`${currency}(${currency && getSymbolFromCurrency(currency)})`}</p>
            <p className="text-2xl font-bold">{dailyFee}</p>
          </div>
        </>
      )}
    </MonetCard>
  );
};

export default DailyFeeCard;
