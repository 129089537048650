import * as Types from 'shared/generated/ep3.graphql.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDashboardMetricsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDashboardMetricsQuery = { __typename?: 'Query', getDashboardMetrics: { __typename?: 'DashboardMetricsResponse', activeCampaigns: number, overdueInvoices: number, fundingBalance: { __typename?: 'MoneyStorage', currency: Types.Currency, amount: number }, fundingAvailable: { __typename?: 'MoneyStorage', currency: Types.Currency, amount: number } } };

export type GetCampaignsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCampaignsQuery = { __typename?: 'Query', getCampaigns: any };

export type GetPayoutsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPayoutsQuery = { __typename?: 'Query', getPayouts: any };


export const GetDashboardMetricsDocument = gql`
    query getDashboardMetrics {
  getDashboardMetrics {
    fundingBalance {
      currency
      amount
    }
    fundingAvailable {
      currency
      amount
    }
    activeCampaigns
    overdueInvoices
  }
}
    `;

/**
 * __useGetDashboardMetricsQuery__
 *
 * To run a query within a React component, call `useGetDashboardMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardMetricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardMetricsQuery(baseOptions?: Apollo.QueryHookOptions<GetDashboardMetricsQuery, GetDashboardMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardMetricsQuery, GetDashboardMetricsQueryVariables>(GetDashboardMetricsDocument, options);
      }
export function useGetDashboardMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardMetricsQuery, GetDashboardMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardMetricsQuery, GetDashboardMetricsQueryVariables>(GetDashboardMetricsDocument, options);
        }
export function useGetDashboardMetricsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDashboardMetricsQuery, GetDashboardMetricsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDashboardMetricsQuery, GetDashboardMetricsQueryVariables>(GetDashboardMetricsDocument, options);
        }
export type GetDashboardMetricsQueryHookResult = ReturnType<typeof useGetDashboardMetricsQuery>;
export type GetDashboardMetricsLazyQueryHookResult = ReturnType<typeof useGetDashboardMetricsLazyQuery>;
export type GetDashboardMetricsSuspenseQueryHookResult = ReturnType<typeof useGetDashboardMetricsSuspenseQuery>;
export type GetDashboardMetricsQueryResult = Apollo.QueryResult<GetDashboardMetricsQuery, GetDashboardMetricsQueryVariables>;
export const GetCampaignsDocument = gql`
    query getCampaigns {
  getCampaigns
}
    `;

/**
 * __useGetCampaignsQuery__
 *
 * To run a query within a React component, call `useGetCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCampaignsQuery(baseOptions?: Apollo.QueryHookOptions<GetCampaignsQuery, GetCampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(GetCampaignsDocument, options);
      }
export function useGetCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignsQuery, GetCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(GetCampaignsDocument, options);
        }
export function useGetCampaignsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCampaignsQuery, GetCampaignsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(GetCampaignsDocument, options);
        }
export type GetCampaignsQueryHookResult = ReturnType<typeof useGetCampaignsQuery>;
export type GetCampaignsLazyQueryHookResult = ReturnType<typeof useGetCampaignsLazyQuery>;
export type GetCampaignsSuspenseQueryHookResult = ReturnType<typeof useGetCampaignsSuspenseQuery>;
export type GetCampaignsQueryResult = Apollo.QueryResult<GetCampaignsQuery, GetCampaignsQueryVariables>;
export const GetPayoutsDocument = gql`
    query getPayouts {
  getPayouts
}
    `;

/**
 * __useGetPayoutsQuery__
 *
 * To run a query within a React component, call `useGetPayoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayoutsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPayoutsQuery(baseOptions?: Apollo.QueryHookOptions<GetPayoutsQuery, GetPayoutsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayoutsQuery, GetPayoutsQueryVariables>(GetPayoutsDocument, options);
      }
export function useGetPayoutsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayoutsQuery, GetPayoutsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayoutsQuery, GetPayoutsQueryVariables>(GetPayoutsDocument, options);
        }
export function useGetPayoutsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPayoutsQuery, GetPayoutsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPayoutsQuery, GetPayoutsQueryVariables>(GetPayoutsDocument, options);
        }
export type GetPayoutsQueryHookResult = ReturnType<typeof useGetPayoutsQuery>;
export type GetPayoutsLazyQueryHookResult = ReturnType<typeof useGetPayoutsLazyQuery>;
export type GetPayoutsSuspenseQueryHookResult = ReturnType<typeof useGetPayoutsSuspenseQuery>;
export type GetPayoutsQueryResult = Apollo.QueryResult<GetPayoutsQuery, GetPayoutsQueryVariables>;