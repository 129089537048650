import { yupResolver } from "@hookform/resolvers/yup";
import { resetPassword } from "aws-amplify/auth";
import MonetAlert, { MonetAlertProps } from "components/tailwind/MonetAlert";
import MonetButton from "components/tailwind/MonetButton";
import MonetAuthFooter from "components/tailwind/auth/MonetAuthFooter";
import MonetAuthTitle from "components/tailwind/auth/MonetAuthTitle";
import MonetInput from "components/tailwind/form/MonetInput";
import AuthViewLayout from "components/tailwind/templates/AuthViewLayout";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { isObjEmpty } from "utils/objects";
import { removeAllWhitespaces } from "utils/strings";
import { AUTH_PATH, SIGN_IN_PATH } from "../AuthPath";
import { PasswordResetFormObject, validationSchema } from "./ForgotPasswordValidationSchema";

const ForgotPassword: React.FC = () => {
  const [alert, setAlert] = useState<undefined | MonetAlertProps>();
  const navigate = useNavigate();

  const onSubmit = async (values: PasswordResetFormObject) => {
    setAlert(undefined);
    try {
      await resetPassword({ username: removeAllWhitespaces(values.email).toLowerCase() });
      setAlert({
        title: "Email Sent",
        variant: "success",
        message: "A password reset link has been sent to the email provided",
      });
    } catch (err) {
      setAlert({
        title: "Password Reset Failed",
        variant: "error",
        message: "An error occurred sending reset link",
        error: err,
      });
    }
  };

  const {
    register,
    formState: { errors, isValid, isSubmitting },
    handleSubmit,
  } = useForm<{ email: string }>({
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
  });

  return (
    <AuthViewLayout helmet="Forgot password">
      <MonetAuthTitle subtitle="Enter your email address below. If the email matches an account you will then receive a email to reset the password">Reset Password</MonetAuthTitle>
      {alert && <MonetAlert variant={alert.variant} title={alert.title} message={alert.message} error={alert.error} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-5">
          <MonetInput
            data-testid="signIn-passwordFormField"
            {...register("email")}
            disabled={isSubmitting}
            label="Email"
            id="email"
            error={errors.email?.message}
            placeholder="Please enter your email"
          />
          <MonetButton loading={isSubmitting} type="submit" disabled={!isObjEmpty(errors) || !isValid || isSubmitting} className="w-full">
            Request password reset email
          </MonetButton>
        </div>
      </form>
      <MonetAuthFooter buttonText="Sign in" buttonProps={{ onClick: () => navigate(AUTH_PATH + SIGN_IN_PATH) }}>
        You didn’t forget your password?
      </MonetAuthFooter>
    </AuthViewLayout>
  );
};

export default ForgotPassword;
