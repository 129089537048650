import { Stack } from "@mui/material";
import { alpha3ToAlpha2, getName, registerLocale } from "i18n-iso-countries";
import countriesLanguague from "i18n-iso-countries/langs/en.json";
import { countryFlagApi } from "utils/country";

registerLocale(countriesLanguague);

type CountryFlagProps = { countryCode: string; width?: number };
const CountryFlag = ({ countryCode, width = 16 }: CountryFlagProps) => (
  <img
    src={countryFlagApi(countryCode?.length === 3 ? alpha3ToAlpha2(countryCode) : countryCode)}
    alt={countryCode}
    style={{
      width,
    }}
  />
);

export const CountryNameAndFlag = ({ countryCode, width = 24 }: { countryCode: string; width?: number }) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    <span>
      <CountryFlag countryCode={countryCode} width={width} />
    </span>
    <span>{getName(countryCode, "en")}</span>
  </Stack>
);

export default CountryFlag;
