import RemoveIcon from "@mui/icons-material/Remove";
import { Fab, styled } from "@mui/material";
import useAppProvider from "contexts/AppProvider";
import React, { useEffect, useMemo } from "react";
import { useForm, useFormState } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import DesktopSubscriptionComponent from "../DesktopSubscriptionComponent";
import MobileSubscriptionComponent from "../MobileSubscriptionComponent";

const RemoveFab = styled(Fab)(({ theme }) => ({
  transform: "scale(0.5)",
  position: "absolute",
  right: 0,
  top: 0,
  marginTop: theme.spacing(-2),
  marginRight: theme.spacing(-2),
}));

type SubscriptionComponentProps = {
  remove(...args: any[]): void;
};
const SubscriptionComponent: React.FC<SubscriptionComponentProps> = ({ remove }) => {
  const { isMobile } = useAppProvider();
  const { values } = useFormState();

  const { change } = useForm();

  const currency = useMemo(() => values.limits?.global?.currency, [values.limits?.global?.currency]);

  useEffect(() => {
    const subscriptions = values.feeRules.subscriptions || [];
    for (const [index] of subscriptions.entries()) {
      change(`feeRules.subscriptions[${index}].creditLimit.currency`, currency);
      change(`feeRules.subscriptions[${index}].costPerMonth.currency`, currency);
    }
  }, [currency]);

  return (
    <FieldArray name="feeRules.subscriptions">
      {({ fields }) =>
        fields.map((_name, index) =>
          isMobile ? (
            <MobileSubscriptionComponent
              key={"sub" + index}
              removeFab={
                <RemoveFab size="small" color="error" aria-label="add" onClick={() => remove("feeRules.subscriptions", index)}>
                  <RemoveIcon />
                </RemoveFab>
              }
              currency={currency}
              index={index}
            />
          ) : (
            <DesktopSubscriptionComponent
              key={"sub" + index}
              removeFab={
                <RemoveFab size="small" color="error" aria-label="add" onClick={() => remove("feeRules.subscriptions", index)}>
                  <RemoveIcon />
                </RemoveFab>
              }
              currency={currency}
              index={index}
            />
          ),
        )
      }
    </FieldArray>
  );
};

export default SubscriptionComponent;
