import { Money, MoneyStorage } from "@monet-money/money-type";
import MonetBorderedCardContent from "components/tailwind/MonetBorderedCardContent";
import MonetDivider from "components/tailwind/MonetDivider";
import MonetDrawerButtons from "components/tailwind/MonetDrawerButtons";
import MonetLabelledItem from "components/tailwind/MonetLabelledItem";
import TailwindDrawer from "components/tailwind/headlessTailwind/TailwindDrawer";
import dayjs from "dayjs";
import { Invoice } from "utils/Invoice";
import { toSentenceCase } from "utils/strings";

type ViewInvoiceSettlementDrawerProps = {
  invoice?: Invoice;
};

const ViewInvoiceSettlementDrawer: React.FC<ViewInvoiceSettlementDrawerProps> = ({ invoice }) => {
  return (
    <>
      <TailwindDrawer title="Invoice settlement" subtitle="Below are all the invoice settlement details">
        <div className="flex flex-col h-full justify-between">
          <div className="flex flex-col gap-4 ">
            <MonetBorderedCardContent>
              <MonetLabelledItem label="Invoice ID" value={invoice?.invoiceId} tooltip="This is the MONET created unique ID for this invoice" />
            </MonetBorderedCardContent>
            <MonetBorderedCardContent>
              <MonetLabelledItem label="Invoice value" value={Money.fromStorageType(invoice?.invoiceValue).format(true, true)} />
              <MonetLabelledItem label="Due date" value={dayjs(invoice?.dueDate).format("DD/MM/YYYY")} />
              <MonetLabelledItem label="Settlement date" value={dayjs(invoice?.settlement?.date).format("DD/MM/YYYY")} />
              <MonetLabelledItem label="Settled by" value={invoice?.settlement?.settledBy} />
            </MonetBorderedCardContent>
            <MonetBorderedCardContent>
              <MonetLabelledItem label="Days late" value={invoice?.settlement?.daysLate} />
              <MonetLabelledItem label="EarlyPay late fees" value={Money.fromStorageType(invoice?.settlement?.totalLateFee as MoneyStorage).format(true, true)} />
              <MonetLabelledItem label="EarlyPay fees" value={Money.fromStorageType(invoice?.settlement?.earlyPayFees as MoneyStorage).format(true, true)} />
              <MonetDivider />
              <MonetLabelledItem label="Total EarlyPay fees" value={Money.fromStorageType(invoice?.settlement?.totalEarlyPayFees as MoneyStorage).format(true, true)} />
              <MonetLabelledItem label="Total agency fees" value={Money.fromStorageType(invoice?.settlement?.partnerFees as MoneyStorage).format(true, true)} />
              <MonetDivider />
              <MonetLabelledItem label="Principal" value={Money.fromStorageType(invoice?.settlement?.principal as MoneyStorage).format(true, true)} />
              <MonetLabelledItem label="Residual" value={Money.fromStorageType(invoice?.settlement?.residual as MoneyStorage).format(true, true)} />
              <MonetDivider />
              <MonetLabelledItem label="All checks to zero" value={toSentenceCase(new Boolean(invoice?.settlement?.checksToZero).toString())} />
            </MonetBorderedCardContent>
            {invoice?.settlement?.notes && (
              <div>
                <p className="font-semibold text-sm">Notes</p>
                <MonetBorderedCardContent>
                  <span>{invoice?.settlement?.notes}</span>
                </MonetBorderedCardContent>
              </div>
            )}
          </div>
          <MonetDrawerButtons cancelDisabled={false} submitDisabled={true} isPassive={true} />
        </div>
      </TailwindDrawer>
    </>
  );
};

export default ViewInvoiceSettlementDrawer;
