import * as Types from 'shared/generated/ep3.graphql.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCurrencyBaseRateMutationVariables = Types.Exact<{
  currencies: Array<Types.UpdateCurrencyBaseRateInput> | Types.UpdateCurrencyBaseRateInput;
}>;


export type UpdateCurrencyBaseRateMutation = { __typename?: 'Mutation', updateCurrencyBaseRate: boolean };


export const UpdateCurrencyBaseRateDocument = gql`
    mutation updateCurrencyBaseRate($currencies: [UpdateCurrencyBaseRateInput!]!) {
  updateCurrencyBaseRate(currencies: $currencies)
}
    `;
export type UpdateCurrencyBaseRateMutationFn = Apollo.MutationFunction<UpdateCurrencyBaseRateMutation, UpdateCurrencyBaseRateMutationVariables>;

/**
 * __useUpdateCurrencyBaseRateMutation__
 *
 * To run a mutation, you first call `useUpdateCurrencyBaseRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrencyBaseRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrencyBaseRateMutation, { data, loading, error }] = useUpdateCurrencyBaseRateMutation({
 *   variables: {
 *      currencies: // value for 'currencies'
 *   },
 * });
 */
export function useUpdateCurrencyBaseRateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCurrencyBaseRateMutation, UpdateCurrencyBaseRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCurrencyBaseRateMutation, UpdateCurrencyBaseRateMutationVariables>(UpdateCurrencyBaseRateDocument, options);
      }
export type UpdateCurrencyBaseRateMutationHookResult = ReturnType<typeof useUpdateCurrencyBaseRateMutation>;
export type UpdateCurrencyBaseRateMutationResult = Apollo.MutationResult<UpdateCurrencyBaseRateMutation>;
export type UpdateCurrencyBaseRateMutationOptions = Apollo.BaseMutationOptions<UpdateCurrencyBaseRateMutation, UpdateCurrencyBaseRateMutationVariables>;