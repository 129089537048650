import { Box, Stack, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";

const PageTitle: React.FC<PropsWithChildren & { hasOptionalText?: boolean }> = ({ children, hasOptionalText }) => {
  return (
    <Box mb={3}>
      <Stack flexDirection={{ md: "row" }}>
        <Typography variant="h2">{children}</Typography>
        {hasOptionalText && (
          <Typography variant="h2" fontWeight={400} display="inline-block">
            &nbsp;(Optional)
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default PageTitle;
