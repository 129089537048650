import { Box, SxProps, Theme, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import useAppProvider from "contexts/AppProvider";
import { RadioData, Radios, ShowErrorFunc } from "mui-rff";
import { useMemo } from "react";
import { DEFAULT_BORDER_RADIUS } from "theme/theme";

type RadioGroupProps = {
  options: RadioData[];
  name: string;

  showError?: ShowErrorFunc;
};

const RadiosWrapper = styled(Box)(({ theme }) => ({
  display: "flex",

  "& .MuiFormControl-root": {
    flex: 1,
  },
  "& .MuiFormGroup-root": {
    flexDirection: "column",
    justifyContent: "flex-end",
    rowGap: theme.spacing(2),
    columnGap: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
}));

const GroupedRadioButtons = ({ name, options, showError = () => true }: RadioGroupProps) => {
  const theme = useTheme();
  const { isMobile } = useAppProvider();

  const radioGroupStyles = useMemo<SxProps<Theme>>(
    () => ({
      "& .MuiFormControlLabel-root": {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: `${DEFAULT_BORDER_RADIUS}px`,
        flex: 1,
        margin: 0,
        py: 1,
        px: 3,
      },
    }),
    [theme],
  );

  return (
    <RadiosWrapper>
      <Radios
        data-testid="radios-container"
        label=""
        name={name}
        required
        showError={showError}
        data={options}
        radioGroupProps={{
          row: !isMobile,
          sx: radioGroupStyles,
        }}
      />
    </RadiosWrapper>
  );
};
export default GroupedRadioButtons;
