import { Currency, Money } from "@monet-money/money-type";
import { Typography } from "@mui/material";
import { CountryNameAndFlag } from "components/CountryFlag/CountryFlag";
import Stepper from "components/StepperTitle/Stepper";
import getSymbolFromCurrency from "currency-symbol-map";
import React from "react";
import { useFormState } from "react-final-form";
import { PartnerInput } from "shared/types/eep.contract.types";
import { enumToNiceString } from "utils/strings";
import { CurrentViewType } from "../utils";

const StepperView: React.FC<{ currentView: CurrentViewType }> = ({ currentView }) => {
  const { values }: { values: PartnerInput } = useFormState();

  const currencySymbol = getSymbolFromCurrency(values.limits?.global.currency);
  const selectedCurrency = values.limits?.global.currency as Currency;

  const StagesInOrder: CurrentViewType[] = [
    "PartnerName",
    "PartnerId",
    "PartnerLocation",
    "PartnerLogo",
    "PartnerUrl",
    "LandingPage",
    "Address",
    "UpperCreditLimit",
    "FeeSubscriptions",
    "FeeSettings",
    "TermsAndConditions",
    "KybStatus",
    "Confirmation",
    "Success",
  ];

  const getStageIndex = React.useCallback((stage: CurrentViewType) => {
    return StagesInOrder.indexOf(stage);
  }, []);

  const feeSettings = values.defaultConfiguration.feeSettings;
  const isLocationSelected = getStageIndex(currentView) > getStageIndex("PartnerLocation");
  return (
    <>
      <Stepper
        title="Details"
        checked={getStageIndex(currentView) > getStageIndex("PartnerName")}
        isCurrentView={["PartnerName", "PartnerId", "PartnerLocation", "PartnerLogo", "PartnerUrl", "LandingPage"].includes(currentView)}
      >
        <Typography>{values.name}</Typography>
        {isLocationSelected && (
          <Typography>
            <CountryNameAndFlag width={16} countryCode={values.country} />
          </Typography>
        )}
        <Typography>{values.partnerId}</Typography>
        <Typography>{values?.returnUrl}</Typography>
        {values?.showLandingPage && <Typography>{values?.showLandingPage ? "Show Landing Page" : "Don't Show Landing Page"}</Typography>}
      </Stepper>
      <Stepper title="Address" checked={getStageIndex(currentView) > getStageIndex("Address")} isCurrentView={currentView === "Address"}>
        {values.address?.addressLine1} <br />
        {values.address?.addressLine2 && (
          <>
            {values.address?.addressLine2} <br />
          </>
        )}
        {values.address?.postcode} <br />
        {values.address?.city}
      </Stepper>
      <Stepper
        title="Fee rules"
        checked={getStageIndex(currentView) > getStageIndex("UpperCreditLimit")}
        isCurrentView={["UpperCreditLimit", "FeeSubscriptions", "FeeSettings"].includes(currentView)}
      >
        <Typography>
          {selectedCurrency} ({currencySymbol})
        </Typography>
        {selectedCurrency && (
          <Typography>
            {"\u2265"} {new Money(values.limits.global?.amount || 0, selectedCurrency).format()}
          </Typography>
        )}
        {getStageIndex(currentView) > getStageIndex("FeeSettings") && (
          <>
            <Typography>Max: {feeSettings?.max}%</Typography>
            <Typography>Default: {feeSettings?.default}%</Typography>
          </>
        )}
      </Stepper>
      <Stepper title="Agreements / T&Cs" checked={getStageIndex(currentView) > getStageIndex("TermsAndConditions")} isCurrentView={currentView === "TermsAndConditions"}>
        <Typography>Invoice Terms: {values.defaultConfiguration.invoiceAssignmentTerms?.link}</Typography>
        <Typography>T&C's: {values.defaultConfiguration.termsAndConditions.link}</Typography>
      </Stepper>
      <Stepper title="KYB status" checked={getStageIndex(currentView) > getStageIndex("KybStatus")} isCurrentView={currentView === "KybStatus"}>
        Set to <strong>{enumToNiceString(values.kybStatus).toUpperCase()}</strong>
      </Stepper>
      <Stepper title="Confirm" checked={getStageIndex(currentView) > getStageIndex("Confirmation")} isCurrentView={currentView === "Success"}>
        Completed
      </Stepper>
    </>
  );
};

export default StepperView;
