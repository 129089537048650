import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { TextField, TextFieldProps } from "mui-rff";
import { handleWheel } from "utils/inputs";

export const PercentageTextField = (props: TextFieldProps) => (
  <TextField
    InputProps={{
      endAdornment: <>%</>,
    }}
    placeholder="0"
    {...props}
    inputProps={{ min: "0", max: "100", step: 0.01 }}
    autoComplete="off"
  />
);

export const PercentageTextFieldMui = ({ InputProps, ...props }: MuiTextFieldProps) => (
  <MuiTextField
    fullWidth
    InputProps={{
      endAdornment: <>%</>,
      ...InputProps,
    }}
    placeholder="0"
    {...props}
    type="number"
    onWheel={handleWheel}
    inputProps={{ min: "0", max: "100", step: 0.00001 }}
    autoComplete="off"
  />
);
