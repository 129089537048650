import { ApolloClient, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { useState } from "react";

const createClient = (): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });
};

const useApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  const [client] = useState<ApolloClient<NormalizedCacheObject>>(() => createClient());

  return client;
};

export default useApolloClient;
