import { Money, MoneyStorage } from "@monet-money/money-type";
import { ReactComponent as ChevronRight } from "assets/tailwind/icons/chevron-right.svg";
import MonetButton from "components/tailwind/MonetButton";
import MonetCardTitle from "components/tailwind/MonetCardTitle";
import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { FullPayout } from "shared/types/eep.contract.types";
import { DATETIME_STRING_FORMAT } from "utils/date";
import { enumToNiceString } from "utils/strings";
import { PAYOUTS_PATH, PAYOUT_DETAILS } from "views/payouts/PayoutPath";

type PayoutDoneStepProps = {
  payoutType: "WITHDRAWAL" | "VENDOR";
  payout?: FullPayout;
};

const PayoutDoneStep: React.FC<PayoutDoneStepProps> = ({ payout, payoutType }) => {
  const navigate = useNavigate();

  const renderPayoutPayeeAlias = useMemo(() => {
    return <span className="text-sm font-semibold">{payout?.payee.payeeAlias}</span>;
  }, [payout]);

  const renderPayoutValue = useMemo(() => {
    return <span className="text-sm font-semibold">{Money.fromStorageType(payout?.feeSettings.earlyPayTotal as MoneyStorage).format(true, true)}</span>;
  }, [payout]);

  const renderPayoutExpiry = useMemo(() => {
    return <span className="text-sm font-semibold">{dayjs(payout?.expiryDate).format(DATETIME_STRING_FORMAT)}</span>;
  }, [payout]);

  const handleViewPayout = useCallback(() => {
    navigate(
      `${PAYOUTS_PATH}${PAYOUT_DETAILS}?` +
        createSearchParams({
          payoutId: payout?.payoutId as string,
        }).toString(),
    );
  }, [payout]);

  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <MonetCardTitle>Payout created</MonetCardTitle>
      {payoutType === "VENDOR" && (
        <p className="text-sm text-center">
          An email has been sent to {renderPayoutPayeeAlias} so they can claim the payout of {renderPayoutValue}. This payout will expire on {renderPayoutExpiry}
        </p>
      )}
      {payoutType === "WITHDRAWAL" && (
        <p className="text-sm text-center">
          Withdrawal payout has been created for bank account {renderPayoutPayeeAlias} with a total value of {renderPayoutValue}. Please allow 24 hours for payout to be processed
        </p>
      )}
      <div className="flex flex-col sm:flex-row w-full gap-4">
        <MonetButton variant="outlined" size="x-small" onClick={() => location.reload()} className="flex-1">
          Create another {enumToNiceString(payoutType).toLowerCase()} payout
        </MonetButton>
        <MonetButton variant="solid" size="x-small" onClick={() => handleViewPayout()} className="flex-1">
          View payout
          <ChevronRight />
        </MonetButton>
      </div>
    </div>
  );
};

export default PayoutDoneStep;
