import { MenuItem } from "@headlessui/react";
import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";
import { ButtonHTMLAttributes, PropsWithChildren, useCallback } from "react";
import { twMerge } from "tailwind-merge";

type MonetDropdownMenuItemProp = PropsWithChildren &
  ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof variants> & {
    onClick?(): void;
    icon?: React.ReactNode;
    subtext?: string;
    closeMenu?: boolean;
  };

const MonetDropdownMenuItem: React.FC<MonetDropdownMenuItemProp> = ({ icon, onClick, children, variant, subtext, className, disabled, closeMenu = true, ...props }) => {
  const handleOnClick = useCallback(
    (close: () => void) => {
      closeMenu && close();
      onClick && onClick();
    },
    [closeMenu],
  );

  return (
    <MenuItem>
      {({ close }) => (
        <button
          {...props}
          className={twMerge(clsx(variants({ variant }), className))}
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            handleOnClick(close);
          }}
        >
          <div className="flex w-full gap-x-2 items-center [&>svg]:w-[18px] [&>svg]:h-[18px]">
            {icon}
            {children}
          </div>
          {subtext && <span className="block text-sm text-gray-500 dark:text-neutral-500">{subtext}</span>}
        </button>
      )}
    </MenuItem>
  );
};

export default MonetDropdownMenuItem;

const variants = cva(
  "flex flex-col w-full items-start py-2 px-5 rounded-lg text-sm focus:outline-none disabled:text-gray-600 disabled:opacity-50 disabled:pointer-events-none text-left",
  {
    variants: {
      variant: {
        info: "text-monet-blue hover:bg-blue-100 focus:bg-blue-100 dark:text-white dark:hover:bg-neutral-700",
        danger: "text-red-800 hover:bg-red-100 focus:bg-red-100 dark:text-white dark:hover:bg-neutral-700",
        text: "text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700",
      },
    },
    defaultVariants: {
      variant: "text",
    },
  },
);
