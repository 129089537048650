import { HSPinInput } from "preline/preline";
import { useEffect } from "react";

const MonetPinInput: React.FC = () => {
  useEffect(() => {
    HSPinInput.autoInit();
  }, []);

  return (
    <div
      className="flex gap-1 sm:gap-x-3"
      id="pin-input"
      data-hs-pin-input='{
            "availableCharsRE": "^[0-9]+$"
            }'
    >
      <input
        type="text"
        className="block w-[38px] h-[38px] sm:w-[48px] sm:h-[48px] text-center border-gray-200 rounded-md text-base sm:text-sm [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
        data-hs-pin-input-item
        autoComplete="one-time-code"
        autoFocus
      />
      <input
        type="text"
        className="block w-[38px] h-[38px] sm:w-[48px] sm:h-[48px] text-center border-gray-200 rounded-md text-base sm:text-sm [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
        data-hs-pin-input-item
      />
      <input
        type="text"
        className="block w-[38px] h-[38px] sm:w-[48px] sm:h-[48px] text-center border-gray-200 rounded-md text-base sm:text-sm [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
        data-hs-pin-input-item
      />
      <input
        type="text"
        className="block w-[38px] h-[38px] sm:w-[48px] sm:h-[48px] text-center border-gray-200 rounded-md text-base sm:text-sm [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
        data-hs-pin-input-item
      />
      <input
        type="text"
        className="block w-[38px] h-[38px] sm:w-[48px] sm:h-[48px] text-center border-gray-200 rounded-md text-base sm:text-sm [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
        data-hs-pin-input-item
      />
      <input
        type="text"
        className="block w-[38px] h-[38px] sm:w-[48px] sm:h-[48px] text-center border-gray-200 rounded-md text-base sm:text-sm [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
        data-hs-pin-input-item
      />
    </div>
  );
};

export default MonetPinInput;
