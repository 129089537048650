import { Money, MoneyStorage, formatStorageType } from "@monet-money/money-type";
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams } from "@mui/x-data-grid";
import { ReactComponent as DeleteIcon } from "assets/tailwind/icons/trash-2.svg";
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import MonetButton from "components/tailwind/MonetButton";
import MonetDropdownMenuItem from "components/tailwind/MonetDropdownMenuItem";
import MonetMenuDropdown from "components/tailwind/MonetMenuDropdown";
import SignedUrl from "components/tailwind/SignedUrl";
import StatusBadge from "components/tailwind/StatusBadge";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import dayjs from "dayjs";
import React, { useCallback, useState } from "react";
import { Campaign, CampaignDeliverable, FullPayout } from "shared/types/eep.contract.types";
import { invoiceFundingStatuses, invoiceStatuses } from "shared/utils/invoice";
import { Invoice } from "utils/Invoice";
import { isInAdminContext, isInPartnerContext } from "utils/User";
import { DEFAULT_DATE_FORMAT } from "utils/date";
import DeleteInvoiceModal from "./DeleteInvoiceModal";
import DeliverablesModal from "./DeliverablesModal";
import SettleInvoiceDrawer from "./SettleInvoiceDrawer";
import UpdateInvoiceDrawer from "./UpdateInvoiceDrawer";
import ViewInvoiceSettlementDrawer from "./ViewInvoiceSettlementDrawer";
import RequestInvoiceFundingModal from "./requestFunding/RequestInvoiceFundingModal";
import UnderwriteInvoiceDrawer from "./requestFunding/UnderwriteInvoiceDrawer";
import ViewFundingRequestDrawer from "./requestFunding/ViewFundingRequestDrawer";

type InvoiceTableProps = {
  invoices: Invoice[];
  refetchCampaign(): void;
  refetchInvoices(): void;
  refetchPayouts(): void;
  payouts?: FullPayout[];
  campaign?: Campaign;
  isLoading?: boolean;
};

const InvoiceTable: React.FC<InvoiceTableProps> = ({ invoices, isLoading, refetchInvoices, campaign, refetchCampaign, refetchPayouts, payouts }) => {
  const { user } = useSessionProvider();

  const { openModal } = useModal();

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    actions: isInPartnerContext(user),
    adminActions: isInAdminContext(user),
    requestFunding: isInPartnerContext(user),
  });

  const getDaysUntilDue = (dueDate: string, invoiceStatus: string): number => {
    const daysRemaining = dayjs(dueDate).diff(dayjs(), "days");
    if (daysRemaining < 0 && invoiceStatus === "SETTLED") {
      return 0;
    } else return daysRemaining;
  };

  const invoiceDeliverables = (deliverableIds: number[]) => {
    if (campaign) {
      return deliverableIds.reduce((result: CampaignDeliverable[], deliverableId) => {
        const deliverable = campaign.deliverables?.find((deliverable) => deliverable.deliverableId === deliverableId);
        if (deliverable) {
          result.push(deliverable);
        }
        return result;
      }, []);
    } else return undefined;
  };

  const isActiveSettledFunding = useCallback((invoice: Invoice) => {
    if (invoice.funding) {
      return ["ACTIVE", "SETTLED"].includes(invoice.funding?.fundingStatus);
    } else return false;
  }, []);

  const isPendingFundingRequest = useCallback((invoice: Invoice) => {
    if (invoice.funding) {
      return ["SUBMITTED"].includes(invoice.funding?.fundingStatus);
    } else return false;
  }, []);

  const isActiveFundingRequest = useCallback((invoice: Invoice) => {
    if (invoice.funding) {
      return ["ACTIVE"].includes(invoice.funding?.fundingStatus);
    } else return false;
  }, []);

  const isFunding = useCallback((invoice: Invoice) => {
    if (invoice.funding) return true;
    return false;
  }, []);

  const isSettled = useCallback((invoice: Invoice) => {
    return invoice.invoiceStatus === "SETTLED";
  }, []);

  const columns: GridColDef[] = [
    {
      type: "actions",
      field: "actions",
      width: 50,
      renderCell: (params: GridRenderCellParams<any, Invoice, any>) => {
        return (
          <MonetMenuDropdown
            menuButtonProps={{
              variant: "white",
            }}
            dropdownPosition="bottom start"
            footerChildren={
              !isActiveSettledFunding(params.row) && (
                <MonetDropdownMenuItem
                  variant="danger"
                  icon={<DeleteIcon />}
                  onClick={() => openModal(DeleteInvoiceModal, { invoice: params.row, refetchInvoices: refetchInvoices })}
                >
                  Delete invoice
                </MonetDropdownMenuItem>
              )
            }
          >
            {!isActiveSettledFunding(params.row) && (
              <MonetDropdownMenuItem
                onClick={() =>
                  openModal(UpdateInvoiceDrawer, {
                    invoice: params.row,
                    campaign: campaign,
                    refetchInvoices: refetchInvoices,
                  })
                }
              >
                Edit invoice
              </MonetDropdownMenuItem>
            )}
            {isFunding(params.row) && (
              <MonetDropdownMenuItem onClick={() => openModal(ViewFundingRequestDrawer, { invoice: params.row })}>View funding request</MonetDropdownMenuItem>
            )}
            {isSettled(params.row) && (
              <MonetDropdownMenuItem onClick={() => openModal(ViewInvoiceSettlementDrawer, { invoice: params.row })}>View settlement</MonetDropdownMenuItem>
            )}
          </MonetMenuDropdown>
        );
      },
    },
    {
      type: "actions",
      field: "adminActions",
      minWidth: 30,
      renderCell: (params: GridRenderCellParams<any, Invoice, any>) => {
        return (
          <MonetMenuDropdown
            menuButtonProps={{
              variant: "white",
            }}
            dropdownPosition="bottom start"
          >
            {isPendingFundingRequest(params.row) && (
              <MonetDropdownMenuItem
                onClick={() =>
                  openModal(UnderwriteInvoiceDrawer, {
                    invoice: params.row,
                    campaign: campaign,
                    invoices: invoices,
                    refetchCampaign: refetchCampaign,
                    refetchInvoices: refetchInvoices,
                  })
                }
              >
                Underwrite invoice
              </MonetDropdownMenuItem>
            )}
            {isFunding(params.row) && (
              <>
                <MonetDropdownMenuItem onClick={() => openModal(ViewFundingRequestDrawer, { invoice: params.row })}>View Funding request</MonetDropdownMenuItem>
                {!isSettled(params.row) && isActiveFundingRequest(params.row) && (
                  <MonetDropdownMenuItem
                    onClick={() =>
                      openModal(SettleInvoiceDrawer, {
                        invoice: params.row,
                        payouts: payouts,
                        refetchInvoices: refetchInvoices,
                        refetchPayouts: refetchPayouts,
                        refetchCampaign: refetchCampaign,
                      })
                    }
                  >
                    Settle invoice
                  </MonetDropdownMenuItem>
                )}
              </>
            )}
            {isSettled(params.row) && (
              <MonetDropdownMenuItem onClick={() => openModal(ViewInvoiceSettlementDrawer, { invoice: params.row })}>View settlement</MonetDropdownMenuItem>
            )}
          </MonetMenuDropdown>
        );
      },
    },
    {
      type: "actions",
      field: "requestFunding",
      minWidth: 150,
      renderCell: (params: GridRenderCellParams<any, Invoice, any>) => {
        return (
          <MonetButton
            size="x-small"
            variant="outlined"
            onClick={() => openModal(RequestInvoiceFundingModal, { invoice: params.row, campaign: campaign, refetchInvoices: refetchInvoices })}
            disabled={isActiveSettledFunding(params.row) || isPendingFundingRequest(params.row)}
            tooltip={isActiveSettledFunding(params.row) || isPendingFundingRequest(params.row) ? "Funding has already been requested for this invoice" : undefined}
          >
            Request funding
          </MonetButton>
        );
      },
    },
    {
      field: "invoiceId",
      headerName: "Monet invoice ID",
      type: "string",
      minWidth: 125,
      flex: 1,
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
      type: "string",
      minWidth: 125,
      flex: 1,
    },
    {
      field: "invoiceStatus",
      headerName: "Invoice status",
      type: "singleSelect",
      valueOptions: invoiceStatuses,
      minWidth: 125,
      flex: 1,
      renderCell: (params: GridRenderCellParams<Invoice>) => {
        return <StatusBadge type="invoice" text={params.row.invoiceStatus} />;
      },
    },
    {
      field: "funding.fundingStatus",
      headerName: "Funding status",
      type: "singleSelect",
      valueOptions: invoiceFundingStatuses,
      minWidth: 125,
      flex: 1,
      renderCell: (params: GridRenderCellParams<Invoice>) => {
        return params.row.funding ? <StatusBadge type="invoice" text={params.row.funding?.fundingStatus} /> : "-";
      },
    },
    {
      field: "invoiceValue",
      headerName: "Invoice value",
      type: "number",
      minWidth: 125,
      flex: 1,
      valueGetter: (value, row) => {
        return Number(Money.fromStorageType(value as MoneyStorage).format(false, false));
      },
      renderCell: (params: GridRenderCellParams<Invoice>) => {
        return formatStorageType(params.row.invoiceValue as MoneyStorage);
      },
    },
    {
      field: "funding.principalAmount",
      headerName: "Funding limit",
      type: "number",
      minWidth: 125,
      flex: 1,
      valueGetter: (value, row) => {
        return Number(Money.fromStorageType(row.funding?.principalAmount as MoneyStorage).format(false, false));
      },
      renderCell: (params: GridRenderCellParams<Invoice>) => {
        return formatStorageType(params.row.funding?.principalAmount as MoneyStorage);
      },
    },
    {
      field: "paymentTerms",
      headerName: "Payment terms",
      type: "number",
      minWidth: 125,
      valueFormatter: (value, row) => (value ? `${value} days` : "-"),
      flex: 1,
    },
    {
      field: "dueDate",
      headerName: "Due date",
      type: "date",
      minWidth: 125,
      flex: 1,
      valueFormatter: (value, row) => (value ? dayjs(value).format(DEFAULT_DATE_FORMAT) : "N/A"),
    },
    {
      field: "daysUntilDue",
      headerName: "Days until due",
      type: "number",
      minWidth: 125,
      flex: 1,
      renderCell: (params: GridRenderCellParams<Invoice>) => {
        return getDaysUntilDue(params.row.dueDate, params.row.invoiceStatus);
      },
    },
    {
      field: "deliverables",
      type: "actions",
      headerName: "Deliverables",
      minWidth: 100,
      flex: 1,
      renderCell: (params: GridRenderCellParams<Invoice>) => <DeliverablesModal invoiceDeliverables={invoiceDeliverables(params.row.deliverables)} />,
    },
    {
      field: "invoiceDocument",
      headerName: "Invoice document",
      minWidth: 400,
      type: "string",
      flex: 1,
      renderCell: (params: GridRenderCellParams<Invoice>) => <SignedUrl url={params.row.invoiceDocument} />,
    },
    {
      field: "createdAt",
      headerName: "Created date",
      type: "string",
      minWidth: 125,
      flex: 1,
      valueFormatter: (value, row) => dayjs(value).format(DEFAULT_DATE_FORMAT),
    },
  ];

  return (
    <CustomDataGrid
      isDataLoading={isLoading}
      rows={invoices}
      columns={columns}
      enableExport={false}
      showAsUnstyledCard={true}
      initialState={{
        sorting: {
          sortModel: [{ field: "dueDate", sort: "desc" }],
        },
      }}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
      getRowId={(row: Invoice) => row.invoiceId}
      sx={{
        height: 300,
      }}
    />
  );
};

export default InvoiceTable;
