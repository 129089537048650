import ViewLayout from "components/tailwind/templates/ViewLayout";
import { useSessionProvider } from "contexts/SessionProvider";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PAGE_NOT_FOUND_PATH } from "routing/paths";
import { isInAdminContext } from "utils/User";
import { ORGANISATION_PROFILE_PATH } from "./OrganisationPath";
import OrganisationProfile from "./Profile/OrganisationProfile";

const OrganisationRoutes: React.FC = () => {
  const { partner, user } = useSessionProvider();

  if (!partner || isInAdminContext(user)) return <Navigate to={PAGE_NOT_FOUND_PATH} />;

  return (
    <ViewLayout helmet="Organisation">
      <Routes>
        <Route path={ORGANISATION_PROFILE_PATH} element={<OrganisationProfile />} />
      </Routes>
    </ViewLayout>
  );
};

export default OrganisationRoutes;
