import React, { useCallback, useMemo, useState } from "react";
import { toast } from "sonner";
import { EP3User } from "utils/User";
import { useDeleteUserMutation } from "views/organisation/graphql/mutations.generated";
import MonetInput from "./form/MonetInput";
import TailwindDialog from "./headlessTailwind/TailwindDialog";

type DeleteAccountModalProps = {
  onSuccessCallback(): void;
  user?: EP3User;
};

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({ user, onSuccessCallback }) => {
  const [deleteUser] = useDeleteUserMutation({ fetchPolicy: "network-only" });
  const [userEmailConfirmation, setUserEmailConfirmation] = useState<string>();

  const handleDelete = useCallback((): Promise<boolean> => {
    return new Promise((resolve) => {
      if (user) {
        deleteUser({
          variables: {
            username: user.email,
          },
        })
          .then(() => {
            onSuccessCallback();
            toast.success("Success", { description: "Account has been deleted" });
          })
          .catch((err) => {
            toast.error("Request failed", { description: `Account could not be deleted. ${err.message}` });
          })
          .finally(() => {
            resolve(true);
          });
      }
    });
  }, [user]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserEmailConfirmation(event.target.value);
  };

  const emailConfirmationMatch = useMemo(() => {
    if (user) {
      return userEmailConfirmation !== user.email;
    } else return true;
  }, [user, userEmailConfirmation]);

  return (
    <TailwindDialog type="danger" title="Delete Account" onConfirmCallback={handleDelete} submitButtonColor="red-solid" submitDisabled={emailConfirmationMatch}>
      <div className="flex flex-col gap-4">
        <p>
          Are you sure? You will be permanently deleting account <span className="font-semibold">{user && user.email}</span>. This action can not be undone
        </p>
        <MonetInput placeholder="Please enter this users email to confirm deletion..." onChange={handleChange} />
      </div>
    </TailwindDialog>
  );
};

export default DeleteAccountModal;
