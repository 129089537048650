import ViewLayout from "components/tailwind/templates/ViewLayout";
import { PayoutProvider } from "contexts/PayoutProvider";
import React from "react";
import { Route, Routes } from "react-router-dom";
import OnboardingRequiredRoute from "routing/OnboardingRequiredRoute";
import { PAYOUT_CREATE, PAYOUT_DETAILS, PAYOUT_LIST_ALL_PATH, PAYOUT_VENDOR_PATH, PAYOUT_WITHDRAWAL_PATH } from "./PayoutPath";
import CreatePayout from "./createPayout/CreatePayout";
import ListPayouts from "./listPayouts/ListPayouts";
import PayoutDetails from "./payoutDetails/PayoutDetails";

const PayoutRoutes: React.FC = () => {
  return (
    <ViewLayout helmet="Payouts">
      <PayoutProvider>
        <Routes>
          <Route element={<OnboardingRequiredRoute redirectPath={PAYOUT_LIST_ALL_PATH} />}>
            <Route path={PAYOUT_CREATE + PAYOUT_WITHDRAWAL_PATH} element={<CreatePayout payoutType="WITHDRAWAL" />} />
            <Route path={PAYOUT_CREATE + PAYOUT_VENDOR_PATH} element={<CreatePayout payoutType="VENDOR" />} />
            <Route path={PAYOUT_LIST_ALL_PATH} element={<ListPayouts />} />
            <Route path={PAYOUT_DETAILS} element={<PayoutDetails />} />
          </Route>
        </Routes>
      </PayoutProvider>
    </ViewLayout>
  );
};

export default PayoutRoutes;
