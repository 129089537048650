import { Percentage } from "@monet-money/percentage-type";
import { countries } from "countries-list";
import { CurrencyWithMargin } from "shared/types/currency";
import { Country } from "./country";

export type CurrencyWithRates = {
  currency: string;
  oldBaseRate: number;
  baseRate: number;
  updatedAt?: string;
  updatedBy?: string;
};

const countriesList: Country = countries;

export const getCurrencies = Object.keys(countriesList)
  .filter((item) => ["GB", "AU", "US", "BE", "CA"].includes(item))
  .map((item) => {
    const countryCode = item === "BE" ? "EU" : item;
    const countryName = item === "BE" ? "European Union" : countriesList[item].name;
    return {
      currency: countriesList[item].currency.split(",")[0],
      name: countryName,
      flag: countriesList[item].emoji,
      countryCode,
    };
  });

export type SelectedCurrency = {
  currency: string;
  name: string;
  countryCode: string;
  margin?: string;
};

export const getCurrenciesFromCountryCode = (currencies: CurrencyWithMargin[]) => {
  const selectedCurrencies: SelectedCurrency[] = [];
  for (const currency of getCurrencies) {
    const foundCurrency = currencies.some((item) => item.currency === currency.currency);
    if (foundCurrency) {
      const matchedCurrency = currencies.find((item) => item.currency == currency.currency)! as unknown as SelectedCurrency;
      selectedCurrencies.push({ ...currency, ...matchedCurrency });
    }
  }
  return selectedCurrencies;
};

export const formatAllowedCurrenciesBeforeSaving = (allowedCurrencies: CurrencyWithMargin[]) => {
  return allowedCurrencies.map((item) => ({
    ...item,
    margin: new Percentage(item.margin! as number).percentage,
  }));
};

export const getFullCurrencyWithMargin = (allowedCurrencies: CurrencyWithMargin[]) => {
  return allowedCurrencies.map((item) => {
    const fullCurrency = getCurrencies.find((i) => i.currency == item.currency)!;
    return {
      ...fullCurrency,
      margin: item.margin,
    };
  });
};

export const formatAllowedCurrenciesForForm = (currencies: any[], marginAsNumber = false) => {
  return (currencies || []).map((item) => {
    if (typeof item == "string") {
      return { currency: item as Components.Schemas.Currency, margin: "n/a" };
    }
    const margin = Percentage.fromStorageValue(item.margin).format(marginAsNumber);

    return {
      currency: (item as CurrencyWithMargin).currency as Components.Schemas.Currency,
      margin,
    };
  });
};

export const getCurrencyReferenceName = (currency: Components.Schemas.Currency) => {
  switch (currency) {
    case "AUD":
      return "Australian Inter Bank Rate";

    case "GBP":
      return "Sonia";

    case "EUR":
      return "Euribor";

    case "USD":
      return "Sofr";

    case "CAD":
      return "Corra";

    default:
      return "n/a";
  }
};
