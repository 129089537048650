import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";
import { HSTooltip } from "preline/preline";
import { ButtonHTMLAttributes, PropsWithChildren, useEffect, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import MonetLoadingSpinner from "./MonetLoadingSpinner";

export type MonetButtonProps = PropsWithChildren &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, "color"> &
  VariantProps<typeof variants> & {
    loading?: boolean;
    tooltip?: string;
  };

const MonetButton: React.FC<MonetButtonProps> = ({ children, variant, size, className, loading, color, tooltip, ...props }) => {
  useEffect(() => {
    HSTooltip.autoInit();
  }, []);

  const buttonWithTooltip = useMemo(() => {
    if (!tooltip) return null;
    return (
      <div className="hs-tooltip inline-block [--placement:bottom-left]">
        <button type="button" className={twMerge(clsx(variants({ variant, size, color }), className))} {...props}>
          {loading && <MonetLoadingSpinner size="small" />}
          {children}
        </button>
        {tooltip && (
          <div
            className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 inline-block absolute invisible z-50 w-fit max-w-[250px] py-1.5 px-2.5 bg-gray-900 text-xs text-left text-white rounded dark:bg-neutral-700 whitespace-break-spaces"
            role="tooltip"
          >
            {tooltip}
          </div>
        )}
      </div>
    );
  }, [tooltip]);

  return (
    buttonWithTooltip ?? (
      <button type="button" className={twMerge(clsx(variants({ variant, size, color }), className))} {...props}>
        {loading && <MonetLoadingSpinner size="small" />}
        {children}
      </button>
    )
  );
};

export default MonetButton;

const variants = cva(
  "text-sm font-semibold inline-flex justify-center items-center gap-x-1 rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none",
  {
    variants: {
      variant: {
        solid: "bg-monet-blue text-white hover:bg-blue-800 border border-transparent disabled:bg-gray-200 disabled:text-gray-600",
        outlined:
          "border border-monet-blue text-monet-blue hover:border-blue-800 hover:text-blue-800 disabled:text-gray-600 disabled:border-gray-600 dark:hover:text-neutral-300 dark:border-white dark:text-white",
        link: "text-monet-blue hover:underline hover:decoration-solid dark:text-white dark:disabled:text-gray-300",
        white:
          "border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800",
      },
      size: {
        "fit-content": "py-0 px-0",
        "x-small": "p-1.5 [&>svg]:w-[20px] [&>svg]:h-[20px]",
        small: "py-2 px-3.5",
        default: "py-3.5 px-4",
        large: "py-6 px-5",
      },
      color: {
        "green-solid": "bg-teal-500 text-white hover:bg-teal-600 border border-transparent disabled:bg-gray-200 disabled:text-gray-600",
        "red-solid": "bg-red-500 text-white hover:bg-red-600 border border-transparent disabled:bg-gray-200 disabled:text-gray-600",
        "gray-outlined": "text-gray-500 border border-gray-500 hover:text-gray-500 hover:border-gray-500 hover:bg-gray-500/20 disabled:border-gray-700 disabled:text-gray-700",
      },
    },
    defaultVariants: {
      variant: "solid",
      size: "default",
      color: undefined,
    },
  },
);
