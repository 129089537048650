import { ApolloLink, HttpLink } from "@apollo/client";
import { fetchAuthSession } from "aws-amplify/auth";
import { AUTH_TYPE } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { UrlInfo } from "aws-appsync-subscription-link/lib/types";

const statusToPathMap: { [key: number]: string } = {
  403: "/403",
  404: "/404",
  500: "/500",
};

const createApolloLink = (partnerId: string): ApolloLink => {
  const getCurrentJwtToken = async (): Promise<string> => {
    return (await fetchAuthSession()).tokens!.idToken!.toString();
  };

  const customFetch: WindowOrWorkerGlobalScope["fetch"] = async (uri, options) => {
    const token = await getCurrentJwtToken();

    const data = {
      token,
      partnerId,
    };

    const base64data = Buffer.from(JSON.stringify(data)).toString("base64");

    let opts = { ...options };

    if (token) {
      const authorizationObject = {
        Authorization: base64data,
      };

      if (opts) {
        opts.headers = { ...opts.headers, ...authorizationObject };
      } else {
        opts = { headers: authorizationObject };
      }
    }
    return fetch(uri, opts).then(async (response) => {
      // Read response text to check for custom error messages
      const responseText = await response.text();
      const parsedResponse = JSON.parse(responseText);

      // If custom error message is not the one to handle, perform redirection
      if (!parsedResponse.errors || !parsedResponse.errors.some((err: any) => err.message === "No active subscription found. You can only view existing data")) {
        const redirectPath = statusToPathMap[response.status];
        if (redirectPath && window.location.pathname !== redirectPath) {
          window.location.href = redirectPath;
        }
      }

      // Re-parse and return the response text for Apollo Client to handle
      return new Response(responseText);
    });
  };

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_AWS_APPSYNC_URL,
    fetch: customFetch,
    useGETForQueries: true,
  });

  const config: UrlInfo = {
    url: process.env.REACT_APP_AWS_APPSYNC_URL,
    region: process.env.REACT_APP_AWS_REGION,
    auth: {
      type: AUTH_TYPE.AWS_LAMBDA,
      token: "",
    },
  };

  return createSubscriptionHandshakeLink(config, httpLink);
};

export default createApolloLink;
