export const generateCampaignId = (campaignName: string, dueDate?: string): string => {
  let campaignId = "";
  let newDueDate = dueDate;

  if (!newDueDate) {
    const currentDate = new Date();
    newDueDate = [currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate()].join("");
  }

  const campaignNameArray = campaignName
    .split(" ")
    // remove special characters
    .map((x) => x.replace(/[^a-zA-Z0-9]/g, ""))
    // Filter out spaces i.e. ""
    .filter((element) => element !== "");
  const randomNumber = Math.floor(Math.random() * 99999)
    .toString()
    .padStart(5, "0");
  const tempName = ["-", newDueDate, "-", randomNumber].join("");
  if (campaignNameArray.length == 1) {
    campaignId = [campaignName.substring(0, 3), tempName].join("");
  } else {
    campaignId = campaignNameArray.map((item) => item[0].charAt(0)).join("") + tempName;
  }

  return campaignId.toUpperCase();
};

export const UNSETTLED_PAYOUT_STATUSES = ["CREATED", "BENEFICIARY_ACCEPTED", "MONET_ACCEPTED", "PAYMENT_PROCESSING"];
export const UNSUPPORTED_FILE_TYPE_ERROR_MESSAGE = "Un-supported file type. Only pdf, txt, doc, docx, png, jpeg and jpg are allowed";
