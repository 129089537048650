import { Money } from "@monet-money/money-type";
import CompleteProfileBanner from "components/onboarding/CompleteProfileBanner";
import MonetMetric from "components/tailwind/MonetMetric";
import MonetViewTitle from "components/tailwind/MonetViewTitle";
import ViewLayout from "components/tailwind/templates/ViewLayout";
import { useEffect, useState } from "react";
import { MetricsData } from "utils/Aggregation";
import DashboardTables from "./components/DashboardTables";
import { useGetDashboardMetricsQuery } from "./graphql/queries.generated";

const Dashboard = () => {
  const [metrics, setMetrics] = useState<MetricsData>({
    fundingBalance: new Money(0),
    fundingAvailable: new Money(0),
    activeCampaigns: undefined,
    overdueInvoices: undefined,
  });
  const { data, loading } = useGetDashboardMetricsQuery({ fetchPolicy: "network-only" });

  useEffect(() => {
    const metrics = data?.getDashboardMetrics as MetricsData;

    if (metrics) {
      setMetrics(metrics);
    }
  }, [data]);

  return (
    <ViewLayout helmet="Dashboard">
      <CompleteProfileBanner />
      <MonetViewTitle>Dashboard</MonetViewTitle>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-6">
        <MonetMetric
          loading={loading}
          label="Funding balance"
          tooltip="The total value of approved funding across all campaigns. This value is an estimate, no currency conversion has been applied"
          value={Money.fromStorageType(metrics.fundingBalance).format(true, true)}
        />
        <MonetMetric
          loading={loading}
          label="Available funding"
          tooltip="The amount of funding that is available to withdraw or payout to vendors and talent across all campaigns. This value is an estimate, no currency conversion has been applied"
          value={Money.fromStorageType(metrics.fundingAvailable).format(true, true)}
        />
        <MonetMetric loading={loading} label="Active campaigns" tooltip="The total number of active campaigns" value={metrics.activeCampaigns} />
        <MonetMetric loading={loading} label="Overdue invoices" tooltip="The total number of overdue invoices that have not been paid" value={metrics.overdueInvoices} />
      </div>
      <DashboardTables />
    </ViewLayout>
  );
};

export default Dashboard;
