import * as Types from 'shared/generated/ep3.graphql.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePartnerMutationVariables = Types.Exact<{
  partner: Types.Scalars['AWSJSON']['input'];
}>;


export type CreatePartnerMutation = { __typename?: 'Mutation', createPartner: any };

export type UpdatePartnerMutationVariables = Types.Exact<{
  partner: Types.Scalars['AWSJSON']['input'];
}>;


export type UpdatePartnerMutation = { __typename?: 'Mutation', updatePartner: any };

export type DeleteUserMutationVariables = Types.Exact<{
  username?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type RemovePartnerMutationVariables = Types.Exact<{
  partnerId: Types.Scalars['String']['input'];
}>;


export type RemovePartnerMutation = { __typename?: 'Mutation', removePartner: boolean };

export type GeneratePartnerDocumentUploadUrlMutationVariables = Types.Exact<{
  category: Types.DocumentCategory;
  fileName: Types.Scalars['String']['input'];
}>;


export type GeneratePartnerDocumentUploadUrlMutation = { __typename?: 'Mutation', generateDocumentUploadUrl: string };

export type RemovePartnerDocumentMutationVariables = Types.Exact<{
  bucketKey: Types.Scalars['String']['input'];
}>;


export type RemovePartnerDocumentMutation = { __typename?: 'Mutation', removePartnerDocument: boolean };


export const CreatePartnerDocument = gql`
    mutation createPartner($partner: AWSJSON!) {
  createPartner(partner: $partner)
}
    `;
export type CreatePartnerMutationFn = Apollo.MutationFunction<CreatePartnerMutation, CreatePartnerMutationVariables>;

/**
 * __useCreatePartnerMutation__
 *
 * To run a mutation, you first call `useCreatePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerMutation, { data, loading, error }] = useCreatePartnerMutation({
 *   variables: {
 *      partner: // value for 'partner'
 *   },
 * });
 */
export function useCreatePartnerMutation(baseOptions?: Apollo.MutationHookOptions<CreatePartnerMutation, CreatePartnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePartnerMutation, CreatePartnerMutationVariables>(CreatePartnerDocument, options);
      }
export type CreatePartnerMutationHookResult = ReturnType<typeof useCreatePartnerMutation>;
export type CreatePartnerMutationResult = Apollo.MutationResult<CreatePartnerMutation>;
export type CreatePartnerMutationOptions = Apollo.BaseMutationOptions<CreatePartnerMutation, CreatePartnerMutationVariables>;
export const UpdatePartnerDocument = gql`
    mutation updatePartner($partner: AWSJSON!) {
  updatePartner(partner: $partner)
}
    `;
export type UpdatePartnerMutationFn = Apollo.MutationFunction<UpdatePartnerMutation, UpdatePartnerMutationVariables>;

/**
 * __useUpdatePartnerMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerMutation, { data, loading, error }] = useUpdatePartnerMutation({
 *   variables: {
 *      partner: // value for 'partner'
 *   },
 * });
 */
export function useUpdatePartnerMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartnerMutation, UpdatePartnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePartnerMutation, UpdatePartnerMutationVariables>(UpdatePartnerDocument, options);
      }
export type UpdatePartnerMutationHookResult = ReturnType<typeof useUpdatePartnerMutation>;
export type UpdatePartnerMutationResult = Apollo.MutationResult<UpdatePartnerMutation>;
export type UpdatePartnerMutationOptions = Apollo.BaseMutationOptions<UpdatePartnerMutation, UpdatePartnerMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($username: String) {
  deleteUser(username: $username)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const RemovePartnerDocument = gql`
    mutation removePartner($partnerId: String!) {
  removePartner(partnerId: $partnerId)
}
    `;
export type RemovePartnerMutationFn = Apollo.MutationFunction<RemovePartnerMutation, RemovePartnerMutationVariables>;

/**
 * __useRemovePartnerMutation__
 *
 * To run a mutation, you first call `useRemovePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePartnerMutation, { data, loading, error }] = useRemovePartnerMutation({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useRemovePartnerMutation(baseOptions?: Apollo.MutationHookOptions<RemovePartnerMutation, RemovePartnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePartnerMutation, RemovePartnerMutationVariables>(RemovePartnerDocument, options);
      }
export type RemovePartnerMutationHookResult = ReturnType<typeof useRemovePartnerMutation>;
export type RemovePartnerMutationResult = Apollo.MutationResult<RemovePartnerMutation>;
export type RemovePartnerMutationOptions = Apollo.BaseMutationOptions<RemovePartnerMutation, RemovePartnerMutationVariables>;
export const GeneratePartnerDocumentUploadUrlDocument = gql`
    mutation generatePartnerDocumentUploadUrl($category: DocumentCategory!, $fileName: String!) {
  generateDocumentUploadUrl(fileName: $fileName, category: $category)
}
    `;
export type GeneratePartnerDocumentUploadUrlMutationFn = Apollo.MutationFunction<GeneratePartnerDocumentUploadUrlMutation, GeneratePartnerDocumentUploadUrlMutationVariables>;

/**
 * __useGeneratePartnerDocumentUploadUrlMutation__
 *
 * To run a mutation, you first call `useGeneratePartnerDocumentUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePartnerDocumentUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePartnerDocumentUploadUrlMutation, { data, loading, error }] = useGeneratePartnerDocumentUploadUrlMutation({
 *   variables: {
 *      category: // value for 'category'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useGeneratePartnerDocumentUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<GeneratePartnerDocumentUploadUrlMutation, GeneratePartnerDocumentUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GeneratePartnerDocumentUploadUrlMutation, GeneratePartnerDocumentUploadUrlMutationVariables>(GeneratePartnerDocumentUploadUrlDocument, options);
      }
export type GeneratePartnerDocumentUploadUrlMutationHookResult = ReturnType<typeof useGeneratePartnerDocumentUploadUrlMutation>;
export type GeneratePartnerDocumentUploadUrlMutationResult = Apollo.MutationResult<GeneratePartnerDocumentUploadUrlMutation>;
export type GeneratePartnerDocumentUploadUrlMutationOptions = Apollo.BaseMutationOptions<GeneratePartnerDocumentUploadUrlMutation, GeneratePartnerDocumentUploadUrlMutationVariables>;
export const RemovePartnerDocumentDocument = gql`
    mutation removePartnerDocument($bucketKey: String!) {
  removePartnerDocument(bucketKey: $bucketKey)
}
    `;
export type RemovePartnerDocumentMutationFn = Apollo.MutationFunction<RemovePartnerDocumentMutation, RemovePartnerDocumentMutationVariables>;

/**
 * __useRemovePartnerDocumentMutation__
 *
 * To run a mutation, you first call `useRemovePartnerDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePartnerDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePartnerDocumentMutation, { data, loading, error }] = useRemovePartnerDocumentMutation({
 *   variables: {
 *      bucketKey: // value for 'bucketKey'
 *   },
 * });
 */
export function useRemovePartnerDocumentMutation(baseOptions?: Apollo.MutationHookOptions<RemovePartnerDocumentMutation, RemovePartnerDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePartnerDocumentMutation, RemovePartnerDocumentMutationVariables>(RemovePartnerDocumentDocument, options);
      }
export type RemovePartnerDocumentMutationHookResult = ReturnType<typeof useRemovePartnerDocumentMutation>;
export type RemovePartnerDocumentMutationResult = Apollo.MutationResult<RemovePartnerDocumentMutation>;
export type RemovePartnerDocumentMutationOptions = Apollo.BaseMutationOptions<RemovePartnerDocumentMutation, RemovePartnerDocumentMutationVariables>;