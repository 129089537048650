import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { useSessionProvider } from "contexts/SessionProvider";
import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useLocation } from "react-router-dom";
import { AUTH_PATH } from "views/auth/AuthPath";
import { ReactComponent as TriangleAlertIcon } from "assets/tailwind/icons/triangle-alert.svg";
import MonetButton from "components/tailwind/MonetButton";

const SystemTimeout = () => {
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const { user, handleSignOut } = useSessionProvider();
  const location = useLocation();

  const handleOnIdle = () => {
    if (user.isAuthenticated && !location.pathname.startsWith(AUTH_PATH)) {
      handleSignOut();
      setShowTimeoutModal(true);
    }
  };

  useIdleTimer({
    timeout: 900000, //15 minutes
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <Dialog transition open={showTimeoutModal} onClose={() => setShowTimeoutModal(false)} className="relative z-50">
      <DialogBackdrop className="fixed inset-0 bg-black/30" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel
          transition
          className="flex flex-col bg-white border shadow-sm max-h-[500px] overflow-auto max-w-[450px] p-4 w-full rounded-lg pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70 transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
        >
          <div className="flex flex-col items-center gap-4">
            <div className="rounded-full p-2 bg-blue-50">
              <div className="rounded-full p-4 bg-blue-100 text-blue-600">
                <TriangleAlertIcon width={24} height={24} />
              </div>
            </div>
            <h3 className="text-2xl font-light text-gray-800 dark:text-white text-center">
              User inactive
            </h3>
            <div className="text-sm text-center w-full">
              You have been logged out due to inactivity
            </div>
            <MonetButton variant="outlined" color="gray-outlined" className="flex-1 w-full" onClick={() => setShowTimeoutModal(false)}>
              Close
            </MonetButton>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default SystemTimeout;