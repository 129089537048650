import { isEmailValid } from "shared/utils/validators";
import { object, SchemaOf, string } from "yup";

export type SignInFormObject = {
  username: string;
  password: string;
};

export const validationSchema = <SchemaOf<SignInFormObject>>object().shape({
  username: string().test("validate-username", "Please provide a valid email address", (value, ctx) => {
    if (!value)
      return ctx.createError({
        type: "required",
        message: "Email is a required field",
        path: `username`,
      });
    if (/\s/.test(value)) {
      return ctx.createError({
        message: "Email cannot contain spaces",
        path: `username`,
      });
    }
    return isEmailValid(value);
  }),
  password: string().required("Password is a required field"),
});
