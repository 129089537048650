import StripePricingTable from "./StripePricingTable";

const StripePricingTableModal: React.FC = () => {
  return (
    <div className="w-full my-auto">
      <div className="flex flex-col gap-2 items-center mb-8">
        <p className="font-semibold text-lg">Platform subscription</p>
        <p className="text-sm text-center max-w-[700px]">
          Get started and select the subscription that works best for you and your business. Enjoy a 14-day free trial with each plan, and if you decide to cancel before the trial
          ends, you will not be charged.
        </p>
      </div>
      <StripePricingTable />
    </div>
  );
};

export default StripePricingTableModal;
