import { getUrl } from "aws-amplify/storage";

export const getSignedUrl = async (downloadUrl: string) => {
  const filePath = decodeURI(downloadUrl).split("amazonaws.com/private/");
  const getUrlResult = await getUrl({
    key: filePath[1],
    options: {
      expiresIn: 36000, //600 minutes
      accessLevel: "private",
    },
  });

  return getUrlResult;
};
