import * as Types from 'shared/generated/ep3.graphql.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCurrencyBaseRatesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCurrencyBaseRatesQuery = { __typename?: 'Query', getCurrencyBaseRates: Array<{ __typename?: 'CurrencyRate', currency: string, baseRate: number, createdAt: string, updatedBy: { __typename?: 'UpdatedBy', firstName: string, lastName: string } }> };

export type GetPartnerFeesQueryVariables = Types.Exact<{
  partnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetPartnerFeesQuery = { __typename?: 'Query', GetPartnerFees: Array<{ __typename?: 'GetPartnerFeesResponse', baseRate: number, margin: number, currency: string }> };


export const GetCurrencyBaseRatesDocument = gql`
    query getCurrencyBaseRates {
  getCurrencyBaseRates {
    currency
    baseRate
    createdAt
    updatedBy {
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetCurrencyBaseRatesQuery__
 *
 * To run a query within a React component, call `useGetCurrencyBaseRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrencyBaseRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrencyBaseRatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrencyBaseRatesQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrencyBaseRatesQuery, GetCurrencyBaseRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrencyBaseRatesQuery, GetCurrencyBaseRatesQueryVariables>(GetCurrencyBaseRatesDocument, options);
      }
export function useGetCurrencyBaseRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrencyBaseRatesQuery, GetCurrencyBaseRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrencyBaseRatesQuery, GetCurrencyBaseRatesQueryVariables>(GetCurrencyBaseRatesDocument, options);
        }
export function useGetCurrencyBaseRatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCurrencyBaseRatesQuery, GetCurrencyBaseRatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrencyBaseRatesQuery, GetCurrencyBaseRatesQueryVariables>(GetCurrencyBaseRatesDocument, options);
        }
export type GetCurrencyBaseRatesQueryHookResult = ReturnType<typeof useGetCurrencyBaseRatesQuery>;
export type GetCurrencyBaseRatesLazyQueryHookResult = ReturnType<typeof useGetCurrencyBaseRatesLazyQuery>;
export type GetCurrencyBaseRatesSuspenseQueryHookResult = ReturnType<typeof useGetCurrencyBaseRatesSuspenseQuery>;
export type GetCurrencyBaseRatesQueryResult = Apollo.QueryResult<GetCurrencyBaseRatesQuery, GetCurrencyBaseRatesQueryVariables>;
export const GetPartnerFeesDocument = gql`
    query GetPartnerFees($partnerId: String) {
  GetPartnerFees(partnerId: $partnerId) {
    baseRate
    margin
    currency
  }
}
    `;

/**
 * __useGetPartnerFeesQuery__
 *
 * To run a query within a React component, call `useGetPartnerFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerFeesQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useGetPartnerFeesQuery(baseOptions?: Apollo.QueryHookOptions<GetPartnerFeesQuery, GetPartnerFeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartnerFeesQuery, GetPartnerFeesQueryVariables>(GetPartnerFeesDocument, options);
      }
export function useGetPartnerFeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerFeesQuery, GetPartnerFeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartnerFeesQuery, GetPartnerFeesQueryVariables>(GetPartnerFeesDocument, options);
        }
export function useGetPartnerFeesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPartnerFeesQuery, GetPartnerFeesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPartnerFeesQuery, GetPartnerFeesQueryVariables>(GetPartnerFeesDocument, options);
        }
export type GetPartnerFeesQueryHookResult = ReturnType<typeof useGetPartnerFeesQuery>;
export type GetPartnerFeesLazyQueryHookResult = ReturnType<typeof useGetPartnerFeesLazyQuery>;
export type GetPartnerFeesSuspenseQueryHookResult = ReturnType<typeof useGetPartnerFeesSuspenseQuery>;
export type GetPartnerFeesQueryResult = Apollo.QueryResult<GetPartnerFeesQuery, GetPartnerFeesQueryVariables>;