import { PropsWithChildren } from "react";
import MonetToolTip from "./MonetTooltip";

type MonetCardTitleProps = PropsWithChildren & {
  tooltip?: string;
};

const MonetCardTitle: React.FC<MonetCardTitleProps> = ({ children, tooltip }) => {
  return (
    <h2 className="text-lg">
      {children}
      {tooltip && <MonetToolTip text={tooltip} />}
    </h2>
  );
};

export default MonetCardTitle;
