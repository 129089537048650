import MonetInput from "components/tailwind/form/MonetInput";
import TailwindSelectInput from "components/tailwind/headlessTailwind/TailwindSelectInput";
import { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BorderlessZoneCode } from "shared/generated/ep3.graphql.types";
import { formatBsbNumber, formatSortCode } from "shared/utils/beneficiaryDetails";
import { borderlessAllFields, borderlessBankAccountTypeList, borderlessCountryCodeFields, borderlessEnumFields, borderlessIsoDateFields } from "shared/utils/borderlessFieldLists";
import { splitCamelCaseString } from "shared/utils/string";
import { PayeeField, RequiredFieldData, getLocalisedFieldName } from "utils/Payouts";
import { useGetZoneCodesLazyQuery } from "views/payouts/graphql/queries.generated";

const getEnumFields = (field: string) => {
  if (field == "bankAccountType") {
    return borderlessBankAccountTypeList;
  }
  return [];
};

const BeneficiaryFormField = ({ field }: { field: string }) => {
  const [zoneCodes, setZoneCodes] = useState<BorderlessZoneCode[]>([]);
  const [getZoneCodes, { data: getZoneCodesData }] = useGetZoneCodesLazyQuery({
    nextFetchPolicy: "network-only",
  });

  const {
    register,
    formState: { errors },
    watch,
    control,
  } = useFormContext();

  const selectedCountry = watch("country");

  const formLabelText = useMemo(() => {
    return getLocalisedFieldName(field, selectedCountry);
  }, [selectedCountry]);

  // Setting beneficiary first name if it is required
  // useEffect(() => {
  //   if (field === "firstname") {
  //     change(getName(field), values?.payee.firstName);
  //   }
  // }, [values?.payee]);

  // Get zone codes
  useEffect(() => {
    if (selectedCountry) {
      getZoneCodes({
        variables: {
          countryCode: selectedCountry,
        },
      });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (getZoneCodesData) {
      setZoneCodes(getZoneCodesData.getZoneCodes);
    }
  }, [getZoneCodesData]);

  const selectedField: RequiredFieldData = {
    name: field,
    placeholder: `Enter ${formLabelText}`,
    label: formLabelText === "Iban" ? "IBAN" : formLabelText,
    subTitle: "",
    options: [],
  };

  if (selectedField) {
    if (borderlessCountryCodeFields.includes(field)) {
      return null;
      // <FormItemRow title={selectedField.label} subTitle={selectedField.subTitle || ""}>
      //   <CountriesField label="" name={selectedField.name} id={selectedField.name} required />
      // </FormItemRow>
    } else if (borderlessEnumFields.includes(field)) {
      let options = getEnumFields(field).map((item: string) => ({ label: splitCamelCaseString(item).replaceAll(/_/g, " "), value: item }));

      if (field == "zoneCode") {
        options = zoneCodes.map((x) => ({ label: x.name, value: x.code }));
      }

      return (
        <Controller
          control={control}
          name={selectedField.name}
          render={({ field: { ...inputProps } }) => (
            <TailwindSelectInput
              inputProps={inputProps}
              placeholder="Please select..."
              error={errors[selectedField.name]?.message as string}
              label={selectedField.label}
              options={options}
            />
          )}
        />
        // <FormItemRow title={selectedField.label} subTitle={selectedField.subTitle || ""} data-testid={`menu-${getName(selectedField.name)}`}>
        //   <Autocomplete
        //     name={getName(selectedField.name)}
        //     disablePortal
        //     fullWidth
        //     getOptionValue={handleOptionValue}
        //     options={options}
        //     renderOption={(props, option) => (
        //       <Box component="li" {...props}>
        //         {option.label}
        //       </Box>
        //     )}
        //   />
        // </FormItemRow>
      );
    } else if (borderlessIsoDateFields.includes(field)) {
      return <MonetInput {...register(selectedField.name)} id={selectedField.name} error={errors[selectedField.name]?.message as string} label={selectedField.label} />;
    }

    const isCountryWithDifferentAccountFormat = ["GBR", "AUS"].includes(selectedCountry);
    let fieldProps = {};

    if (isCountryWithDifferentAccountFormat && field === "routingNumber") {
      fieldProps = {
        autoComplete: "off",
        inputProps: { maxLength: 8, inputMode: "numeric" },
        placeholder: selectedCountry == "AUS" ? "000-000" : "00-00-00",
        fieldProps: {
          parse: (value: string) => (selectedCountry == "AUS" ? formatBsbNumber(value) : formatSortCode(value)),
        },
      };
    } else if (isCountryWithDifferentAccountFormat && ["accountNumber"].includes(field)) {
      fieldProps = {
        inputProps: { inputMode: "numeric" },
        placeholder: "12345678",
        autoComplete: "off",
        fieldProps: {
          parse: (value: string) => {
            return (value ?? "").replace(/\D/g, "");
          },
        },
      };
    } else if (field === "lastName") {
      fieldProps = {
        placeholder: "Bloggs",
      };
    } else {
      fieldProps = {
        fieldProps: {
          parse: (value?: string) => value ?? "",
        },
      };
    }

    const hiddenFields = ["firstname"];
    const subTitle = selectedField.name === "businessName" ? "Registered business name on the account" : selectedField.subTitle || "";

    return !hiddenFields.includes(selectedField.name.toLowerCase()) ? (
      <MonetInput {...register(selectedField.name)} id={selectedField.name} error={errors[selectedField.name]?.message as string} label={selectedField.label} tooltip={subTitle} />
    ) : null;
    // <FormItemRow isHidden={hiddenFields.includes(selectedField.name.toLowerCase())} title={selectedField.label} subTitle={subTitle}>
    //   <Field name={getName(selectedField.name)} validate={(value) => validatePayeeField(value, getName(selectedField.name), payeeList)}>
    //     {({ input }) => (
    //       <FormControl fullWidth>
    //         <TextField {...fieldProps} label="" fullWidth placeholder={selectedField.placeholder} id={getName(selectedField.name)} name={input.name} />
    //       </FormControl>
    //     )}
    //   </Field>
    // </FormItemRow>
  }
  return null;
};

const BeneficiaryRequiredFields = ({ requiredFields }: { requiredFields: PayeeField[] }) => {
  const allFields: string[] = useMemo(() => {
    const fieldsToShow = requiredFields.filter((x) => !!x.value).map((x) => x.key);
    return borderlessAllFields.filter((x) => fieldsToShow.includes(x));
  }, [requiredFields]);

  return (
    <>
      {allFields.map((item) => (
        <BeneficiaryFormField field={item} key={item} />
      ))}
    </>
  );
};

export default BeneficiaryRequiredFields;
