import { Box, Tooltip, Typography } from "@mui/material";
import MoneyTextField from "components/Forms/MoneyTextField";
import React from "react";

type DesktopSubscriptionComponentProps = {
  removeFab: React.ReactElement;
  index: number;
  currency: Components.Schemas.Currency;
};

const DesktopSubscriptionComponent: React.FC<DesktopSubscriptionComponentProps> = ({ removeFab, index, currency }) => {
  return (
    <Box mb={1} display="flex" alignItems="flex-start" justifyContent="space-between" position={"relative"} key={`subscription-${index}`}>
      <MoneyTextField fullWidth={false} name={`feeRules.subscriptions[${index}].costPerMonth.amount`} sx={{ width: 215 }} currency={currency} />
      <Box height={55} display="flex" alignItems="center">
        <Typography textAlign="center" marginX={2}>
          ── up to a <b>&nbsp;Credit Limit&nbsp;</b> of ──
        </Typography>
      </Box>
      <MoneyTextField fullWidth={false} name={`feeRules.subscriptions[${index}].creditLimit.amount`} sx={{ width: 215 }} currency={currency} />
      <Tooltip title="Remove subscription" followCursor>
        {removeFab}
      </Tooltip>
    </Box>
  );
};

export default DesktopSubscriptionComponent;
