import { Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/system";
import React from "react";

interface ViewHeaderProps {
  icon?: React.ReactElement;
  header: string;
  subheader?: string | React.ReactElement;
  button?: React.ReactElement | false;
}

const ViewHeader: React.FC<ViewHeaderProps> = ({ icon, header, subheader, button }) => {
  const theme = useTheme();
  return (
    <Stack direction={{ md: "row" }} alignItems="flex-start" justifyContent="space-between" width="100%" my={4} spacing={2}>
      <Stack spacing={3} direction="row" alignSelf="center">
        {icon && (
          <Box
            data-testid="viewHeader-icon-container"
            color={theme.palette.primary.main}
            width={50}
            height={50}
            minWidth={50}
            minHeight={50}
            sx={{ "& svg": { width: "100%", height: "100%" } }}
          >
            {icon}
          </Box>
        )}
        <Stack spacing={1}>
          <Typography variant="h1" data-testid="viewHeader-header">
            {header}
          </Typography>
          {subheader && <Typography data-testid="viewHeader-subheader">{subheader}</Typography>}
        </Stack>
      </Stack>
      {button && (
        <Box display="contents" width="100%" maxWidth={{ xs: "100%" }} alignSelf="flex-end" data-testid="viewHeader-button-container">
          {button}
        </Box>
      )}
    </Stack>
  );
};

export default ViewHeader;
