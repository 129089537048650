import { Percentage } from "@monet-money/percentage-type";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, FormControl, FormLabel, InputAdornment, MenuItem, OutlinedInput, Stack, styled, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import AlertView from "components/AlertView/AlertView";
import CountryFlag from "components/CountryFlag/CountryFlag";
import FlexRow from "components/FlexRow/FlexRow";
import CountriesField from "components/Forms/CountriesField";
import { MultiSelectCurrenciesWithMargin } from "components/Forms/MultiSelectCurrenciesWithMargin/MultiSelectCurrenciesWithMargin";
import StackWithBorder from "components/utils/StackWithBorder";
import useAppProvider from "contexts/AppProvider";
import getSymbolFromCurrency from "currency-symbol-map";
import arrayMutators from "final-form-arrays";
import { makeValidate, Select, TextField } from "mui-rff";
import React, { useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { PartnerDocument } from "shared/generated/ep3.graphql.types";
import { LogoInput } from "shared/types/eep.contract.types";
import { TermsAndConditionType } from "shared/utils/partner";
import { formatAllowedCurrenciesBeforeSaving, formatAllowedCurrenciesForForm, getCurrenciesFromCountryCode } from "utils/currency";
import { convertImageUrlToFileInput, defaultFileInput } from "utils/file";
import { handleWheel, limitDecimalPlaces } from "utils/inputs";
import { initialSubscriptionRow, Partner } from "utils/Partner";
import PartnerLogoSection from "../AddPartner/PartnerLogoSection";
import { useUpdatePartnerMutation } from "../graphql/mutations.generated";
import { useGetPartnerDocumentsQuery } from "../graphql/queries.generated";
import { newPartnerSchema } from "../PartnerValidation/PartnerValidationSchema";
import CollectionAccounts from "./components/CollectionAccounts/CollectionAccounts";
import DeletePartnerModal from "./components/DeletePartnerModal";
import SubscriptionComponent from "./components/SubscriptionComponent";
import TermsAndConditions from "./components/TermsAndConditions";

type EditPartnerProps = {
  partner: Partner;
  toggleEdit(): void;
  successCallback(): void;
};

const StackWithBoldText = styled(Stack)({
  "label, input": {
    fontWeight: "bold",
  },
});

const defaultPercentage = 0;

const EditPartner: React.FC<EditPartnerProps> = ({ partner, toggleEdit, successCallback }) => {
  const [updatePartner, { loading }] = useUpdatePartnerMutation();
  const [documents, setDocuments] = useState<PartnerDocument[]>([]);
  const [initialLogoInput, setInitialLogoInput] = useState<LogoInput>(defaultFileInput);
  const { name, address, apiKeyId, defaultConfiguration, feeRules, kybStatus, partnerId, limits, country } = partner;

  const { data: documentsData } = useGetPartnerDocumentsQuery({
    fetchPolicy: "network-only",
    variables: {
      partnerId: partner.partnerId,
      category: "INVOICE_FINANCE_TERMS",
    },
  });

  const getDocumentTargetUsingDocumentId = (documentId: TermsAndConditionType) => {
    return documents.find((x) => x.documentId == documentId)?.target ?? "";
  };

  useEffect(() => {
    if (documentsData) {
      setDocuments(documentsData.getPartnerDocuments);
    }
  }, [documentsData]);

  const [error, setError] = useState<Error>();
  const { isMobile } = useAppProvider();

  const onSubmit = async (formData: Partner) => {
    try {
      const payload = formData;
      setError(undefined);
      await updatePartner({
        variables: {
          partner: JSON.stringify({
            ...payload,

            defaultConfiguration: {
              ...payload.defaultConfiguration,
              allowedCurrencies: formatAllowedCurrenciesBeforeSaving(payload.defaultConfiguration.allowedCurrencies),
            },
          }),
        },
      }).then(() =>
        //Success
        successCallback(),
      );
    } catch (err: Error | any) {
      setError(err);
    }
  };

  const validate = makeValidate(newPartnerSchema as any);

  useEffect(() => {
    if (partner?.defaultConfiguration?.logoUrl) {
      convertImageUrlToFileInput(partner?.defaultConfiguration?.logoUrl).then((res) => {
        setInitialLogoInput(res);
      });
    }
  }, []);

  const initialValues = useMemo(() => {
    const isInvoiceAssignmentGeneric = defaultConfiguration.invoiceAssignmentTerms?.linkType == "Generic";

    return {
      name,
      address,
      country,
      collectionAccounts: partner.collectionAccounts || [],
      defaultConfiguration: {
        ...defaultConfiguration,
        allowedCurrencies: formatAllowedCurrenciesForForm(defaultConfiguration.allowedCurrencies, true),
        invoiceAssignmentTerms: {
          ...defaultConfiguration.invoiceAssignmentTerms,
          earlyPayAgreement: isInvoiceAssignmentGeneric ? "" : getDocumentTargetUsingDocumentId("earlypay_agreement"),
          onboardingAgreement: isInvoiceAssignmentGeneric ? "" : getDocumentTargetUsingDocumentId("onboarding_agreement"),
          standardTerms: isInvoiceAssignmentGeneric ? "" : getDocumentTargetUsingDocumentId("standard_terms"),
        },
        termsAndConditions: defaultConfiguration.termsAndConditions,
        feeSettings: {
          max: Percentage.fromStorageValue(defaultConfiguration.feeSettings?.max || defaultPercentage).format(true),
          default: Percentage.fromStorageValue(defaultConfiguration.feeSettings?.default || defaultPercentage).format(true),
        },
      },
      feeRules: {
        subscriptions: !!feeRules?.subscriptions?.length ? feeRules!.subscriptions : [initialSubscriptionRow],
      },
      kybStatus,
      apiKeyId,
      partnerId,
      limits,
      logo: initialLogoInput,
    };
  }, [partner, documents, initialLogoInput, defaultConfiguration]);

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={initialValues}
      render={({
        handleSubmit,
        form: {
          mutators: { push, remove }, // injected from final-form-arrays via arrayMutators
          change,
        },
        pristine,
        values,
        invalid,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <StackWithBoldText spacing={3}>
              <StackWithBorder title="Parent company settings" subtitle="Basic settings for the parent company">
                <FlexRow>
                  <FormControl fullWidth>
                    <FormLabel>Name</FormLabel>
                    <TextField name="name" autoComplete="off" helperText="The name of the parent company" />
                  </FormControl>
                </FlexRow>
                <FlexRow>
                  <FormControl fullWidth>
                    <FormLabel>Country of partner</FormLabel>
                    <CountriesField helperText="The country of the partner" name="country" label="" countryCode={country} />
                  </FormControl>
                </FlexRow>
                <FlexRow>
                  <PartnerLogoSection hideTitle />
                </FlexRow>
                <FlexRow>
                  <Tooltip title="Partner ID can not be changed" followCursor>
                    <FormControl fullWidth>
                      <FormLabel>Partner ID</FormLabel>
                      <TextField disabled name="partnerId" autoComplete="off" helperText="The partner ID of the partner" />
                    </FormControl>
                  </Tooltip>
                </FlexRow>
                <Box>
                  <FlexRow mt={1}>
                    <FormControl fullWidth>
                      <FormLabel>Address Line 1</FormLabel>
                      <TextField name="address.addressLine1" autoComplete="off" />
                    </FormControl>
                    <FormControl fullWidth>
                      <FormLabel>Address Line 2</FormLabel>
                      <TextField name="address.addressLine2" autoComplete="off" />
                    </FormControl>
                  </FlexRow>
                  <FlexRow mt={1}>
                    <FormControl fullWidth>
                      <FormLabel>Postcode</FormLabel>
                      <TextField name="address.postcode" autoComplete="off" />
                    </FormControl>
                    <FormControl fullWidth>
                      <FormLabel>City</FormLabel>
                      <TextField name="address.city" autoComplete="off" />
                    </FormControl>
                  </FlexRow>
                </Box>
                <FlexRow>
                  <FormControl fullWidth>
                    <FormLabel>Allowed Currencies</FormLabel>
                    <MultiSelectCurrenciesWithMargin name="defaultConfiguration.allowedCurrencies" allowedCurrencies={values.defaultConfiguration.allowedCurrencies} />
                  </FormControl>
                </FlexRow>
                <FlexRow mb={2} spacing={0} flexDirection="row">
                  <FormControl fullWidth>
                    <FormLabel>Credit limit</FormLabel>
                    <Stack flexDirection="row">
                      <TextField name="limits.global.amount" type="number" onWheel={handleWheel} inputProps={{ min: "0", step: "1" }} placeholder="0" autoComplete="off" />
                      <Box maxWidth={150}>
                        <Select sx={{ borderLeft: 0 }} name="limits.global.currency" required displayEmpty input={<OutlinedInput />}>
                          <MenuItem disabled value="">
                            {<em>Select Currency</em>}
                          </MenuItem>
                          {getCurrenciesFromCountryCode(values.defaultConfiguration.allowedCurrencies).map((item) => (
                            <MenuItem key={item.currency} value={item.currency}>
                              <CountryFlag countryCode={item.countryCode} />
                              &nbsp;{item.currency}&nbsp;({getSymbolFromCurrency(item.currency)})
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Stack>
                  </FormControl>
                </FlexRow>
                <Box>
                  <FlexRow direction={"row"} justifyContent={"space-between"}>
                    <Typography width={"auto"} fontWeight="bold">
                      Fee Rules
                    </Typography>
                    <Button
                      variant="text"
                      sx={{ padding: 0, width: "auto" }}
                      onClick={() => {
                        push("feeRules.subscriptions", initialSubscriptionRow(values.limits.global.currency));
                      }}
                    >
                      <AddIcon fontSize="small" /> Add rule
                    </Button>
                  </FlexRow>
                  {!isMobile && (
                    <FlexRow mt={2} flexDirection="row">
                      <Typography mb={0} fontWeight="bold">
                        MONET Subscription per month
                      </Typography>
                      <Typography textAlign="end" mb={0} fontWeight="bold">
                        Credit Limit
                      </Typography>
                    </FlexRow>
                  )}
                  <Stack mt={1} flexDirection="column">
                    <SubscriptionComponent remove={remove} />
                  </Stack>
                </Box>
                <FlexRow>
                  <FormControl fullWidth>
                    <FormLabel>Maximum fee %</FormLabel>
                    <TextField
                      name="defaultConfiguration.feeSettings.max"
                      autoComplete="off"
                      InputProps={{
                        onChange: (e) => change("defaultConfiguration.feeSettings.max", limitDecimalPlaces(e.target.value, 2)),
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                    />
                  </FormControl>
                </FlexRow>
                <FlexRow>
                  <FormControl fullWidth>
                    <FormLabel>Default % fee</FormLabel>
                    <TextField
                      name="defaultConfiguration.feeSettings.default"
                      autoComplete="off"
                      InputProps={{
                        onChange: (e) => change("defaultConfiguration.feeSettings.default", limitDecimalPlaces(e.target.value, 2)),
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                    />
                  </FormControl>
                </FlexRow>
                <FlexRow>
                  <CollectionAccounts />
                </FlexRow>
                <FlexRow>
                  <FormControl fullWidth>
                    <FormLabel>EarlyPay payout duration</FormLabel>
                    <TextField
                      name="defaultConfiguration.payoutTtlDelta"
                      InputProps={{
                        endAdornment: <>hours</>,
                      }}
                      type="number"
                      onWheel={handleWheel}
                      inputProps={{ min: "0", max: "1000", step: "1" }}
                      placeholder="0"
                      autoComplete="off"
                    />
                  </FormControl>
                </FlexRow>
                <FlexRow>
                  <TermsAndConditions name="invoiceAssignmentTerms" title="Invoice assignment agreement" />
                </FlexRow>
                <FlexRow>
                  <TermsAndConditions name="termsAndConditions" title="Creator Terms & Conditions" />
                </FlexRow>
              </StackWithBorder>
              <StackWithBorder title="KYB Settings" subtitle="Set the setting for the company’s KYB status">
                <FlexRow>
                  <Box display="flex" flexDirection="column" mb={0}>
                    <FormLabel>KYB Status</FormLabel>
                    <Stack flexDirection="row" alignItems="center" height="100%" maxWidth={{ md: "300px" }}>
                      {/* hidden text field to register kybStatus with the form  */}
                      <TextField name="kybStatus" disabled sx={{ display: "none" }} />
                      <Stack width="100%" gap={2} minWidth={{ md: "280px" }} display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                        <Button variant={values.kybStatus !== "VERIFIED" ? "contained" : "outlined"} onClick={() => change("kybStatus", "NOT_VERIFIED")} fullWidth>
                          Not Verified
                        </Button>

                        <Button variant={values.kybStatus === "VERIFIED" ? "contained" : "outlined"} onClick={() => change("kybStatus", "VERIFIED")} fullWidth>
                          Verified
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box mt={{ xs: 2, md: 0 }}>
                    <Tooltip title="API key can not be changed" followCursor>
                      <FormControl fullWidth>
                        <FormLabel>API Key Id</FormLabel>
                        <TextField name="apiKeyId" autoComplete="off" disabled />
                      </FormControl>
                    </Tooltip>
                  </Box>
                </FlexRow>
              </StackWithBorder>
              <Stack justifyContent="space-between" gap={2} direction={{ xs: "column-reverse", sm: "row" }} spacing={{ xs: 1, md: 16 }}>
                <DeletePartnerModal partner={partner} />

                <Box display={{ xs: "flex" }} gap={2} justifyContent={{ xs: "space-between" }} flexDirection={{ xs: "column-reverse", md: "row" }}>
                  <Button data-testid="cancelChanges-btn" variant="text" color="inherit" onClick={toggleEdit}>
                    Cancel changes
                  </Button>
                  <Button data-testid="saveChanges-btn" type="submit" variant="contained" disabled={loading || invalid || pristine}>
                    Save changes
                  </Button>
                </Box>
              </Stack>
              {error && <AlertView title="Error processing request" severity="error" error={error} />}
            </StackWithBoldText>
          </form>
        );
      }}
    />
  );
};

export default EditPartner;
