import { SchemaOf, object, string } from "yup";

export type BaseRatesFormObject = {
  CAD: string;
  USD: string;
  GBP: string;
  EUR: string;
  AUD: string;
};

const isValidNonNegativeNumber = (fieldName: string) => {
  return string()
    .required(`Please enter a valid base rate for ${fieldName}`)
    .test("is-number", `${fieldName} must be a valid number`, (value) => !isNaN(Number(value)))
    .test("is-non-negative", `Please enter a valid base rate for ${fieldName}`, (value) => Number(value) >= 0);
};

export const balanceSheetReportFormSchema = <SchemaOf<BaseRatesFormObject>>object().shape({
  CAD: isValidNonNegativeNumber("CAD"),
  USD: isValidNonNegativeNumber("USD"),
  GBP: isValidNonNegativeNumber("GBP"),
  EUR: isValidNonNegativeNumber("EUR"),
  AUD: isValidNonNegativeNumber("AUD"),
});
