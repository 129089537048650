import { CurrencyWithMargin } from "shared/types/currency";
import { array, number, object, string } from "yup";

export const currencyMarginOrRateSchema = (type: "Margin" | "Base rate") =>
  number()
    .test("${type}-range-check", "${type} range check", (val, ctx) => {
      const form = ctx.parent as CurrencyWithMargin;
      const message = `${type} for ${form.currency}`;
      if (typeof val != "number" || !/^-?\d+(\.\d{0,6})?$/.test(val.toString())) {
        return ctx.createError({
          type: "required",
          message: `${message} must be a number with max 6 decimal places`,
          path: `selectedCurrency.${type}`,
        });
      }
      if (val > 100) {
        return ctx.createError({
          type: "required",
          message: `${message} must be less than or equal to 100`,
          path: `selectedCurrency.${type}`,
        });
      }
      if (val < -100) {
        return ctx.createError({
          type: "required",
          message: `${message} must be bigger than or equal to -100`,
          path: `selectedCurrency.${type}`,
        });
      }
      return true;
    })
    .required();

const currencyWithMarginSchema = object().shape({
  currency: string().required(),
  margin: currencyMarginOrRateSchema("Margin"),
});

export const multiCurrencyWithMarginSchema = object().shape({
  selectedCurrencies: array().of(currencyWithMarginSchema),
});
