import { ReactComponent as ChevronRight } from "assets/tailwind/icons/chevron-right.svg";
import MonetAlert from "components/tailwind/MonetAlert";
import MonetButton from "components/tailwind/MonetButton";
import { useSessionProvider } from "contexts/SessionProvider";
import { useNavigate } from "react-router-dom";
import { isPartnerProfileComplete } from "utils/Partner";
import { isInAdminContext } from "utils/User";
import { ORGANISATION_PATH, ORGANISATION_PROFILE_PATH } from "views/organisation/OrganisationPath";

const CompleteProfileBanner = () => {
  const navigate = useNavigate();
  const { user, partner } = useSessionProvider();

  if (isPartnerProfileComplete(partner, user) || isInAdminContext(user)) return null;

  return (
    <MonetAlert variant="info" title="Finish setting up your profile">
      <div className="flex flex-col gap-4 items-start">
        <p>Complete your organisation profile. Once the required details are provided, you'll be able to create campaigns and apply for financing.</p>
        <MonetButton size="small" onClick={() => navigate(ORGANISATION_PATH + ORGANISATION_PROFILE_PATH)}>
          Finish Profile
          <ChevronRight />
        </MonetButton>
      </div>
    </MonetAlert>
  );
};

export default CompleteProfileBanner;
