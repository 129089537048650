import VisibilityIcon from "@mui/icons-material/Visibility";
import { CircularProgress, IconButton } from "@mui/material";
import ItemBox from "components/ItemBox/ItemBox";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Partner } from "utils/Partner";
import { useGetApiKeyLazyQuery } from "../graphql/queries.generated";

type ApiKeyInfoProps = {
  partner: Partner;
};

const ApiKeyInfo: React.FC<ApiKeyInfoProps> = ({ partner }) => {
  const [apiKey, setApiKey] = useState<string>();
  const [getApiKey, { loading: isLoading }] = useGetApiKeyLazyQuery();
  const { enqueueSnackbar } = useSnackbar();

  const getKey = async () => {
    try {
      const response = await getApiKey({
        variables: {
          apiKeyId: partner.apiKeyId,
        },
      });

      setApiKey(response.data?.getApiKey);
    } catch (error: Error | any) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  return (
    <ItemBox
      title={apiKey ? "API Key" : "API Key Id"}
      value={
        <>
          {partner.kybStatus === "VERIFIED" ? apiKey ?? partner.apiKeyId : "Pending verification"}
          {!apiKey && !isLoading && (
            <IconButton sx={{ ml: 1 }} onClick={getKey}>
              <VisibilityIcon />
            </IconButton>
          )}
          {isLoading && <CircularProgress sx={{ ml: 1 }} size={12} />}
        </>
      }
    />
  );
};

export default ApiKeyInfo;
