import { Amplify } from "aws-amplify";
import { createPortal } from "react-dom";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "sonner";
import App from "./App";
import "./index.css";

Amplify.configure(
  {
    Auth: {
      Cognito: {
        userPoolClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID,
        userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
        identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL,
      },
    },
    Storage: {
      S3: {
        region: process.env.REACT_APP_AWS_REGION,
        bucket: process.env.REACT_APP_EEP_CAMPAIGN_BUCKET,
      },
    },
  },
  {
    Storage: {
      S3: {
        prefixResolver: async ({ accessLevel }) => {
          if (accessLevel === "guest") {
            return "public/";
          } else if (accessLevel === "protected") {
            return `protected/`;
          }
          return `private/`;
        },
      },
    },
  },
);

if (process.env.NODE_ENV === "development") {
  console.info("process.env", process.env);
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    {createPortal(<Toaster expand visibleToasts={5} position="top-right" richColors closeButton offset={16} toastOptions={{ duration: 5000 }} />, document.body)}
  </>,
);
