import { ReactComponent as ChartColumnIcon } from "assets/tailwind/icons/chart-column.svg";
import { ReactComponent as DashboardIcon } from "assets/tailwind/icons/layout-dashboard.svg";
import { ReactComponent as MegaphoneIcon } from "assets/tailwind/icons/megaphone.svg";
import { ReactComponent as EarlyPayIcon } from "assets/tailwind/icons/receipt-pound-sterling.svg";
import { ReactComponent as SettingsIcon } from "assets/tailwind/icons/settings-2.svg";
import { ReactComponent as UsersIcon } from "assets/tailwind/icons/users.svg";
import MonetNavLinkItem from "components/tailwind/MonetNavLinkItem";
import { useSessionProvider } from "contexts/SessionProvider";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_PATH } from "routing/paths";
import { isInAdminContext, isInPartnerContext } from "utils/User";
import { ANALYTICS_PATH, REPORTS_PATH } from "views/analytics/AnalyticsPath";
import { CAMPAIGNS_PATH } from "views/campaigns/CampaignsPath";
import { ORGANISATION_PATH, ORGANISATION_PROFILE_PATH } from "views/organisation/OrganisationPath";
import { PARTNERS_LIST_ALL_PATH, PARTNERS_PATH } from "views/partners/PartnersPath";
import { PAYOUTS_PATH, PAYOUT_LIST_ALL_PATH } from "views/payouts/PayoutPath";
import { SETTINGS_BASE_RATES_ADMIN, SETTINGS_DAILY_FEES, SETTINGS_PATH } from "views/settings/SettingsPath";
import { USER_PATH, USER_PROFILE_PATH } from "views/user/UserPath";
import MonetNavAccordion from "../../MonetAccordion";

const NavigationMenu = () => {
  const { user } = useSessionProvider();
  const navigate = useNavigate();

  return (
    <nav className="hs-accordion-group p-3 w-full flex flex-col flex-wrap" data-hs-accordion-always-open>
      <ul className="space-y-1.5">
        <MonetNavLinkItem onClick={() => navigate(DASHBOARD_PATH)} icon={<DashboardIcon />} id="dashboard">
          Dashboard
        </MonetNavLinkItem>
        <MonetNavLinkItem onClick={() => navigate(CAMPAIGNS_PATH)} id="campaigns" icon={<MegaphoneIcon />}>
          Campaigns
        </MonetNavLinkItem>
        <MonetNavLinkItem onClick={() => navigate(PAYOUTS_PATH + PAYOUT_LIST_ALL_PATH)} id="payouts" icon={<EarlyPayIcon />}>
          Payouts
        </MonetNavLinkItem>
        {user.isAdministrator && isInAdminContext(user) && (
          <MonetNavLinkItem onClick={() => navigate(PARTNERS_PATH + PARTNERS_LIST_ALL_PATH)} id="partners" icon={<UsersIcon />}>
            Partners
          </MonetNavLinkItem>
        )}
        <MonetNavLinkItem onClick={() => navigate(ANALYTICS_PATH + REPORTS_PATH)} id="reports" icon={<ChartColumnIcon />}>
          Reports
        </MonetNavLinkItem>
        <MonetNavAccordion label="Settings" id="settings" icon={<SettingsIcon />}>
          {isInPartnerContext(user) && (
            <MonetNavLinkItem onClick={() => navigate(ORGANISATION_PATH + ORGANISATION_PROFILE_PATH)} id="organisation">
              Organisation
            </MonetNavLinkItem>
          )}
          <MonetNavLinkItem onClick={() => navigate(USER_PATH + USER_PROFILE_PATH)} id="profile">
            Profile
          </MonetNavLinkItem>
          {user.isAdministrator && isInAdminContext(user) && (
            <MonetNavLinkItem onClick={() => navigate(SETTINGS_PATH + SETTINGS_BASE_RATES_ADMIN)} id="base-rates">
              Base rates
            </MonetNavLinkItem>
          )}
          {isInPartnerContext(user) && (
            <MonetNavLinkItem onClick={() => navigate(SETTINGS_PATH + SETTINGS_DAILY_FEES)} id="daily-fees">
              Daily fees
            </MonetNavLinkItem>
          )}
        </MonetNavAccordion>
      </ul>
    </nav>
  );
};

export default NavigationMenu;
