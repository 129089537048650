import { ReactComponent as Calendar } from "assets/tailwind/icons/calendar.svg";
import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";
import { InputHTMLAttributes } from "react";
import Flatpickr, { DateTimePickerProps } from "react-flatpickr";
import { ControllerRenderProps } from "react-hook-form";
import { twMerge } from "tailwind-merge";

export type MonetDatePickerProps = Omit<InputHTMLAttributes<HTMLInputElement>, "size"> &
  VariantProps<typeof variants> & {
    inputProps: ControllerRenderProps;
    label?: string;
    error?: string;
    pickerOptions?: DateTimePickerProps["options"];
  };

const MonetDatePicker: React.FC<MonetDatePickerProps> = ({ error, label, variant, size, pickerOptions, inputProps, ...props }) => {
  const errorStyling = error ? "border-red-500 ring ring-red-100 ring-offset-0 focus:ring-red-500 focus:ring focus:ring-red-100 focus:outline-offset-0" : undefined;

  return (
    <div className="w-full">
      {label && (
        <div className="flex justify-between items-center mb-2 text-sm">
          <label htmlFor={label} className="block font-semibold text-gray-800 dark:text-white">
            {label}
          </label>
        </div>
      )}
      <div className="relative">
        <Flatpickr
          {...inputProps}
          disabled={props.disabled}
          options={{
            ...pickerOptions,
          }}
          render={({ name, onBlur, onChange, disabled }, ref) => {
            return (
              <div className="relative">
                <input
                  placeholder="Please select a date..."
                  ref={ref}
                  name={name}
                  onBlur={onBlur}
                  onChange={() => onChange}
                  disabled={disabled}
                  className={twMerge(clsx(variants({ variant, size }), errorStyling))}
                />
                <div className="absolute inset-y-0 end-0 flex items-center pointer-events-none pe-3 [&>svg]:w-[18px] [&>svg]:h-[18px]">
                  <Calendar />
                </div>
              </div>
            );
          }}
        />
        {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
      </div>
    </div>
  );
};

export default MonetDatePicker;

const variants = cva(
  "block w-full text-base sm:text-sm border rounded-lg hover:cursor-pointer disabled:opacity-70 disabled:pointer-events-none disabled:bg-gray-100 dark:bg-transparent dark:disabled:bg-transparent",
  {
    variants: {
      variant: {
        default:
          "border-gray-200 placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600",
      },
      size: {
        small: "py-2 px-3.5",
        default: "py-3.5 px-4",
        large: "py-6 px-5",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);
