import { ReactComponent as InfoIcon } from "assets/tailwind/icons/info.svg";
import { HSTooltip } from "preline/preline";
import { useEffect } from "react";

type MonetToolTipProps = {
  text: string;
};

const MonetToolTip: React.FC<MonetToolTipProps> = ({ text }) => {
  useEffect(() => {
    HSTooltip.autoInit();
  }, []);

  return (
    <div className="hs-tooltip inline-block [--placement:bottom-left]">
      <InfoIcon className="w-[14px] h-[14px] ml-1" />
      <div
        className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 inline-block absolute invisible z-50 w-fit max-w-[250px] py-1.5 px-2.5 bg-gray-900 text-xs text-left text-white rounded dark:bg-neutral-700 whitespace-break-spaces"
        role="tooltip"
      >
        {text}
      </div>
    </div>
  );
};

export default MonetToolTip;
