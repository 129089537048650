import { Box, FormControl, FormLabel } from "@mui/material";
import FlexRow from "components/FlexRow/FlexRow";
import { TextField } from "mui-rff";
import React, { useCallback } from "react";
import { Field } from "react-final-form";
import { Partner } from "utils/Partner";
import PageTitle from "./PageTitle";

type PartnerNameSectionProps = {
  existingPartners?: Partner[];
  loading: boolean;
};

const PartnerNameSection: React.FC<PartnerNameSectionProps> = ({ existingPartners, loading }) => {
  const validatePartnerName = useCallback(
    (name: string) => {
      if (existingPartners) {
        const isDuplicate = existingPartners!.find((partner) => partner.name === name);
        if (isDuplicate) {
          return "Partner name already exists. Please enter a unique name.";
        }
      }
      return false;
    },
    [existingPartners],
  );

  return (
    <Box>
      <PageTitle>What's the name of the partner?</PageTitle>
      <Box mb={2}>
        <FlexRow>
          <Box>
            <Field name="name" validate={validatePartnerName}>
              {({ input }) => (
                <FormControl fullWidth>
                  <FormLabel>Partner Name</FormLabel>
                  <TextField name={input.name} data-testid="partnerJourney-partnerNameField" placeholder="Enter partner name" autoComplete="off" disabled={loading} />
                </FormControl>
              )}
            </Field>
          </Box>
        </FlexRow>
      </Box>
    </Box>
  );
};

export default PartnerNameSection;
