import { countries } from "countries-list";
import { alpha2ToAlpha3 } from "i18n-iso-countries";

export type Country = Record<
  string,
  {
    name: string;
    emoji: string;
    currency: string;
  }
>;

export const getAllCountries = () => {
  const allCountries: Country = countries;
  const listOfCountries = Object.keys(allCountries).map((item) => {
    return {
      value: alpha2ToAlpha3(item) || "",
      label: allCountries[item].name,
    };
  });
  return listOfCountries.sort((a, b) => a.label.localeCompare(b.label));
};

export const countryFlagApi = (countryCode?: string): string => (countryCode ? `https://flagcdn.com/20x15/${countryCode.toLowerCase()}.png` : "");
