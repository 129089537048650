import * as Types from 'shared/generated/ep3.graphql.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCampaignsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCampaignsQuery = { __typename?: 'Query', getCampaigns: any };

export type GetCampaignQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['String']['input'];
  partnerId: Types.Scalars['String']['input'];
}>;


export type GetCampaignQuery = { __typename?: 'Query', getCampaign: any };

export type GetConfigurationsQueryVariables = Types.Exact<{
  partnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetConfigurationsQuery = { __typename?: 'Query', getConfigurations: any };

export type GetConfigurationQueryVariables = Types.Exact<{
  configurationId: Types.Scalars['String']['input'];
  partnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetConfigurationQuery = { __typename?: 'Query', getConfiguration: any };

export type GetPartnerQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPartnerQuery = { __typename?: 'Query', getPartner: any };

export type GetPayoutsByCampaignQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['String']['input'];
}>;


export type GetPayoutsByCampaignQuery = { __typename?: 'Query', getPayoutsByCampaign: any };

export type GetPartnerFeesQueryVariables = Types.Exact<{
  partnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetPartnerFeesQuery = { __typename?: 'Query', GetPartnerFees: Array<{ __typename?: 'GetPartnerFeesResponse', baseRate: number, margin: number, currency: string }> };

export type GetInvoicesByCampaignQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['String']['input'];
  partnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetInvoicesByCampaignQuery = { __typename?: 'Query', getInvoicesByCampaign: any };

export type GetInvoicesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetInvoicesQuery = { __typename?: 'Query', getInvoices: any };


export const GetCampaignsDocument = gql`
    query getCampaigns {
  getCampaigns
}
    `;

/**
 * __useGetCampaignsQuery__
 *
 * To run a query within a React component, call `useGetCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCampaignsQuery(baseOptions?: Apollo.QueryHookOptions<GetCampaignsQuery, GetCampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(GetCampaignsDocument, options);
      }
export function useGetCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignsQuery, GetCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(GetCampaignsDocument, options);
        }
export function useGetCampaignsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCampaignsQuery, GetCampaignsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(GetCampaignsDocument, options);
        }
export type GetCampaignsQueryHookResult = ReturnType<typeof useGetCampaignsQuery>;
export type GetCampaignsLazyQueryHookResult = ReturnType<typeof useGetCampaignsLazyQuery>;
export type GetCampaignsSuspenseQueryHookResult = ReturnType<typeof useGetCampaignsSuspenseQuery>;
export type GetCampaignsQueryResult = Apollo.QueryResult<GetCampaignsQuery, GetCampaignsQueryVariables>;
export const GetCampaignDocument = gql`
    query getCampaign($campaignId: String!, $partnerId: String!) {
  getCampaign(campaignId: $campaignId, partnerId: $partnerId)
}
    `;

/**
 * __useGetCampaignQuery__
 *
 * To run a query within a React component, call `useGetCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useGetCampaignQuery(baseOptions: Apollo.QueryHookOptions<GetCampaignQuery, GetCampaignQueryVariables> & ({ variables: GetCampaignQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampaignQuery, GetCampaignQueryVariables>(GetCampaignDocument, options);
      }
export function useGetCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignQuery, GetCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampaignQuery, GetCampaignQueryVariables>(GetCampaignDocument, options);
        }
export function useGetCampaignSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCampaignQuery, GetCampaignQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCampaignQuery, GetCampaignQueryVariables>(GetCampaignDocument, options);
        }
export type GetCampaignQueryHookResult = ReturnType<typeof useGetCampaignQuery>;
export type GetCampaignLazyQueryHookResult = ReturnType<typeof useGetCampaignLazyQuery>;
export type GetCampaignSuspenseQueryHookResult = ReturnType<typeof useGetCampaignSuspenseQuery>;
export type GetCampaignQueryResult = Apollo.QueryResult<GetCampaignQuery, GetCampaignQueryVariables>;
export const GetConfigurationsDocument = gql`
    query getConfigurations($partnerId: String) {
  getConfigurations(partnerId: $partnerId)
}
    `;

/**
 * __useGetConfigurationsQuery__
 *
 * To run a query within a React component, call `useGetConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigurationsQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useGetConfigurationsQuery(baseOptions?: Apollo.QueryHookOptions<GetConfigurationsQuery, GetConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConfigurationsQuery, GetConfigurationsQueryVariables>(GetConfigurationsDocument, options);
      }
export function useGetConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConfigurationsQuery, GetConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConfigurationsQuery, GetConfigurationsQueryVariables>(GetConfigurationsDocument, options);
        }
export function useGetConfigurationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetConfigurationsQuery, GetConfigurationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetConfigurationsQuery, GetConfigurationsQueryVariables>(GetConfigurationsDocument, options);
        }
export type GetConfigurationsQueryHookResult = ReturnType<typeof useGetConfigurationsQuery>;
export type GetConfigurationsLazyQueryHookResult = ReturnType<typeof useGetConfigurationsLazyQuery>;
export type GetConfigurationsSuspenseQueryHookResult = ReturnType<typeof useGetConfigurationsSuspenseQuery>;
export type GetConfigurationsQueryResult = Apollo.QueryResult<GetConfigurationsQuery, GetConfigurationsQueryVariables>;
export const GetConfigurationDocument = gql`
    query getConfiguration($configurationId: String!, $partnerId: String) {
  getConfiguration(configurationId: $configurationId, partnerId: $partnerId)
}
    `;

/**
 * __useGetConfigurationQuery__
 *
 * To run a query within a React component, call `useGetConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigurationQuery({
 *   variables: {
 *      configurationId: // value for 'configurationId'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useGetConfigurationQuery(baseOptions: Apollo.QueryHookOptions<GetConfigurationQuery, GetConfigurationQueryVariables> & ({ variables: GetConfigurationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConfigurationQuery, GetConfigurationQueryVariables>(GetConfigurationDocument, options);
      }
export function useGetConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConfigurationQuery, GetConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConfigurationQuery, GetConfigurationQueryVariables>(GetConfigurationDocument, options);
        }
export function useGetConfigurationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetConfigurationQuery, GetConfigurationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetConfigurationQuery, GetConfigurationQueryVariables>(GetConfigurationDocument, options);
        }
export type GetConfigurationQueryHookResult = ReturnType<typeof useGetConfigurationQuery>;
export type GetConfigurationLazyQueryHookResult = ReturnType<typeof useGetConfigurationLazyQuery>;
export type GetConfigurationSuspenseQueryHookResult = ReturnType<typeof useGetConfigurationSuspenseQuery>;
export type GetConfigurationQueryResult = Apollo.QueryResult<GetConfigurationQuery, GetConfigurationQueryVariables>;
export const GetPartnerDocument = gql`
    query getPartner {
  getPartner
}
    `;

/**
 * __useGetPartnerQuery__
 *
 * To run a query within a React component, call `useGetPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPartnerQuery(baseOptions?: Apollo.QueryHookOptions<GetPartnerQuery, GetPartnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartnerQuery, GetPartnerQueryVariables>(GetPartnerDocument, options);
      }
export function useGetPartnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerQuery, GetPartnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartnerQuery, GetPartnerQueryVariables>(GetPartnerDocument, options);
        }
export function useGetPartnerSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPartnerQuery, GetPartnerQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPartnerQuery, GetPartnerQueryVariables>(GetPartnerDocument, options);
        }
export type GetPartnerQueryHookResult = ReturnType<typeof useGetPartnerQuery>;
export type GetPartnerLazyQueryHookResult = ReturnType<typeof useGetPartnerLazyQuery>;
export type GetPartnerSuspenseQueryHookResult = ReturnType<typeof useGetPartnerSuspenseQuery>;
export type GetPartnerQueryResult = Apollo.QueryResult<GetPartnerQuery, GetPartnerQueryVariables>;
export const GetPayoutsByCampaignDocument = gql`
    query getPayoutsByCampaign($campaignId: String!) {
  getPayoutsByCampaign(campaignId: $campaignId)
}
    `;

/**
 * __useGetPayoutsByCampaignQuery__
 *
 * To run a query within a React component, call `useGetPayoutsByCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayoutsByCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayoutsByCampaignQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetPayoutsByCampaignQuery(baseOptions: Apollo.QueryHookOptions<GetPayoutsByCampaignQuery, GetPayoutsByCampaignQueryVariables> & ({ variables: GetPayoutsByCampaignQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayoutsByCampaignQuery, GetPayoutsByCampaignQueryVariables>(GetPayoutsByCampaignDocument, options);
      }
export function useGetPayoutsByCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayoutsByCampaignQuery, GetPayoutsByCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayoutsByCampaignQuery, GetPayoutsByCampaignQueryVariables>(GetPayoutsByCampaignDocument, options);
        }
export function useGetPayoutsByCampaignSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPayoutsByCampaignQuery, GetPayoutsByCampaignQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPayoutsByCampaignQuery, GetPayoutsByCampaignQueryVariables>(GetPayoutsByCampaignDocument, options);
        }
export type GetPayoutsByCampaignQueryHookResult = ReturnType<typeof useGetPayoutsByCampaignQuery>;
export type GetPayoutsByCampaignLazyQueryHookResult = ReturnType<typeof useGetPayoutsByCampaignLazyQuery>;
export type GetPayoutsByCampaignSuspenseQueryHookResult = ReturnType<typeof useGetPayoutsByCampaignSuspenseQuery>;
export type GetPayoutsByCampaignQueryResult = Apollo.QueryResult<GetPayoutsByCampaignQuery, GetPayoutsByCampaignQueryVariables>;
export const GetPartnerFeesDocument = gql`
    query getPartnerFees($partnerId: String) {
  GetPartnerFees(partnerId: $partnerId) {
    baseRate
    margin
    currency
  }
}
    `;

/**
 * __useGetPartnerFeesQuery__
 *
 * To run a query within a React component, call `useGetPartnerFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerFeesQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useGetPartnerFeesQuery(baseOptions?: Apollo.QueryHookOptions<GetPartnerFeesQuery, GetPartnerFeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartnerFeesQuery, GetPartnerFeesQueryVariables>(GetPartnerFeesDocument, options);
      }
export function useGetPartnerFeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerFeesQuery, GetPartnerFeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartnerFeesQuery, GetPartnerFeesQueryVariables>(GetPartnerFeesDocument, options);
        }
export function useGetPartnerFeesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPartnerFeesQuery, GetPartnerFeesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPartnerFeesQuery, GetPartnerFeesQueryVariables>(GetPartnerFeesDocument, options);
        }
export type GetPartnerFeesQueryHookResult = ReturnType<typeof useGetPartnerFeesQuery>;
export type GetPartnerFeesLazyQueryHookResult = ReturnType<typeof useGetPartnerFeesLazyQuery>;
export type GetPartnerFeesSuspenseQueryHookResult = ReturnType<typeof useGetPartnerFeesSuspenseQuery>;
export type GetPartnerFeesQueryResult = Apollo.QueryResult<GetPartnerFeesQuery, GetPartnerFeesQueryVariables>;
export const GetInvoicesByCampaignDocument = gql`
    query getInvoicesByCampaign($campaignId: String!, $partnerId: String) {
  getInvoicesByCampaign(campaignId: $campaignId, partnerId: $partnerId)
}
    `;

/**
 * __useGetInvoicesByCampaignQuery__
 *
 * To run a query within a React component, call `useGetInvoicesByCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesByCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesByCampaignQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useGetInvoicesByCampaignQuery(baseOptions: Apollo.QueryHookOptions<GetInvoicesByCampaignQuery, GetInvoicesByCampaignQueryVariables> & ({ variables: GetInvoicesByCampaignQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoicesByCampaignQuery, GetInvoicesByCampaignQueryVariables>(GetInvoicesByCampaignDocument, options);
      }
export function useGetInvoicesByCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesByCampaignQuery, GetInvoicesByCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoicesByCampaignQuery, GetInvoicesByCampaignQueryVariables>(GetInvoicesByCampaignDocument, options);
        }
export function useGetInvoicesByCampaignSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetInvoicesByCampaignQuery, GetInvoicesByCampaignQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetInvoicesByCampaignQuery, GetInvoicesByCampaignQueryVariables>(GetInvoicesByCampaignDocument, options);
        }
export type GetInvoicesByCampaignQueryHookResult = ReturnType<typeof useGetInvoicesByCampaignQuery>;
export type GetInvoicesByCampaignLazyQueryHookResult = ReturnType<typeof useGetInvoicesByCampaignLazyQuery>;
export type GetInvoicesByCampaignSuspenseQueryHookResult = ReturnType<typeof useGetInvoicesByCampaignSuspenseQuery>;
export type GetInvoicesByCampaignQueryResult = Apollo.QueryResult<GetInvoicesByCampaignQuery, GetInvoicesByCampaignQueryVariables>;
export const GetInvoicesDocument = gql`
    query getInvoices {
  getInvoices
}
    `;

/**
 * __useGetInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
      }
export function useGetInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
        }
export function useGetInvoicesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
        }
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>;
export type GetInvoicesLazyQueryHookResult = ReturnType<typeof useGetInvoicesLazyQuery>;
export type GetInvoicesSuspenseQueryHookResult = ReturnType<typeof useGetInvoicesSuspenseQuery>;
export type GetInvoicesQueryResult = Apollo.QueryResult<GetInvoicesQuery, GetInvoicesQueryVariables>;