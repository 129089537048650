export type Payee = Components.Schemas.Payee & {
  partnerId: string;
  payeeId: number;
  createdAt: string;
};

export const getPayeeInitials = (payeeName: string) => {
  const [name1, name2] = payeeName.split(" ");
  return (name1.substring(0, 1) + (name2?.substring(0, 1) || "")).toUpperCase();
};
