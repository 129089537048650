import { ReactComponent as ExternalLink } from "assets/tailwind/icons/external-link.svg";
import clsx from "clsx";
import { AnchorHTMLAttributes, PropsWithChildren, useMemo } from "react";
import { twMerge } from "tailwind-merge";

type MonetLinkProps = PropsWithChildren &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href"> & {
    href: string;
    showIcon?: boolean;
  };
const MonetLink: React.FC<MonetLinkProps> = ({ children, showIcon, className, href, ...props }) => {
  const link = useMemo(() => {
    if (!href.startsWith("https://") && !href.startsWith("http://")) {
      return "https://" + href;
    } else {
      return href;
    }
  }, [href]);

  return (
    <a
      {...props}
      className={twMerge(
        clsx(
          "inline-flex flex-row items-center gap-1 text-inherit break-all hover:underline hover:decoration-solid disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:decoration-solid",
          className,
        ),
      )}
      href={link}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
      {showIcon && <ExternalLink width={14} height={14} style={{ minWidth: 14, minHeight: 14 }} />}
    </a>
  );
};

export default MonetLink;
