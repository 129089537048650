import { ReactComponent as SignOutIcon } from "assets/tailwind/icons/log-out.svg";
import { ReactComponent as UserXIcon } from "assets/tailwind/icons/user-x.svg";
import { ReactComponent as UserIcon } from "assets/tailwind/icons/user.svg";
import MonetAccordion from "components/tailwind/MonetAccordion";
import MonetButton from "components/tailwind/MonetButton";
import MonetDropdownMenuItem from "components/tailwind/MonetDropdownMenuItem";
import TailwindDropdown from "components/tailwind/headlessTailwind/TailwindDropdown";
import { useSessionProvider } from "contexts/SessionProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_PATH } from "routing/paths";
import { Partner } from "utils/Partner";
import { isInPartnerContext } from "utils/User";
import { ORGANISATION_PATH, ORGANISATION_PROFILE_PATH } from "views/organisation/OrganisationPath";
import { USER_PATH, USER_PROFILE_PATH } from "views/user/UserPath";
import { useGetPartnersQuery } from "./graphql/query.generated";

const AccountDropdown = () => {
  const [selectedPartner, setSelectedPartner] = useState<Partner>();
  const [partners, setPartners] = useState<Partner[]>();
  const navigate = useNavigate();
  const { user, handleImpersonation, handleSignOut } = useSessionProvider();
  const { data: partnersData } = useGetPartnersQuery({ skip: !user.isAdministrator });

  const { partner } = useSessionProvider();

  useEffect(() => {
    if (partnersData) {
      //Data returned will always be at index [0] in the object
      const partnersList = [...(JSON.parse(Object.values(partnersData)[0] as string) as Partner[])];
      setPartners(partnersList);
    }
  }, [partnersData]);

  const selectPartner = (partnerId: string) => {
    setSelectedPartner(partners!.find((x) => x.partnerId === partnerId));
  };

  const handleImpersonate = useCallback(
    async (exitImpersonation?: boolean) => {
      if (!exitImpersonation) {
        if (selectedPartner) {
          await handleImpersonation(selectedPartner!.partnerId);
        }
      } else {
        await handleImpersonation();
      }

      navigate(DASHBOARD_PATH);
    },
    [selectedPartner],
  );

  const partnerItems = useMemo(() => {
    if (partners) {
      return partners
        .filter((x) => x.partnerId !== partner?.partnerId)
        .map((partner) => (
          <MonetDropdownMenuItem key={partner.name} onClick={() => selectPartner(partner.partnerId)} closeMenu={false}>
            {partner.name}
          </MonetDropdownMenuItem>
        ));
    }
    return null;
  }, [partners, partner]);

  const organisationItem = useMemo(() => {
    if (user && isInPartnerContext(user)) {
      return <MonetDropdownMenuItem onClick={() => navigate(ORGANISATION_PATH + ORGANISATION_PROFILE_PATH)}>Organisation</MonetDropdownMenuItem>;
    } else return null;
  }, [user]);

  const dropdownFooter = useMemo(() => {
    const elements = [
      <MonetDropdownMenuItem key="sign-out" variant="info" onClick={handleSignOut} icon={<SignOutIcon />}>
        Sign out
      </MonetDropdownMenuItem>,
    ];
    if (user.isImpersonating) {
      elements.push(
        <MonetDropdownMenuItem key="exit-impersonation" variant="info" onClick={() => handleImpersonate(true)} icon={<UserXIcon />}>
          Exit impersonation
        </MonetDropdownMenuItem>,
      );
    }
    return elements.reverse();
  }, []);

  return (
    <TailwindDropdown
      className="w-[300px]"
      hideFooter={Boolean(selectedPartner)}
      menuButtonProps={{
        size: "x-small",
        variant: "white",
        children: <UserIcon />,
      }}
      footerChildren={dropdownFooter}
      onCloseCallback={() => setSelectedPartner(undefined)}
    >
      {!selectedPartner ? (
        <>
          <MonetDropdownMenuItem onClick={() => navigate(USER_PATH + USER_PROFILE_PATH)}>Profile</MonetDropdownMenuItem>
          {organisationItem}
          {user.isAdministrator && (
            <div className="hs-accordion-group">
              <MonetAccordion label={user.isImpersonating ? "Switch impersonation" : "Impersonate"} id="impersonate">
                <div className="max-h-[250px] overflow-y-auto">{partnerItems}</div>
              </MonetAccordion>
            </div>
          )}
        </>
      ) : (
        <div className="flex flex-col">
          <p className="text-sm text-neutral-800 mb-4 dark:text-white">
            Confirm impersonation as: <span className="font-semibold">{selectedPartner.name}</span>
          </p>
          <MonetButton variant="outlined" size="small" className="mb-2" onClick={() => setSelectedPartner(undefined)}>
            Cancel
          </MonetButton>
          <MonetButton variant="solid" size="small" onClick={() => handleImpersonate()}>
            Confirm
          </MonetButton>
        </div>
      )}
    </TailwindDropdown>
  );
};

export default AccountDropdown;
