import { DialogPanel } from "@headlessui/react";
import { PropsWithChildren } from "react";

type TailwindDrawerProps = PropsWithChildren & {
  title: string;
  subtitle?: string;
};

const TailwindDrawer: React.FC<TailwindDrawerProps> = ({ children, title, subtitle }) => {
  return (
    <div className="fixed inset-0 overflow-hidden">
      <div className="absolute inset-0 overflow-hidden">
        <div className="pointer-events-none fixed inset-y-0 right-0 flex pl-1 w-full max-w-xs sm:max-w-[450px]">
          <DialogPanel transition className="pointer-events-auto relative w-screen max-w-md transform transition duration-300 ease-in-out data-[closed]:translate-x-full">
            <div className="flex flex-col p-4 sm:py-6 h-full justify-between overflow-y-auto bg-white border-s dark:bg-neutral-800 dark:border-neutral-700">
              <div className="flex flex-col flex-1 gap-4">
                <div>
                  <h3 className="font-normal text-2xl text-gray-800 dark:text-white">{title}</h3>
                  <p className="text-gray-800 text-sm font-normal dark:text-neutral-400">{subtitle}</p>
                </div>
                <div className="flex flex-col flex-1">{children}</div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </div>
  );
};

export default TailwindDrawer;
