import { yupResolver } from "@hookform/resolvers/yup";
import MonetCard from "components/tailwind/MonetCard";
import MonetCardTitle from "components/tailwind/MonetCardTitle";
import MonetEditSaveButton from "components/tailwind/MonetEditSaveButton";
import MonetInput from "components/tailwind/form/MonetInput";
import TailwindSelectInput from "components/tailwind/headlessTailwind/TailwindSelectInput";
import { useSessionProvider } from "contexts/SessionProvider";
import getSymbolFromCurrency from "currency-symbol-map";
import { useCallback, useMemo, useState } from "react";
import { Controller, FormProvider, Resolver, useForm } from "react-hook-form";
import { toast } from "sonner";
import { getTurnoversForInput, industries } from "utils/Partner";
import { getAllCountries } from "utils/country";
import { getCurrencies } from "utils/currency";
import { useUpdatePartnerBusinessMetadataMutation } from "views/organisation/graphql/mutations.generated";
import { OrganisationProfileDetailsFormObject, organisationProfileDetailsSchema } from "../../../ValidationSchemas/editOrganisationProfileSchema";
import MonetRow from "components/tailwind/MonetRow";
import StatusBadge from "components/tailwind/StatusBadge";

const OrganisationProfileSection = () => {
  const [editable, setEditable] = useState(false);
  const { partner, refetchCurrentPartner } = useSessionProvider();

  const [updateMetadata] = useUpdatePartnerBusinessMetadataMutation();

  const onSubmit = async (formData: OrganisationProfileDetailsFormObject) => {
    try {
      await updateMetadata({
        variables: {
          partnerBusinessMetadata: {
            ...formData,
            functionalCurrencies: formData.functionalCurrencies.filter((item) => item !== "-"),
          },
        },
      });
      toast.success("Success", { description: "Organisation profile has been updated" });
      location.reload();
    } catch (error) {
      toast.error("Request failed", { description: "Error updating organisation profile. Please try again" });
    } finally {
      toggleEdit();
    }
  };

  const methods = useForm<OrganisationProfileDetailsFormObject>({
    mode: "onTouched",
    resolver: yupResolver(organisationProfileDetailsSchema) as Resolver<OrganisationProfileDetailsFormObject>,
    defaultValues: {
      name: partner?.name,
      countryOfRegistration: partner?.businessMetadata?.countryOfRegistration,
      turnover: partner?.businessMetadata?.turnover,
      companyWebsite: partner?.businessMetadata?.companyWebsite,
      functionalCurrencies: partner?.businessMetadata?.functionalCurrencies,
      industry: partner?.businessMetadata?.industry,
      address: {
        addressLine1: partner?.address?.addressLine1,
        city: partner?.address?.city,
        postcode: partner?.address?.postcode,
      },
    },
  });

  const toggleEdit = useCallback(() => {
    if (editable) {
      methods.reset();
    }
    setEditable(!editable);
  }, [editable]);

  const country = methods.watch("countryOfRegistration");

  const turnoverOptions = useMemo(() => {
    if (country) {
      return getTurnoversForInput(country);
    } else return undefined;
  }, [country]);

  const transactionCurrencies = useMemo(() => {
    return getCurrencies.map((item) => ({
      label: `${item.currency} (${getSymbolFromCurrency(item.currency)})`,
      value: item.currency,
    }));
  }, []);

  const concatAddress = useMemo(() => {
    if (partner?.address) {
      const { addressLine1, addressLine2, city, postcode } = partner.address;
      return [addressLine1, addressLine2, city, postcode].filter(Boolean).join(", ");
    } else return "";
  }, [partner]);

  return (
    <MonetCard className="col-span-10 md:col-span-7 overflow-visible">
      <FormProvider {...methods}>
        <form className="flex flex-col gap-4" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-row justify-between gap-4 mb-4">
            <MonetCardTitle>Profile</MonetCardTitle>
            <MonetEditSaveButton
              showSaveButton={editable}
              onEditCallBack={toggleEdit}
              onCancelCallback={toggleEdit}
              saveButtonDisabled={!methods.formState.isValid}
              loading={methods.formState.isSubmitting}
            />
          </div>
          <MonetRow>
            <div className="w-full max-w-[450px]">
              <p className="font-semibold">Profile status</p>
            </div>
            <StatusBadge type="partner" text={partner?.kybStatus} />
          </MonetRow>
          <MonetRow>
            <div className="w-full max-w-[450px]">
              <p className="font-semibold">Company Name</p>
            </div>
            <MonetInput
              {...methods.register("name")}
              id="name"
              error={methods.formState.errors.name?.message as string}
              disabled={methods.formState.isSubmitting}
              readOnly={!editable}
            />
          </MonetRow>
          <MonetRow>
            <div className="w-full max-w-[450px]">
              <p className="font-semibold">Company website</p>
            </div>
            <MonetInput
              {...methods.register("companyWebsite")}
              id="companyWebsite"
              error={methods.formState.errors.companyWebsite?.message as string}
              disabled={methods.formState.isSubmitting}
              readOnly={!editable}
            />
          </MonetRow>
          <MonetRow>
            <div className="w-full max-w-[450px]">
              <p className="font-semibold">Country of registration</p>
            </div>
            <Controller
              control={methods.control}
              name="countryOfRegistration"
              render={({ field: { ...inputProps } }) => (
                <TailwindSelectInput
                  inputProps={inputProps}
                  options={getAllCountries()}
                  placeholder="Please select..."
                  error={methods.formState.errors.countryOfRegistration?.message as string}
                  disabled={methods.formState.isSubmitting || !editable}
                />
              )}
            />
          </MonetRow>
          <MonetRow>
            <div className="w-full max-w-[450px]">
              <p className="font-semibold">Industry</p>
            </div>
            <Controller
              control={methods.control}
              name="industry"
              render={({ field: { ...inputProps } }) => (
                <TailwindSelectInput
                  inputProps={inputProps}
                  placeholder="Please select..."
                  options={industries.map((x) => ({ label: x, value: x }))}
                  error={methods.formState.errors.industry?.message as string}
                  disabled={methods.formState.isSubmitting || !editable}
                />
              )}
            />
          </MonetRow>
          <MonetRow>
            <div className="w-full max-w-[450px]">
              <p className="font-semibold">Turnover</p>
            </div>
            <Controller
              control={methods.control}
              name="turnover"
              render={({ field: { ...inputProps } }) => (
                <TailwindSelectInput
                  inputProps={inputProps}
                  placeholder="Please select..."
                  options={turnoverOptions || []}
                  error={methods.formState.errors.turnover?.message as string}
                  disabled={methods.formState.isSubmitting || !editable || !country}
                />
              )}
            />
          </MonetRow>
          <MonetRow>
            <div className="w-full max-w-[450px]">
              <p className="font-semibold">Transaction currencies</p>
              <p>The currencies that {partner?.name} use in daily operations</p>
            </div>
            <Controller
              control={methods.control}
              name="functionalCurrencies"
              render={({ field: { ...inputProps } }) => (
                <TailwindSelectInput
                  inputProps={inputProps}
                  multiple={true}
                  placeholder="Please select..."
                  tooltip={`The currencies that ${partner?.name} use in daily operations`}
                  options={transactionCurrencies}
                  error={methods.formState.errors.functionalCurrencies?.message as string}
                  disabled={methods.formState.isSubmitting || !editable}
                />
              )}
            />
          </MonetRow>
          <MonetRow className={editable ? "sm:items-start" : undefined}>
            <div className="w-full max-w-[450px]">
              <p className="font-semibold">Company address</p>
            </div>
            {editable ? (
              <div className="flex flex-col gap-4 w-full">
                <MonetInput
                  {...methods.register("address.addressLine1")}
                  id="address.addressLine1"
                  placeholder="Address line 1"
                  error={methods.formState.errors.address?.addressLine1?.message as string}
                  disabled={methods.formState.isSubmitting}
                />
                <MonetInput
                  {...methods.register("address.addressLine2")}
                  id="address.addressLine2"
                  placeholder="Address line 2"
                  error={methods.formState.errors.address?.addressLine2?.message as string}
                  disabled={methods.formState.isSubmitting}
                />
                <MonetInput
                  {...methods.register("address.city")}
                  placeholder="City"
                  error={methods.formState.errors.address?.city?.message as string}
                  disabled={methods.formState.isSubmitting}
                />
                <MonetInput
                  {...methods.register("address.postcode")}
                  id="address.postcode"
                  placeholder="Postcode"
                  error={methods.formState.errors.address?.postcode?.message as string}
                  disabled={methods.formState.isSubmitting}
                />
              </div>
            ) : (
              <MonetInput value={concatAddress} readOnly={true} />
            )}
          </MonetRow>
          <MonetRow>
            <div className="w-full max-w-[450px]">
              <p className="font-semibold">MONET ID</p>
              <p>{partner?.name}'s unique platform ID</p>
            </div>
            <MonetInput
              value={partner?.partnerId}
              disabled={true}
            />
          </MonetRow>
        </form>
      </FormProvider>
    </MonetCard>
  );
};

export default OrganisationProfileSection;
