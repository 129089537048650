import { Box, styled, SxProps, Typography } from "@mui/material";
import React, { ReactElement } from "react";

type ItemBoxProps = {
  title?: string;
  value?: string | number | ReactElement;
  sx?: SxProps;
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(3),
  overflow: "hidden",
  overflowWrap: "anywhere",

  "&:last-child": {
    marginBottom: 0,
  },

  [theme.breakpoints.up("md")]: {
    marginBottom: theme.spacing(5),
  },
}));

const ItemBox: React.FC<ItemBoxProps> = ({ title, value, sx }) => {
  return (
    <StyledBox sx={{ ...sx }}>
      {title && (
        <Typography color="text.secondary" data-testid="itemBox-title">
          {title}
        </Typography>
      )}
      {value && (
        <Typography variant="body1Large" data-testid="itemBox-value">
          {value}
        </Typography>
      )}
    </StyledBox>
  );
};

export default ItemBox;
