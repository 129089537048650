import dayjs from "dayjs";

export type EP3User = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  isAdministrator: boolean;
  isImpersonating: boolean;
  isAuthenticated: boolean;
  role: string;
  partner: string;
  mfaEnabled?: boolean;
  groups: string[];
};

export const AnonymousEP3User: EP3User = {
  email: "",
  firstName: "John",
  lastName: "Doe",
  isAdministrator: false,
  isImpersonating: false,
  isAuthenticated: false,
  groups: [],
  role: "Anonymous",
  phoneNumber: "",
  partner: "",
};

export type ImpersonationPersistentContext = {
  partnerId: string;

  /** unix timestamp in seconds */
  expiryTime: number;
};

export const saveImpersonationCtx = async (partnerId: string): Promise<void> => {
  const context: ImpersonationPersistentContext = {
    partnerId: partnerId,
    expiryTime: dayjs().add(6, "hour").unix(),
  };
  localStorage.setItem("impCtx", JSON.stringify(context));
};

export const loadImpersonationCtx = (): ImpersonationPersistentContext | undefined => {
  const contextString = localStorage.getItem("impCtx");
  if (contextString) {
    const context = JSON.parse(contextString) as ImpersonationPersistentContext;
    return context.expiryTime >= dayjs().unix() ? context : undefined;
  }
  return undefined;
};

export const removeImpersonationCtx = async (): Promise<void> => {
  localStorage.removeItem("impCtx");
};

/**
 * Checks if a user is allowed to access a resource
 * @param user EP3User
 * @param isAdminViewOnly boolean
 * @returns boolean
 */
export const restrictUserAccess = (user: EP3User, isAdminAccessOnly?: boolean): boolean => {
  if (isAdminAccessOnly) {
    return !user.isAdministrator || user.isImpersonating;
  }
  return user.isAdministrator && !user.isImpersonating;
};

export const isInAdminContext = (user: EP3User): boolean => {
  return user.isAdministrator && !user.isImpersonating;
};

export const isInPartnerContext = (user: EP3User): boolean => {
  return user.isImpersonating || !user.isAdministrator;
};

export const isMonetAdminImpersonating = (user: EP3User): boolean => {
  if (user.isAdministrator && !user.isImpersonating && user.partner !== "MONET_ADMIN") {
    return false;
  }
  return true;
};

/**
 * Checks if the current user has access to a resource
 * @param allowedList UserGroups[] | undefined
 * @param blockedList UserGroups[] | undefined
 * @returns boolean
 */
export const hasAccess = (user: EP3User, allowedList?: string[], blockedList?: string[]) => {
  if (blockedList) {
    if (blockedList.length && blockedList.some((item) => user.groups.includes(item))) {
      return false;
    }
  } else {
    return false;
  }

  if (allowedList) {
    if (allowedList.length) {
      return allowedList.some((item) => user.groups.includes(item));
    }
  }
  return true;
};
