import { Money, MoneyStorage, formatStorageType } from "@monet-money/money-type";
import { Percentage } from "@monet-money/percentage-type";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { DEFAULT_DATE_FORMAT } from "shared/logic/calculate";
import { SYSTEM_ALLOWED_CURRENCIES } from "shared/utils/currency";
import { invoiceFundingStatuses, invoiceStatuses } from "shared/utils/invoice";

type InvoiceData = {
  rowId: string;
  partnerId: string;
  campaignId: string;
  campaignName: string;
  clientName: string;
  brandPromoted: string;
  invoiceId: string;
  invoiceNumber: string;
  invoiceStatus: string;
  fundingStatus: string;
  dueDate: string;
  daysUntilDue: number;
  invoiceCurrency: string;
  invoiceValue: MoneyStorage;
  advanceLimit: MoneyStorage;
  advanceRate: number;
  fundingRequestedBy: string;
  fundingRequestedDate: string;
  fundingProcessedBy: string;
  fundingProcessedDate: string;
  experianRiskScore: string;
  wiserfundingRiskScore: string;
  createdAt: string;
};

export const invoiceSummaryCols: GridColDef<InvoiceData>[] = [
  {
    field: "campaignName",
    headerName: "Campaign name",
    type: "string",
    width: 200,
  },
  {
    field: "clientName",
    headerName: "Client name",
    type: "string",
    minWidth: 200,
  },
  {
    field: "brandPromoted",
    headerName: "Brand promoted",
    type: "string",
    minWidth: 200,
  },
  {
    field: "invoiceNumber",
    headerName: "Invoice number",
    type: "string",
    minWidth: 150,
  },
  {
    field: "invoiceStatus",
    headerName: "Invoice status",
    type: "singleSelect",
    valueOptions: invoiceStatuses,
    minWidth: 150,
  },
  {
    field: "fundingStatus",
    headerName: "Funding status",
    type: "singleSelect",
    valueOptions: invoiceFundingStatuses,
    minWidth: 150,
  },
  {
    field: "dueDate",
    headerName: "Due date",
    type: "string",
    minWidth: 150,
    valueGetter: (value, row) => (value ? dayjs(value, DEFAULT_DATE_FORMAT).toDate() : undefined),
    valueFormatter: (value, row) => (value ? dayjs(value).format(DEFAULT_DATE_FORMAT) : undefined),
  },
  {
    field: "daysUntilDue",
    headerName: "Days until due",
    type: "string",
    minWidth: 150,
  },
  {
    field: "invoiceCurrency",
    headerName: "Invoice currency",
    type: "singleSelect",
    valueOptions: SYSTEM_ALLOWED_CURRENCIES,
    minWidth: 150,
  },
  {
    field: "invoiceValue",
    headerName: "Invoice value",
    type: "number",
    minWidth: 150,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return formatStorageType(params.row.invoiceValue as MoneyStorage);
    },
  },
  {
    field: "advanceLimit",
    headerName: "Advance Limit",
    type: "number",
    minWidth: 150,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return formatStorageType(params.row.advanceLimit as MoneyStorage);
    },
  },
  {
    field: "advanceRate",
    headerName: "Advance rate",
    type: "number",
    minWidth: 150,
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return params.row.advanceRate ? Percentage.fromStorageValue(params.row.advanceRate).format() : undefined;
    },
  },
  {
    field: "fundingRequestedBy",
    headerName: "Funding requested by",
    type: "string",
    minWidth: 200,
  },
  {
    field: "fundingRequestedDate",
    headerName: "Funding requested date",
    type: "date",
    minWidth: 200,
    valueGetter: (value, row) => dayjs(value, DEFAULT_DATE_FORMAT).toDate(),
    valueFormatter: (value, row) => dayjs(value).format(DEFAULT_DATE_FORMAT),
  },
  {
    field: "fundingProcessedBy",
    headerName: "Funding processed by",
    type: "string",
    minWidth: 200,
  },
  {
    field: "fundingProcessedDate",
    headerName: "Funding processed date",
    type: "date",
    minWidth: 200,
    valueGetter: (value, row) => dayjs(value, DEFAULT_DATE_FORMAT).toDate(),
    valueFormatter: (value, row) => dayjs(value).format(DEFAULT_DATE_FORMAT),
  },
  {
    field: "experianRiskScore",
    headerName: "Experian risk score",
    type: "string",
    minWidth: 200,
  },
  {
    field: "wiserfundingRiskScore",
    headerName: "Wiserfunding risk score",
    type: "string",
    minWidth: 200,
  },
  {
    field: "partnerId",
    headerName: "Partner ID",
    type: "string",
    width: 200,
  },
  {
    field: "campaignId",
    headerName: "Campaign ID",
    type: "string",
    width: 200,
  },
  {
    field: "invoiceId",
    headerName: "Invoice ID",
    type: "string",
    width: 150,
  },
  {
    field: "createdAt",
    headerName: "Created at",
    type: "date",
    minWidth: 200,
    valueGetter: (value, row) => (value ? dayjs(value, DEFAULT_DATE_FORMAT).toDate() : undefined),
    valueFormatter: (value, row) => (value ? dayjs(value).format(DEFAULT_DATE_FORMAT) : undefined),
  },
];
