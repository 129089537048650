import { Money, MoneyStorage, formatStorageType } from "@monet-money/money-type";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { DEFAULT_DATE_FORMAT } from "shared/logic/calculate";
import { SYSTEM_ALLOWED_CURRENCIES } from "shared/utils/currency";
import { PAYOUT_TYPES_LIST, payoutStatuses } from "utils/Payouts";

type PayoutData = {
  rowId: string;
  partnerId: string;
  campaignId: string;
  campaignName: string;
  invoiceId: string;
  payoutId: number;
  payoutRef: string;
  payoutType: string;
  payoutStatus: string;
  payeeAlias: string;
  payeeEmail: string;
  payeeCurrency: string;
  payoutCurrency: string;
  payoutValue: MoneyStorage;
  daysFunded: number;
  monetFee: MoneyStorage;
  agencyFee: MoneyStorage;
  passFeeOn: boolean;
  earlyPayTotal: MoneyStorage;
  paymentStatus: string;
  paymentCreatedDate: string;
  paymentPaidDate: string;
  createdAt: string;
};

export const payoutSummaryCols: GridColDef<PayoutData>[] = [
  {
    field: "campaignName",
    headerName: "Campaign name",
    type: "string",
    minWidth: 200,
  },
  {
    field: "payoutType",
    headerName: "Payout type",
    type: "singleSelect",
    valueOptions: PAYOUT_TYPES_LIST,
    minWidth: 200,
  },
  {
    field: "payeeAlias",
    headerName: "Payee alias",
    type: "string",
    minWidth: 200,
  },
  {
    field: "payeeEmail",
    headerName: "Payee email",
    type: "string",
    minWidth: 300,
  },
  {
    field: "invoiceNumber",
    headerName: "Invoice Number",
    type: "string",
    minWidth: 200,
  },
  {
    field: "payoutStatus",
    headerName: "Status",
    type: "singleSelect",
    valueOptions: payoutStatuses,
    minWidth: 200,
  },
  {
    field: "payoutRef",
    headerName: "Payout Ref",
    type: "string",
    minWidth: 200,
  },
  {
    field: "payeeCurrency",
    headerName: "Payee currency",
    type: "string",
    minWidth: 200,
  },
  {
    field: "payoutCurrency",
    headerName: "Payout currency",
    type: "singleSelect",
    valueOptions: SYSTEM_ALLOWED_CURRENCIES,
    minWidth: 200,
  },
  {
    field: "payoutValue",
    headerName: "Payout value",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<PayoutData>) => {
      return formatStorageType(params.row.payoutValue as MoneyStorage);
    },
  },
  {
    field: "daysFunded",
    headerName: "Days funded",
    type: "number",
    minWidth: 200,
  },
  {
    field: "monetFee",
    headerName: "MONET fee",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<PayoutData>) => {
      return formatStorageType(params.row.monetFee, true);
    },
  },
  {
    field: "agencyFee",
    headerName: "Agency fee",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<PayoutData>) => {
      return formatStorageType(params.row.agencyFee, true);
    },
  },
  {
    field: "passFeeOn",
    headerName: "Pass fee onto payee",
    type: "boolean",
    minWidth: 200,
  },
  {
    field: "earlyPayTotal",
    headerName: "Payout total",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<PayoutData>) => {
      return formatStorageType(params.row.earlyPayTotal, true);
    },
  },
  {
    field: "paymentStatus",
    headerName: "Payment status",
    type: "singleSelect",
    valueOptions: ["PAYMENT_SUCCESSFUL", "PAYMENT_RECEIVED", "PAYMENT_PENDING", "PAYMENT_FAILED"],
    minWidth: 200,
  },
  {
    field: "paymentPaidDate",
    headerName: "Payment paid date",
    type: "date",
    minWidth: 200,
    valueGetter: (value, row) => (value ? dayjs(value, DEFAULT_DATE_FORMAT).toDate() : undefined),
    valueFormatter: (value, row) => (value ? dayjs(value).format(DEFAULT_DATE_FORMAT) : undefined),
  },
  {
    field: "partnerId",
    headerName: "Partner ID",
    type: "string",
    minWidth: 200,
  },
  {
    field: "payoutId",
    headerName: "Payout ID",
    type: "string",
    minWidth: 200,
  },
  {
    field: "campaignId",
    headerName: "Campaign ID",
    type: "string",
    minWidth: 200,
  },
  {
    field: "invoiceId",
    headerName: "Invoice ID",
    type: "string",
    minWidth: 200,
  },
  {
    field: "createdAt",
    headerName: "Payout created date",
    type: "date",
    minWidth: 200,
    valueGetter: (value, row) => (value ? dayjs(value, DEFAULT_DATE_FORMAT).toDate() : undefined),
    valueFormatter: (value, row) => (value ? dayjs(value).format(DEFAULT_DATE_FORMAT) : undefined),
  },
];
