import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";

const MonetDivider: React.FC<VariantProps<typeof variants>> = ({ margin }) => {
  return <hr className={twMerge(clsx(variants({ margin })))} />;
};

export default MonetDivider;

const variants = cva("border-gray-200 dark:border-white", {
  variants: {
    margin: {
      small: "my-2",
      default: "my-3",
      large: "my-4",
    },
  },
  defaultVariants: {
    margin: "default",
  },
});
