import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Box, Button, Drawer, DrawerProps, Stack, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";

type CustomDrawerProps = {
  title: string;
  subTitle?: string;
  hideButtons?: boolean;
  hideHeader?: boolean;
  onClose?(): void;
  submitButtonText: string;
  cancelButtonText?: string;
  submitButtonProps?: LoadingButtonProps;
} & PropsWithChildren &
  DrawerProps;
const CustomDrawer: React.FC<CustomDrawerProps> = ({
  title,
  hideButtons,
  hideHeader,
  subTitle,
  children,
  cancelButtonText = "Cancel",
  submitButtonText,
  submitButtonProps,
  onClose,
  ...props
}) => {
  return (
    <Drawer
      {...props}
      onClose={onClose}
      data-testid="app-customDrawer"
      PaperProps={{
        sx: {
          width: { md: 500, xs: "90%" },
          zIndex: 1,
        },
      }}
      anchor="right"
    >
      <Stack px={4} py={5} gap={5} justifyContent={hideHeader && hideButtons ? "center" : "space-between"} height="100%">
        {!hideHeader && (
          <Box>
            <Typography variant="h2" fontWeight="bold" textTransform="uppercase">
              {title}
            </Typography>
            <Typography color="text.secondary">{subTitle}</Typography>
          </Box>
        )}
        <Box
          maxHeight={{ md: 450, lg: 800 }}
          sx={{
            overflow: "auto",
          }}
        >
          {children}
        </Box>
        {!hideButtons && (
          <Box>
            <Stack direction={{ md: "row", xs: "column-reverse" }} justifyContent={onClose ? "space-between" : "center"} gap={2}>
              {onClose && (
                <Button variant="outlined" data-testid="drawer-cancelBtn" onClick={onClose} fullWidth>
                  {cancelButtonText}
                </Button>
              )}
              <LoadingButton
                color="error"
                data-testid="drawer-submitBtn"
                variant="contained"
                {...submitButtonProps}
                sx={{
                  textWrap: "nowrap",
                  ...submitButtonProps?.sx,
                }}
                fullWidth={!!onClose}
                loadingPosition="end"
              >
                {submitButtonText}
              </LoadingButton>
            </Stack>
          </Box>
        )}
      </Stack>
    </Drawer>
  );
};

export default CustomDrawer;
