import { VariantProps, cva } from "class-variance-authority";
import MonetInput from "components/tailwind/form/MonetInput";
import getSymbolFromCurrency from "currency-symbol-map";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import MonetInputWithCurrency from "./MonetInputWithCurrency";

type MonetMoneyInputProps = VariantProps<typeof variants> & {
  name: string;
  label?: string;
  currency?: string;
  allowedCurrencies?: string[];
  showCurrencyDropdown?: boolean;
  currencyInputName?: string;
  currencyInputDefaultValue?: string;
  disabled?: boolean;
};

const MonetMoneyInput: React.FC<MonetMoneyInputProps> = ({
  currency,
  name,
  label,
  showCurrencyDropdown,
  allowedCurrencies,
  currencyInputName,
  currencyInputDefaultValue,
  size,
  disabled,
}) => {
  const { control } = useFormContext();

  const input = showCurrencyDropdown ? MonetInputWithCurrency : MonetInput;

  const currencyDropdownProps = useMemo(() => {
    if (showCurrencyDropdown) {
      return {
        allowedCurrencies: allowedCurrencies,
        currencyInputName: currencyInputName,
        currencyInputDefaultValue: currencyInputDefaultValue,
      };
    } else return null;
  }, [showCurrencyDropdown]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, name }, formState: { errors, isSubmitting } }) => (
        <NumericFormat
          thousandSeparator
          decimalScale={2}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          customInput={input}
          label={label}
          disabled={isSubmitting || disabled}
          startAdornment={currency && getSymbolFromCurrency(currency)}
          error={errors?.[name]?.message as string}
          size={size}
          {...currencyDropdownProps}
        />
      )}
    />
  );
};

export default MonetMoneyInput;

const variants = cva(
  "block w-full pe-24 text-sm border rounded-lg disabled:opacity-70 disabled:pointer-events-none disabled:bg-gray-100 dark:bg-transparent dark:disabled:bg-transparent",
  {
    variants: {
      variant: {
        default:
          "border-gray-200 placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600",
      },
      size: {
        small: "py-2 px-3.5",
        default: "py-3.5 px-4 h-[50px]",
        large: "py-6 px-5",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);
