import { confirmSignIn, rememberDevice, signIn } from "aws-amplify/auth";
import MonetAlert, { MonetAlertProps } from "components/tailwind/MonetAlert";
import MonetButton from "components/tailwind/MonetButton";
import MonetAuthFooter from "components/tailwind/auth/MonetAuthFooter";
import MonetAuthTitle from "components/tailwind/auth/MonetAuthTitle";
import MonetCheckbox from "components/tailwind/form/MonetCheckbox";
import MonetPinInput from "components/tailwind/form/MonetPinInput";
import AuthViewLayout from "components/tailwind/templates/AuthViewLayout";
import { useSessionProvider } from "contexts/SessionProvider";
import dayjs from "dayjs";
import { HSPinInput } from "preline/preline";
import React, { useCallback, useMemo, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import { DASHBOARD_PATH } from "routing/paths";
import { toast } from "sonner";
import { MFA_TTL, UserMFAChallenge } from "utils/Auth";
import { AUTH_PATH, SIGN_IN_PATH } from "../AuthPath";

const MFAAuthentication: React.FC = () => {
  const [alert, setAlert] = useState<undefined | MonetAlertProps>();
  const [isVerifyingCode, setIsVerifyingCode] = useState(false);
  const [selectedRememberMe, setSelectedRememberMe] = useState(false);
  const { user, initialiseContext } = useSessionProvider();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { username, password, mfaMethod } = state as UserMFAChallenge;

  const { isRunning, minutes, seconds, restart } = useTimer({
    autoStart: true,
    expiryTimestamp: dayjs().add(MFA_TTL, "s").toDate(),
  });
  // return 2:00 instead of 2:0
  const fullSeconds = `0${seconds}`.slice(-2);

  const restartTimer = useCallback(() => {
    const newExpiryTimestamp = dayjs().add(MFA_TTL, "s");
    restart(newExpiryTimestamp.toDate());
  }, []);

  const resendButtonText = isRunning ? `Resend (available in ${minutes}:${fullSeconds})` : "Resend";

  const resendCode = async () => {
    if (isRunning) {
      return;
    }
    setAlert(undefined);
    try {
      await signIn({
        password,
        username,
      });
      restartTimer();
      toast.success("Success", { description: "New code sent" });
    } catch (err) {
      setAlert({
        title: "Resending code failed",
        message: "Please try again. If this problem continues try signing in again",
        error: err,
      });
    }
  };

  const handleRememberMeChange = () => {
    setSelectedRememberMe(!selectedRememberMe);
  };

  const handleRememberDevice = useCallback(async () => {
    if (selectedRememberMe) {
      try {
        await rememberDevice();
      } catch (err) {
        setAlert({
          title: "Cannot remember device",
          message: "We are unable to remember your device",
          error: err,
        });
      }
    }
  }, [selectedRememberMe]);

  const handleAuthentication = async () => {
    const el = HSPinInput.getInstance("#pin-input") as any;
    const code = (el.currentValue as Array<string>).join("");

    setIsVerifyingCode(true);
    try {
      await confirmSignIn({ challengeResponse: code })
        .then(async () => {
          await handleRememberDevice();
          await initialiseContext().then(() => {
            navigate(DASHBOARD_PATH);
          });
        })
        .finally(() => {
          setIsVerifyingCode(false);
        });
    } catch (err: Error | any) {
      setIsVerifyingCode(false);
      if (err.name === "UserNotConfirmedException") {
        setAlert({
          title: "Confirmation Step Incomplete",
          message: "You didn't finish the confirmation step when signing up, resend the code and confirm",
          error: err,
        });
      } else if (err.name === "PasswordResetRequiredException") {
        setAlert({
          title: "Your password has been reset",
          message: "Go back to the login in page and click 'Forgot password' to reset your password",
          error: err,
        });
      } else if (err.name === "NotAuthorizedException" || err.name === "CodeMismatchException" || err.name === "EmptyChallengeResponse") {
        setAlert({
          title: "Incorrect code",
          message: "Please enter a valid code",
          error: err,
        });
      } else if (err.name === "UserNotFoundException") {
        setAlert({
          title: "Invalid user",
          message: "Your username or email does not exist",
          error: err,
        });
      } else {
        setAlert({
          title: "Error",
          message: "Failed to authenticate",
          error: err,
        });
      }
    }
  };

  const mfaMessage = useMemo(() => {
    switch (mfaMethod) {
      case "CONFIRM_SIGN_IN_WITH_SMS_CODE":
        return "Please enter the code with have sent to your mobile number below";
      case "CONFIRM_SIGN_IN_WITH_TOTP_CODE":
        return "Please enter the code displayed on your authenticator app below";
      default:
        break;
    }
    return undefined;
  }, [user]);

  if (!user) {
    return <Navigate to={AUTH_PATH + SIGN_IN_PATH} />;
  }

  return (
    <AuthViewLayout helmet="2FA">
      <MonetAuthTitle subtitle={mfaMessage}>2-Step authentication</MonetAuthTitle>
      {alert && <MonetAlert variant="error" title={alert.title} message={alert.message} error={alert.error} />}
      <MonetPinInput />
      <MonetCheckbox label="Remember device" onClick={handleRememberMeChange} id="remember-me-checkbox" className=" self-start mt-1">
        Remembered devices will not need to authenticate for the next 30 days
      </MonetCheckbox>
      <MonetButton type="submit" className="w-full" onClick={handleAuthentication} loading={isVerifyingCode} disabled={isVerifyingCode}>
        Sign in
      </MonetButton>
      {mfaMethod === "CONFIRM_SIGN_IN_WITH_SMS_CODE" && (
        <MonetAuthFooter buttonText={resendButtonText} buttonProps={{ onClick: resendCode, disabled: isRunning }}>
          Didn’t receive the code?
        </MonetAuthFooter>
      )}
    </AuthViewLayout>
  );
};

export default MFAAuthentication;
