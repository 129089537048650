import CloseIcon from "@mui/icons-material/Close";
import { AlertTitle, IconButton, Stack, Typography } from "@mui/material";
import Alert, { AlertColor } from "@mui/material/Alert";

import { getEnvFormattedError } from "utils/error";

export type AlertProps = {
  title: string;
  severity: AlertColor;
  description?: string;
  error?: Error;
  handleClose?: () => void;
};
const AlertView: React.FC<AlertProps> = ({ title, severity, description, error, handleClose }) => {
  return (
    <Alert sx={{ mb: 2 }} severity={severity} data-testid="server-error">
      <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
        <AlertTitle data-testid="alertView-title" sx={{ mb: 0 }}>
          {title}
        </AlertTitle>

        {handleClose && (
          <IconButton
            sx={{
              padding: 0,
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Stack>
      {description && <Typography mt={1}>{description}</Typography>}
      {error && (
        <Typography component="pre" sx={{ textWrap: "wrap", overflowWrap: "anywhere" }}>
          {getEnvFormattedError(error)}
        </Typography>
      )}
    </Alert>
  );
};

export default AlertView;
