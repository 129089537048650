import { ReactComponent as PlusIcon } from "assets/tailwind/icons/plus.svg";
import CreatePayoutButton from "components/tailwind/CreatePayoutButton";
import MonetButton from "components/tailwind/MonetButton";
import MonetDropdownMenuItem from "components/tailwind/MonetDropdownMenuItem";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Campaign, FullPayout } from "shared/types/eep.contract.types";
import { restrictPlatformAccess } from "utils/Partner";
import { isInPartnerContext } from "utils/User";
import CreateCampaignDrawer from "views/campaigns/AddCampaign/CreateCampaignDrawer";
import { CAMPAIGNS_LIST_ALL_PATH, CAMPAIGNS_PATH } from "views/campaigns/CampaignsPath";
import { PAYOUTS_PATH, PAYOUT_CREATE, PAYOUT_LIST_ALL_PATH, PAYOUT_VENDOR_PATH, PAYOUT_WITHDRAWAL_PATH } from "views/payouts/PayoutPath";
import { useGetCampaignsQuery, useGetPayoutsQuery } from "../graphql/queries.generated";
import CampaignsTable from "./CampaignsTable";
import PayoutsTable from "./PayoutsTable";

const DashboardTables: React.FC = () => {
  const [payouts, setPayouts] = useState<FullPayout[]>([]);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);

  const { data: campaignsData, loading: isCampaignsLoading } = useGetCampaignsQuery({ fetchPolicy: "network-only" });
  const { data: payoutsData, loading: isPayoutsLoading } = useGetPayoutsQuery({ fetchPolicy: "network-only" });

  const navigate = useNavigate();
  const { user, partner } = useSessionProvider();
  const { openModal } = useModal();

  useEffect(() => {
    if (payoutsData && campaignsData) {
      setCampaigns(JSON.parse(campaignsData.getCampaigns));
      setPayouts(JSON.parse(payoutsData.getPayouts) as FullPayout[]);
    }
  }, [payoutsData, campaignsData]);

  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 gap-6 min-h-[400px]">
      <CampaignsTable
        campaigns={campaigns}
        isCampaignsLoading={isPayoutsLoading || isCampaignsLoading}
        actionItems={[
          {
            key: "create-campaign",
            visible: !restrictPlatformAccess(partner, user) && isInPartnerContext(user),
            isPrimaryAction: true,
            element: (
              <MonetButton size="x-small" onClick={() => openModal(CreateCampaignDrawer, {})} className="hidden sm:flex">
                <PlusIcon />
                Create campaign
              </MonetButton>
            ),
          },
          {
            key: "create-campaign-mobile",
            visible: !restrictPlatformAccess(partner, user) && isInPartnerContext(user),
            element: (
              <MonetDropdownMenuItem className="flex sm:hidden" onClick={() => openModal(CreateCampaignDrawer, {})}>
                Create campaign
              </MonetDropdownMenuItem>
            ),
          },
          {
            key: "view-all-campaigns",
            visible: !restrictPlatformAccess(partner, user),
            element: <MonetDropdownMenuItem onClick={() => navigate(CAMPAIGNS_PATH + CAMPAIGNS_LIST_ALL_PATH)}>View all campaigns</MonetDropdownMenuItem>,
          },
        ]}
      />
      <PayoutsTable
        payouts={payouts}
        isPayoutsLoading={isPayoutsLoading || isCampaignsLoading}
        campaigns={campaigns}
        actionItems={[
          {
            key: "create-payout",
            visible: !restrictPlatformAccess(partner, user) && isInPartnerContext(user),
            isPrimaryAction: true,
            element: <CreatePayoutButton />,
            hideInMobileMenu: true,
          },
          {
            key: "view-all-payouts",
            visible: !restrictPlatformAccess(partner, user),
            element: <MonetDropdownMenuItem onClick={() => navigate(PAYOUTS_PATH + PAYOUT_LIST_ALL_PATH)}>View all payouts</MonetDropdownMenuItem>,
          },
          {
            key: "withdraw-funds",
            visible: !restrictPlatformAccess(partner, user) && isInPartnerContext(user),
            element: (
              <MonetDropdownMenuItem className="block sm:hidden" onClick={() => navigate(PAYOUTS_PATH + PAYOUT_CREATE + PAYOUT_WITHDRAWAL_PATH)} subtext="Get invoices paid early">
                Withdraw funds
              </MonetDropdownMenuItem>
            ),
          },
          {
            key: "vendor-payout",
            visible: !restrictPlatformAccess(partner, user) && isInPartnerContext(user),
            element: (
              <MonetDropdownMenuItem className="block sm:hidden" onClick={() => navigate(PAYOUTS_PATH + PAYOUT_CREATE + PAYOUT_VENDOR_PATH)} subtext="Pay suppliers/talent early">
                Pay vendors
              </MonetDropdownMenuItem>
            ),
          },
        ]}
      />
    </div>
  );
};

export default DashboardTables;
