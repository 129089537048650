import { Box, styled, Tooltip, Typography } from "@mui/material";
import MoneyInput from "components/Forms/MoneyTextField";
import React from "react";

type MobileSubscriptionComponentProps = {
  removeFab: React.ReactElement;
  index: number;
  currency: Components.Schemas.Currency;
};

const MobileSubscriptionBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  postion: "relative",
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  filter: "brightness(0.97)",
  marginBottom: theme.spacing(2),
}));

const MobileSubscriptionComponent: React.FC<MobileSubscriptionComponentProps> = ({ removeFab, index, currency }) => {
  return (
    <MobileSubscriptionBox key={`subscription-${index}`}>
      <MoneyInput currency={currency} helperText="MONET Subscription per month" name={`feeRules.subscriptions[${index}].costPerMonth.amount`} />
      <Typography textAlign="center" m={1}>
        ── up to a <b>Credit Limit</b> of ──
      </Typography>
      <MoneyInput currency={currency} helperText="Credit limit" name={`feeRules.subscriptions[${index}].creditLimit.amount`} />
      <Tooltip title="Remove subscription" followCursor>
        {removeFab}
      </Tooltip>
    </MobileSubscriptionBox>
  );
};

export default MobileSubscriptionComponent;
